/** @file Este archivo provee utilidades varias para realizar
 * interacciones
 */

/**
 * retorna el primer ancestro/padre interactivo ('a', 'input' o 'button') del target del evento.
 *
 * Este metodo es útil para saber si debemos gatillar una interacción o
 * no hacer nada porque tenemos algún padre que realizara alguna acción.
 *
 */
export function getInteractiveAncestorTag(target) {
  const tags = 'a, input, button';
  return $(target).closest(tags);
}

/** Determina si un elemento está en el DOM
 *
 * @param {Node} element
 */
export function isInDom(element) {
  return document.body.contains(element);
}
