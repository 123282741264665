/** @file Método para llamar noty temporal  */

var template = `<div id="notify" class="noty_content w-100">
  <div class="noty_content__title d-flex justify-content-between">
    <div class="noty_content__tilte-icon d-flex">
      <span class="material-icons-outlined fa-spin">
        check_circle_outline
      </span>
      <h4 class="ml-3"><span class="noty-msg"></h4>
    </div>
  </div>
</div>
`;

var templateComplete = `<div>
    <div id="notify-complete" class="noty_content w-100">
      <div class="noty_content__title d-flex justify-content-between">
        <div class="noty_content__tilte-icon d-flex">
          <span class="material-icons-outlined first-icon">
          </span>
          <h4 class="ml-3 noty-title"></h4>
        </div>
        <span class="material-icons-outlined">
          close
        </span>
      </div>
      <div class="noty_content__body">
        <p><span class="noty-msg"></p>
      </div>
    </div>
  </div>
`;

/**
 *
 * @param {String} message
 * @param {Number} pause
 */
export async function notyAlert(message, pause) {
  const { default: Noty } = await import('noty');
  var $tmpl = $(template);
  $tmpl.find('.noty-msg').html(message);

  return new Noty({
    theme: 'bootstrap-v4',
    type: 'info',
    layout: 'topRight',
    text: $tmpl.html(),
    timeout: pause,
  }).show();
}

/**
 *
 * @param {String} title
 * @param {String} message
 * @param {Number} pause
 * @param {String} icon
 * @param {String} type
 */
export async function notyAlertComplete(title, message, pause, type) {
  const { default: Noty } = await import('noty');
  const iconType = {
    'success': 'check_circle_outline',
    'info': 'info',
    'warning': 'report_problem',
    'error': 'block',
  };
  var $tmpl = $(templateComplete);
  $tmpl.find('.noty-title').html(title);
  $tmpl.find('.noty-msg').html(message);
  $tmpl.find('.first-icon').html(iconType[type]);

  return new Noty({
    theme: 'bootstrap-v4',
    type: type,
    layout: 'topRight',
    text: $tmpl.html(),
    timeout: pause,
  }).show();
}
