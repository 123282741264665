/** @file TODO: documentar */
import onmount from 'onmount';
// Esto deberias invocarlo en algun metodo del archivo
import ckeditor from '../../../../../../app/assets/javascripts/components/mail-ckeditor';

// JS para reemplazar el objeto text_box por el ckeditor al montarse este y removerlo una vez
// se quita del DOM.
onmount('.operation_template_email_template', async function (obj) {
  // eslint-disable-next-line max-len
  const { loadCKeditor, CKeditorVersion, loadCKeditor5 } = await import('../../../../../../app/assets/javascripts/components/ckeditor/load');

  if (CKeditorVersion() === '4') {
    await loadCKeditor();
    obj.editor = ckeditor(this);
  }
  else {
    await loadCKeditor5(this, obj);
  }
}, function (obj) {
  obj.editor.destroy();
});
