/** @file TODO: documentar */
import onmount from 'onmount';

// Utilizado para poder desplazarse horizontalmente en las categorías de beneficios
// dentro de la vista Mis beneficios
onmount('#generic-categories-bar', function () {
  const scrollDuration = 300;
  const leftArrow = $(this).find('.generic-categories-bar-scrolls-left');
  const rightArrow = $(this).find('.generic-categories-bar-scrolls-right');
  const categoriesSection = $(this).find('.generic-categories');
  const itemsLength = $(this).find('.generic-category').length;
  const itemSize = 110;
  const myIntegrationSection = $('#myown').find('.row').width();

  // Corresponde al ancho disponible para mostrar los items
  const getCategoriesWrapperSize = () => categoriesSection.outerWidth();
  let categoriesWrapperSize = getCategoriesWrapperSize();
  // Para que sea responsivo
  $(window).on('resize', () => {
    categoriesWrapperSize = getCategoriesWrapperSize();
  });
  categoriesWrapperSize = categoriesWrapperSize === 0 ? myIntegrationSection : getCategoriesWrapperSize();
  // Ancho total a utilizar para todos los items
  const getTotalItemsSize = () => itemsLength * itemSize;
  const totalItemsSize = getTotalItemsSize();
  // Porción que es invisible
  const getInvisibleSize = () => totalItemsSize - categoriesWrapperSize;
  let invisibleSize = getInvisibleSize();
  // Oculta/Muestra las flechas de scroll dependiendo del tamaño disponible
  const showAndHideScroll = () => {
    if (invisibleSize > 0) {
      categoriesSection.css('justify-content', 'start');
      categoriesSection.css('width', '90%');
      leftArrow.removeClass('hidden');
      rightArrow.removeClass('hidden');
    }
    else {
      categoriesSection.removeAttr('style');
      leftArrow.addClass('hidden');
      rightArrow.addClass('hidden');
    }
  };
  showAndHideScroll();
  // Hace que las flechas tengan un comportamiento responsivo
  $(window).on('resize', () => {
    invisibleSize = getInvisibleSize();
    showAndHideScroll();
  });

  // Scroll a la derecha
  $(rightArrow).on('click', () => {
    categoriesSection.animate({
      scrollLeft: `+=${itemSize}`,
    }, scrollDuration);
  });
  // Scroll a la izquierda
  $(leftArrow).on('click', () => {
    categoriesSection.animate({
      scrollLeft: `-=${itemSize}`,
    }, scrollDuration);
  });
});

const doMarkAmplitude = (element, message, data) => {
  element.trigger('amplitudeTracking',
    {
      message: message,
      data: data,
    });
};

const selectFilterAll = (targets, data, selector) => {
  targets.hide().each(function () {
    var child = $(this).find(data.targetText);
    if (child.text() === selector.find('option:selected').text()) {
      $(this).show();
    }
    else if (selector.val() === '') {
      $(this).show();
    }
  });
};

const createAmplitudeMarker = (data, selector) => {
  const event = data.amplitudeEvent;
  const filterName = selector.val();
  data.amplitudeData['filter_name'] = filterName ? filterName : 'All';
  doMarkAmplitude(selector, event, data.amplitudeData);
};

onmount('#marketplace-solutions-section', function () {
  $(this).find('.nav-item a').on('click', () => {
    $(this).find('.nav-item a .badge').toggleClass('text-info');
    $(this).find('.nav-item a .badge').toggleClass('text-disabled');
  });

  $('.select_word_filter').on('change', function () {
    const data = $(this).data();
    const targets = $(data.targetContainer).find(data.targetNode);
    selectFilterAll(targets, data, $(this));
    if (data.amplitudeEvent) {
      createAmplitudeMarker(data, $(this));
    }
  });
});

onmount('#marketplace-integrations-section', function () {
  $(this).find('.nav-item a').on('click', () => {
    $(this).find('.nav-item a .badge').toggleClass('text-info');
    $(this).find('.nav-item a .badge').toggleClass('text-disabled');
  });

  $('.select_word_filter').on('change', function () {
    const data = $(this).data();
    const targets = $(data.targetContainer).find(data.targetNode);
    selectFilterAll(targets, data, $(this));
    if (data.amplitudeEvent) {
      createAmplitudeMarker(data, $(this));
    }
  });
});

$(document).on('click', '.bundle_starter_popover', function (event) {
  event.preventDefault();
  const targetDiv = document.querySelector(`div[data-id="${$(this).attr('data-id')}"]`);
  if (targetDiv) {
    targetDiv.scrollIntoView({ behavior: 'smooth' });
  }
});
