/** @file TODO: documentar */
import onmount from 'onmount';

onmount('#personals-index-table', function () {
  const table = $(this).DataTable();
  const tableInfo = table.page.info();
  const checkBoxDestroy = table.$("input[name^=destroy]");
  const checkBoxFinish = table.$("input[name^=finish]");
  const selectAllDestroy = $("#personals_select_all_destroy");
  const selectAllFinish = $("#personals_select_all_finish");
  const destroySelectedButton = $("#destroy_action");
  const finishSelectedButton = $("#finish_action");

  // Estado inicial para los botones 'destroy'
  if(!checkBoxDestroy.is(':checked')) {
    destroySelectedButton.prop('disabled', 'disabled');
  }
  // Estado inicial para los botones 'finish´
  if(!checkBoxFinish.is(':checked')) {
    finishSelectedButton.prop('disabled', 'disabled');
  }

  // selecciona un elemento en particular
  checkBoxDestroy.on('change', function () {
    if($(this).closest('tr').hasClass('selected danger')) {
      $(this).closest('tr').removeClass('selected danger');
    }
    else {
      $(this).closest('tr').addClass('selected danger');
    }
    toggleButtonPerPage();
    toggleButtonDestroy();
  });
  // selecciona un elemento en particular
  checkBoxFinish.on('change', function () {
    if($(this).closest('tr').hasClass('selected danger')) {
      $(this).closest('tr').removeClass('selected danger');
    }
    else {
      $(this).closest('tr').addClass('selected danger');
    }
    toggleButtonPerPage();
    toggleButtonFinish();
  });

  // selecciona toda la página
  selectAllDestroy.on('click', function () {
    const checked = this.checked;
    const checkBoxPerPage = $("input[name^=destroy]", table.rows({ page: 'current' }).nodes());
    if (checked) {
      checkBoxPerPage.prop('checked', 'checked').closest('tr').addClass('selected danger');
    }
    else {
      checkBoxPerPage.prop('checked', false).closest('tr').removeClass('selected danger');
    }
    toggleButtonDestroy();
  });

  // selecciona toda la página
  selectAllFinish.on('click', function () {
    const checked = this.checked;
    const checkBoxPerPage = $("input[name^=finish]", table.rows({ page: 'current' }).nodes());
    if (checked) {
      checkBoxPerPage.prop('checked', 'checked').closest('tr').addClass('selected danger');
    }
    else {
      checkBoxPerPage.prop('checked', false).closest('tr').removeClass('selected danger');
    }
    toggleButtonFinish();
  });

  // eliminar elementos seleccionados
  destroySelectedButton.on('click', function () {
    const checkboxValue = [];
    checkBoxDestroy.each(function () {
      const ischecked = $(this).is(":checked");
      if (ischecked) {
        checkboxValue.push($(this).val());
      }
    });

    // volver seleccionar página a estado inicial
    selectAllDestroy.prop('checked', false);
    $.ajax({
      url: $(this).data('url'),
      type: "delete",
      data: {personals_ids: checkboxValue}
    });
  });

  $(this).on('page.dt', function () {
    toggleButtonPerPage();
  });

  $(this).on('search.dt', function () {
    toggleButtonPerPage();
  });

  // finish bonos form
  $('#personals_finish_form').submit(function (e) {
    e.preventDefault();
    $(this).append(table.$("input[name^=finish]:checked").clone().attr('type', 'hidden'));
    this.submit();
  });

  //destroy_bonos_form
  $('#personals_destroy_form').submit(function (j) {
    j.preventDefault();
    $(this).append(table.$("input[name^=destroy]:checked").clone().attr('type', 'hidden'));
    this.submit();
  });

  function toggleButtonPerPage() {
    const checkBoxPerPage = $("input[name^=destroy]:checked", table.rows({ page: 'current' }).nodes());
    if(checkBoxPerPage.length === tableInfo.length) {
      selectAllDestroy.prop('checked', 'checked');
    }
    else {
      selectAllDestroy.prop('checked', false);
    }
  }

  function toggleButtonDestroy() {
    const checkBoxTotal = table.$("input[name^=destroy]:checked");
    if(checkBoxTotal.length > 0) {
      destroySelectedButton.prop('disabled', false);
    }
    else {
      destroySelectedButton.prop('disabled', 'disabled');
    }
  }

  function toggleButtonFinish() {
    const checkBoxTotal = table.$("input[name^=finish]:checked");
    if(checkBoxTotal.length > 0) {
      finishSelectedButton.prop('disabled', false);
    }
    else {
      finishSelectedButton.prop('disabled', 'disabled');
    }
  }

});
