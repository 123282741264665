/** @file
 * Archivo de marcas de amplitud para logged changes histories
 * No ocupamos el modulo de amplitud general ya que tenemos que preprocesar los inputs
 */
import onmount from 'onmount';

onmount('[data-amplitude="filter_changes_histories"]', async function () {
  const form = $(this);
  const { default: moment } = await import('moment');
  form.on('submit', () => {
    const data = {};
    const formData = form.serializeHash();
    const startDate = moment(formData['change_history[start_date]'], 'DD/MM/YYYY');
    const endDate = moment(formData['change_history[end_date]'], 'DD/MM/YYYY');
    const minDate = moment.min(startDate, endDate);
    const monthsBehind = moment().diff(minDate, 'months');
    // Formateamos la data para poder visualizar mejor en amplitude los rangos
    data['start_date'] = startDate.isValid() ? startDate.format('MM-YYYY') : 'undefined';
    data['end_date'] = endDate.isValid() ? endDate.format('MM-YYYY') : 'undefined';
    data['months_behind'] = monthsBehind ? monthsBehind : 'undefined';
    data['employee'] = !!formData['change_history[employee]'];
    data['modified_by'] = !!formData['change_history[modified_by]'];
    const entity = formData['change_history[entity]'][0];
    data['entity_filtered'] = entity ? entity : 'any';
    form.trigger('amplitudeTracking', { message: 'Filter_Changes_Histories', data: data });
  });
});
