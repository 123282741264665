/** @file relacionado a app/cells/job/colombia/pensionado_porcentaje_ccf/show.erb */

$('#job_tipo_contrato').on('change', function () {
  var div = $('.job_pensionado_porcentaje_ccf_colombia');
  if(this.value === 'Pensionado') {
    div.css('display', 'block');
  }
  else {
    div.css('display', 'none');
  }
});
