/** @file
* Archivo para aplicar la funcionalidad de filtro avanzado del feed
*/
import onmount, { $ } from 'onmount';

function setUpApplyBtn(from = '', to = '') {
  const $applyBtn = $('#btn-advanced-filter-apply');
  const url = $applyBtn.attr('ic-get-from');
  const [base, searchStr] = url.split('?');
  const search = new URLSearchParams(searchStr);

  search.set('from', from);
  search.set('to', to);
  $applyBtn.attr('ic-get-from', `${base}?${search}`);
  $applyBtn.attr('ic-src', `${base}?${search}`);
}

onmount('#advanced-filter-asidebar', function () {
  const dateSelectorFrom = $('#date_range_from');
  const dateSelectorTo = $('#date_range_to');
  const previousFrom = $('#filter-advanced').attr('date-from');
  const previousTo = $('#filter-advanced').attr('date-to');

  dateSelectorFrom.val(previousFrom);
  dateSelectorTo.val(previousTo);

  if (previousFrom !== '' || previousTo !== '') {
    setUpApplyBtn(previousFrom, previousTo);
    $('#btn-advanced-filter-apply').attr('disabled', false);
  }

  $('.feed-filter-range-input').on('change', function () {
    const $applyBtn = $('#btn-advanced-filter-apply');
    const from = dateSelectorFrom.val();
    const to = dateSelectorTo.val();

    setUpApplyBtn(from, to);

    if (from === '' && to === '')
      $applyBtn.attr('disabled', true);
    else
      $applyBtn.attr('disabled', false);
  });

  $(document).on('after.success.ic', function (evt) {
    const path = $(evt.target).attr('ic-get-from');
    if (!path.includes('/employee_portal/murals/publications')) return;
    const url = new URLSearchParams(path);
    if (url.get('content_type') || url.get('page') || url.get('page_size')) {
      const asideBase = $('.aside-base');
      if (asideBase.length) {
        $('.aside-base')[0].__vue__.close();
      }
      const hasDateFilter = url.get('from') || url.get('to');
      const $dateFilterItem = $('#filter-advanced');
      const $loadMoreBtn = $('#load-more-btn-wrapper .load-more button');
      const asyncPathBase = '/employee_portal/murals/advanced_filter';

      if (hasDateFilter) {
        $dateFilterItem.addClass('active');
        const dateFrom = url.get('from');
        const dateTo = url.get('to');
        const contentType = url.get('contentType');

        const asyncPathParameters = `?from=${dateFrom}&to=${dateTo}&content_type=${contentType}`;

        const dataOpenAsideBarContent = `
            <div data-render-async="${asyncPathBase + asyncPathParameters}">
              <div class="container-loader">
                <div class="buk-loader"></div>
              </div>
            </div>
          `;

        $dateFilterItem.attr('data-open-aside-bar', dataOpenAsideBarContent);
        $dateFilterItem.attr(
          'data-ic-deps',
          `${asyncPathBase + asyncPathParameters}`
        );

        $dateFilterItem.attr('date-from', dateFrom);
        $dateFilterItem.attr('date-to', dateTo);

        $('.portal-comunicaciones-filter__item').each(function () {
          const targetUrl = $(this).attr('ic-get-from');

          if (targetUrl !== undefined) {
            const [base, searchStr] = targetUrl.split('?');
            const search = new URLSearchParams(searchStr);

            search.set('from', dateFrom);
            search.set('to', dateTo);

            $(this).attr('ic-get-from', `${base}?${search}`);
            $(this).attr('ic-src', `${base}?${search}`);
          }
        });

        if ($loadMoreBtn !== undefined && $loadMoreBtn.length) {
          const loadMoreTargetUrl = $loadMoreBtn.attr('ic-get-from');
          const [base, searchStr] = loadMoreTargetUrl.split('?');
          const search = new URLSearchParams(searchStr);

          search.set('from', dateFrom);
          search.set('to', dateTo);

          $loadMoreBtn.attr('ic-get-from', `${base}?${search}`);
          $loadMoreBtn.attr('ic-src', `${base}?${search}`);
        }
      }
      else {
        $dateFilterItem.removeClass('active');

        const dataOpenAsideBarContent = `
            <div data-render-async="${asyncPathBase}">
              <div class="container-loader">
                <div class="buk-loader"></div>
              </div>
            </div>
          `;

        $dateFilterItem.attr('data-open-aside-bar', dataOpenAsideBarContent);
        $dateFilterItem.attr(
          'data-ic-deps',
          `${asyncPathBase}`
        );

        $dateFilterItem.attr('date-from', '');
        $dateFilterItem.attr('date-to', '');

        $('.portal-comunicaciones-filter__item').each(function () {
          const targetUrl = $(this).attr('ic-get-from');

          if (targetUrl !== undefined) {
            const [base, searchStr] = targetUrl.split('?');
            const search = new URLSearchParams(searchStr);

            search.delete('from');
            search.delete('to');

            $(this).attr('ic-get-from', `${base}?${search}`);
            $(this).attr('ic-src', `${base}?${search}`);
          }
        });

        if ($loadMoreBtn !== undefined && $loadMoreBtn.length) {
          const loadMoreTargetUrl = $loadMoreBtn.attr('ic-get-from');
          const [base, searchStr] = loadMoreTargetUrl.split('?');
          const search = new URLSearchParams(searchStr);

          search.delete('from');
          search.delete('to');

          $loadMoreBtn.attr('ic-get-from', `${base}?${search}`);
          $loadMoreBtn.attr('ic-src', `${base}?${search}`);
        }
      }
    }
  });
});
