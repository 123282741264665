/** @file Archivo utilizado para cambiar el texto de los tooltips */
import onmount from 'onmount';
import { periodStringtoDate } from '../../../../../../../app/assets/javascripts/lib/buk/format-date';

onmount('#select-filter', function () {
  changeTooltipMessage($(this).val());

  $('#select-filter').on('change', function () {
    changeTooltipMessage($(this).val());
  });
});

function changeTooltipMessage(date) {
  let message = 'Este es el total que ya has retirado con Buk Adelantos';
  if (date) {
    const dateObjetc = periodStringtoDate(date, '-');

    if (dateObjetc) {
      const mes = dateObjetc.toLocaleDateString('es-CL', { month: 'long' });
      const year = dateObjetc.toLocaleDateString('es-CL', { year: 'numeric' });

      message = `${message} en ${mes} del ${year}`;
    }
  }

  const card = document.querySelector('#card-historial-retiros-adelantos');
  card.dataset.originalTitle = message;
}
