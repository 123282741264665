/** @file TODO: documentar */
import onmount, { $ } from 'onmount';

function toggleRecaudadora(prefix) {
  const permitirAfcSinAfp = $(`#${prefix}_cotiza`).data('permitirAfcSinAfp');
  const planOption = $(`#${prefix}_cotiza`).val();
  const esTrabajadorExtranjero = $('#plan_tecnico_extranjero').is(':checked');
  const puedeIngresarRecaudadora = planOption === 'ips'
                                   || (permitirAfcSinAfp
                                   && (planOption === 'no_cotiza' || esTrabajadorExtranjero));
  if($(`#${prefix}_afc`).val() !== 'no_cotiza_afc' && puedeIngresarRecaudadora) {
    $(`#${prefix}_afp_recaudadora`).closest('.form-group').fadeIn();
    $(`#${prefix}_afp_recaudadora`).prop('disabled', false);
  }
  else {
    $(`#${prefix}_afp_recaudadora`).closest('.form-group').fadeOut();
    $(`#${prefix}_afp_recaudadora`).prop('disabled', true);
  }
}

onmount('#plan_afc,#plan_cotiza,#plan_afp,#plan_tecnico_extranjero', function () {
  $(this).change(() => toggleRecaudadora('plan'));
});
onmount('#alta_operation_afc,#alta_operation_cotiza,#alta_operation_afp', function () {
  $(this).change(() => toggleRecaudadora('alta_operation'));
});

function toggleSeguroComplementario() {
  $('#plan_plan_seguro_complementario').prop('disabled', !this.value);
  $('#plan_seguro_complementario_uf').prop('disabled', !this.value);
}

onmount('#plan_compania_seguro_complementario', function () {
  $(this).change(toggleSeguroComplementario);
  toggleSeguroComplementario();
});

const hiddenPrevisionOptions = (options) => {
  options.forEach(option => {
    $(`#tasa_${option}`).parent().parent().fadeOut();
    $(`#tasa_${option}`).prop('disabled', true);
    $(`#collapse-tasa-${option}`).collapse('hide');
  });
};

function toggleOpcionesPrevision() {
  const selectedOption = $('#plan_cotiza').val();
  const options = ['ips', 'afp'];
  if (options.includes(selectedOption)) {
    $(`#tasa_${selectedOption}`).parent().parent().fadeIn();
    $(`#tasa_${selectedOption}`).prop('disabled', false);
    hiddenPrevisionOptions(options.filter(opcion => opcion !== selectedOption));
  }
  else {
    hiddenPrevisionOptions(options);
  }
}

// abre opciones fondo de cesantia si se escoge IPS o no cotiza como régimen provisional
// ya que en estos casos es necesario seleccionar institución recaudadora
function showUnemploymentFundOptions() {
  const selectedOption = $('#plan_cotiza').val();
  if(selectedOption === 'ips' || selectedOption === 'no_cotiza') {
    $('#collapse-afc').collapse('show');
  }
}

onmount('#plan_cotiza', function () {
  $(this).change(showUnemploymentFundOptions);
  $(this).change(toggleOpcionesPrevision);
  showUnemploymentFundOptions();
  toggleOpcionesPrevision();
});

/**
 * Esta función lo que busca es enviar el valor del campo que se esta deshabilitando ya que actualmente los campos
 * marcados con `disabled` no se envian al backend en el formulario. Para esto se usa un campo oculto con nombre
 * original y se deshabilita el selector con otro nombre que no afecta en el backend.
 */
function showAPVOptions() {
  const extranjeroNoObligadoCotizarPension = $('#plan_extranjero_no_obligado_cotizar_pension');
  const apvColombiaOptions = $('#plan_apv_colombia_options');
  const afpRecaudadora = $('#plan_afp_recaudadora');
  if (extranjeroNoObligadoCotizarPension.is(':checked')) {
    apvColombiaOptions.prop('disabled', true);
    $('#plan_apv_colombia').val('false').trigger('change');
    afpRecaudadora.prop('disabled', true).trigger('change');
  }
  else {
    apvColombiaOptions.prop('disabled', false).trigger('change');
    afpRecaudadora.prop('disabled', false).trigger('change');
  }
}

/**
 * Se procura que el selector y el campo oculto tengan el mismo valor al enviar el formulario.
 */
onmount('#plan_apv_colombia_options', function () {
  $(this).change(() => $('#plan_apv_colombia').val(this.value).trigger('change'));
});

/**
 * Se deshabilita el selector de APV Colombia si el empleado extranjero no está obligado a cotizar pensión.
 */
onmount('#plan_extranjero_no_obligado_cotizar_pension', function () {
  $(this).change(showAPVOptions);
  showAPVOptions();
});
