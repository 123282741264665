/**
 * @file Permite autocompletar un input text que necesita ser una url
 * Modo de Uso:
 * usar data-autocomplete-url en el input que se use este comportamiento
 * @example
 * google.cl devuelve http://google.cl
 */

import onmount from 'onmount';

onmount('[data-autocomplete-url]', function () {
  $(this).on('blur', () => {
    const val = $(this).val();
    if (val && !/^https?:\/\//i.test(val)) {
      $(this).val(`http://${val}`);
    }
  });
});
