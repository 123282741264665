/** @file TODO: documentar */
import { warning, success } from '../../../../../../../app/assets/javascripts/lib/buk/alert.js';
import onmount from 'onmount';

onmount('.massive_assignment_component', function () {

  unbindEvents();

  const output = $($(this).data('outputSelector'));
  const renderPath = $(this).data('renderPath');
  const variableId = $(this).data('variableId');
  const canSendAll = $(this).data('sendAll');
  const advancedFilters = $('#advanced_filters').val();
  const paramVista = $('#param_vista').val();
  const paginate = $('#table_employee').data('ajax')?.data['paginate_table'];
  const processId = $('#process_id').val();
  const modelName = $('#model_name').val();

  const allSelectedIds = () => {
    const allIds = $('#all_selected_ids').val().split(',');
    return allIds;
  };

  function countEmployeesSelected() {
    if (paginate) {
      return Number($("a:contains('Participantes seleccionados')").text().match(/\d/)[0]);
    }
    else {
      return $('.massive-assignment-selected-employees').length;
    }
  }

  const Ids = (ids) => {
    if (countEmployeesSelected() > 0) {
      if (paginate) {
        ids = ids.concat(allSelectedIds());
      }
      else {
        const employeesSelectedCells = $('#table_employee_selected').DataTable().cells().nodes();
        $(employeesSelectedCells).find(':checkbox').each(function () {
          ids.push($(this).data('id'));
        });
      }
    }
    return ids;
  };

  // selecciona los empleados en el select oculto
  output.val(Ids([]));
  function countEmployeesSelectChecked() {
    let countChecked = 0;
    $('.massive-assignment-select-employees').each(function () {
      if(this.checked && $(this).closest('tr').is(':visible')) {
        countChecked = countChecked + 1;
      }
    });
    return countChecked;
  }

  if (canSendAll) {
    subscribeTableEmployeeChanges();
  }

  function allEmployeesCount() {
    if (paginate) {
      return $('#table_employee').DataTable().page.info().recordsDisplay;
    }
    return $('#table_employee').DataTable().rows({ search: 'applied' }).count();
  }

  function notFilterApplied() {
    if (paginate) { return true; }
    const tableEmployee = $('#table_employee').DataTable();
    return tableEmployee.rows({ search: 'applied' }).count() === tableEmployee.rows().count();
  }

  function countShowMassive() {
    const countChecked = countEmployeesSelectChecked();
    const labelTitle = canSendAll && !countChecked && notFilterApplied() ?
      `Agregar Todos (${allEmployeesCount()})` : `Agregar Selección (${countChecked})`;
    $('#massive-assignment-add-selected').find('span').html(labelTitle);
  }

  function sendAllCondition() {
    return canSendAll && !countEmployeesSelectChecked() && notFilterApplied();
  }

  $(document).on('click', '.massive-assignment-select-employees', function () {
    countShowMassive();
  });

  $(document).on('change', '.massive-assignment-select-all', function () {
    countShowMassive();
  });

  // Renueva el contador de participantes en cada actualización del datatable
  $('#table_employee').on('draw.dt', function () {
    countShowMassive();
  });

  function countEmployeesSelectedChecked() {
    let countChecked = 0;
    $('.massive-assignment-selected-employees').each(function () {
      if(this.checked && $(this).closest('tr').is(':visible')) {
        countChecked = countChecked + 1;
      }
    });
    return countChecked;
  }

  function countShowMassiveSelected() {
    const countChecked = countEmployeesSelectedChecked();
    $('#span-remove-selected').html('(' + countChecked + ')');
  }

  $(document).on('click', '.massive-assignment-selected-employees', function () {
    countShowMassiveSelected();
  });

  $(document).on('change', '.massive-assignment-selected-all', function () {
    countShowMassiveSelected();
  });

  function spinnerDisabledButtons() {
    const btnAdd = $('#massive-assignment-add-selected');
    const btnRemove = $('#massive-assignment-remove-selected');
    btnAdd.prepend("<i class='fas fa-sync fa-spin'>");
    btnRemove.prepend("<i class='fas fa-sync fa-spin'>");
    btnAdd.attr('disabled', true);
    btnRemove.attr('disabled', true);
  }

  function areButtonsDisable() {
    return $('#massive-assignment-add-selected').prop('disabled');
  }

  function enableButtons() {
    const btnAdd = $('#massive-assignment-add-selected');
    const btnRemove = $('#massive-assignment-remove-selected');
    btnAdd.children('i').remove();
    btnRemove.children('i').remove();
    btnAdd.attr('disabled', false);
    btnRemove.attr('disabled', false);
  }

  function addEmployees(ids, messageSucces, messageError, isAdding = true) {
    if (areButtonsDisable()) {
      return false;
    }

    spinnerDisabledButtons();
    const advancedFiltersParams = $('#serialized_filters').val();
    const massiveAssignmentParams = {
      employee_ids: ids,
      segments: $('#select_segment').val(),
      variable_id: variableId,
      bono_id: $('.massive_assignment_component').data('bonoId'),
      process_type: $('.massive_assignment_component').data('processType'),
      advanced_filters_params: advancedFiltersParams,
      advanced_filters: advancedFilters,
      vista: paramVista,
      paginate: paginate,
      model_id: processId,
      model_name: modelName,
    };
    if (isAdding && sendAllCondition()) {
      massiveAssignmentParams['add_all'] = true;
    }

    $.ajax({
      url: renderPath,
      method: 'put',
      data: {
        massive_assignment: massiveAssignmentParams,
      },
      success: function (data) {
        $('.massive_assignment_component').html(data);
        if (countEmployeesSelected() > 0) {
          output.find('option').remove();
          var employeesSeleted = paginate ? data.match(/selected_ids.*\[(.*)\]/)[1].split(',') : Ids([]);
          $('#table_employee_selected').DataTable();
          for (let index = 0; index < employeesSeleted.length; index++) {
            const employeeId = employeesSeleted[index];
            if (!output.val().includes(employeeId.toString())) {
              output.append(new Option(`${employeesSeleted[index]}`, `${employeesSeleted[index]}`));
            }
          }
        }
        output.val(employeesSeleted);
        output.trigger('change');
        $('#table_employee').DataTable();
        enableButtons();
        if (canSendAll) {
          subscribeTableEmployeeChanges();
        }
        if (messageSucces != '') {
          success(messageSucces);
        }
      },
      error: function () {
        enableButtons();
        if (messageError != '') {
          warning(messageError);
        }
      },
    });
  }

  function subscribeTableEmployeeChanges() {
    $('#table_employee')
      .on('processing.dt', function () {
        countShowMassive();
      })
      .on('search.dt', function () {
        countShowMassive();
      })
      .DataTable();
  }

  $(document).on('click', '#massive-assignment-add-selected', function () {
    const ids = [];
    var message = '';
    event.preventDefault();
    if (sendAllCondition()) {
      if (!allEmployeesCount()) {
        warning('No hay participantes que agregar');
        return;
      }
      message = getEmployeesToAddMessage(allEmployeesCount());
      addEmployees(Ids([]), message, 'Hubo un problema al agregar participantes');
      return;
    }
    if(countEmployeesSelectChecked() == 0) {
      warning('Seleccione al menos un participante');
    }
    else{
      $('.massive-assignment-select-employees:checked').each(function () {
        ids.push($(this).data('id'));
      });
      message = getEmployeesToAddMessage(ids.length);
      addEmployees(Ids(ids), message, 'Hubo un problema al agregar participantes');
    }
  });

  function getEmployeesToAddMessage(totalToAdd) {
    return totalToAdd + (totalToAdd > 1 ? ' Participantes agregados con éxito' : ' Participante agregado con éxito');
  }

  $(document).on('click', '#massive-assignment-remove-selected', function () {
    let ids = [];
    var message = '';
    event.preventDefault();
    if(countEmployeesSelectedChecked() == 0) {
      warning('Seleccione al menos un participante');
    }
    else {
      const employeesSelectedCells = $('#table_employee_selected').DataTable().cells().nodes();
      $(employeesSelectedCells).find(':checkbox').each(function () {
        if(paginate ? this.checked : !this.checked) {
          ids.push($(this).data('id'));
        }
      });
      if (paginate) {
        ids = ids.map(String);
        ids = allSelectedIds().filter(x => !ids.includes(x));
      }
      if (countEmployeesSelectedChecked() > 1) {
        message = ' Participantes eliminados de la selección';
      }
      else {
        message = ' Participante eliminado de la selección';
      }
      addEmployees(ids, countEmployeesSelectedChecked() + message, 'Hubo un problema al agregar participantes', false);
    }
  });

  $(document).on('change', '#select_segment', function () {
    const ids = [];
    event.preventDefault();
    addEmployees(Ids(ids), 'Grupo cargado', 'Hubo un problema al seleccionar grupo');
  });

  function unbindEvents() {
    $(document).off('click', '#massive-assignment-add-selected');
    $(document).off('click', '#massive-assignment-remove-selected');
    $(document).off('change', '#select_segment');
    $('#table_employee').off('search.dt');
    $('#table_employee').off('processing.dt');
  }
});
