/** @file Aplica amplitud a los eventos del tour. */
/* eslint-disable camelcase */
/** Envia el evento de tracking para amplitude
 * @param {object} data - Data del evento para amplitude
**/
const sendAmplitudeTrackingIfEnabled = ({ message, tour_name }) => {
  if (!message || !tour_name) return false;
  $(document).trigger('amplitudeTracking', {
    message,
    data: {
      tour_name,
    },
  });
};

/** Envia una mensaje de que el Tour fue completado
 * @param {object} data - Data del evento para amplitude
**/
const tourComplete = ({ tour_name }) => {
  sendAmplitudeTrackingIfEnabled({
    message: 'End_Tour',
    tour_name,
  });
};

/** Envia una mensaje de que el Tour fue inicializado
 * @param {object} data - Data del evento para amplitude
**/
const sendStart = ({ tour_name }) => {
  sendAmplitudeTrackingIfEnabled({
    message: 'Start_Tour',
    tour_name,
  });
};

/** Verifica si amplitud fue inicializado y invoca la función sendStart
 * @param {object} data - Data del evento para amplitude
**/
const tourStart = (data) => {
  if (window.amplitude) {
    sendStart(data);
  }
  else {
    $(document).on('amplitude_init', () => sendStart(data));
  }
};

document.addEventListener('tourComplete', ({ detail }) => tourComplete(detail));
document.addEventListener('tourStart', ({ detail }) => tourStart(detail));
