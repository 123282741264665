/** @file TODO: documentar */
import onmount from 'onmount';
import { mountVue } from '../vendor/vue-load';

let vueInstances = {};
let backups = {};

function handleVueMount() {
  onmount('[data-vue-autoload]', async function () {
    const $self = $(this);
    const autoload = $self.data('vueAutoload') !== false;
    if (!autoload) {
      return;
    }
    const componentName = `data-vue-rebuild-${$self.prop('tagName')}-${uuidv4()}`;
    $self.attr(componentName, 'true');
    backups[componentName] = this.outerHTML;
    // prevent onmount loops: vue removes and reinserts elements
    $self.removeAttr('data-vue-autoload');
    vueInstances[componentName] = await mountVue({ el: this });
  });
}

function handleVueDestruction() {
  Object.values(vueInstances).forEach((vueInstance) => {
    if (vueInstance && typeof vueInstance.$destroy === 'function' && document.body.contains(vueInstance.$el)) {
      vueInstance.$destroy();
    }
  });
  vueInstances = {};

  Object.keys(backups).forEach((backupKey) => {
    $(`[${backupKey}]`).replaceWith(backups[backupKey]);
  });
  backups = {};
}

function loadVueNoOnmount() {
  $('[data-vue-autoload]').each(async function () {
    const $self = $(this);
    const autoload = $self.data('vueAutoload') !== false;
    if (!autoload) {
      return;
    }
    const componentName = `data-vue-rebuild-${$self.prop('tagName')}-${uuidv4()}`;
    $self.attr(componentName, 'true');
    backups[componentName] = this.outerHTML;
    // prevent onmount loops: vue removes and reinserts elements
    $self.removeAttr('data-vue-autoload');
    vueInstances[componentName] = await mountVue({ el: this });
  });
}

document.addEventListener('load-vue-components', () => {
  handleVueDestruction();
  loadVueNoOnmount();
});

document.addEventListener('turbolinks:load', handleVueMount);
document.addEventListener('turbolinks:before-cache', handleVueDestruction);

onmount('[data-vue-trigger-load]', function () {
  const $self = $(this);
  const $target = $($self.data('vueTriggerLoad'));
  if ($target.length === 0) {
    throw new Error(`Target ${$self.data('vueTriggerLoad')} not found`);
  }
  $self.one('click', () => {
    $target.each(function () {
      mountVue({ el: this });
    });
  });
});

// genera un valor aleatorio para usar de identificador, se intentó
// usar crypto crypto.randomUUID(), pero dio problemas en algunos
// navegadores.
function uuidv4() {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
