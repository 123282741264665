/** @file funcionalidades de la vista de grupos */
import onmount from 'onmount';
import { actionMessage } from '../../../../../../../../app/assets/javascripts/lib/buk/alert.js';

onmount('[data-linked-warning]', function () {
  const button = $(this);
  button.on('click', function () {
    actionMessage('Información', button.data('linked-warning'), 'Ok', null, 'info');
  });
});
