/** @file TODO: documentar */
/**
 *
 * Muestra/Oculta una lista de elementos con checkboxes creada con DataTables.
 *
 * El atributo 'data-multi-select-toggle' se aplica a la acción, un enlace.
 */

import onmount from 'onmount';
import {
  prepareLinkContainer,
  handleLinkContainer
} from "./multi-select-common.js";

function setupToggleLink(link, $target) {
  const listItem = link.parent();
  prepareLinkContainer(listItem);
  link.on('click', function (e) {
    const checked = $target.find('tbody input[type=checkbox]:checked').length;
    if (checked == 0) return false;
    e.preventDefault();
    const $datatable = $target.data('DataTable');

    $.fn.dataTable.ext.search.push(function (_settings, _data, dataIndex) {
      const row = $datatable.row(dataIndex).node();
      if(link.data('show-selected')) {
        return !!$(row).find('input').prop('checked');
      }
      else {
        return true;
      }
    });
    $datatable.draw();
    // con esto evitamos que persista el filtro
    $.fn.dataTable.ext.search.pop();
    $(this).data('show-selected', !$(this).data('show-selected'));
  });

  $target.on('checkboxCount', (event) => {
    handleLinkContainer(listItem, event.count);
    link.find('span').text('(' + event.count + ')');
  });
}

onmount('[data-multi-select-action="toggle"]', async function () {
  const $table = $(this.getAttribute('data-multi-select-source'));
  await $table.waitDataTable();
  const link = $(this);

  setupToggleLink(link, $table);
});
