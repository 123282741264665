/** @file
 * Este componente toma todos los botones con href='#void' y limita el evento default
 */
import onmount from 'onmount';

onmount('a[href="#void"]', function () {
  $(this).on('click', (e) => {
    e.preventDefault();
  });
});
