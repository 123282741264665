/** @file TODO: documentar */
import onmount from 'onmount';

onmount('.comment-dropdown', function () {
  const showComment = $(this).find('a');
  showComment.on('click', function () {
    if (showComment.text().includes('Mostrar')) {
      showComment.text('Ocultar comentarios');
    }
    else {
      showComment.text('Mostrar comentarios')  ;
    }
  });
});

onmount('#datatable_verifications', async function () {
  const dataTable = $(this);
  await dataTable.waitDataTable();
  const approveButton = $('#verification_massive_approve');
  const rejectButton = $('#verification_massive_reject');

  dataTable.on('checkboxCount', () => {
    const checked =  $('.verification_evaluator_people_ids:checked');
    const disabledButtons = checked.length === 0 || checked.hasClass('pending') || checked.hasClass('rejected');
    rejectButton.prop('disabled', disabledButtons);
    approveButton.prop('disabled', disabledButtons || checked.hasClass('approved'));
  });
});

onmount('[data-evaluations-alert-change]', function () {
  const inputCheckBox = $('[type="checkbox"][data-evaluations-alert-change]');
  const inputNumber = $('[type="number"][data-evaluations-alert-change]');
  const alert = $('#alert-message-max-evaluations');

  const originalCheckboxValue = inputCheckBox.is(":checked");
  const originalInputNumber = inputNumber.val();

  $(this).on('keyup change', function () {
    const newCheckboxValue = inputCheckBox.is(":checked");
    const newInputNumber = inputNumber.val();

    if (originalCheckboxValue != newCheckboxValue || ((originalInputNumber != newInputNumber) && newCheckboxValue)) {
      alert.show();
    }
    else {
      alert.hide();
    }
  });
});

onmount('[data-reject-all]', function () {
  const radioApproveAll = $('[data-approve-all]');
  const radioRejectAll = $('[data-reject-all]');
  $(radioApproveAll).on('change', function () {
    radioRejectAll.prop('checked', !radioApproveAll.val());
  });

  $(radioRejectAll).on('change', function () {
    radioApproveAll.prop('checked', !radioRejectAll.val());
  });
});

onmount('.my_evaluations_box', async function () {
  const evaluationBox = $(this);
  const dataTable = evaluationBox.find('table');
  await dataTable.waitDataTable();
  const massiveSendButton = evaluationBox.find('.massive_send');

  dataTable.on('checkboxCount', () => {
    const checked =  evaluationBox.find('.attempt_checkbox_ids:checked');
    const disabledButtons = checked.length === 0 || checked.hasClass('send_false');
    massiveSendButton.find('span').text(`(${checked.length})`);
    if (disabledButtons) {
      massiveSendButton.addClass('disabled');
    }
    else {
      massiveSendButton.removeClass('disabled');
    }
  });
});
