/** @file TODO: documentar */
import onmount from 'onmount';

onmount('.form_electronic_licence_reject_licences', function () {
  $('#electronic_licence_rejection_start_date').val($('#electronic_licence_start_date').val());
  $('#electronic_licence_rejection_end_date').val($('#electronic_licence_end_date').val());

  var selectedLicenceIds = $('[name="electronic_licence[licence_ids][]"]:checked').map(function () {
    return this.value;
  }).get();
  var licenceIds = selectedLicenceIds.join(';');

  $('#electronic_licence_rejection_licence_ids').val(licenceIds);
});
