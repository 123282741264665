/** @file Permite ocultar un modal al accionar un botón que ejecute un proceso asíncrono.
 * Cuando existe un botón que tiene el comportamiento de ejecutar una acción asíncrona y al mismo tiempo cerrar un modal
 * es necesario accionar el cierre del modal y su backdrop.
 * Generalmente este tipo de comportamientos funcionan correctamente en contextos donde un boton acciona un submit y se recarga la pagina,
 * cosa que no sucede al ejecutar de foma asíncrona una acción ya que el modal sigue en su posición.
 */
import onmount from "onmount";

/*
  Permite remover el modal y su backdrop a través de un botón. Generalmente se ocupa para botones que ejecutan acciones
  asíncronas y que al mismo tiempo tienen la misión de cerrar el modal.
*/
onmount('[data-remove-backdrop-modal]', function () {
  $(this).on('click', function () {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  });
});
