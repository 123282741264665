/** @file TODO: documentar */
import onmount, { $ } from 'onmount';
import { once } from 'lodash-es';

const ENTER_KEY_CODE = 13;

const loadDatePicker = once(async function loadDatePicker() {
  await import('../vendor/datepicker');
});

onmount('[data-provide=datepicker]', async function () {
  await loadDatePicker();

  const $this = $(this);
  const startDate = $this.attr('min');
  const endDate = $this.attr('max');
  if (startDate) $this.datepicker('setStartDate', new Date(startDate));
  if (endDate) $this.datepicker('setEndDate', new Date(endDate));
  $this.prop('autocomplete', 'off');
  $this.on('keydown', function (event) {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      // Ocultamos el datepicker para evitar que se borre la fecha
      $this.data('datepicker').hide();
      $this.blur();
    }
  });
});

onmount('[data-link-datepicker-start-date]', async function () {
  await loadDatePicker();
  const $this = $(this);
  const otherSelector = $this.data('linkDatepickerStartDate');

  $this.on('keyup change', function () {
    const date = $(this).datepicker('getDate');
    const $other = $(otherSelector);
    const otherDate = $other.datepicker('getDate');
    $other.datepicker('setStartDate', date);
    if (otherDate && otherDate < date) {
      $other.datepicker('setDate', null);
    }
  });

});

onmount('[data-clear-datepicker]', async function () {
  await loadDatePicker();
  $(this).on('click', function (ev) {
    const $target = $(ev.target);
    ev.preventDefault();
    $target.parents('.input-group').find('input').each(function () {
      const $this = $(this);
      if ($this.data('datepicker') || $this.data('provide') === 'datepicker') {
        $this.datepicker('clearDates');
      }
    });
    return false;
  });
});

onmount('[data-enabled-days]', async function () {
  await loadDatePicker();
  const enableDaysByDefault = (domElement) => {
    const enabledDays = JSON.parse(domElement.dataset.enabledDays);
    const [month, year] = domElement.dataset.dateDefaultViewDate.split('-').splice(1, 2);
    enabledDays[enabledDays.length - 1] = new Date(year, month, 0).getDate();
    domElement.dataset.enabledDays = JSON.stringify(enabledDays);
  };

  // Encuentra el inicio del periodo a partir de la fecha seleccionada
  const beginningOfPeriod = (date, periodType, enabledDays, startOfPeriods) => {
    const [day, month, year] = date.split('-');
    if (periodType === 'weekly') {
      const currentDate = new Date(year, month - 1, day);
      const tmpDate = new Date(currentDate);
      const diff = tmpDate.getDate() - 6; // siempre debe ser 7 dias antes
      const newDate = new Date(tmpDate.setDate(diff));
      return `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`;
    }
    else {
      const periodPosition = JSON.parse(enabledDays).indexOf(parseInt(day));
      return `${JSON.parse(startOfPeriods)[periodPosition]}-${month}-${year}`;
    }
  };

  const selectStartOfPeriod = (event) => {
    const datepicker = event.data.datepicker[0];
    const currentValue = datepicker.value;
    const dataset = datepicker.dataset;
    datepicker.value = beginningOfPeriod(currentValue, dataset.periodType, dataset.enabledDays, dataset.startOfPeriods);
  };
  // Cargar los dias activos enviados por el back y ajustar si son fines de periodos
  if (this.dataset.endOfPeriod === 'true') {
    enableDaysByDefault(this);
  }
  const disableDates = (event) => { // Solamente desactiva los días
    const enabledDays = JSON.parse(event.target.dataset.enabledDays);
    Array.from(document.getElementsByClassName('day')).forEach(dayNode => {
      if (dayNode.className.includes('new')
        || dayNode.className.includes('old')
        || !enabledDays.includes(parseInt(dayNode.innerText, 10))
      ) {
        dayNode.classList.add('disabled', 'disabled-date');
      }
    });
  };
  // Cambia el fin de mes activo. Se activa cuando se cambia el mes
  const changeEnabledDays = (event) => {
    const enabledDays = JSON.parse(event.target.dataset.enabledDays);
    enabledDays[enabledDays.length - 1] = new Date(event.date.getFullYear(),
      event.date.getMonth() + 1, 0).getDate();
    event.target.dataset.enabledDays = JSON.stringify(enabledDays);
  };

  // El motivo de este listener es evitar que al modificar el texto del input
  // se resetee el disable de las fechas al cambiar la vista del calendario.
  // Esto debido que al cambiar el mes del calendario de esta forma, el datepicker no
  // lanza un evento de show. Con esto nos aseguramos que las fechas se deshabiliten.
  $(this).on('keyup', event => {
    setTimeout(() => {
      enableDaysByDefault(this);
      disableDates(event);
    }, 50);
  });

  // Solo deshabilitiamos los días si el datepicker no es semanal, ya que vienen desahabilitados del back
  if (this.dataset.periodType !== 'weekly') { $(this).on('show', disableDates); }
  if (this.dataset.endOfPeriod === 'true') {
    $(this).datepicker().on('changeMonth', changeEnabledDays); //Solo montar si el datepicker es de término de periodo
    $(document).on('submit', { datepicker: $(this) }, selectStartOfPeriod);
  }
});

onmount('[data-enabled-months]', async function () {
  await loadDatePicker();
  const disableDates = (event) => {
    const enabledMonths = JSON.parse(event.target.dataset.enabledMonths);
    Array.from(document.getElementsByClassName('month')).forEach(monthNode => {
      if (!enabledMonths.includes(monthNode.outerText)
      ) {
        monthNode.classList.add('disabled', 'disabled-date');
      }
    });
  };

  $(this).on('show', disableDates);
});

/**
 * Este listener valida las fechas en el datepicker de un formulario cada vez
 * que se selecciona una fecha en este.
 *
 * Requisitos:
 * - El input que se desee validar debe tener el atributo `data-display-warnings`
 *  en el selector del elemento donde se mostraran los mensajes de advertencia,
 *  un atributo 'data-validations' que debe contener la lista de validaciones
 *  que se quieren considerar para dicho input.
 * - En el caso de que se quiera validar la relación de los valores de los
 *  inputs `end_date` y `start_date`, es necesario asignarle un atributo
 *  'data-linked-date' al `end_date` señalando al selector del input `start_date`.
 *
 * Funcionamiento:
 * - Cada vez que se escoge una fecha se valida si los inputs cumplen con los
 *  criterios establecidos, de no pasar la validacion, se añaden los mensajes
 *  de error correspondientes al elemento seleccionado y se deshabilita el
 *  boton de submit del formulario en tanto no se realice la correccion
 *  advertida
 *
 * Ejemplo de uso:
 *  <%= period_date_picker f, :start_at,
 *                         ...
 *                         data: {
 *                          'display-warnings': '#custom-report-warnings',
 *                          'validations': ['start_date_present'],
 *                         } %>
 *  <%= period_date_picker f, :end_at,
 *                         ...
 *                         data: {
 *                          'display-warnings': '#custom-report-warnings',
 *                          'linked-date': '#start_date',
 *                          'validations': ['end_date_after_start_date'],
 *                         } %>
 */
const currentWarnings = [];
onmount('[data-display-warnings]', async function () {
  await loadDatePicker();
  const input = $(this);
  const linkedStartDate = $(input.data('linkedDate'));
  const alertBox = $(input.data('displayWarnings'));
  const validations = $(input.data('validations'));
  const submitButton = input.closest('form').find(':submit');

  let selfWarnings = [];

  const validateInputs = () => {
    const parseDate = (dateStr) => {
      if (!dateStr) return '';

      const [day, month, year] = dateStr.split('-');
      return new Date(year, month - 1, day);
    };

    const newWarnings = [];
    Object.values(validations).forEach(validation => {
      switch(validation) {
        case 'start_date_present': {
          if (input.val() === '') {
            newWarnings.push('La fecha de inicio no puede estar en blanco.');
          }
          break;
        }
        case 'end_date_after_start_date': {
          const startDate = linkedStartDate.val() === '' ? -Infinity : parseDate(linkedStartDate.val());
          const endDate = input.val() === '' ? Infinity : parseDate(input.val());
          if (endDate < startDate) {
            newWarnings.push(
              'La fecha de fin no puede ser anterior a la fecha de inicio.'
            );
          }
          break;
        }
      }
    });

    selfWarnings.forEach(warning => {
      currentWarnings.splice(currentWarnings.indexOf(warning), 1);
    });

    newWarnings.forEach(warning => currentWarnings.push(warning));

    selfWarnings = newWarnings;

    if (currentWarnings.length > 0) {
      alertBox.removeClass('hidden');
      alertBox.html(currentWarnings.join('<br/>'));
      submitButton.attr('disabled', true);
    }
    else {
      alertBox.addClass('hidden');
      submitButton.attr('disabled', false);
    }
  };

  input.datepicker().on('changeDate', validateInputs);
  linkedStartDate.datepicker().on('changeDate', validateInputs);
});
