/** @file TODO: documentar */
import { once, camelCase } from 'lodash-es';

// Copiado de sentry sdk para integrar vue.
// No podemos usar el sdk directo porque no soporta carga dinamica.
// https://github.com/getsentry/sentry-javascript/issues/3232
const COMPONENT_NAME_REGEXP = /(?:^|[-_/])(\w)/g;
const ROOT_COMPONENT_NAME = 'root';
const ANONYMOUS_COMPONENT_NAME = 'anonymous component';
const UNRENDERED_COMPONENTS = [
  'select2',
];

// https://github.com/getsentry/sentry-javascript/blob/master/packages/utils/src/path.ts

// Split a filename into [root, dir, basename, ext], unix version
// 'root' is just a slash, or nothing.
const splitPathRe = /^(\/?|)([\s\S]*?)((?:\.{1,2}|[^/]+?|)(\.[^./]*|))(?:[/]*)$/;

function splitPath(filename) {
  const parts = splitPathRe.exec(filename);
  return parts ? parts.slice(1) : [];
}

function basename(path, ext) {
  let f = splitPath(path)[2];
  if (ext && f.substr(ext.length * -1) === ext) {
    f = f.substr(0, f.length - ext.length);
  }
  return f;
}

function getComponentName(vm) {
  if (!vm) {
    return ANONYMOUS_COMPONENT_NAME;
  }

  if (vm.$root === vm) {
    return ROOT_COMPONENT_NAME;
  }

  if (!vm.$options) {
    return ANONYMOUS_COMPONENT_NAME;
  }

  if (vm.$options.name) {
    return vm.$options.name;
  }

  if (vm.$options._componentTag) {
    return vm.$options._componentTag;
  }

  // injected by vue-loader
  if (vm.$options.__file) {
    const unifiedFile = vm.$options.__file.replace(/^[a-zA-Z]:/, '').replace(/\\/g, '/');
    const filename = basename(unifiedFile, '.vue');
    return (
      this._componentsCache[filename] ||
      (this._componentsCache[filename] = filename.replace(COMPONENT_NAME_REGEXP, (_, c) =>
        c ? c.toUpperCase() : ''
      ))
    );
  }

  return ANONYMOUS_COMPONENT_NAME;

}

/** @returns {Promise<VueConstuctor<Vue>>} */
async function doLoadVue() {
  const { default: Vue } = await import('vue');
  window.Vue = Vue;
  Vue.config.productionTip = false;
  const oldErrorHandler = Vue.config.errorHandler;
  Vue.config.errorHandler = function (err, vm, info) {
    /** @type {Sentry?} */
    const Sentry = window.Sentry;
    if (Sentry) {
      const extra = {
        componentName: getComponentName(vm),
        propsData: vm?.$options?.propsData,
        lifecycleHook: info,
      };
      // En el sdk lo hacen con timeout
      setTimeout(() => {
        Sentry.getCurrentHub().withScope(scope => {
          scope.setContext('vue', extra);
          Sentry.getCurrentHub().captureException(err);
        });
      });

    }
    return oldErrorHandler?.call(this, err, vm, info);
  };
  await import('../components/vue-components');
  return Vue;
}

/** @function
 * @returns {Promise<VueConstuctor<Vue>>}
 */
export const loadVue = once(doLoadVue);

function extendFunc(...funcs) {
  return function extended(...args) {
    const self = this;
    funcs.forEach(func => {
      func?.apply(self, args);
    });
  };
}

/** Crea una instancia vue con las opciones indicadas.
 *
 * Se asegura de que gatillemos correctamente el onmount una vez cargado
 * @param {ThisTypedComponentOptionsWithArrayProps<Vue, object, object, object, never>} options
 * @returns {Promise<VueConstuctor<Vue>>}
 */
export async function mountVue(options) {

  options.mounted = extendFunc(options.mounted, triggerLoad);
  options.updated = extendFunc(options.updated, triggerLoad);

  const Vue = await loadVue();
  let loaded = false;
  const el = options.el;
  const element = el.tagName.toLowerCase();
  if (!UNRENDERED_COMPONENTS.includes(element)) {
    // Cambiamos la forma en la que se renderizan los componentes Vue. Ahora usamos`createElement`.
    // Le debemos enviar `props` en camelCase que son los atributos propios del componente Vue.
    // También le pasamos `attrs` que son los demás atributos html (data-, ic-, atributos de form-switch, etc).
    //
    // Ej. Si tenemos un elemento html con varios atributos
    //
    // <mycomponent html_attr1=val1 html_attr2=val2 my_prop1=propval1, my_prop2=propval2 \>
    //
    // A `createElement` le pasamos **todos** los atributos de <mycomponent> como `props`
    // y también se los pasamos como atributos html normales `attrs`.
    //
    // Aunque le enviemos atributos duplicados en `props` y en `attrs` a `createElement`
    // no afectan el funcionamiento del componente.
    // Los props que se envían de más son filtrados por Vue y los atributos html enviados de más simplemente aparecen
    // en el renderizado final del html del componente.
    const props = options.props || {};
    const allAttributes = {};
    const attrNames = el.getAttributeNames();
    attrNames.forEach(attr => {
      const camelCaseAttr =  camelCase(attr);
      const attrValue =  el.getAttribute(attr);
      allAttributes[attr] = attrValue;
      props[camelCaseAttr] = maybeParseJson(attrValue);
    });
    options.render = function (createElement) {
      return createElement(element, { props: props, attrs: allAttributes });
    };
  }
  return new Vue(options);
  // Como Vue elimina el elemento y lo reemplaza por otro, necesitamos reejecutar el
  // onmount una vez que ya haya sido cargado el componente (por ejemplo para form-toggle)
  // Lo hacemos solo una vez porque no hay que pretender usar onmount dentro
  // del componente vue sin que el componente coopere.
  function triggerLoad() {
    if (loaded) {
      return;
    }
    // Vue genera un comment cuando no puede renderear aun el componente.
    // Reintentamos despues, cuando se actualice
    if (this.$el.nodeType === Node.COMMENT_NODE) {
      return;
    }
    loaded = true;
    $(this.$el).trigger('buk:load-content');
    // Y para que ya no corramos denuevo cuando se actualice el componente
    const triggerLoadIndex = this.$options.updated.indexOf(triggerLoad);
    this.$options.updated.splice(triggerLoadIndex, 1);
  }

  function maybeParseJson(str) {
    try {
      return JSON.parse(str);
    }
    catch (e) {
      return str;
    }
  }
}
