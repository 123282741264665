/** @file
 * Este componente toma las acciones necesarias para añadir el nonce en los requests de turbolinks
 */
import onmount from 'onmount';

onmount('meta[name="csp-nonce"]', function () {
  document.addEventListener('turbolinks:request-start', function (event) {
    var xhr = event.data.xhr;
    // Si hace mas de una petición, añadimos un meta tag que nos permite saber si el nonce ya esta en los headers
    if ($('#added_csp_to_request').length === 0) {
      const tag = document.createElement('meta');
      tag.setAttribute('id', 'added_csp_to_request');
      $('head').append(tag);
      xhr.setRequestHeader('X-Turbolinks-Nonce', $("meta[name='csp-nonce']").prop('content'));
    }
  });

  document.addEventListener('turbolinks:before-cache', function () {
    // Añadimos manualmente el nonce a las peticiones async
    $('script[nonce]').each(function (_index, element) {
      $(element).attr('nonce', element.nonce);
    });
  });
});
