/** @file TODO: documentar */
import * as notify from './notice';
import * as alert from './alert';
import * as confirmBoxAjax from './confirm-box-ajax';
import * as notyalert from './noty-alert';

export default {
  notify,
  alert,
  confirmBoxAjax,
  notyalert,
};
