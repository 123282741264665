/**
 * @file Detecta si algún campo de un formulario fue cambiado
 *  para notificar en caso de que el usuario sin querer
 *  abandone la página y pierda los cambios.
 */

import onmount from 'onmount';

onmount('[data-track-form-changes]', async function () {
  const { loadCKeditor, CKeditorVersion } = await import('../../../../../../../\
app/assets/javascripts/components/ckeditor/load');

  if (CKeditorVersion() === '4') {
    await loadCKeditor();
    for(const instanceName in CKEDITOR.instances) {
      const instance = CKEDITOR.instances[instanceName];
      instance.on('change', function () {
        enableAlert();
      });
    }
  }
  const inputs = $(this).find(':input');
  inputs.each((_, input) => {
    $(input).on('change', () => {
      enableAlert();
    });
  });

  function enableAlert() {
    const submitWithAlert = $("[data-form-alert='true']");
    const submitWithoutAlert = $("[data-form-alert='false']");
    submitWithAlert.removeClass('hidden');
    submitWithoutAlert.addClass('hidden');
  }
});
