/** @file TODO: documentar */
import onmount from 'onmount';
import { warning } from '../../../../../../../app/assets/javascripts/lib/buk/alert';

onmount('[data-user-form]', function () {
  const $permissionRoleId = $(this).find('#user_permission_role_id'),
    $userLimitsForm = $(this).find('#user_limits'),
    $userVeRolPrivado = $(this).find('#user_ve_rol_privado'),
    $userLimitAreasCheckbox = $(this).find('#user_limit_areas');

  const currentLimitAreas = $userLimitAreasCheckbox.data('userLimitedByAreas');
  $permissionRoleId.on('change', function () {
    const checkedRole = $permissionRoleId.find('option:selected');
    if(checkedRole.data('historial') === 1) {
      warning('Se está autorizando al usuario a acceder al historial de cambios.');
    }
    if (checkedRole.text() === 'Administrador' || checkedRole.text() === 'Normal') {
      disableLimits($userLimitsForm, $userLimitAreasCheckbox);
    }
    else {
      $userLimitsForm.show('slow');
      $userLimitsForm.find(':input').attr('disabled', false);
      if (currentLimitAreas) {
        $userLimitAreasCheckbox.prop('checked', true);
        $userLimitAreasCheckbox.value = 1;
        $userLimitAreasCheckbox.change();
        $userLimitAreasCheckbox.prop('disabled', true);
      }
    }
  });
  $permissionRoleId.change();

  // Al enviar el formulario quitamos el disabled del limite de áreas
  // para que se envie al servidor
  $('form').submit(function () {
    $('div.user_limit_areas').find(':input').prop('disabled', false);
  });

  $(this).find('#user_is_superadmin').change(function () {
    var method = this.checked ? 'hide' : 'show';
    $permissionRoleId.parents('.form-group')[method]('slow');
    if (this.checked) disableLimits($userLimitsForm, $userLimitAreasCheckbox);
  });

  $userVeRolPrivado.on('change', function () {
    if ($userVeRolPrivado.data('same-user')) {
      warning('Sólo un administrador podrá revertir este cambio.');
    }
  });
  // Oculta los límites de el usuario a crear/editar
  // Además setea el límite de áreas en falso y deshabilitado
  function disableLimits(form, limitAreas) {
    limitAreas.prop('checked', false);
    limitAreas.value = 0;
    form.hide('slow');
    form.find(':input').prop('disabled', true);
  }
});
