/** @file Funciones para chips, sirve para setear un background con opacidad a los chips personalizados */
import onmount from 'onmount';

function rgbToRgba(rgb, opacity) {
  const match = rgb.match(/(\d+), (\d+), (\d+)/);
  const r = match[1];
  const g = match[2];
  const b = match[3];

  const rgba = `rgba(${r}, ${g}, ${b}, ${opacity})`;

  return rgba;
}

onmount('.personalized-color.opacity-chip', async function () {
  const chips = document.querySelectorAll('.opacity-chip');

  chips.forEach(chip => {
    const chipStyles = window.getComputedStyle(chip);
    const rgbColor = chipStyles.getPropertyValue('color');
    const rgbaColor = rgbToRgba(rgbColor, 0.1);

    chip.style.backgroundColor = rgbaColor;
  });
});
