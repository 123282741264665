/** @file Comportamientos de los botones / modal de reprocesos */
import onmount, { $ } from 'onmount';

onmount('#reproceso', function () {
  const startDate = $('#record_start_date');
  const reprocessButton = $('#button-create-reproceso');

  startDate.on('change', async e => {
    if (e.currentTarget.value === '') {
      reprocessButton.addClass('disabled');
    }
    else {
      reprocessButton.removeClass('disabled');
    }
  });

});
