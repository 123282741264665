/** @file
 * Archivo de marcas de amplitud para el modal solicitar vacaciones
 */
import onmount from 'onmount';

onmount('[data-amplitude="Start_Request_Vacations"]', function () {
  const button = $(this);
  const source = button.data('source');

  if (source) {
    button.on('click', () => {
      button.trigger('amplitudeTracking', {
        message: 'Start_Request_Vacations',
        data: {
          source,
        },
      });
    });
  }
});

onmount('[data-amplitude="View_Vacations"]', function () {
  const button = $(this);
  const source = button.data('source');

  if (source) {
    button.on('click', () => {
      button.trigger('amplitudeTracking', {
        message: 'View_Vacations',
        data: {
          source,
        },
      });
    });
  }
});
