/** @file TODO: documentar */
/**
 *
 * Permite filtrar las opciones de un select de acuerdo a otro cuando estos funcionan como nested items y la gema 'cocoon'
 *
 * Como funciona:
 * - Ambos select deben tener un parent con la clase 'nested-select-filter'
 * - El select por el cual se van a filtrar los valores debe tener la clase 'nested-select-filter-by'; asi como los data attributes 'data-filtered-by-attribute' que corresponde al atributo del selector a filtrar y 'data-filtered-attribute' con el atributo del select con las opciones a filtrar
 * - El select con las opciones que se van a filtrar debe tener la clase 'nested-select-filter-options'
 * - Por ultimo cada una de las opciones debe tener el attributo 'filter-by-value' el cual corresponde al valor de la opción padre por la cual se van a filtrar
 */

import onmount from 'onmount';

onmount('.nested-select-filter', function () {
  $('.nested-select-filter').on('click select2:opening', '.nested-select-filter-options', function () {
    $('.nested-select-filter-by').trigger('change');
  });
  $('.nested-select-filter').on('change', '.nested-select-filter-by', function () {
    const selectId = $(this).attr('id');
    const filteredByAttribute = $(this).data('filtered-by-attribute');
    const filteredAttribute = $(this).data('filtered-attribute');
    const regex = new RegExp(`\\d+_${filteredByAttribute}`);
    const selectorIdentifier = selectId.match(regex);
    const uniqueId = selectorIdentifier[0].match(/\d+/);

    const optionsSelect = $(`select.nested-select-filter-options[id*=${uniqueId}_${filteredAttribute}]`);
    const optionsSelectId = optionsSelect.attr('id');
    if ($(this).data('options') === void 0) {
      $(this).data('options', $(`#${optionsSelectId} option`).clone());
    }
    const filterOptionId = $(this).val() || 0;
    let options = $(this).data('options').filter(`[filter-by-value=${filterOptionId}]`);
    if (!optionsSelect.prop('required')) {
      const emptyOption = $(this).data('options').filter("[value='']");
      options = emptyOption.add(options);
    }
    optionsSelect.html(options);
    optionsSelect.removeAttr('disabled');
  });
});

/**
 *
 * Permite filtrar las opciones de un select de acuerdo a otro cuando estos funcionan como nested items
 *
 * Como funciona:
 * - Ambos select deben tener un parent con la clase 'nested-select'
 * - El select por el cual se van a filtrar los valores debe tener la clase 'nested-select-filter-by'; asi como los data attributes 'data-filtered-by-attribute' que corresponde al atributo del selector a filtrar y 'data-filtered-attribute' con el atributo del select con las opciones a filtrar
 * - El select con las opciones que se van a filtrar debe tener la clase 'nested-select-filter-options'
 * - Por ultimo cada una de las opciones debe tener el attributo 'filter-by-value' el cual corresponde al valor de la opción padre por la cual se van a filtrar
 */
onmount('.nested-select', function () {
  $('.nested-select').each(function () {
    const $nestedSelect = $(this);

    $nestedSelect.on('click select2:opening', '.nested-select-filter-options', function () {
      $nestedSelect.find('.nested-select-filter-by').trigger('change');
    });
    $nestedSelect.on('click select2:opening', '.nested-select-filter-by', function () {
      $nestedSelect.find('.nested-select-filter-by').trigger('change');
    });
    $nestedSelect.on('change', '.nested-select-filter-by', function () {
      const $filterBy = $(this);
      const optionsSelect = $nestedSelect.find('select.nested-select-filter-options');
      const optionsSelectId = optionsSelect.attr('id');

      if ($filterBy.data('options') === void 0) {
        $filterBy.data('options', $(`#${optionsSelectId} option`).clone());
      }

      const filterOptionId = $filterBy.val() || 0;
      let options = $filterBy.data('options').filter(`[filter-by-value='${filterOptionId}']`);

      if (!optionsSelect.prop('required')) {
        const emptyOption = $filterBy.data('options').filter("[value='']");
        options = emptyOption.add(options);
      }

      optionsSelect.html(options);
      optionsSelect.removeAttr('disabled');
      optionsSelect.trigger('change');
    });
  });
});
