/** @file El archivo contiene la configuración necesaria para generar las iconos de
 * cada participante, los cuales contienen su foto de perfil y un 'popover' con el
 * detalle del mismo.
 *
 * Todo lo anterior se ajusta de manera dinámica de acuerdo a los datos que contiene
 * la tabla de participantes asociada al Ninebox.
 */
import onmount, {
  $
} from 'onmount';

class Ninebox {
  constructor(quadrantsContainer, source) {
    this.quadrantsContainer = quadrantsContainer;
    this.source = source;

    this.yAxisMaxValue = quadrantsContainer.data('y-axis-max-value');
    this.xAxisMaxValue = quadrantsContainer.data('x-axis-max-value');

    this.cleanNineboxInnerHTML;
    this.firstUpdate = true;
    this.picturesContainers = $('[data-participants-grid-quadrant]');
    this.quadrantPicturesContainer = $('[data-participants-quadrant]');

    this.tableContainer = $(this.quadrantsContainer.data('source-table'));
    if (quadrantsContainer.data('hide-actions')) { // Se esconden las acciones cuando la calibración está cerrada.
      this.tableContainer.DataTable().columns(5).visible(false);
    }
    this.tableContainer.DataTable().column(6).visible(false); // Se esconde la última columna de datos.

    this.source.onDataUpdated(this.updateSource.bind(this));
  }

  updateSource() {
    for (let i = 0; i < this.picturesContainers.length; i++) {
      this.picturesContainers[i].innerHTML = '';
      this.quadrantPicturesContainer[i].innerHTML = '';
    }

    const data = this.source.nineboxv2Data();
    const quadrants = data[0];
    const participantsInfo = data[1];
    const yAxisGrades = data[2];
    const xAxisGrades = data[3];
    const sortedParticipants = {};
    const nineboxQuadrants = $('[data-quadrant]');

    for (let i = 0; i < quadrants.length; i++) {
      (sortedParticipants[quadrants[i]] || (sortedParticipants[quadrants[i]] = [])).push(participantsInfo[i]);
      for (let j = 0; j < this.quadrantPicturesContainer.length; j++) {
        if (quadrants[i].replace(/\s+/g, '') === $(this.quadrantPicturesContainer[j]).parents("[data-quadrant]").attr('id').split('-')[1]) {

          const quadrantMinPointX = nineboxQuadrants[j].getAttribute('data-min-point-x');
          const quadrantMinPointY = nineboxQuadrants[j].getAttribute('data-min-point-y');
          const quadrantWidth = nineboxQuadrants[j].getAttribute('data-quadrant-width');
          const quadrantHeight = nineboxQuadrants[j].getAttribute('data-quadrant-height');

          const profilePhoto = participantsInfo[i];
          const profileCircle = '<div class="participant"' +
            `style="left: ${(xAxisGrades[i] - quadrantMinPointX) * 100 / quadrantWidth}%; ` +
            `bottom: ${(yAxisGrades[i] - quadrantMinPointY) * 100 / quadrantHeight}%;" ` +
            profilePhoto +
            '</div>';

          this.quadrantPicturesContainer[j].innerHTML += profileCircle;
        }
      }
    }

    for (let j = 0; j < this.picturesContainers.length; j++) {
      const participantsByQuadrant = sortedParticipants[this.picturesContainers[j].parentElement.id] || [];
      for (let i = 0; i < participantsByQuadrant.length; i++) {
        if (i > 5) {
          this.picturesContainers[j].innerHTML += '<div class="extra-participants">+' +
            (participantsByQuadrant.length - 6) +
            '</div>';
          break;
        }
        const profilePhoto = participantsByQuadrant[i];
        const profileCircle = '<div class="participant" style="margin-left: 5px;"> ' +
          profilePhoto +
          '</div>';
        this.picturesContainers[j].innerHTML += profileCircle;
      }
    }
  }
}

onmount('[data-controller="talent-management-ninebox-v2"]', async function () {
  const quadrantsContainer = $(this);
  const table = $(this).data('source-table');
  const { default: ChartTableDataDriver } = await import(
    './drivers'
  );
  const source = new ChartTableDataDriver(table, ['quadrant', 'hidden', 'y_axis_grade', 'x_axis_grade'], 'filter');
  new Ninebox(quadrantsContainer, source);
});

onmount('[data-grid-quadrant]', function () {
  $(this).click(function () {
    $(this).parent().css('display', 'none');
    $('[data-position="' + $(this).data('position') + '"]').fadeIn();
    $('#ninebox-go-grid-container').css('cursor', 'pointer');
  });
});

onmount('#ninebox-go-grid-container', function () {
  $(this).add('.minimize-icon').click(function () {
    this.style.removeProperty('cursor');
    $('[data-quadrant]').css('display', 'none');
    $('[data-ninebox-grid]').css('display', 'grid').hide().fadeIn();
  });
});

onmount('[data-zoom-button="true"]', function () {
  const $self = $(this);
  const $zoomPanel = $self.parent();
  const $zoomButtons = $zoomPanel.children();
  const $participantsContainer = $zoomPanel.parent().find('.participants');
  const zoomType = $self.data('zoom-type');
  const zoomCoefficients = $zoomPanel.data('zoom-coefficients');
  if (zoomType === 'minus') { $self.prop('disabled', true); }
  $self.on('click', function () {
    let disableButton;
    let currentZoomIndex = $zoomPanel.data('current-zoom-index');
    switch (zoomType) {
      case 'plus':
        currentZoomIndex += 1;
        disableButton = currentZoomIndex === (zoomCoefficients.length - 1);
        break;

      case 'minus':
        currentZoomIndex -= 1;
        disableButton = currentZoomIndex === 0;
        break;

      default:
        return;
    }
    const newCoefficient = zoomCoefficients[currentZoomIndex];
    [$zoomButtons[0].disabled, $zoomButtons[1].disabled] = [false, false];
    $self.prop('disabled', disableButton);
    $zoomPanel.data('current-zoom-index', currentZoomIndex);
    $participantsContainer.css('width', `calc( (100% - 10px) * ${newCoefficient} )`);
    $participantsContainer.css('height', `calc( (100% - 40px) * ${newCoefficient} )`);
  });
});
