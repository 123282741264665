/**
 * @file select2.js
 * @description Inicializa Select2 en elementos que coinciden con el selector `[data-provide="select2"]`, ajusta su tamaño si están ocultos, y configura opciones de Ajax para datos dinámicos.
 *              Maneja eventos de cambio en elementos relacionados, asegura el enfoque en el cuadro de búsqueda al abrir el dropdown,
 *              y permite la creación de opciones personalizadas con un prefijo.
 *              Además, transfiere atributos personalizados a las opciones del Select2 si el JSON recibido contiene atributos en el formato `data-x`.
 */
import { map, each, keys } from 'lodash-es';
import onmount from 'onmount';
import { isInDom } from '../lib/interaction-functions';

onmount('[data-provide="select2"]', async function (obj) {
  // eslint-disable-next-line max-len
  const { buildCreateOption, buildTemplateResult } = await import('./select2/create-option.js');
  await import('../vendor/select2');

  var $this = $(this);
  // Si el input está invisible al momento de correr esto (por ejemplo en un tab no visible)
  // El select2 no puede calcular el tamaño. Por lo tanto, le ponemos width 100% en el caso en qe sea un form-control (bootstrap)
  if ($this.hasClass('form-control')) {
    $this.css('width', '100%');
  }
  var opts = { };
  var bind = $this.data('ajaxParamsBind');
  if (bind) {
    obj.namespace = '.select2-' + obj.id;
    if (typeof(bind) === 'string') {
      bind = [bind];
    }
    opts.ajax = opts.ajax || {};

    const watch = map(bind, (value, index) => {
      const selector = typeof(index) === 'number' ? value : index;
      const name = typeof(index) === 'number' ? null : value;
      return { name, selector };
    });
    opts.ajax.data = params => {
      each(watch, (i) => {
        const $el = $(i.selector);
        const name = i.name || $el.attr('name');
        params[name] = $el.val();
      });
      return params;
    };

    $(document).on('change' + obj.namespace, map(watch, 'selector').join(','), async () => {
      // Cuando cambian los inputs relacionados, tratamos de encontrar los valores que estaban
      // antes en la nueva seleccion (por ejemplo, el mismo rol en distintas areas)
      const select2 = $this.data('select2');
      if (!select2) {
        return; // select2 not initialized yet
      }
      const selected = {};
      $this.find(':selected').each(function () {
        selected[this.value] = this.text;
      });
      delete selected[''];
      delete selected[null];
      delete selected[undefined];
      if (keys(selected).length == 0) {
        return;
      }

      function querySelectedValue(value, key) {
        const deferred = $.Deferred();
        select2.dataAdapter.query({ term: value }, res => {
          if (res.results.filter(item => item.id == key).length == 0) {
            delete selected[key];
          }
          deferred.resolve();
        });
        return deferred;
      }

      function applySelectedValues() {
        $this.val(keys(selected)).trigger('change');
      }

      const promises = map(selected, querySelectedValue);
      await Promise.all(promises);
      applySelectedValues();
    });
  }

  if ($this.prop('multiple') && $this.data('closeOnSelect') === undefined) {
    opts.closeOnSelect = false;
  }

  // Select2 necesita que le pasemos un elemento, así que pasamos por una opcion extra
  // https://github.com/select2/select2/issues/5426
  if ($this.data('dropdownParentString')) {
    const parent = $($this.data('dropdownParentString'));
    if (parent.length > 0) {
      // pero no lo agregamos si no existe
      opts.dropdownParent = parent;
    }
  }
  else {
    opts.dropdownParent = $this.parent();
  }

  // Habilitar la creación de opciones personalizadas y especificar un prefijo
  // Ejemplo: <select data-create-option="custom-">
  const createOptionPrefix = $this.data('createOption');

  if (createOptionPrefix) {
    opts = {
      ...opts,
      tags: true,
      createTag: buildCreateOption(createOptionPrefix),
      templateResult: buildTemplateResult,
    };
  }
  else {
    /**
     * Transfiere la información del <span> del select2 a la <option> al seleccionar una opción.
     *
     * @param {Object} data - Objeto que contiene los datos de la opción seleccionada.
     * @returns {string} - El texto de la opción seleccionada.
     */
    opts.templateSelection = function (data) {
      appendDataAttrs(data, data.element);
      return data.text;
    };
  }
  $this.select2(opts);
  obj.select2 = $this;

  // Con jquery 3.6 select2 tiene un bug que no enfoca el buscador al abrir.
  // hacemos workaround de eso haciendo focus sobre el buscador con el evento
  // nativo javascript, sin pasar por jquery
  // https://github.com/select2/select2/issues/5993
  function focusSearchBox() {
    /** @type HTMLElement */
    const dropdown = $this.data('select2').$dropdown[0];
    if (isInDom(dropdown)) {
      const search = dropdown.querySelector('.select2-search__field');
      search?.focus();
    }
  }

  /**
   * Añade atributos de datos personalizados a un contenedor.
   *
   * @param {Object} data - Objeto que contiene los datos a procesar. Los atributos que comienzan con 'data-' serán añadidos.
   * @param {HTMLElement} container - El contenedor al que se añadirán los atributos.
   */
  function appendDataAttrs(data, container) {
    Object.entries(data).forEach(([key, value]) => {
      if (key.indexOf('data-') === 0) {
        $(container).attr(key, value);
      }
    });
  }

  $this.on('select2:open', () => setTimeout(focusSearchBox, 0));
}, function (obj) {
  if (obj.namespace) {
    $(document).off(obj.namespace);
  }
  if (isInDom(obj.select2.get(0))) {
    obj.select2.select2('destroy');
  }
});
