/** @file TODO: documentar */
import onmount from 'onmount';
import Turbolinks from 'turbolinks';

async function createSource() {
  const { default: Bloodhound } = await import(/* webpackChunkName: "typeahead" */ 'corejs-typeahead/dist/bloodhound.js');
  const tok = Bloodhound.tokenizers.obj.whitespace('name');
  return new Bloodhound({
    datumTokenizer: d => tok(d),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    identify: d => d.id,
    //prefetch: '../data/films/post_1960.json',
    limit: 10,
    remote: {
      url: '/employees/global_search.json?term=%QUERY',
      wildcard: '%QUERY',
      transform: d => d.employees
    }
  });
}

const emptyEmplate = `
<div class="no-results text-center text-muted">No hay resultados</div>
`;

const searchingTemplate = `
<div class="tt-search text-center"><span class="buk-loader"></span></div>
`;

const elementTemplate = `
<div class="tt-suggestion tt-selectable clearfix">
  {img}
  <div class="name text-truncate" data-toggle="tooltip" title="{name}">{name}</div>
  {label}
</div>
`;

onmount('#global_employee_search', async function () {
  await import(/* webpackChunkName: "typeahead" */ 'corejs-typeahead/dist/typeahead.jquery');
  const $self = $(this);
  $self.typeahead(
    {
      hint: false,
    },
    {
      name: 'global-employee-search',
      display: 'name',
      limit: 10,
      async: true,
      source: await createSource(),
      templates: {
        pending: searchingTemplate,
        empty: emptyEmplate,
        suggestion: e => elementTemplate.replace('{label}', e.status_code).replace('{id}', e.id).replace('{name}', e.name).replace('{name}', e.name).replace('{img}', e.picture),
      }
    }
  );
  $self.on('typeahead:select', (_ev, suggestion) => {
    Turbolinks.visit(suggestion.url);
  });

  // presionar enter nos envia a la misma pagina con el texto buscado
  $self.on('keydown', function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  });
});

document.addEventListener('keyup', function (e) {
  // CTRL + B
  if (e.ctrlKey && e.keyCode == 66) {
    $('#global_employee_search').focus();
  }
}, false);
