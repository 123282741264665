/** @file TODO: documentar */
import onmount from 'onmount';

onmount('#graficos-resultado-evaluacion', function () {
  const autoevaluacion = $(this).data('autoevaluacion');
  const talentos = $(this).data('talentos');

  const autoevaluacionData = {
    data: autoevaluacion,
    borderWidth: 2,
    borderColor: 'rgba(0, 166, 90, 0.8)',
    backgroundColor: 'rgba(0, 166, 90, 0.3)',
    pointBackgroundColor: 'rgba(255, 255, 255, 1)',
  };

  const talentosData = {
    data: talentos,
    borderWidth: 2,
    borderColor: 'rgba(245, 105, 84,0.8)',
    backgroundColor: 'rgba(245, 105, 84,0.4)',
    pointBackgroundColor: 'rgba(255, 255, 255, 1)',
  };

  const evaluationChart = $(this).find('#grafico-promedio').data('evaluacion-chart');

  $(this).find('.toggle-autoevaluacion').on('click', function () {
    toggleData(evaluationChart.data.datasets.indexOf(autoevaluacionData), autoevaluacionData);
  });

  $(this).find('.toggle-talentos').on('click', function () {
    toggleData(evaluationChart.data.datasets.indexOf(talentosData), talentosData);
  });

  function toggleData(index, data) {
    if(index >= 0) {
      evaluationChart.data.datasets.splice(index, 1);
    }
    else {
      evaluationChart.data.datasets.push(data);
    }
    evaluationChart.update();
  }
});
