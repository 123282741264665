/** @file Aqui se presentan soluciones de front para el manejo de campos en Plantillas de Solicitudes o Solicitudes,
 * que necesitan de un comportamiento responsivo y por medio de RoR o por el uso de IC no es viable.
 */

/**
 * Cambia los atributos "display_level" y "is_required" de los form_fields ocultos del
 * formulario de plantillas de solicitudes, de acuerdo a la opción elegida en el selector
 * de estado de los campos con clase "change_status_form_field"
 */
$.onmount('.change_status_form_field', function () {
  const $self = $(this);
  const otId = $self.data('operation-template-id');
  const formFieldId = $self.data('form-field-id');

  $self.on('select2:select', function (e) {
    const selectedStatus = e.params.data.id;
    let isRequired = 'false';
    let displayLevel = 'editable';

    switch (selectedStatus) {
      case 'displayed':
        displayLevel = 'displayed';
        break;
      case 'editable_optional':
        break;
      case 'editable_required':
        isRequired = 'true';
        break;
      default:
        displayLevel = 'hidden';
    }

    // Actualizar Hidden Field
    const name = `process_template[operation_templates][${otId}][form_fields_attributes][${formFieldId}]`;
    const inputDisplayLevel = document.querySelector(`input[name="${name}[display_level]"]`);
    const inputIsRequired = document.querySelector(`input[name="${name}[is_required]"]`);

    inputDisplayLevel.value = displayLevel;
    inputIsRequired.value = isRequired;

    // Gatillar evento Change, para que sea escuchado por IC
    inputDisplayLevel.dispatchEvent(new Event('change', { bubbles: true }));
  });
});

/**
* Habilita o deshabilita el botón de acciones masivas sobre campos dependiendo
* si hay campos seleccionados o no al configurar una plantilla
*/
$.onmount('.form_fields_table_operation', async function () {
  const $self = $(this);
  const operationId = $self.data('operation-template-id');
  const $btn = document.querySelector(`#form_fields_massive_actions_btn_operation_${operationId}`);
  $self.on('click change', `.form_field_table_checkbox_operation_${operationId}, .form_fields_select_all_js`, ()=>{
    // Usamos $self para referirnos al elemento jQuery y [0] para acceder al elemento DOM.
    const FormFields = [...$self[0].querySelectorAll(`.form_field_table_checkbox_operation_${operationId}`)];
    const selectedFormFieldsCount = FormFields.filter(checkbox => checkbox.checked).length;
    $btn.disabled = !selectedFormFieldsCount;
  });
});

/**
* Checkea o descheckea todos los campos listados en la tabla de form_fiels
* de una operación de plantilla al momento de seleccionar el checkbox superior de la tabla.
*/
$.onmount('.form_fields_select_all_js', function () {
  const $self = $(this);
  $self.on('click', function (_e) {
    const isChecked = $self.is(':checked');
    const operationTemplateId = $self.data('operation-template-id');
    const formFieldsclassName = `form_field_table_checkbox_operation_${operationTemplateId}`;
    $(`.${formFieldsclassName}`).prop('checked', isChecked);
  });
});

/*
  Deshabilita los inputs cuando se realiza la acción show_form de una operación.
*/
$.onmount('.read-mode-operation', function () {
  $('.read-mode-operation input').prop('disabled', true);
  $('.read-mode-operation select').prop('disabled', true);
  $('.read-mode-operation textarea').prop('readonly', true);
  $('#search_employee_rut').addClass('disabled');

  //  Si se cargan más inputs via Ajax, que estos tambien aparezcan deshabilitados
  $(this).on('DOMNodeInserted', function (event) {
    $(event.target).find('input').prop('disabled', true);
    $(event.target).find('select').prop('disabled', true);
  });
});

/*
  Evento para permitir que se haga un búsqueda tanto inline como por request junto a los otros filtros
  (status y type) de solicitudes.
*/
$.onmount('.workflow-filters-container', function () {
  $('#workflow-fuzzy-search').change(function () {
    $('#filter_search').val($('#workflow-fuzzy-search').val());
  });
});

/*
  Remueve el buscador para select2 al montarse estos
*/
$.onmount('[data-workflow-select2-remove-search]', async function () {
  await import('../../../../../../app/assets/javascripts/vendor/select2');
  $(this).select2({ minimumResultsForSearch: Infinity });
});

/*
  Vista de solicitudes (workflow process index)
  Permite que al hacer click en una operación que redirija a un formulario, no se gatille el evento que abre/cierra
  el colapsable de bux-list
*/
$.onmount('.workflow-process-list-item-operations', function () {
  $(this).find('a').on('click', (e) => e.stopPropagation());
});

/**
 * Permite modificar el href del boton para autocompletar el formulario por RUT añadiendolo a la url referenciada.
 */
$.onmount('#search_employee_rut', function () {
  $(this).click(function () {
    var operationId = $('#alta_operation_operation_id').val();
    var targetUrl = '/workflow/operations/' + operationId + '/employee_by_rut?rut=';
    var rut = $('#alta_operation_rut:visible').val();
    rut =  rut || $('div#person_identifier_type_other #alta_operation_rut').val();
    $(this).attr('href', targetUrl + encodeURIComponent(rut));
  });
});
