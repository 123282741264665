/** @file TODO: documentar */
$(document).on('turbolinks:load', function () {

  $('#departments_list').change(function () {
    $('div[id^="department_"]').hide();
    $('select[id^="department_"]').prop("disabled", true);
    $('div#department_' + $(this).val()).fadeIn();
    $('select#department_' + $(this).val()).prop("disabled", false);
  });

  $('#divisions_list').change(function () {
    $('div[data-division-id]').hide();
    $('div[data-division-id="' + $(this).val() + '"]').fadeIn();
  });

  $(this).ajaxError(function (_, jqxhr) {
    if (jqxhr.status === 401) {
      location.href = "/";
    }
  });
});


// Recordar que tab estaba abierto al volver atras
$(document).on('turbolinks:load', function () {
  if (location.hash !== '') {
    $('a[data-toggle="tab"][href="' + location.hash + '"]').tab('show');
  }
});
$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
  if (history && history.replaceState && history.state) {
    history.replaceState(history.state, document.title, $(e.target).attr('href'));
  }
});

// Por algún motivo jQuery genera una ruta predeterminada si no está definida la URL al invocar
// algún  método ajax (.ajax, .post, .get, etc.). Con esto evitamos que se genere un 403 o 404 y
// tenemos mejor trazabilidad de qué línea de código js gatilló la llamada.
const oldAjax = $.ajax;
$.ajax = function (url, options) {
  if(!url || !url['url']) {
    throw new Error('Se intentó invocar $.ajax() sin URL!');
  }

  return oldAjax(url, options);
};
