/** @file Componente JS para agrupar todas las validaciones de un formulario compuesto de multiples partes, y
 * mostrarlas todas en un mismo contenedor tipo alert.
 *
 * Requiere que el form que contiene las multiples partes tenga un atributo data-multiform-errors con valor
 * un selector de un contenedor donde agregar la lista de errores (generalmente un alert oculto)
 *
 * Las partes o subformularios que conforman el form deben tener un atributo data-multiform-element, con valor
 * un nombre de la sección, con tal de mostrarse los errores de esta parte junto a una identificación de esta.
 *
 * Actualmente soporta inputs y selects. Para mostrar un mensaje de error personalizado (por ahora solo
 * valueMissing y patternMismatch), agregar un atributo data en el input o un elemento que lo contenga según
 * el tipo de error que se necesite soportar.
 *
 * data-multiform-blank-message: 'Mensaje a mostrar cuando falta ingresar un valor'
 * data-multiform-mismatch-message: 'Mensaje a mostrar cuando el valor no coincide con el pattern (HTML)'
 *
 * Para CKEDITOR, se soporta actualmente el verificar que no estén en blanco, agregando la clase .multiform-ckeditor y
 * un atributo data-multiform-blank-message en el input del textarea que reemplaza.
 *
 * Se soportan validaciones custom, mediante un atributo data-multiform-custom-error-message en un elemento, el cual
 * debe tener un metodo 'validate' (sin parámetros), el cual retorna un valor true o false dependiendo de si es valido.
 * Este se llama al validar el form, y de ser false, se muestra el mensaje de error asignado en el atributo data.
*/

$.onmount('[data-multiform-errors]', async function () {
  const { loadCKeditor, CKeditorVersion } = await import('./ckeditor/load');

  if (CKeditorVersion() === '4') {
    await loadCKeditor();
  }
  const $form = $(this);
  const $errorBox = $($form.attr('data-multiform-errors'));

  // Agrega una lista dentro del box si no existe, para determinar donde agregar los mensajes.
  if ($errorBox.find('ul').length === 0) { $errorBox.append('<ul></ul>'); }

  // El alert solo se oculta en vez de removerse del dom para reutilizarlo.
  $errorBox.find('button.close').on('click', function (e) {
    $errorBox.hide();
    e.stopPropagation();
  });

  $form.find('[type="submit"]').on('click', function (e) {
    // Verificar primero si hay ckeditors que sean requeridos y no tengan texto
    const emptyCkeIds = [];
    $form.find('.multiform-ckeditor[data-multiform-blank-message]').each(function (_, element) {
      const id = $(element).attr('id');
      const text = CKeditorVersion() === '4' ? CKEDITOR.instances[id].getData() : $(element).val();
      if (text.length === 0) {
        emptyCkeIds.push(id);
      }
    });

    // Verificar validaciones custom
    const customInvalidElements = [];
    $form.find('[data-multiform-custom-error-message]').each(function (_, element) {
      if (element.validate !== undefined && !element.validate()) {
        customInvalidElements.push(element);
      }
    });

    if (!$form[0].checkValidity() || emptyCkeIds.length > 0 || customInvalidElements.length > 0) {
      const errors =  [];

      $form.find('[data-multiform-element]').each(function (_, subForm) {
        const name = $(subForm).attr('data-multiform-element').trim();

        $(subForm).find('input:invalid, select:invalid').each(function (_i, element) {
          let errorMessage = element.validationMessage;

          if (element.validity['valueMissing']) {
            const messageElement = $(element).closest('[data-multiform-blank-message]');
            if (messageElement.length >= 1) {
              errorMessage = messageElement.attr('data-multiform-blank-message');
            }
          }
          else if (element.validity['patternMismatch']) {
            const messageElement = $(element).closest('[data-multiform-mismatch-message]');
            if (messageElement.length >= 1) {
              errorMessage = messageElement.attr('data-multiform-mismatch-message');
            }
          }

          errors.push(name + ': ' + errorMessage);
        });

        if (emptyCkeIds.length > 0) {
          $(subForm).find('.multiform-ckeditor[data-multiform-blank-message]').each(function (_i, element) {
            const id = $(element).attr('id');
            if (emptyCkeIds.includes(id)) {
              errors.push(name + ': ' + $('#' + id).attr('data-multiform-blank-message'));
            }
          });
        }

        if (customInvalidElements.length > 0) {
          customInvalidElements.forEach(function (element) {
            if ($(subForm).find(element).length > 0) {
              errors.push(name + ': ' + $(element).attr('data-multiform-custom-error-message'));
            }
          });
        }
      });

      const $newList = $(document.createElement('ul'));
      errors.forEach(function (errorMessage) {
        $(document.createElement('li')).text(errorMessage).appendTo($newList);
      });

      $errorBox.find('ul').replaceWith($newList);
      $errorBox.show();
      e.preventDefault(); // Al finalizar se detiene el submit normal.
    }
  });
});
