/** @file TODO: documentar */
import { mapValues, groupBy, map } from 'lodash-es';

/**
 * Retorna los valores de una serie de inputs en forma de hash name => valor.
 * En caso de haber multiples valores con el mismo nombre, los guarda como un array
 *
 * @return {Object}
 */
$.fn.serializeHash = function () {
  var asArray = this.serializeArray();
  return mapValues(groupBy(asArray, 'name'), (v) => map(v, 'value'));
};
