/**
 * @file add-participants-widget.js
 * Funciones para select2 de agregar participantes
 */
import onmount from 'onmount';

/**
 * Función para definir el template de resultado de busqueda y de selección
 * En este caso, toma la clase con que venga la opción, y si es "employee",
 * le asigna el icono de usuario.
 *
 * @param {Object} state - Información de la opción seleccionada, proporcionado por Select2
 * @param {string} state.class - Clase de la opción
 * @param {string} options.text - Texto de la opción
 * @returns {string|HTMLElement} - HTML que representa el resultado de la opción
 */
function formatState(state) {
  const groupIcon = '<span class="material-icons">groups</span> <span><span></span></span>';
  const isGroup = (typeof state?.id === 'string' && state?.id?.includes('group'));
  var $state = $(
    isGroup ? groupIcon : '<span><span></span></span>'
  );
  if (state?.element?.className === 'external-group') {
    $state = $('<span class="material-icons">groups</span><span class="external-group-selected"><span></span></span>');
    $(function () {
      $('.external-group-selected').each(function () {
        $(this).parent().addClass('warning');
      });
    });
  }

  $state.find('span').text(state.text);
  return $state;
}

onmount('.add-participants-select2', async function () {
  const selector = $(this);
  await import('../../../../../../../app/assets/javascripts/vendor/select2.js');
  $(function () {
    selector.select2({
      templateSelection: formatState,
      templateResult: formatState,
      allowClear: true,
      placeholder: selector.select2('data').placeholder,
      closeOnSelect: false,
      ajax: {
        type: 'POST',
        data: function (params) {
          const data = selector.select2('data');
          // eslint-disable-next-line prefer-const
          let selectedIds = data.map((ele) => (ele.id));
          const query = {
            search: params.term,
            selectedIds: selectedIds,
          };
          return query;
        },
      },
    });
  });
});
