/** @file
 * Verifica el alto de la página para corrección en mobile */

let timeoutId = null;

const documentHeight = () => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
  }, 200);
};

window.addEventListener('resize', documentHeight);
documentHeight();
