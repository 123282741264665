/** @file Se encarga de mostrar un modal con las imágenes asociadas
 * a las publicaciones de un mural del portal de empleados */
import onmount from 'onmount';
onmount('.preview_image_generals', function () {
  $(document).on('change', '.custom-file-input', function (e) {
    const idInputFile = $(e.target).data('id');
    const idPreviewImageGenerals = `#label-file-img-${idInputFile}`;

    setLabelWithImgFileName(e.target.files);
    previewImage(e.target.files);
    return false;

    function setLabelWithImgFileName(files) {
      if (!files || files.length === 0) {
        return;
      }
      var file = files[0];
      var label = document.querySelector(idPreviewImageGenerals);
      label.innerHTML = file.name;
    }

    function previewImage(files) {
      const container = document.getElementById('preview-container-' + idInputFile);

      container.innerHTML = '';

      if (files && files[0]) {
        $('#preview-example-' + idInputFile).hide();
        $(`#preview-image-container-${idInputFile}`).hide();

        const img = document.createElement('img');
        img.src = URL.createObjectURL(files[0]);
        img.onload = function () {
          URL.revokeObjectURL(this.src);
        };
        img.classList.add('mt-3', 'p-1', 'max-w-45', 'mh-100', 'shadow-1', 'rounded', 'border', 'bg-white');

        container.appendChild(img);
      }
    }
  });
});
