/** @file TODO: documentar */
import onmount from 'onmount';

onmount('form.roles_talentos_form', function () {
  $(this).on('input', 'input[type=range]', function () {
    $(this).closest('td').find('.show-value').html($(this).val());
  });

  $('form').on('submit', function () {
    const btn = $(this).find("button[type='submit']");
    btn.children().text('Guardando...');
    btn.prop('disabled', true);
  });
});

onmount('[data-role-description]', function () {
  const $self = $(this);
  const $target = $($self.data('roleDescription'));
  $self.on('change', function () {
    const val = $self.val();
    if (!val) {
      return;
    }
    $.getJSON('/roles/{id}/description'.replace('{id}', val))
      .then(function (description) {
        if ($target.is(':input')) {
          $target.val(description);
        }
        else {
          $target.text(description);
        }
      });
  });
});

onmount('[data-role-requirements]', function () {
  const $self = $(this);
  const $target = $($self.data('roleRequirements'));
  $self.on('change', function () {
    const val = $self.val();
    if (!val) {
      return;
    }
    $.getJSON('/roles/{id}/requirements'.replace('{id}', val))
      .then(function (requirements) {
        if ($target.is(':input')) {
          $target.val(requirements);
        }
        else {
          $target.text(requirements);
        }
      });
  });
});

// Comportamiento bukcopilot
onmount('form.roles_talentos_form', function () {
  const setButtonDisabledStatus = (button, disabled) => {
    button.prop('disabled', disabled);
  };

  const bukCopilotButton = $(this).find('#role_autocomplete_bukcopilot_edit_button,' +
                                        '#role_autocomplete_bukcopilot_create_button');
  // Para mantener deshabilitado botón de crear con BukCopilot en caso de que no
  // se tenga un valor ingresado en el nombre del cargo
  const roleNameInput = $(this).find('#role_name');
  setButtonDisabledStatus(bukCopilotButton, roleNameInput.val().length === 0); // Estado inicial del botón
  roleNameInput.on('change', () => {
    setButtonDisabledStatus(bukCopilotButton, roleNameInput.val().length === 0);
  });

  // Autocompletar
  bukCopilotButton.on('click', () => {
    setButtonDisabledStatus(bukCopilotButton, true);
    $('#new_role_bukcopilot_loader').removeClass('d-none');
    $.ajax({
      url: '/roles/complete_with_ai',
      data: {
        role_name: $('#role_name').val(),
      },
      success: (data, _status) => {
        $('#role_description').val(data.role.description);
        $('#role_requirements').val(data.role.requirements);
        $('#new_role_bukcopilot_loader').addClass('d-none');
        $('#alert_error_copilot').addClass('d-none');
      },
      error: () => {
        $('#alert_error_copilot').removeClass('d-none');
        $('#new_role_bukcopilot_loader').addClass('d-none');
        setButtonDisabledStatus(bukCopilotButton, false);
      },
    });
  });
});
