/** @file Comportamientos del index de órdenes de boleta **/

import onmount, { $ } from 'onmount';

/**
 * Cuenta la cantidad de checkbox (con alguna condición) chequeados
 * @param {DataTable} table Contenedor de los checkboxes.
 * @param {optional} conditional Atributo a filtrar.
 * @return {object} El número de checkboxes chequeados
 */
export default function countConditionalCheckbox(table, conditional) {
  const conditionalCheckedBoxes = table[0].querySelectorAll(`input[${conditional}]:checked`);
  const conditionalCount = conditionalCheckedBoxes.length;
  return { count: conditionalCount };
}
/**
 *
 * Permite reemplazar el mensaje de confirmación del multi-select,
 * si es que clickeamos sobre una fila que tenga el atributo 'data-checkbox-condition="true"'
 *
 */
onmount('[data-multi-select-with-condition]', function () {

  function buildMessage(link, count) {
    if (count > 0) {
      if (count === 1) {
        return link.getAttribute('data-multi-select-alternative-swal-singular');
      }
      return link.getAttribute('data-multi-select-alternative-swal').replace('[X]', count);
    }
    return link.getAttribute('data-multi-select-original-swal');
  }
  const $table = $(this.getAttribute('data-multi-select-source'));
  const link = $(this)[0];

  $table.on('change', function () {
    const { count } = countConditionalCheckbox($table, 'data-checkbox-condition="notified"');
    link.setAttribute('data-multi-select-confirm-swal', buildMessage(link, count));
  });
});

onmount('#change_payment_date_modal', function () {
  const $table = $('#pending_boleta_orders_table');
  const link = $('#change_payment_date_button')[0];
  $table.on('change', function () {
    const originalMsg = link.getAttribute('data-multi-select-original-swal');
    const { count } = countConditionalCheckbox($table, 'data-fecha-pago="true"');
    if (count > 0) {
      link.setAttribute('data-multi-select-confirm-swal', originalMsg);
    }
    else {
      link.setAttribute('data-multi-select-confirm-swal', '');
    }
  });
});

onmount('#new_boleta_order', function () {
  $('#service-assign-modal').on('hidden.bs.modal', function () {
    const $servicesSelect = $('#select2-service-assigns-service-id');
    $servicesSelect.val([]).change();
  });
});

onmount('#edit_boleta_order', function () {
  $('#service-assign-modal').on('hidden.bs.modal', function () {
    const $servicesSelect = $('#select2-service-assigns-service-id');
    $servicesSelect.val([]).change();
  });
});

onmount('#pending_boleta_orders_table', function () {
  const toShow = document.querySelectorAll("a[data-multi-select-toggle='1']");
  const toHide = document.querySelectorAll("a[data-multi-select-toggle='0']");
  const $table = $(this);

  /**
   * Cuenta las filas chequeadas de la tabla que cumplan cierta condición y
   * muestra, esconde y/o deshabilita las que tengan el atributo 'data-multi-select-toggle'
   * Además, muestra y esconde un tooltip dependiendo de la condición.
   */
  $('#toggle-actions-by-condition').on('click', function () {
    const { count: notifiedCount } = countConditionalCheckbox($table, 'data-checkbox-condition="notified"');
    const { count: generatedCount } = countConditionalCheckbox($table, 'data-checkbox-condition="generated"');
    const { count: validatedCount } = countConditionalCheckbox($table, 'data-checkbox-condition="validated"');
    if (validatedCount > 0 && notifiedCount === 0 && generatedCount === 0) {
      toShow.forEach((e) => {
        $(e).hide();
      });
      toHide.forEach((e) => {
        $(e).show();
      });
    }
    else if (validatedCount > 0 && (notifiedCount > 0 || generatedCount > 0)) {
      toShow.forEach((link) => {
        const $link = $(link);
        $link.show();
        $link.tooltip('enable');
        $link.addClass('disabled');
        $(link.parentElement).addClass('disabled');
      });
      toHide.forEach((e) => {
        $(e).hide();
      });
    }
    else {
      toShow.forEach((link) => {
        const $link = $(link);
        $link.tooltip('disable');
        $link.removeClass('disabled');
        $link.show();
        $(link.parentElement).removeClass('disabled');
      });
      toHide.forEach((link) => {
        $(link).hide();
      });
    }
  });
});
