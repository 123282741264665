/** @file TODO: documentar */
import onmount from 'onmount';

onmount('[data-result-scale="true"]', function () {
  const self = this;

  function wireMinMaxValues() {
    const rows = $('[data-result-scale="true"]').find('[data-result-scale="row"]:visible');
    const reversedRows = $(rows.get().reverse());

    let minInput = '#' + reversedRows.first().find("[data-result-scale-field='min']").attr('id');

    reversedRows.slice(1).each(function () {
      const row = $(this);
      row.find("[data-result-scale-field='max']").data('value-change-input', minInput).keyup();
      minInput = '#' + row.find("[data-result-scale-field='min']").attr('id');
    });
  }

  const config = { attributes: false, childList: true, subtree: false };
  const callback = function (mutationsList, _observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        wireMinMaxValues();
      }
    }
  };

  const inputs = $(self).find('input[type="text"],input[type="number"]');
  inputs.each(function () {
    const inp = $(this);
    const val = inp.val();
    const parent = inp.closest('.form-group');
    parent.toggleClass('has-error', val.length === 0);

    inp.on('change', function () {
      parent.removeClass('has-error');
    });
  });

  const observer = new MutationObserver(callback);
  observer.observe(self, config);
  $(self).find('.observe').each((_, el) => { observer.observe(el, config); });
});

onmount('input[data-id^=result_scale_max_]', function () {
  const $self = $(this);

  $self.on('change', function () {
    const position = $self.attr('data-input-position');
    const nextMinInputId = $self.data('id').replace('max', 'min').replace(position, $self.attr('data-bounded-to'));
    const $nextMinInput = $(`input[data-id=${nextMinInputId}]`);
    if (!$nextMinInput) {
      return;
    }

    $nextMinInput.val((parseFloat($self.val()) + 0.01).toFixed(2));
  });
});

onmount('div[data-id$=_scale_results_ranges]', function () {
  const $self = $(this);
  const config = { childList: true };
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      const $addedNode = $(Array.from(mutation.addedNodes).pop());
      const $removedNode = $(Array.from(mutation.removedNodes).pop());
      if ($addedNode !== undefined) {
        const $maxInput = $addedNode.find('input[data-id^=result_scale_max_]');
        const $minInput = $addedNode.find('input[data-id^=result_scale_min_]');
        const $rangesDiv = $addedNode.closest('#original, #other');

        const scaleType = $rangesDiv.data('scale-type');
        const allMaxInputs = Array.from($rangesDiv.find(`input[data-id^=result_scale_max_${scaleType}_]`));
        const $lastMaxInput = $(allMaxInputs.pop());
        const newPosition = parseInt($lastMaxInput.attr('data-bounded-to'));

        $lastMaxInput.attr('step', 0.01);
        $minInput.attr('data-id', `result_scale_min_${scaleType}_${newPosition}`);
        $minInput.attr('data-input-position', newPosition);
        $minInput.val((parseFloat($lastMaxInput.val()) + 0.01).toFixed(2));
        $maxInput.attr('data-id', `result_scale_max_${scaleType}_${newPosition}`);
        $maxInput.attr('data-input-position', newPosition);
        $maxInput.attr('data-bounded-to', newPosition + 1);
        $maxInput.attr('step', 1);
        $maxInput.val((parseFloat($lastMaxInput.val()) + 1).toFixed(2));
      }
      else if ($removedNode !== undefined) {
        const scaleType = $self.data('scale-type');

        const $removedMaxInput = $removedNode.find('input[data-id^=result_scale_max_]');
        const $removedMinInput = $removedNode.find('input[data-id^=result_scale_min_]');
        const inputPosition = $removedMaxInput.attr('data-input-position');
        const $boundedMax = $self.find(`input[data-bounded-to=${inputPosition}]`);
        const removedBoundedTo = $removedMaxInput.attr('data-bounded-to');
        const $boundedMin = $self.find(`input[data-id=result_scale_min_${scaleType}_${removedBoundedTo}]`);

        if ($boundedMax !== undefined) {
          $boundedMax.attr('data-bounded-to', removedBoundedTo);
        }

        if ($boundedMin !== undefined) {
          $boundedMin.val($removedMinInput.val());
        }

        if ($removedMaxInput.attr('step') === '1' && $boundedMax !== undefined) {
          $boundedMax.val(parseInt($boundedMax.val()));
          $boundedMax.attr('value', parseInt($boundedMax.val()));
          $boundedMax.attr('step', 1);
        }
      }
    });
  });

  observer.observe($self.get(0), config);
});
