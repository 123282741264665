/** @file Comportamientos del index de nomina electrónica **/

$(document).ajaxComplete(function (_, request, settings) {
  if (settings.url.includes('integrations/nomina_electronica/index')) {
    const {
      approved,
      closedSettlements,
      collaborators,
      rejected,
      notAllSettlementsClosed,
      employeesWithoutSecondLastName,
    } = request.responseJSON;
    const kpiCollaborators = $('#ep-kpi-collaborators-value');
    kpiCollaborators.html(collaborators);
    const kpiApproved = $('#ep-kpi-approved-value');
    kpiApproved.html(approved);
    const kpiRejected = $('#ep-kpi-rejected-value');
    kpiRejected.html(rejected);
    const kpiClosedSettlements = $('#ep-kpi-closed-settlements-indicator-value');
    kpiClosedSettlements.html(closedSettlements);
    const warningLabel = $('#closed_settlements_warning_label');
    const settlementsTooltip = $('#closed_settlements_tooltip');
    if (notAllSettlementsClosed) {
      warningLabel.css('display', 'inline');
      settlementsTooltip.css('display', 'inline');
    }
    const employeesWithoutSecondLastNameWarning = $('#employees_without_second_last_name_warning_label');
    const employeesWithoutSecondLastNameTooltip = $('#employees_without_second_last_name_tooltip');
    if (employeesWithoutSecondLastName) {
      employeesWithoutSecondLastNameWarning.css('display', 'inline');
      employeesWithoutSecondLastNameTooltip.css('display', 'inline');
    }
  }
});

$('#tabla_envios_nomina_electronica').on('click', function () {
  setEnvioMasivoNominaSwalMessages();
});

function setEnvioMasivoNominaSwalMessages() {
  const $link = $('[data-multi-select-form="#envio-masivo-nomina"]');
  if ($link.hasClass('disabled') !== 'true') return;

  let collaboratorsWithValidationErrors = 0;
  const $source = $('#datatable-nomina-electronica-employees');
  $source.data('DataTable').$('input[type=checkbox]:checked').each(function () {
    const $checkbox = $(this);
    const $row = $checkbox.closest('tr');
    const $estadoValidacionTd = $row.find('td:eq(5)'); // Changed to find the 6th column (index 5)
    if ($estadoValidacionTd.text().trim() === 'Error') {
      collaboratorsWithValidationErrors++;
    }
  });

  if (collaboratorsWithValidationErrors === 0) {
    $link.attr('data-multi-select-confirm-swal',
      $link.attr('envio-masivo-nomina-non-collaborators-with-validation-errors-confirm-message-swal'));
    $link.attr('data-confirm-swal-secondary-message', '');
  }
  else {
    if (collaboratorsWithValidationErrors === 1) {
      $link.attr('data-multi-select-confirm-swal',
        $link.attr('envio-masivo-nomina-collaborators-with-validation-errors-singular-confirm-message-swal'));
    }
    else {
      $link.attr('data-multi-select-confirm-swal',
        $link.attr('envio-masivo-nomina-collaborators-with-validation-errors-plural-confirm-message-swal')
          .replace(/\[x\]/, collaboratorsWithValidationErrors));
    }
    $link.attr('data-confirm-swal-secondary-message',
      $link.attr('envio-masivo-nomina-collaborators-with-validation-errors-secondary-message-swal'));
  }
}
