/** @file TODO: documentar */
import onmount from 'onmount';
import { warning } from '../../../../../../../../app/assets/javascripts/lib/buk/alert';

// TODO: mover a datepicker.js

onmount('[data-date-warn-date-entry]', async function () {
  const { default: moment } = await import('moment');
  const dataDateBefore = moment($(this).data('dateWarnDateBefore'), 'DD-MM-YYYY');
  const dataDateAfter = moment($(this).data('dateWarnDateAfter'), 'DD-MM-YYYY');
  const dataDateEntry = moment($(this).data('dateWarnDateEntry'), 'DD-MM-YYYY');
  $(this).on('change', () => {
    const thisDate = $(this).datepicker('getDate');
    if (!thisDate) {
      return;
    }
    const thisMoment = moment(thisDate);
    let msg = '';
    if (thisMoment.isBefore(dataDateBefore)) {
      msg += `<p>Está seleccionando una fecha (${thisMoment.format('DD-MM-YYYY')}) anterior al mes abierto.
      No se actualizará la fecha de inicio del primer trabajo.</p>`;
    }
    if (thisMoment.isAfter(dataDateAfter)) {
      msg += `<p>Está seleccionando una fecha (${thisMoment.format('DD-MM-YYYY')}) posterior al mes abierto.</p>`;
    }
    if (thisMoment.isAfter(dataDateEntry)) {
      msg += `<p>En caso de colocar una fecha de ingreso posterior a las fechas de desasignación de las políticas
      asignadas, estas serán eliminadas. Para volver a agregarlas debe ir al modal de asignación.</p>`;
    }
    else {
      msg += `<p>No se cambiarán las Fechas de Inicio de Vigencia de las políticas,
      en caso de querer cambiarlas dirigirse al modal de asignación.</p>`;
    }
    warning(msg);
  });
});
