/** @file Da formato a un elemento input de tipo number para limitar el número
 * de decimales que pueda ingresar el usuario.
 * Se considera que un número es decimal/float cuando el valor del input contiene un punto (.)
 *
 * Recibe como parámetro el número máximo de decimales que puede tener el input.
 * @example
 *   <input input-max-decimal = "1" />
 *   <%= f.number_field 'input-max-decimal: 1' %>
 *
*/
import onmount from 'onmount';

onmount('[input-max-decimal]', function () {
  $(this).on('input', () => {
    const number = $(this).val();
    const maxDecimals = $(this).attr('input-max-decimal');

    const dotIndex = number.indexOf('.');
    const isFloatNumber = dotIndex > 0;
    // Si el número es float
    if (isFloatNumber) {
      const decimalsQuantity = number.slice(dotIndex + 1);
      // Si tiene más de los decimales máximos, corta el valor del input hasta los decimales permitidos
      if (decimalsQuantity.length > maxDecimals) {
        const indexOfLastDecimal = dotIndex + (parseInt(maxDecimals) + 1);
        const slicedNumber = number.slice(0, indexOfLastDecimal);

        $(this).val(slicedNumber);
      }
    }
  });
});
