/** @file Integra buk en amplitude */

import { forEach, isPlainObject } from 'lodash-es';

const instanceCode = process.env.AMPLITUDE_INSTANCE || 'cc5aec11117f5dbde31c3679aba1421f';

const bannedActions = {
  'employees': ['show'],
};
/**
 * Dado un objeto con profundidad arbitraria, retorna un objeto "aplanado",
 * donde foo.bar se convierte en foo_bar
 *
 * Es necesario porque amplitude no soporta objetos nesteados
 * */
function flattenObject(obj) {
  const res = {};
  forEach(obj, (value, key) => {
    if (isPlainObject(value)) {
      forEach(flattenObject(value), (subValue, subkey) => {
        res[`${key}_${subkey}`] = subValue;
      });
    }
    else {
      res[key] = value;
    }
  });
  return res;
}

// No se puede await en el top-level :(
import('amplitude-js/amplitude.esm').then(({ default: amplitude }) => {

  const instance = amplitude.getInstance();
  instance.init(instanceCode);
  window.amplitude = instance;

  function parseJSON(json) {
    return JSON.parse(json || 'null');
  }
  function processUserInfo() {
    const userInfo = parseJSON(document.getElementById('buk-user-info')?.text);
    if (!userInfo) return;
    const userHeight = window.screen.availHeight;
    const userWidth = window.screen.availWidth;
    userInfo['screen_resolution'] =  `${userWidth} x ${userHeight}`;
    if (sessionStorage.getItem('benefitSource')) {
      userInfo['benefit_source'] = sessionStorage.getItem('benefitSource');
    }
    instance.setUserId(userInfo.email);
    const identify = new amplitude.Identify();

    const flattenedUserInfo = flattenObject(userInfo);
    flattenedUserInfo.permission_role = !!userInfo.permission_role;
    forEach(flattenedUserInfo, (value, key) => identify.set(key, value));
    instance.identify(identify);
  }

  function getRequestInfo() {
    return parseJSON(document.getElementById('buk-request-info')?.text);
  }

  function registerPageView() {
    const requestInfo = getRequestInfo();
    if (requestInfo && (document.referrer === '' || document.referrer !== location.origin + location.pathname)) {
      const controller = requestInfo.controller;
      const action = requestInfo.action;
      if (controller && controller === 'benefit/benefits' && sessionStorage.getItem('benefitSource')) {
        requestInfo['source'] = sessionStorage.getItem('benefitSource');
      }
      const isBanned = bannedActions[controller] && bannedActions[controller].includes(action);
      if (!isBanned) {
        processUserInfo();
        requestInfo.host = location.host;
        instance.logEvent(`Visit ${controller}/${action}`, requestInfo);
      }
    }
  }

  /** Registra un evento submit. Para registrar este evento debe incluirse la propiedad
   * data-track-submit en el form, conteniendo un objeto json. Este objeto json tiene las
   * siguientes propiedades:
   *
   * - event: obligatorio. Es el nombre del form en amplitude
   * - attach: Opcional. Si es un string, indica un selector jquery de valores del formulario a incluir en el evento
   */
  function registerSubmit(ev) {
    const $target = $(ev.target);
    const trackData = $target.data('trackSubmit');
    if (!trackData || ! trackData.event) {
      return;
    }
    if (trackData.attach) {
      const src = trackData.attach === true ? $target : $target.find(trackData.attach);
      const formData = src.serializeHash();
      delete formData.authenticity_token;
      delete formData.utf8;
      trackData.formData = formData;
    }
    instance.logEvent(`Submit ${trackData.event}`, trackData);
  }

  function registerAmplitudTracking(_, data) {
    processUserInfo();
    instance.logEvent(data.message, data.data);
  }

  $(registerPageView);
  $(document).on('turbolinks:load', registerPageView);
  $(document).on('submit', 'form[data-track-submit]', registerSubmit);
  $(document).on('amplitudeTracking', registerAmplitudTracking);
  $(document).trigger('amplitude_init');
});
