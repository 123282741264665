/** @file
*
* Se encarga de homologar los estilos de los datatables mientras se migran las vistas a fiji.
* TODO: una vez migradas todas las vistas, eliminar este archivo.
*/
import onmount from 'onmount';

/**
 * Método para homologar los estilos de los datatables.
 *
 * @param {Array} classToHomologate clases que se homologarán.
 * @param {HTMLElement} tableResposive elemento que contiene la tabla bootstrap.
 * @param {Array} classList lista de clases del elemento table que se homologará.
 */

function homologateFijiStyles({ element, classToHomologate }) {
  const classList = element.attr('class').split(/\s+/);
  element.addClass('fiji-table');
  classList.forEach((className) => {
    if (classToHomologate.includes(className)) {
      element.removeClass(className);
    }
  });
  element.find('tfoot')?.remove();
}

/**
 * Verifica si un elemento con una etiqueta específica está presente dentro del selector dado.
 *
 *
 * @param {string} selector - Un selector CSS utilizado para ubicar el elemento padre.
 * @param {string} tag - El nombre de la etiqueta que se busca dentro del elemento seleccionado.
 * @returns {boolean} - Retorna `true` si se encuentra la etiqueta, de lo contrario `false`.
 */
const hasElementWithTag = (selector, tag) => $(selector).find(tag).length > 0;

/**
* @param {Function} homologateTables función que homologa los estilos de las tablas.
* @param {String} selectorItem selector de las tablas a homologar.
* @param {Boolean} urlEmployee indica si la url actual es ficha de empleados, no aplica estilos.
*/

function homologateTables(tableArrayElements) {
  const urlListToAvoid = ['employees', 'portal'];
  const urlToAvoid = urlListToAvoid.some((url) => window.location.href.includes(url));
  const isTableInCkeditor = hasElementWithTag('.ck-editor', 'table');
  const nestedForms = hasElementWithTag('.with_nested_form', 'table');
  if (urlToAvoid || isTableInCkeditor || nestedForms) {
    return;
  }
  tableArrayElements.forEach(function (selectorItem) {
    if($(selectorItem).length === 0) return;
    $(selectorItem).each(function () {
      homologateFijiStyles({
        element: $(this),
        classToHomologate: ['table-bordered', 'table-striped', 'table'],
      });
    });
  });
}

/**
* @param {Function} homologateTables función que homologa los estilos de las tablas.
*/

onmount('.dataTable, .table', function () {
  homologateTables(['.dataTable', '.table']);
});
