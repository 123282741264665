/** @file
 * Incluimos este js en el login para redireccionar cuando existan urls de redireccion
 * utiles en caso de SSO
 */
import onmount from 'onmount';
import { action } from '../../../../../../../app/assets/javascripts/lib/buk/alert.js';

onmount('#login-form', function () {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const redirectTo = urlParams.get('redirect_to');

  if (redirectTo) {
    action('No se pudo completar el inicio de sesión',
      'Ir al proveedor SSO',
      null,
      'warning',
      `Parece que nos estas registrado en Buk.
      Necesitamos redireccionarte a tu proveedor SSO para que gestiones tu cuenta con ellos.
      ¡Esperamos volver a verte pronto!`,
      ''
    ).then((
      result) => {
      if(result.isConfirmed) {
        window.open(redirectTo, '_blank');
      }
    });
  }
});
