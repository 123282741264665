/** @file Comportamientos del formulario de creación/edición de órdenes de boleta **/
import onmount, { $ } from 'onmount';

/**
*
* Listener del selector de empleados en el formulario de órdenes de boleta.
* - Oculta el checkbox de notificar la orden dependiendo de si el empleado seleccionado tiene usuario o no.
* - Habilita el botón de "Basarse en última orden" dependiendo de si el empleado seleccionado tiene órdenes previas.
*
*/
onmount('.boleta-orders-employee-select2', function () {

  function handleCheckboxBehavior(selected, infoTooltip, notifyCheckbox, notifyCheckboxLabel) {
    // Chequea si el empleado seleccionado tiene usuario asociado
    if (selected.attr('data-has-user') === 'true') {
      notifyCheckbox.prop('disabled', false);
      infoTooltip.attr('data-original-title', notifyCheckboxLabel.attr('enabled-tooltip'));
    }
    else {
      notifyCheckbox.prop('disabled', true);
      notifyCheckbox.prop('checked', false);
      infoTooltip.attr('data-original-title', notifyCheckboxLabel.attr('disabled-tooltip'));
    }
  }

  function handleLastOrderButton(selected, button) {
    if (selected.attr('data-has-previous-orders') === 'true') {
      button.removeClass('disabled');
      button.tooltip('disable');
      button.removeAttr('data-confirm-swal-disable');
    }
    else {
      button.addClass('disabled');
      button.tooltip('enable');
      button.attr('data-confirm-swal-disable', 'true');
    }
  }

  const defaultNoSelectedText = this[0].innerHTML;
  const basedOnLastOrderButton = $('#based-on-last-order-button');
  const notifyCheckbox = $('input[type="checkbox"][id="solicitud_de_emision"]');
  const notifyCheckboxLabel = $('#solicitud-emision-boleta-label');
  const infoTooltip = notifyCheckboxLabel.find("span[data-toggle='tooltip']");

  // Acciones iniciales
  basedOnLastOrderButton.tooltip('disable');

  const initialSelected = $(this.selectedOptions[0]);
  // Para cuando se llega al formulario desde la ficha
  if (initialSelected[0].innerHTML !== defaultNoSelectedText) {
    handleCheckboxBehavior(initialSelected, infoTooltip, notifyCheckbox, notifyCheckboxLabel);
    handleLastOrderButton(initialSelected, basedOnLastOrderButton);
  }

  $(this).on('change', function () {
    const selectedOption = $(this.selectedOptions[0]);
    if (selectedOption[0].innerHTML === defaultNoSelectedText) {
      basedOnLastOrderButton.tooltip('disable');
      basedOnLastOrderButton.addClass('disabled');
      basedOnLastOrderButton.attr('data-confirm-swal-disable', 'true');
      notifyCheckboxLabel.hide();
      return;
    }
    // Chequea si el empleado seleccionado tiene usuario asociado
    setTimeout(() => {
      handleCheckboxBehavior(selectedOption, infoTooltip, notifyCheckbox, notifyCheckboxLabel);
      // Chequea si el empleado seleccionado tiene órdenes previas
      handleLastOrderButton(selectedOption, basedOnLastOrderButton);
    }, 10);
  });
});

/**
*
* Función para setear el mensaje del confirm-swal cada vez que se clickea el botón del dropdown
* Esto se hace porque el sweet-alert elimina el atributo luego de submitear el formulario
*
*/
onmount('#add-services-dropdown', function () {
  $(this).on('click', function (_) {
    const basedOnLastOrderButton = $('#based-on-last-order-button');
    basedOnLastOrderButton.attr('data-confirm-swal', basedOnLastOrderButton.attr('data-confirm-original-message'));
  });
});
