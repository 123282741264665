/** @file Definir Swiper para Carrusel de Beneficios */

import onmount from 'onmount';

onmount('#carousel-widget', async function () {
  const { Swiper } = await import('swiper');
  const { Navigation, Pagination, Autoplay } = await import('swiper/modules');
  Swiper.use([Navigation, Pagination, Autoplay]);

  new Swiper('.BenefitCarousel', {
    direction: 'horizontal',
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 10,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    createElements: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      disabledClass: 'swiper-button-disabled',
    },
    breakpoints: {
      960: {
        spaceBetween: 30,
        speed: 2500,
      },
    },
  });
});

onmount('#carousel-card-widget', async function () {
  const { Swiper } = await import('swiper');
  const { Navigation, Pagination, Autoplay } = await import('swiper/modules');
  Swiper.use([Navigation, Pagination, Autoplay]);

  new Swiper('#carousel-card-widget', {
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      680: {
        slidesPerView: 2,
      },
      1366: {
        slidesPerView: 3,
      },
    },
  });
});
