/** @file TODO: documentar */
import onmount from 'onmount';
import { error } from '../lib/buk/notice';

onmount('[data-behavior=notificar_liquidaciones]', function () {
  let $errorMsg;
  $(this).on('ajax:error', function () {
    if($errorMsg) {
      $errorMsg.remove();
    }
    $errorMsg = error("No se pudo comenzar el proceso de envío.", "#detalle-notificacion-liquidacion");
  });
});

onmount('[href="#liquidaciones"][data-toggle=tab]', function () {
  const pageHash = window.location.hash;

  function showLiquidacion(id) {
    $('[data-liquidacion-id="' + id + '"]').trigger('click');
  }

  const hashTest = /^#liquidaciones\/(\d+)$/.exec(pageHash);
  if(hashTest) {
    $(this).tab('show');
    showLiquidacion(hashTest[1]);
  }

});
