/** @file
 * Maneja los eventos de botones del onboarding,
 * habilita y deshabilita botones dependiendo de los campos llenos o vacios,
 * modifica los estilos del stepper superior y cambia el título de la pagina.
 */
import onmount, { $ } from 'onmount';

onmount('#onboarding_box', function () {
  $("[data-id='next']").click(function () {
    const oldTitle = $('.box-title').text();
    const newTitle = $('#onboarding_box').attr('hidden_title');
    $('#step_one_panel').hide();
    $('#step_two_panel').show();
    $('.box-title').text(newTitle);
    $('#onboarding_box').attr('hidden_title', oldTitle);
    $('#step_one_background').attr('class', 'fa fa-circle fa-stack-2x text-success');
    $('#step_one_text').html('<i class="fa fa-check"></i>');
    $('#step_two_background').attr('class', 'fa fa-circle fa-stack-2x text-blue');
    $('#onboarding_cargo').focus();
  });

  $("[data-id='back']").click(function () {
    const oldTitle = $('.box-title').text();
    const newTitle = $('#onboarding_box').attr('hidden_title');
    $('#step_one_panel').show();
    $('#step_two_panel').hide();
    $('.box-title').text(newTitle);
    $('#onboarding_box').attr('hidden_title', oldTitle);
    $('#step_one_background').attr('class', 'fa fa-circle fa-stack-2x text-blue');
    $('#step_one_text').html('1');
    $('#step_two_background').attr('class', 'fa fa-circle fa-stack-2x text-muted');
  });

  $('#onboarding_box').on('input keyup change blur', modificarBtnNext);
  $('#onboarding_box').on('input keyup change blur', modificarBtnFinalize);

  // Por si nos cargaron el form con datos cambiar a next
  modificarBtnNext();
  modificarBtnFinalize();

  /**
   * Habilita y deshabilita el boton Siguiente
   */
  function modificarBtnNext() {
    var value = (
      $('#onboarding_form_nombre').val() === '' ||
      $('#onboarding_form_apellido').val() === '' ||
      $('#onboarding_form_segundo_apellido').val() === '' ||
      $('#onboarding_form_fecha_nacimiento').val() === '' ||
      $('#onboarding_form_sexo').val() === '' ||
      $('#onboarding_form_rut').val() === '' ||
      $('div.onboarding_form_rut.has-error').length > 0
    );
    $("[data-id='next']").prop('disabled', value);
  }

  /**
   * Habilita y deshabilita el boton Finalizar
   */
  function modificarBtnFinalize() {
    var value = (
      $('#onboarding_form_cargo').val() === '' ||
      $('#onboarding_form_sueldo_base').val() === '' ||
      $('#onboarding_form_afp').val() === '' ||
      $('#onboarding_form_salud').val() === ''
    );
    $("[data-id='finalize']").prop('disabled', value);
  }
});
