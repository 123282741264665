/** @file Pestaña documentos
 * Cambia el anchor a "#documentos" si hubo una recarga de la página
 * Las sub-pestañas son #tab-todos y #tab-pendientes
 * Al haber una recarga dentro de la ficha sin hacer esta validación se
 * cargará la primer pestaña de la ficha, haciendo que el usuario pierda el
 * foco de la pestaña documentos
 */
if (window.location.href.match(/employees\/\d+/)) {
  let tabId = window.location.hash; /* eslint prefer-const: "off" */
  if (['#tab-todos', '#tab-pendientes'].includes(tabId))
    window.location.replace(window.location.href.replace(tabId, '#documentos'));
  // Si el modal se sigue mostrando lo ocultamos
  if ($('.modal.fade.show').is(':visible'))
    $('body > div.modal.fade.show').click();
}
