/** @file TODO: documentar */
import { throttle, debounce } from 'lodash-es';
import onmount from 'onmount';
import * as notify from '../../../../../../../../app/assets/javascripts/lib/buk/notice';

const rawSalaryInput = '#raw_salary_modal #simulation_raw_salary';
const liquidFoundInput = '#raw_salary_modal #simulation_liquid_found';
const estimateRawButton = '#raw_salary_modal #simulation_raw_salary_button';
const taxInput = '#raw_salary_modal #simulation_tax';
const legalDiscountInput = '#raw_salary_modal #simulation_legal_discount';
const baseImpInput = '#raw_salary_modal #simulation_base_imp';
const contractTypeInput = '#raw_salary_modal #simulation_contract_type';
const gratificationInput = '#raw_salary_modal #simulation_gratification';
const liquidInput = '#raw_salary_modal #job_liquido_teorico';
const taxableAmountInput = '#raw_salary_modal #job_haberes_imponibles';
const notTaxableAmountInput = '#raw_salary_modal #job_haberes_no_imponibles';
const resultsContainer = '#raw_salary_modal #results_raw_salary_modal';
const errorMessage = '#raw_salary_modal #error';
const errorContainer = '#raw_salary_modal #net_salary_error';

onmount('[data-calcular-liquido]', function (obj) {
  const $this = $(this);
  const $form = $this.closest('form');
  const url = $this.data('calcularLiquido');

  obj.namespace = '.' + obj.id;
  obj.form = $form[0];

  function updateLiquido() {
    const data = $form.serialize().replace(/method=[A-Z]+&/i, '');
    $.post({
      url: url,
      data: data,
      dataType: 'json',
    })
      .done(function (d) {
        if (d.liquido === undefined) {
          notify.clear();
          $this.val('N/A');
          notify.error(d.error_message);
        }
        else {
          $this.val(d.liquido);
        }
      })
      .fail(function () {
        $this.val('N/A');
      });
  }

  function handleLiquido() {
    $(contractTypeInput).val($('#job_tipo_contrato option:selected').val());
    updateLiquido();
  }

  $form.on('input', ':input', debounce(handleLiquido, 500));
  $form.on('click', ".remove_record[data-target='.sueldo-base-row']", throttle(handleLiquido, 400));
  $form.on('click', '#close_modal_raw_salary_button', throttle(handleLiquido, 400));
  updateLiquido();
}, obj => $(obj.form).off(obj.namespace));

onmount('[data-calcular-bruto]', function (obj) {
  const $this = $(this);
  const $form = $this.closest('form');
  const urlCalculate = $this.data('calcularBruto');
  const urlAssets = $this.data('assetsAmounts');
  const rawFormGroup = $(rawSalaryInput).closest('.form-group');
  const liquidFormGroup = $(liquidFoundInput).closest('.form-group');

  obj.namespace = '.' + obj.id;
  obj.form = $form[0];

  function updateRawSalary() {
    blockButton();
    send(urlCalculate, showResults, enableButton);
  }

  function loadAssets() {
    cleanModal();
    send(urlAssets, displayAssets);
  }

  function blockButton() {
    $(estimateRawButton).html('Calculando ... ');
    $(estimateRawButton).attr('disabled', 'disabled');
  }
  function enableButton() {
    $(estimateRawButton).html('Calcular');
    $(estimateRawButton).removeAttr('disabled');
  }

  function cleanModal() {
    $(resultsContainer).css('display', 'none');
  }

  function send(url, successCallback, alwaysCallback = function () {}) {
    const data = $form.serialize().replace(/method=[A-Z]+&/i, '');
    $.get({
      url: url,
      data: data,
      dataType: 'json',
    })
      .done(successCallback)
      .always(alwaysCallback);
  }

  function showResults(response) {
    $(resultsContainer).removeClass('d-none');
    $(resultsContainer).addClass('d-block');
    $(taxInput).val(response.impuestos);
    $(rawSalaryInput).val(response.sueldo_base);
    $('#job_wage').val(response.sueldo_base);
    $(legalDiscountInput).val(response.descuentos_tributables);
    $(baseImpInput).val(response.base_imponible);
    $(contractTypeInput).val(response.tipo_contrato);
    $(liquidFoundInput).val(response.liquido_encontrado);
    $(gratificationInput).val(response.gratificacion);
    if ($(liquidInput).val() != 0) {
      if(response.encontrado) {
        liquidFormGroup.removeClass('has-error');
        rawFormGroup.removeClass('has-error');
        $(errorContainer).removeClass('d-block');
        $(errorContainer).addClass('d-none');
      }
      else{
        $(errorContainer).removeClass('d-none');
        $(errorContainer).addClass('d-block');
        $(errorMessage).html(response.error);
        rawFormGroup.addClass('has-error');
        liquidFormGroup.addClass('has-error');
      }
    }
  }

  function displayAssets(response) {
    $(taxableAmountInput).val(response.haberes_imponibles);
    $(notTaxableAmountInput).val(response.haberes_no_imponibles);
    $(taxableAmountInput + ', ' + notTaxableAmountInput).removeAttr('disabled');
  }

  $form.on('click', '[data-target="#raw_salary_modal"]', throttle(loadAssets, 400));
  $form.on('click', estimateRawButton, throttle(updateRawSalary, 400));
}, obj => $(obj.form).off(obj.namespace));
