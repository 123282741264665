/** @file TODO: documentar */
export default class ModalBuilder {
  /**
   * Constructor de modalbuilder
   */
  constructor() {
    // seteamos los defaults
    this.footer = `<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
  </div>`;
    this.size = 'modal-md';
    this.scroll = false;
    this.modal = null;
    this.title = null;
    this.body = null;
    this.icon = '';
    this.iconSource = 'material';
  }
  /**
   * Setea el footer que tendra el modal
   * @param {string} footer - el footer del modal (en html)
   * @return retorna la instancia ModalBuilder
   */
  setFooter(footer) {
    this.footer = footer;
    return this;
  }
  /**
   * Genera el modal, seteando el title, icon, body, footer y size configurado
   * @return retorna la instancia ModalBuilder
   */
  generateModal(tabindex = -1) {
    var template = `
      <div class="modal fade" tabindex="${tabindex}" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content no-min-height">
            <div class="modal-header">
              <div id="modal-icon-container" class="mr-2"></div>
              <h2 class="modal-title text-truncate">
                Cargando...
              </h2>
              <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                <span aria-hidden="true">
                  <span class="material-icons" aria-hidden="true">close</span>
                </span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-loader"><div class="buk-loader loader-large"></div></div>
            </div>
            ${this.footer}
          </div>
        </div>
      </div>
        `;

    this.modal = $(template);
    this.modal.find('.modal-dialog').addClass(this.size);
    this.modal.find('.modal-dialog').addClass(this.scroll);
    if (this.icon) {
      if(this.iconSource === 'material') {
        this.modal.find('#modal-icon-container')
          .append(`<span class="modal-icon material-icons"> ${this.icon} </span>`);
      }
      if(this.iconSource === 'bki_icon') {
        this.modal.find('#modal-icon-container')
          .append(`<i class="modal-icon bki-icon bki-${this.icon}"></i>`);
      }
    }
    if (this.title) {
      this.modal.find('.modal-title').html(this.title);
    }
    if (this.body) {
      this.modal.find('.modal-body').html(this.body);
    }
    if (this.id) {
      this.modal.attr('id', this.id);
    }
    this.addHiddenModal();
    return this;
  }
  /**
   * Retorna el modal
   * @return retorna el modal generado
   */
  getModal(tabindex = -1) {
    if (this.modal === null) {
      this.generateModal(tabindex);
    }
    return this.modal;
  }
  /**
   * Setea el tamaño del modal, por defecto es md
   * @param {string} size - tamaño
   * @return retorna la instancia ModalBuilder
   */
  setSize(size) {
    if (size && size.indexOf('modal-') !== 0) {
      size = 'modal-' + size;
    }
    this.size = size ? size : 'modal-md';
    return this;
  }
  /**
   * Setea el scroll en el body del modal, por defecto es false
   * @param {boolean} scroll -
   * @return retorna la instancia ModalBuilder
   */
  setScroll(scroll) {
    this.scroll = scroll === true ? 'modal-dialog-scrollable modaljs' : '';
    return this;
  }
  /**
   * Setea el icono del modal
   * @param {string} icon -
   * @return retorna la instancia ModalBuilder
   */
  setIcon(icon) {
    this.icon = icon;
    return this;
  }
  /**
   * Setea la fuente del icono
   * @param {string} iconSource - La fuente del icono
   * @return retorna la instancia ModalBuilder
  */
  setIconSource(iconSource) {
    this.iconSource = iconSource;
    return this;
  }
  /**
   * Setea el titulo
   * @param {string} title -
   * @return retorna la instancia ModalBuilder
   */
  setTitle(title) {
    this.title = title;
    return this;
  }
  /**
   * Setea el id del modal
   * @param {string} id -
   * @return retorna la instancia ModalBuilder
   */
  setId(id) {
    this.id = id;
    return this;
  }
  /**
  * setea el body para la proxima construcion del modal
  * @param {string} body
  * @return retorna la instancia ModalBuilder
  */
  setBody(body) {
    this.body = body;
    return this;
  }
  /**
  * Ejecuta el get del modal generado
  * además le setea el titulo y el body que retorna de
  * respuesta la peticion
  * @param {string} url url a realizar la peticion
  * @param {Object} params parametros a enviar en la peticion
  * @param {string} title nuevo titulo luego de la respuesta de la peticion
  * @param {string} httpMethod metodo de la petición, puede ser get o post
  * @param {string} icon nuevo icono luego de la respuesta de la peticion
  * @param {string} iconSource fuente del ícono. Puede ser: 'material' | 'bki_icon'
  */
  loadFromRemote(url, params, title, httpMethod, icon, iconSource) {
    const method = httpMethod ?? 'get';
    const source = iconSource ?? 'material';

    if (params === undefined || params === null) {
      params = {};
    }
    params.dataType = 'html';
    var self = this;
    if (title) self.modal.find('.modal-footer').remove();
    const request = method === 'get' ? $.get : $.post;
    request(url, params, function (html) {
      if (!self.modal) return;
      if (title) {
        self.modal.find('.modal-title').html(title);

        if (icon) {
          if(source === 'material') {
            self.modal.find('#modal-icon-container')
              .append(`<span class="modal-icon material-icons"> ${icon} </span>`);
          }
          if(source === 'bki_icon') {
            self.modal.find('#modal-icon-container')
              .append(`<i class="modal-icon bki-icon bki-${icon}"></i>`);
          }
        }
      }
      else self.modal.find('.modal-header').remove();
      self.modal.find('.modal-body').html(html);
      self.modal.trigger('buk:load-content');
    }).fail((data, _response, kind) => {
      if (!self.modal) return;
      self.modal
        .find('.modal-body')
        .html(
          `<div class='alert alert-danger'>
            Ocurrió un error al procesar esta accion: ${data.status} ${kind}
          </div>`
        );
    });
  }
  /**
   * Le agrega una funcion para remover la instancia del modal generado
   * @return retorna la instancia ModalBuilder
   */
  addHiddenModal() {
    var self = this;
    self.modal.on('hidden.bs.modal', function () {
      if (!self.modal) {
        return;
      }
      self.modal.remove();
      self.modal = null;
    });
    return this;
  }
  /**
   * Despliega el modal
   * @return retorna la instancia ModalBuilder
   */
  show(tabindex = -1) {
    $('body').append(this.getModal(tabindex));
    this.getModal(tabindex).modal({ show: true });
    return this;
  }
}
