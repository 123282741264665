/** @file TODO: documentar */
import onmount from 'onmount';

async function exportChart($canvas) {
  const title = $.trim($canvas.data('title'));
  const canvas = $canvas[0];

  const { default: jsPDF } = await import('jspdf');

  // Make our in-memory canvas
  const canvasWidth = parseInt($canvas.attr('width'));
  const canvasHeight = parseInt($canvas.attr('height'));

  const doc = new jsPDF('l', 'mm', 'a4');
  doc.setFontSize(20);
  doc.text(5, 15, title);

  let hratio = 0;
  let height = 0;
  let width = 0;

  //Se realiza proporcion dependiendo de la medida mas grande
  if (canvasWidth <= canvasHeight) {
    hratio = canvasWidth / canvasHeight;
    height = doc.internal.pageSize.height - 250;
    width = height * hratio;
  }
  else {
    hratio = canvasHeight / canvasWidth;
    width = doc.internal.pageSize.width - 150;
    height = width * hratio;
  }

  const newCanvasImg = canvas.toDataURL(1);
  doc.addImage(newCanvasImg, 'PNG', 10, 25, width, height);

  doc.save(`${title}.pdf`);
}

async function exportChartv2(chart, $canvas) {
  const { Chart } = await import('chart.js');
  //Se crea un canvas ficticio en el cual clonamos el gráfico y le agregamos los números a las barras
  var canvasv2 = document.createElement('canvas');
  canvasv2.width = parseInt($canvas.attr('width'));
  canvasv2.height = parseInt($canvas.attr('height'));
  document.body.appendChild(canvasv2);
  const title = $.trim($canvas.data('title'));
  $(canvasv2).data('title', title);
  const options = { ... chart.options };
  options.responsive = false;
  //Agregamos los valores al chart ficticio
  options.animation = {
    duration: 1,
    onComplete: function () {
      var chartInstance = this.chart, ctx = chartInstance.ctx;
      var max;
      ctx.font = "bold 8px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
      if (this.config.type === 'horizontalBar') {
        ctx.textAlign = 'left'; ctx.textBaseline = 'middle';
        max = chart.options.scales.xAxes[0].ticks.max || 100;
      }
      else{
        ctx.textAlign = 'center', ctx.textBaseline = 'bottom';
        max = chart.options.scales.yAxes[0].ticks.max || 100;
      }
      this.data.datasets.forEach(function (dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function (bar, index) {
          var data = dataset.data[index];
          if (data !== max) {
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          }
        });
      });
      exportChart($(canvasv2));
    },
  };
  new Chart(canvasv2.getContext('2d'), {
    type: chart.config.type,
    data: chart.data,
    options: options,
  });
  document.body.removeChild(canvasv2);
  canvasv2.remove();

}

onmount('[data-export-chart-pdf]', async function () {
  $(this).on('click', () => {
    const canvasId = $(this).data('exportChartPdf');
    const $canvas = $(canvasId);
    if ($canvas.length === 0) {
      throw new Error(`No existe el selector '${canvasId}'`);
    }
    const chart = $(canvasId).data('graph');
    $canvas.data('exporter') === 'v2' ? exportChartv2(chart, $canvas) : exportChart($canvas);
  });
});
