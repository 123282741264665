/** @file TODO: documentar */
import onmount from 'onmount';
let chartDataDriverResolver;

async function loadChartDataDriverResolver() {
  ({ chartDataDriverResolver } = await import(
    '@packs/talent/ninebox/app/assets/javascripts/components/charts/drivers'
  ));
}

onmount('canvas[data-chart="gauss"]', async function () {
  if (!chartDataDriverResolver) { await loadChartDataDriverResolver(); }
  const { GaussChart } = await import('./charts/gauss_chart');
  const container = $(this);
  const column = container.attr('data-source-column');
  const table = $(container.attr('data-source-table'));
  const tableOptional = $(container.attr('data-source-table-without-filters'));
  const arraySource = chartDataDriverResolver(container.data('source'));
  const opts = container.data();
  container.data('gaussChart', new GaussChart(container, table, tableOptional, column, arraySource, opts));
});

/**
 * Crea un gráfico de barra mostrando un histograma de las distintas fuentes de datos.
 * Soporta 2 tipos de fuentes: tabla o array.
 * {type: 'table', table: 'table_selector', columns: ['column_a', 'column_b'], show: 'immediate'}
 * {type: 'array', data: [column: 2, another_column: 3]}
 */

onmount('canvas[data-chart="histogram"]', async function () {
  if (!chartDataDriverResolver) { await loadChartDataDriverResolver(); }
  const { HistogramChart } = await import('./charts/histogram_chart');
  const container = $(this);
  let optionalSource = null;

  const source = chartDataDriverResolver(container.data('source'));
  if (container.data('optionalSource')) {
    optionalSource = chartDataDriverResolver(container.data('optionalSource'));
  }

  container.data('histogramChart', new HistogramChart(container, source, optionalSource));
});

/**
 * Crea un gráfico de barra dinamico a partir de distintas fuentes de datos.
 * Soporta 2 tipos de fuentes: tabla o array.
 * {type: 'table', table: 'table_selector', columns: ['column_a', 'column_b'], show: 'immediate'}
 * {type: 'array', data: [column: 2, another_column: 3]}
 */
onmount('canvas[data-chart="bar"]', async function () {
  if (!chartDataDriverResolver) { await loadChartDataDriverResolver(); }
  const { BarChart } = await import('./charts/bar_chart');
  const container = $(this);

  const source = chartDataDriverResolver(container.data('source'));
  const optionalSource = chartDataDriverResolver(container.data('optionalSource'));
  container.data('BarChart', new BarChart(container, source, optionalSource));
});

/**
 * Crea un gráfico radar dinamico a partir de distintas fuentes de datos.
 * Soporta 2 tipos de fuentes: tabla o array.
 * {type: 'table', table: 'table_selector', columns: ['column_a', 'column_b'], show: 'immediate'}
 * {type: 'array', data: [column: 2, another_column: 3]}
 */
onmount('canvas[data-chart="radar"]', async function () {
  if (!chartDataDriverResolver) { await loadChartDataDriverResolver(); }
  const { RadarChart } = await import('./charts/radar_chart');
  const container = $(this);

  const source = container.data('source');
  const max = container.data('max');
  const optionalSource = chartDataDriverResolver(container.data('optionalSource'));
  container.data('RadarChart', new RadarChart(container, source, optionalSource, max));
});

/**
 * Crea un gráfico de puntos dinamico a partir de distintas fuentes de datos.
 * Soporta 2 tipos de fuentes: tabla o array.
 * {type: 'table', table: 'table_selector', columns: ['column_a', 'column_b'], show: 'immediate'}
 * {type: 'array', data: [column: 2, another_column: 3]}
 */
onmount('canvas[data-chart="scatter"]', async function () {
  if (!chartDataDriverResolver) { await loadChartDataDriverResolver(); }
  const { ScatterChart } = await import('./charts/scatter_chart');
  const container = $(this);
  const source = chartDataDriverResolver(container.data('source'));

  container.data('ScatterChart', new ScatterChart(container, source));
});

/**
 * Crea un gráfico a partir de multiples datasets con labels y colores
 */
onmount('canvas[data-chart="multiple-data-bar"]', async function () {
  if (!chartDataDriverResolver) { await loadChartDataDriverResolver(); }
  const { MultipleDataBarChart } = await import('./charts/multiple_data_bar_chart');
  const container = $(this);

  const source = chartDataDriverResolver(container.data('source'));
  container.data('MultipleDataBarChart', new MultipleDataBarChart(container, source));
});
