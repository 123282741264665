/** @file maneja el formulario de tipos de vacaciones */
import onmount from 'onmount';
import { toCode as toSlug } from '../../../../../../../../app/assets/javascripts/lib/string.js';
import { actionMessage } from '../../../../../../../../app/assets/javascripts/lib/buk/alert.js';

onmount("[data-expirable-warning='true']", function () {
  const checkbox = $(this);
  let msg;
  checkbox.click(() => {
    if (checkbox.is(':not(:checked)')) {
      msg = `Desactivar esta opción no impedirá que venzan los saldos
             de vacaciones que ya tienen configurada una fecha de vencimiento`;
      actionMessage('Información', msg, 'Aceptar', null, 'info');
    }
  });
});

const toCode = (text) => {
  text = text.replace(/[$%&|<>]*/g, '');
  return toSlug(text).replace(/[^a-z0-9_]*/ig, '');
};

const initVacationDefinitionEvents = (form) => {
  const nameField = $('#vacation_definition_name', form);
  const codeField = $('#vacation_definition_code', form);

  nameField.keyup(function () {
    let name = nameField.val();
    name = toCode(name);
    codeField.val(name);
  });

  if (codeField.val() === '' && nameField.val() !== '') {
    nameField.keyup();
  }

  codeField.blur(function () {
    let name = codeField.val();
    name = toCode(name);
    codeField.val(name);
  });
};

onmount('#new_vacation_definition', function () {
  const form = $('#new_vacation_definition');
  initVacationDefinitionEvents(form);

  $('a.btn.add_fields').on('click', e => e.preventDefault());
});

onmount('#edit_vacation_definition', function () {
  const form = $('#edit_vacation_definition');
  initVacationDefinitionEvents(form);
});

export {
  initVacationDefinitionEvents
};
