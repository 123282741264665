/** @file TODO: documentar */
import onmount from 'onmount';

// La opción onlyText habilita solo las opciones de edicion de texto simple.
// La opción onlyNotify habilita solo las opciones para basicas para envio de notificaciones
// Quita opciones de tabla, html e imágenes.
export const EDITOR_OPTIONS = (isMailerTemplate = true, onlyText = false, onlyNotify = false,
  textAndLinks = false, videoLinks = false) => {
  const customFonts = ';Frutiger Light;Frutiger Bold;Handelgothic BT';
  const arrayAllowedContent = ['img[width,height,align];'];
  const options = {
    removePlugins: 'exportpdf',
    language: 'es',
    shiftMode: CKEDITOR.ENTER_BR,
    base64image: { allow_remote: false },
    disallowedContent: 'img{width,height,float}',
    extraPlugins: 'tableresize,pastefromword,table,templates,base64image,copyformatting,colorbutton,justify,' +
      'pagebreak,font',
    height: isMailerTemplate ? 200 : 800,
    contentsCss: isMailerTemplate ? ['https://cdn.ckeditor.com/4.22.1/full-all/contents.css'] :
      ['https://cdn.ckeditor.com/4.22.1/full-all/contents.css', '/document_editor.css', '/ckeditor_custom_fonts.css'],
    font_names: isMailerTemplate ? '' : customFonts,
    bodyClass: isMailerTemplate ? 'mailer-editor' : 'document-editor',
    format_tags: 'p;h1;h2;h3;pre',
    removeDialogTabs: 'image:advanced;link:advanced',
    stylesSet: [
      /* Inline Styles */
      { name: 'Marker', element: 'span', attributes: { 'class': 'marker' } },
      { name: 'Cited Work', element: 'cite' },
      { name: 'Inline Quotation', element: 'q' },
      /* Object Styles */
      {
        name: 'Special Container',
        element: 'div',
        styles: {
          padding: '5px 10px',
          background: '#eee',
          border: '1px solid #ccc',
        },
      },
      {
        name: 'Compact table',
        element: 'table',
        attributes: {
          cellpadding: '5',
          cellspacing: '0',
          border: '1',
          bordercolor: '#ccc',
        },
        styles: {
          'border-collapse': 'collapse',
        },
      },
      {
        name: 'Borderless Table', element: 'table',
        styles: { 'border-style': 'hidden', 'background-color': '#E6E6FA' },
      },
      { name: 'Square Bulleted List', element: 'ul', styles: { 'list-style-type': 'square' } },
    ],
    versionCheck: false,
  };

  let toolbar;
  if (onlyText) {
    toolbar = [
      { name: 'clipboard', items: ['Undo', 'Redo'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'styles', groups: ['styles'] },
    ];
  }
  else if(onlyNotify) {
    toolbar = [
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike'] },
      { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
      { name: 'styles', items: ['Format'] },
      { name: 'styles', groups: ['styles'] },
    ];
  }
  else if(textAndLinks) {
    toolbar = [
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike'] },
      { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
      { name: 'styles', items: ['Format'] },
      { name: 'links', items: ['Link', 'Unlink'] },
      { name: 'styles', groups: ['styles'] },
    ];
  }
  else {
    toolbar = [
      { name: 'clipboard', items: ['Undo', 'Redo', 'Source'] },
      { name: 'styles', items: ['Format'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'align', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'links', items: ['Link', 'Unlink'] },
      { name: 'insert', items: ['Table', 'PageBreak', 'base64image'] },
      { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
      { name: 'tools', items: ['Maximize'] },
      { name: 'editing', items: ['Scayt'] },
      { name: 'styles', groups: ['styles'] },
    ];
  }
  if (videoLinks) {
    CKEDITOR.plugins.addExternal('youtube', '');
    options.extraPlugins += ',youtube';
    options.youtube_disabled_fields = ['chkAutoplay', 'chkResponsive'];
    options.youtube_responsive = true;
    for (let i = 0; i < toolbar.length; i++) {
      if (toolbar[i].name === 'insert') {
        toolbar[i].items.push('Youtube');
        break;
      }
      else if (i === toolbar.length - 1) {
        toolbar.push({ name: 'insert', items: ['Youtube'] });
      }
    }
    arrayAllowedContent.push('iframe[src,style,height,width,frameborder,allowfullscreen];');
  }
  toolbar.push({ name: 'styles', items: ['Font', 'FontSize'] });
  options.toolbar = toolbar;
  options.removeButtons = '';
  options.extraAllowedContent = arrayAllowedContent.join(' ');
  return options;
};

// Hack, el CK editor no muestra las vistas de los videos al ingresar en el modo fuente
const parseContentForVideo = function () {
  const ckIframe = document.querySelector(`#${this.container.getId()} iframe`);
  if (ckIframe && ckIframe.contentDocument) {

    // Se reemplaza el contenido actual de CK editor con el contenido de la instancia
    // removiendo el atributo sandbox que genera error
    ckIframe.contentDocument.body.innerHTML = `${this.getData()}`.replace('sandbox=""', '');

    // Se buscan todos los iframes tipo video youtube
    // para agregar el atributo style por defecto para ajustar el video al tamaño del TextArea
    const videoIframeList = ckIframe.contentDocument.body.querySelectorAll('.youtube-embed-wrapper > iframe');
    Array.from(videoIframeList).forEach(videoIframe => {
      videoIframe.style = 'position:absolute;top:0;left:0;width:100%;height:100%';
    });
  }
};

onmount('[data-ck-editor]', async function (input) {
  const { loadCKeditor, CKeditorVersion, loadCKeditor5 } = await import('./load');
  const {
    RICH_EDITOR_OPTIONS,
    SIMPLE_EDITOR_OPTIONS,
    BASIC_OPTIONS,
  } = await import('@assets/javascripts/components/ckeditor/custom_configs');
  // Esta opción habilita opciones de edicion de texto simple.
  // Esta opción onlyNotify habilita la opciones basica para notificaciones
  // Quita opciones de tabla, html e imagenes
  const onlyText = $(this).data('ckOnlyTxt');
  const onlyNotify = $(this).data('ckOnlyNotify');
  const textAndLinks = $(this).data('ckTextAndLinks');
  const videoLinks = $(this).data('ckVideoLinks');

  if (CKeditorVersion() === '4') {
    await loadCKeditor();
    input.editor = CKEDITOR.replace(this, EDITOR_OPTIONS(true, onlyText, onlyNotify, textAndLinks, videoLinks));

    input.editor.on('change', async function () {
      input.editor.updateElement();
    });

    if (videoLinks) {
      // Al terminar de cargar
      input.editor.on('instanceReady', parseContentForVideo);

      // Al Salir del modo fuente
      input.editor.on('mode', function (event) {
        if (event.editor.mode === 'wysiwyg') { parseContentForVideo.bind(this)(); }
      });
    }
  }
  else {
    let editorConfig = RICH_EDITOR_OPTIONS(videoLinks);
    if (textAndLinks) {
      editorConfig = BASIC_OPTIONS();
    }

    if (onlyText || onlyNotify) {
      editorConfig = SIMPLE_EDITOR_OPTIONS();
    }

    await loadCKeditor5(this, input, editorConfig);
  }
});
