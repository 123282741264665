
/** @file
 * Manejar el comportamiento del la funcionalidad `Permitir cargar ítems en el futuro`
 * y `Reajuste porcentual`, si una esta activa la otra no lo puede estar.
 * */

import onmount from 'onmount';

onmount("[data-import-action='bonos_predefinidos']", function () {
  const $container = $(this);
  const $uploadFormUseStartDate = $container.find('#bonos_predefinido_upload_form_use_start_date');
  const $uploadFormPercentageReadjustment = $container.find('#bonos_predefinido_upload_form_percentage_readjustment');

  dependentDeactivation($uploadFormUseStartDate, $uploadFormPercentageReadjustment);
  dependentDeactivation($uploadFormPercentageReadjustment, $uploadFormUseStartDate);
});

function dependentDeactivation($emit, $target) {
  $emit.change(function () {
    const element = $(this);
    if (element.is(':checked')) {
      $target.prop('checked', false);
    }
  });
}
