/** @file TODO: documentar */

/**
 * Popup de confirmación para hacer requerimiento ajax
 * @param {string} url URL del requerimiento
 * @param {string} title Título del popup
 * @param {string} confirmButtonText  Texto del botón de confirmación
 * @param {string} text Mensaje del popup
 * @param {string} errorMessage Mensaje de error si falla el requerimiento
 * @param {string} method Método del requerimiento: 'POST', 'GET', etc.
 */
async function confirmBox(url, title, confirmButtonText, text, errorMessage, method) {
  const { default: swal } = await import('sweetalert2/dist/sweetalert2.js');
  return await swal.fire({
    title: title,
    confirmButtonText: confirmButtonText,
    text: text,
    showLoaderOnConfirm: true,
    showCancelButton: true,
    reverseButtons: true,
    cancelButtonText: 'No por el momento',
    preConfirm: () => fetch(url, {
      method: method,
      headers: {
        'X-CSRF-Token': $('[name="csrf-token"]')[0].content,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .catch(() => { swal.showValidationMessage(errorMessage); })
  });
}

/**
 * Popup de confirmación para hacer requerimiento ajax de tipo GET
 * @param {string} url URL del requerimiento
 * @param {string} title Título del popup
 * @param {string} confirmButtonText  Texto del botón de confirmación
 * @param {string} text Mensaje del popup
 * @param {string} errorMessage Mensaje de error si falla el requerimiento
 */
function get(url, title, confirmButtonText, text, errorMessage) {
  return confirmBox(url, title, confirmButtonText, text, errorMessage, 'GET');
}

/**
 * Popup de confirmación para requerimiento ajax de tipo POST
 * @param {string} url URL del requerimiento
 * @param {string} title Título del popup
 * @param {string} confirmButtonText  Texto del botón de confirmación
 * @param {string} text Mensaje del popup
 * @param {string} errorMessage Mensaje de error si falla el requerimiento
 */
function post(url, title, confirmButtonText, text, errorMessage) {
  return confirmBox(url, title, confirmButtonText, text, errorMessage, 'POST');
}

export { get, post };
