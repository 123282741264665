/** @file
 * Archivo de marcas de amplitud para exportadores export_centers
 */
import onmount from 'onmount';

onmount('div#export-exportador', async function () {
  $('button.button-export-exportador').on('click', function () {
    // Obtener el botón en el que se hizo clic
    const element = $(this);
    // Obtener los datos específicos de este botón
    const importData = {
      'source': element.attr('data-report-source'),
      'report_name': element.attr('data-template-name'),
    };
    // Enviar los datos a la función de seguimiento con Amplitude
    sendAmplitudeTracking(element, 'Exportar_Plantilla_Exportador', importData);
  });
});

function sendAmplitudeTracking(element, message, data) {
  element.trigger('amplitudeTracking', {
    message: message,
    data: data,
  });
}
