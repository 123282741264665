/** @file TODO: documentar */
/**
 * Al setear data-auto-change-url en un input, se actualizara la URL
 * agregando el valor del input al query string.
 *
 * En caso de ya existir el parametro, se reemplaza, para no pasar multiples
 * veces el parametro
 */

import onmount from 'onmount';
import Turbolinks from 'turbolinks';

function replaceParam(url, parameter, value) {
  var search = value ? parameter + '=' + encodeURIComponent(value) : '',
    newUrl = url.replace(new RegExp(parameter + '=[^&]+(&|$)', 'i'), "").replace(/(\?|&)$/, ''),
    joiner = newUrl.indexOf('?') === -1 ? '?' : '&';
  if (search) {
    newUrl = newUrl + joiner + search;
  }
  return newUrl;
}

function replaceQueryParameter(parameter, value) {
  const newUrl = replaceParam(document.URL, parameter, value);

  Turbolinks.visit(newUrl);
}

onmount(':input[data-auto-change-url]', function () {
  const name = $(this).attr('name');
  if (!name) {
    return;
  }
  $(this).on('change', () => {
    let cur = $(this);
    if (cur.is(':checkbox')) {
      cur = cur.filter(':checked');
    }

    replaceQueryParameter(name, cur.val());
  });
});
