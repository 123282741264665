/** @file TODO: documentar */
import onmount from 'onmount';

onmount('[data-working-day-input]', function () {
  const $this = $(this);

  const saveButton = $this.find('button[type=submit]');
  const input = $this.find(`[name="${$.escapeSelector('working_day[dias_laborales]')}"]`);

  let origValue = input.val();

  input.on('change', () => {
    saveButton.prop('disabled', input.val() == origValue);
  });

  saveButton.on('after.success.ic', () => {
    origValue = input.val();
    saveButton.prop('disabled', true);
  });
});
