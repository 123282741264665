/** @file TODO: documentar */
/**
 *
 * Maneja el envío de formularios que necesitan tomar checkboxes desde una tabla de
 * Datatable u otro contenedor.
 *
 * El envío del formulario se realiza al dar click a un enlace que requiere de los
 * siguientes atributos:
 *
 * 'data-multi-select-form' es el identificador del formulario a enviar y al que
 *    se le adjuntarán los checkboxes chequeados.
 *
 * 'data-multi-select-source' es el identificador del contenedor de checkboxes.
 * 'data-multi-select-confirm' es el mensaje que se mostrara usando la función confirm nativa
 * 'data-multi-select-confirm-swal' es el mensaje de confirmación que mostrara usando sweet alert
 * 'data-multi-select-heavy-form' es el formulario en el caso que necesites hacer peticiones de mayor tamaño
 * 'data-multi-select-form-threshold' es el umbral para que se haga efectivo el cambio al formulario pesado
 */

import onmount from 'onmount';
import {
  prepareLinkContainer,
  handleLinkContainer,
  countCheckbox,
  toogleContainerDisplay
} from "./multi-select-common.js";
import { action, actionMessage } from '../lib/buk/alert';

onmount('[data-multi-select-form]', async function () {
  const $source = $(this.getAttribute('data-multi-select-source'));
  const sourceIsDatatable = $source.data('DataTable');
  if (sourceIsDatatable) {
    await $source.waitDataTable();
  }

  const form = $(this.getAttribute('data-multi-select-form'));
  const link = $(this);
  const confirmMsg = this.getAttribute('data-multi-select-confirm');
  const listItem = link;
  const isBlank = this.getAttribute('data-multi-select-blank');
  const heavyForm = $(this.getAttribute('data-multi-select-heavy-form'));
  const thresholdHeavyForm = this.getAttribute('data-multi-select-form-threshold');

  // El tooltip que se agrega puede generar conflictos si se usa con un botón y se desea desplegar un modal al mismo
  // tiempo. Se usa este atributo para identificar si se desea saltar el agregar el tooltip pero mantener el disable.
  if (this.getAttribute('data-multi-select-skip-prepare-tooltip')) {
    listItem.addClass(' disabled');
    toogleContainerDisplay(listItem, false); // Se oculta el botón si está presente data-multi-select-hide
  }
  else {
    prepareLinkContainer(listItem);
  }

  const sendForm = (checkedBoxes) => {
    const useHeavyForm = (heavyForm[0] && thresholdHeavyForm && thresholdHeavyForm < checkedBoxes.length);
    const actualForm = useHeavyForm ? heavyForm : form;
    actualForm.find('input[type=checkbox]').remove();
    actualForm.find('input[data-multi-select-input=true]').remove();
    checkedBoxes
      .clone()
      .hide()
      .appendTo(actualForm);

    if(typeof actualForm[0].requestSubmit !== 'undefined') {
      actualForm[0].requestSubmit();
    }
  };

  link.on('click', function (e) {
    e.preventDefault();
    if (e.target.classList.contains('disabled')) return false; // retornamos si es que el link se encuentra deshabilitado.
    const { checkedBoxes, count } = countCheckbox($source);
    if (count === 0) return false;
    if (confirmMsg !== null && confirmMsg.length > 0) {
      if (confirm(confirmMsg) === false) {
        return false;
      }
      else {
        sendForm(checkedBoxes);
        return true;
      }
    }

    const getAttribute = (attribute) => (
      e.target.getAttribute(attribute) || link[0].getAttribute(attribute)
    );

    const confirmSwalMsg = getAttribute('data-multi-select-confirm-swal')?.replace('[X]', count);
    const subMessage = getAttribute('data-confirm-swal-secondary-message');
    const accept = getAttribute('data-confirm-swal-accept') || 'Sí';
    const decline = getAttribute('data-confirm-swal-decline') || 'No';
    const icon = getAttribute('data-confirm-swal-icon');
    const customAccept = getAttribute('data-confirm-swal-custom-accept');

    if ((confirmSwalMsg && confirmSwalMsg.length > 0) || subMessage) {
      let promise;
      if (subMessage) {
        promise = actionMessage(confirmSwalMsg, subMessage, accept, decline, icon, customAccept);
      }
      else {
        promise = action(confirmSwalMsg, accept, decline, icon, customAccept);
      }

      promise.then(result => {
        if (result.value) {
          sendForm(checkedBoxes);
          $(e.currentTarget).trigger('data-multi-select-swal-confirmed');
          return true;
        }
      });

      return false;
    }

    if (isBlank) {
      form.empty();
    }

    sendForm(checkedBoxes);
  });

  /* Al contarse los checkboxes se actualiza el número en el link */
  $source.on("checkboxCount", event => {
    handleLinkContainer(listItem, event.count);
    // Si listItem tiene la clase entonces no reemplazamos el texto del botón.
    if (listItem.hasClass('disable-name-change-multi-select')) {
      return;
    }
    link.find("span").text("(" + event.count + ")");
  });

});
