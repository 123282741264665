/** @file TODO: documentar */
import numeral from 'numeral';

/*
 * Permite extraer el valor de la celda independiente del markup de la tabla
 */
const extractors = {
  input: function extractDataFromInput(api) {
    return api.nodes().map(function (node) {
      const value = parseCurrencyWithSymbols(node.querySelector('input').value);
      return parseInt(value);
    });
  },
  api: function extractDataFromApi(api) {
    return api.data().map(number => parseFloat(parseCurrencyWithSymbols(number)));
  }
};

function parseCurrencyWithSymbols(number) {
  return number.replace('$', '').replace(/\./g, '');
}

function summaryTable() {
  var api = this.api();

  var summaryFooter = $('[data-summary="results"]');
  var summaryIndex = api.column("[data-summary]:first").index();

  const dataSummaryOnly = $('[data-summary-only]');

  if (dataSummaryOnly) {
    $('th:nth-child('+summaryIndex+')', summaryFooter).html('Total:');
  }
  else {
    $('th:nth-child('+summaryIndex+')', summaryFooter).html('Promedio <br> Media');
  }

  api.columns().every(function (column) {
    if (this.header().getAttribute('data-summary') == null)
      return;
    const extractor = this.header().getAttribute('data-extract-function') || 'api';

    // Total over all pages
    const allColumnData = extractors[extractor](api.column(column));
    const total = allColumnData.reduce(sum, 0);

    // Update footer

    if (dataSummaryOnly) {
      // Total with filter
      const filteredColumnData = extractors[extractor](api.column(column, {search: 'applied'}));
      const filteredTotal = filteredColumnData.reduce(sum, 0);

      $(`th:nth-child(${column+1})`, summaryFooter).html(
        `${numeral(filteredTotal).format('0,000.00')}<br>`
      );
    }
    else {

      const totalAverage = total / allColumnData.length;
      const totalMedian = median(allColumnData.toArray());

      // Total with filter
      const filteredColumnData = extractors[extractor](api.column(column, {search: 'applied'}));
      const filteredTotal = filteredColumnData.reduce(sum, 0);

      const filteredAverage = filteredTotal / filteredColumnData.length;
      const filteredMedian = median(filteredColumnData.toArray());

      $(`th:nth-child(${column+1})`, summaryFooter).html(
        `${filteredAverage.toFixed(2)} (${totalAverage.toFixed(2)} total) <br>`+
        `${filteredMedian.toFixed(2)} (${totalMedian.toFixed(2)} total)`
      );
    }
  });
}

function sum(a, b) {
  return a + b;
}

function median(values) {
  values = values.sort((a, b) => a - b);
  if (values.length ===0) return 0;

  var half = Math.floor(values.length / 2);
  if (values.length % 2)
    return values[half];
  else
    return (values[half - 1] + values[half]) / 2.0;
}

export default summaryTable;
