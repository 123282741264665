/** @file TODO: documentar */
import onmount from 'onmount';
import { formatCurrency } from '../lib/buk/format-currency.js';
import { limitString } from '../lib/string.js';

const backgroundColors = [
  'rgba(71, 100, 249, 0.7)',
  'rgba(124, 144, 249, 0.7)',
  'rgba(166, 206, 255, 0.7)',
  'rgba(195, 234, 255, 0.7)',
  'rgba(32, 0, 255, 0.7)',
  'rgba(10, 0, 80, 0.7)',
];

const borderColors = [
  'rgba(71, 100, 249, 1)',
  'rgba(124, 144, 249, 1)',
  'rgba(166, 206, 255, 1)',
  'rgba(195, 234, 255, 1)',
  'rgba(32, 0, 255, 1)',
  'rgba(10, 0, 80, 1)',
];
const pointBackgroundColor = ['rgba(255, 255, 255, 1)',
  'rgba(255, 255, 255, 1)',
  'rgba(255, 255, 255, 1)',
];

function htmlTooltip(tooltipModel) {
  const datasets = this._data.datasets;
  // Tooltip Element
  var tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.classList.add('tooltip');
    tooltipEl.innerHTML = `<div class="tooltip-arrow"></div>
                           <div class="tooltip-inner" style="max-width: none; text-align:left;"></div>`;
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('center', 'above', 'below', 'no-transform');

  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  }
  else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    var titleLines = tooltipModel.title || [];
    var bodyLines = tooltipModel.body.map(getBody);
    const datapoints = tooltipModel.dataPoints;

    var innerHtml = '<div>';

    titleLines.forEach(function (title) {
      innerHtml += '<div style="text-align: center">' + title + '</div>';
    });
    innerHtml += '</div>';

    bodyLines.forEach(function (body, i) {
      var style = 'background:' + (datasets[datapoints[i].datasetIndex]['backgroundColor'] || backgroundColors[i]);
      style += '; border: 1px solid ' +  borderColors[i];
      style += '; display: inline-block';
      style += '; width: 1em';
      style += '; height: 1em';
      const span = '<span style="' + style + '"></span>';

      const wrapperSpan =
        '<span style="background: white; display: inline-flex; margin-right: 0.5em">' + span + '</span>';

      const label = datasets[datapoints[i].datasetIndex].label;
      innerHtml += `<div style="white-space:nowrap;">${wrapperSpan} ${label} : ${body} </div>`;
    });

    var tooltipInner = tooltipEl.querySelector('.tooltip-inner');
    tooltipInner.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  var position = this._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  //tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.pointerEvents = 'none';
}

function calculateStepSize(max) {
  if (max < 5) return 1;
  return (max / 5);
}

onmount('canvas[data-chartjs]', async function () {
  const container = $(this);
  const type = container.data('chartjs');
  const labels = container.data('labels');
  const formatType = container.data('format_type');

  const datasets = container.data('datasets').map(function (dataset, index) {
    dataset.borderWidth = 2;
    dataset.backgroundColor = dataset.backgroundColor ? dataset.backgroundColor : backgroundColors[index];
    dataset.borderColor = dataset.borderColor ? dataset.borderColor : borderColors[index];
    dataset.pointBackgroundColor = pointBackgroundColor[index];
    return dataset;
  });

  const max = container.data('max');
  const min = container.data('min') || 0;
  let options = container.data('options') || {};

  const ticks = {
    min: min,
    max: max,
    stepSize: calculateStepSize(max),
    precision: 2,
    integerSteps: false,
  };

  options = {
    ...options,
    scales: {
      yAxes: [
        {
          ticks: {
            callback: (value) => limitString(value, 20),
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            callback: (value) => limitString(value, 40),
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: (items, data) => data.labels[items[0].index],
        label: (items, data) => `${data.datasets[items.datasetIndex].label}: ${items.xLabel}`,
      },
    },
    responsive: true,
  };

  if (type === 'bar' || type === 'radar') {
    options.tooltips.enabled = false;
    options.tooltips.custom = htmlTooltip;
    options.tooltips.callbacks.label = function (p) {
      /* if contains the formatCurrency parameter, the tooltip add $ simbol and format the value to currency */
      if (formatType === 'formatCurrency')
        return formatCurrency(p.yLabel);
      else
        return (p.yLabel);
    };
  }

  if (type === 'radar') {
    delete options.scales;
    options.tooltips.mode = 'index';
    options.tooltips.callbacks.afterTitle = function (p) {
      const tooltip = p[0];
      if(!container.data('datasets')[tooltip.datasetIndex].tooltip)
        return '';
      const originalText = container.data('datasets')[tooltip.datasetIndex].tooltip[tooltip.index];
      if(!originalText)
        return '';
      return originalText;
    };

    options.scale = {
      ticks: {
        beginAtZero: true,
        max: max || 5,
        stepSize: calculateStepSize(max) || 1,
      },
      pointLabels: {
        callback: (text) => limitString(text, 24),
      },
    };
  }

  if(type === 'polarArea') {
    delete options.scales;
    delete options.tooltips;
  }

  if(type === 'bar') {
    options.scales.yAxes = [{
      ticks: ticks,
    }];
  }

  if(type === 'horizontalBar') {
    options.scales.xAxes = [{
      ticks: ticks,
    }];
  }

  if(type === 'line') {
    options.scales.yAxes[0].ticks.stepSize = calculateStepSize(max);
  }

  const { Chart } = await import('../vendor/charts.js');

  const graph = new Chart(container, {
    type: type,
    data: {
      labels: labels,
      datasets: datasets,
    },
    options: options,
  });
  container.data('graph', graph);
});
