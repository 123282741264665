/** @file TODO: documentar */
import onmount from 'onmount';

onmount(".form-opciones-progresivas", function () {
  const $self = $(this);

  $self.on('change', 'input[type=checkbox]', function () {
    const method = $self.find('input[type=checkbox]:checked').length === 2 ? 'show' : 'hide';

    $self.find('.fecha-inicio-progresivas-wrapper')[method]();
    if(method === 'hide') {
      $self.find('.fecha-inicio-progresivas-wrapper input').val(null);
    }
  });
});
