/** @file TODO: documentar */
import { defaults, identity } from 'lodash-es';
import onmount from 'onmount';

function AutoCalcOf(element, options) {
  const self = this;
  const $element = $(element);
  options = defaults(options || {}, {
    formatFunc: identity,
  });

  this.eventMap = {
    'change autocalc input': doCalc,
  };

  const prefix = options.v2 ? '#form_visible' : '#finiquito';
  const FILAS = `${prefix}_indemnizacion_legal_por_anos_servicios`;
  const FMINR = `${prefix}_monto_indemnizacion_no_renta`;
  const FIPC = `${prefix}_indemnizacion_pactada_contractualmente`;
  const FIV = `${prefix}_indemnizacion_voluntaria`;

  this.selectors = [FILAS, FMINR, FIPC, FIV].join(',');
  $(document).on(this.eventMap, this.selectors);
  doCalc();

  async function doCalc() {
    const { isInDom } = await import('../lib/interaction-functions');
    if (!isInDom($element[0])) {
      self.destroy();
      return;
    }
    var calc = 0;

    const montoPorAnosServicio = self.getValue(FILAS);
    const montoNoRenta = self.getValue(FMINR);
    const montoPactado = self.getValue(FIPC);
    const montoVoluntario = self.getValue(FIV);

    const compensationWithoutTaxes = Math.max(montoNoRenta - montoPorAnosServicio, 0);
    calc = Math.max(montoPactado + montoVoluntario - compensationWithoutTaxes, 0);

    calc = calc || 0;

    if ($element.is(':input')) {
      $element.val(options.formatFunc(calc));
    }
    else {
      // Todo: install numeral and use here
      $element.text(options.formatFunc(calc));
    }
    $element.data('autoCalcValue', calc).trigger('autocalc').change();
  }
}

AutoCalcOf.prototype.destroy = function () {
  $(document).off(this.eventMap, this.selectors);
};

AutoCalcOf.prototype.getValue = function getValue(elem) {
  var $e = $(elem);
  if (typeof ($e.data('autoCalcValue')) !== 'undefined') {
    return +$e.data('autoCalcValue');
  }
  else if ($e.is(':input')) {
    return +$e.val();
  }
  return 0;
};

onmount('[data-autocalc-of]', function (obj) {
  var $this = $(this),
    formatFunc = $this.data('autocalcFormatFunc');
  var v2 =  Boolean($(this).attr('autocalc_of_v2'));
  if (typeof formatFunc === 'string') {
    formatFunc = window[formatFunc];
  }
  var autocalcs = [];
  $this.each(function () {
    const options = { formatFunc: formatFunc, v2: v2 };
    autocalcs.push(new AutoCalcOf(this, options));
  });
  obj.autocalcs = autocalcs;
}, function (obj) {
  (obj.autocalcs || []).forEach(i => i.destroy());
});
