/* eslint-disable buk/no-static-import */
/** @file TODO: documentar */
const ZONE = 'es';
import onmount from 'onmount';
import {
  warning, info, action as swalAction, actionMessage as swalActionMessage
} from '../../../../../../../app/assets/javascripts/lib/buk/alert.js';
import { SIMPLE_EDITOR_OPTIONS } from '@assets/javascripts/pages/document_templates.js';
import { groupBy } from 'lodash-es';
import queryString from 'query-string';
import {
  CompletionPercentageCalculator
} from '../behaviors/completion-percentage-on-change.js';
import * as notify from '../../../../../../../app/assets/javascripts/lib/buk/notice.js';

window.info = info;

$(document).on('change', '.autosave input, .autosave textarea, .autosave select', function () {
  const self = this;
  const url = $(self).parents('.autosave').data('root-path');
  const questionId = self.name.match(/attempt\[(\d*)\].*/)?.[1];

  if (!questionId) { return; }

  const data = { question_id: questionId };
  if (self.type == 'checkbox') {
    data[self.name] = $(`[name="${self.name}"]:checked`).map((_i, inp) => inp.value).toArray();
    if (data[self.name].length == 0) {
      data[self.name] = [""];
    }
  }
  // Evitar realizar auto-save para inputs de tipo archivo.
  // TODO: Cambiar el data, para que envie un formulario en vez un hash con la información
  // Esto porque los archivos no se estan enviando correctamente. Estos deben ser enviador en un Formulario.
  else if (self.type === 'file') {
    return;
  }
  else {
    data[this.name] = this.value;
  }

  $.ajax({
    url,
    data,
    method: 'PUT',
    success: function (_response) {
      $(self).closest('div').find('.errors').hide();
      if ($(self).closest('label').hasClass('special-radio-button')) {
        $('.errors').hide();
        $('div.has-error').removeClass('form-group has-error');
      }
      const now = new Date();
      $(self)
        .parents('.answer-box')
        .find('.autosave-response')
        .hide()
        .html(`Respuesta guardada ${now.toLocaleDateString(ZONE)} ${now.toLocaleTimeString(ZONE)}`)
        .fadeIn();
    },
    error: function (request) {
      const response  = request.responseJSON;
      const error = response ? response.errors : 'Ocurrió un error desconocido, por favor intente nuevamente';
      const nps = $(self).closest('label').hasClass('special-radio-button');
      const div = nps ? $('div.toggle_buttons') : $(self).closest('div');
      if(div.parents('#edit_attempt').length === 0) {
        div.find('.errors').hide();
        div.append(`<div class="errors text-danger">${error}</div>`);
        div.addClass('form-group has-error');
        div.parents('.answer-box').find('.autosave-response').html('');
      }
    },
  });
});

const updateSections = (target) => {
  let total = 0;
  const sectionSelector = target.data('section-selector');
  target.find(`${sectionSelector} > .sortable-box__title .section-measure-trigger`).each((_, e) => {
    const checked = $(e).is(":checked");
    const parent = $(e).closest(sectionSelector);
    if(checked) {
      if(parent.data('ponderable')) total += parseFloat(parent.find(`${sectionSelector}.weight-container .weight`).val() || 0);
      parent.removeClass('disabled');
      parent.find(`${sectionSelector}.activable`).prop('disabled', false);
    }
    else {
      parent.addClass('disabled');
      parent.find(`${sectionSelector}.activable`).prop('disabled', true);
    }
  });
  target.find(`${sectionSelector}.total-weight-container .total-weight`).text(total);
  $("#total_weight_sections_value").text($("#total_weight_sections").text());
  if (total == 100) {
    $("#total_weight_sections_final").removeClass("text-danger").addClass('text-success');
    target.find(`${sectionSelector}.total-weight-container`).removeClass("text-danger").addClass('text-success');
  }
  else {
    $("#total_weight_sections_final").removeClass("text-success").addClass('text-danger');
    target.find(`${sectionSelector}.total-weight-container`).removeClass("text-success").addClass('text-danger');
  }
};

$(document).on('change', '.measurable-activable .section-measure-trigger', function () {
  const target = $(this).closest('.measurable-activable');
  updateSections(target);
  const sectionSelector = target.data('section-selector');
  const checked = $(this).is(":checked");
  const parent = $(this).parents(sectionSelector);
  const boxAction = checked ? 'expand' : 'collapse';
  parent.boxWidget(boxAction);
});

$(document).on('keyup change', '.measurable-activable .weight', function () {
  updateSections($(this).closest('.measurable-activable'));
});

$(document).on('click', 'a.bs-wizard-step[data-toggle="tab"]', function () {
  $(this).addClass('completed');
});

function updateEvaluationEmployees(input) {
  const target = $($(input).data('selectTarget'));
  const id = $(input).data('id');
  const value = target.val();
  const newValue = value.includes(`${id}`) && (!$(input).is(":checked") || ($(input).is(":checked")&& !$(input).hasClass('remove-evaluated-selected')))? value.filter(i => i != id) : value.concat(id);
  target.val(newValue);
  if($("#new_employees_link").attr("href")) {
    const href = $("#new_employees_link").attr("href").split("?")[0];
    const params = newValue.map(v => `selected_ids[]=${v}`).join("&");
    $("#new_employees_link").attr('href', `${href}?${params}`);
  }
}
function showRemoveMassive() {
  let countNotVisible = 0;
  let countEmployees = 0;
  $('.seleted-employees').each(function () {
    if(!($(this).closest('tr').is(':visible'))) {
      countNotVisible = countNotVisible + 1;
    }
    countEmployees = countEmployees + 1;
  });
  if(countEmployees == countNotVisible) {
    $('.remove-evaluated-selected').hide();
  }
  else{
    $('.remove-evaluated-selected').show();
  }
}

function countEmployeesChecked() {
  let countChecked = 0;
  $('.seleted-employees').each(function () {
    // If checkbox is checked
    if(this.checked && $(this).closest('tr').is(':visible')) {
      countChecked = countChecked + 1;
    }
  });
  $("#span-remove-selected").html('('+countChecked+')');
}

function countShowMassive() {
  countEmployeesChecked();
  showRemoveMassive();
}

$(document).on('change', '.evaluated_employee, .remove-evaluated', function () {
  updateEvaluationEmployees(this);
  countShowMassive();
});

$(document).on('click', '.remove-evaluated', function () {
  updateEvaluationEmployees(this);
  countShowMassive();
});

$(document).on('click', '.seleted-employees', function () {
  countShowMassive();
});

$(document).on('change', '.seleted-all-employees', function () {
  countShowMassive();
});

$(document).on('click', '.remove-evaluated-selected', function (e) {
  e.preventDefault();
  $('.seleted-employees').each(function () {
    // If checkbox is checked
    if(this.checked) {
      updateEvaluationEmployees(this);
      $(this).prev('input[type=hidden][name*="_destroy"]').val('1');
      $(this).closest($(this).data('target') || 'tr').hide();
      this.checked = false;
      $(this).attr("disabled", true);
    }
  });
  countShowMassive();
});

//Cambiar tooltip al cambiar la escala por seccion de evaluación
$(document).on('change', '.section_scale_select', function () {
  const sectionId = $(this).data('sectionId');
  const tooltipElement = $(`#section-scale-${sectionId} [data-toggle="tooltip"][data-html]`);
  const scaleType = $(this).closest('.section').find('.section_scale_type');
  const optgroup = this.options[this.selectedIndex].parentElement;

  if (optgroup.label == 'Otros') {
    tooltipElement.attr('data-original-title', tooltipElement.attr('data-title'));
    scaleType.val(this.value);
  }
  else {
    scaleType.val('scale');
  }
});

$(document).on('change', '.employee-checkbox', function () {
  if ($(".employee-checkbox").is(":checked")) {
    $(".reminder-button").removeClass("disabled");
  }
  else {
    $(".reminder-button").addClass("disabled");
  }
});

$(document).on('click', '.reminder-button', function () {
  const url = $(location).attr("pathname") + "/reminder";
  const ids = [];
  const btnReminder = $(this);
  btnReminder.attr('disabled', true);

  $(".employee-checkbox:checked").each(
    function () {
      const id = $(this).attr("data-id");
      ids.push(id);
    });

  if (ids.length==0) {
    warning("Seleccione una evaluación");
  }
  else {
    $.ajax({
      type: "POST",
      url: url,
      data: {evaluator_person_ids: ids},
      success: function () {
        btnReminder.attr('disabled', false);
        info("Recordatorios enviados");
      },
      error: function () {
        btnReminder.attr('disabled', false);
        warning("Hubo un problema con el envio de Recordatorios");
      }
    });
  }
});

$(document).on('click', '.feedback-reminder-button', function () {
  const url = $(location).attr("pathname") + "/feedback_reminder";
  const textSendReminders = $(this).data('text-reminder');
  swalAction(textSendReminders, 'Aceptar', 'Cancelar').then(function (res) {
    if (res.value) {
      $.ajax({
        type: 'POST',
        url: url,
        success: function () {
          info('Recordatorios enviados');
        },
        error: function () {
          warning('Hubo un problema con el envio de Recordatorios');
        },
      });
    }
  });
});

$(document).on('click', '#evaluation-result-tabs a[data-toggle="tab"]', function () {
  const anchor = $(this).attr('href');
  const action = $("#evaluation-filters").attr('action');
  $("#evaluation-filters").attr('action', `${action}${anchor}`);
});

onmount("#evaluation-filters", function () {
  const anchor = $(location).attr('hash');
  const action = $(this).attr('action');
  $(this).attr('action', `${action}${anchor}`);
});
// este código debe ser borrado cuando se elimine la vista antigua de desempeño
$(document).on('change', '#talent_evaluation_process_score_type', function () {
  const resultScaleMaxScore = $('[data-max-score]');
  if($('#original').hasClass('hide')) {
    $('#other').addClass('hide');
    $("#other").find(":input").prop("disabled",true);

    $('#original').removeClass('hide');
    $("#original").find(":input").prop("disabled",false);
  }
  else{
    $('#original').addClass('hide');
    $("#original").find(":input").prop("disabled",true);

    $('#other').removeClass('hide');
    $("#other").find(":input").prop("disabled",false);
  }
  switch ($('#talent_evaluation_process_score_type').val()) {
    case 'porcentaje':
      resultScaleMaxScore.val(100);
      break;
    case 'nota':
    default:
      resultScaleMaxScore.val(5);
  }
});

onmount("#capacities-table", function () {
  function deleteSelected(url, capacitiesIds) {
    $.ajax({
      url: url,
      method: 'delete',
      data: {
        capacities_ids: capacitiesIds
      }
    });
  }
  function getCapacitiesIds() {
    const ids = [];
    $('.capacities_id:checked').each(function () {
      ids.push($(this).data('id'));
    });
    return ids;
  }
  const capacitiesLink = $('#selected_capacities');
  capacitiesLink.attr('disabled', 'true');
  capacitiesLink.on('click', function () {
    const capacitiesIds = getCapacitiesIds();
    if (capacitiesIds.length > 0) {
      if (confirm('¿Seguro desea eliminar los elementos selecionados?')) {
        deleteSelected(capacitiesLink.data('url'), capacitiesIds);
      }
    }
  });
  var count = 0;
  const maxCount = $('.capacities_id_all').data('value');
  $('.capacities_id').on('click', function () {
    count = getCapacitiesIds().length;
    capacitiesLink.attr("disabled", count == 0);
    $("#seleccionados").html(`(${count})`);
  });
  $('.capacities_id_all').on('click', function () {
    if($('.capacities_id_all').prop('checked')) {
      capacitiesLink.attr("disabled", maxCount == 0);
      $("#seleccionados").html(`(${maxCount})`);
    }
    else{
      capacitiesLink.attr("disabled", maxCount > 0);
      count = 0;
      $("#seleccionados").html(`(${count})`);
    }
  });
});

onmount('[data-auto-select-supervisor=true]', function () {
  const selector = $(this);
  selector.on('change', function () {
    const employeeId =  selector.data('id');
    const values = selector.find(':selected');
    const firstEvaluatorBoss = $(`[data-evaluator=1][data-step-kind=boss][data-employee=${employeeId}]`);
    firstEvaluatorBoss.val(values.attr('boss_id')).trigger('change');
    const selectedRow = firstEvaluatorBoss.closest('tr');
    selectedRow.find('[data-table-attribute="division_name"]').html(values.attr('division'));
    selectedRow.find('[data-table-attribute="area_name"]').html(values.attr('area'));
    selectedRow.find('[data-table-attribute="department_name"]').html(values.attr('department'));
    selectedRow.find('[data-table-attribute="jefe"]').html(values.attr('boss_name'));
  });
});

// Nueva vista para evaluación de desempeño

const removeAlert = (div) => {
  div.empty();
};

const showErrors = (errors) => {
  let list = '';
  errors.forEach(function (error) {
    list += `<li class="text-danger"><strong>${error}</strong></li>`;
  });
  return list;
};

const createAlertError = (errors, div) => {
  removeAlert(div);
  div.append(`
    <div class="row">
      <div class="col-md-12">
        <div class="alert bg-danger error-explanation">
          <ul data-error-list="true">
            ${showErrors(errors)}
          </ul>
        </div>
      </div>
    </div>
  `);
};

const addInputSelect2Error = (select2, msg = '') => {
  select2.siblings('.select2-container').addClass('border-danger-700');
  const wrapper = select2.parent('div');
  if (wrapper.hasClass('has-error')) return;
  wrapper.addClass('has-error');
  if (msg !== '') wrapper.append(`<span class="help-block">${msg}</span>`);
};

const removeInputSelect2Error = (select2) => {
  select2.siblings('.select2-container').removeClass('border-danger-700');
  select2.parent('div').removeClass('has-error');
  select2.parent('div').find('span[class="help-block"]').remove();
};

const propagateBossPotentialScales = function () {
  const formWithOutRemove = $('.nested-fields').not('.removed').has('.select-steps-active option:selected');
  const stepBossId = $('[data-kind="boss"]').data('id');
  const bossFormsWithoutRemove = formWithOutRemove.filter(function () {
    return ($(this).find('.select-steps-active option:selected').toArray().filter(opt => opt.value === `${stepBossId}`).length > 0);
  });
  const mainBossForm = bossFormsWithoutRemove.first();
  const mainBossTab = $(`#${mainBossForm.data('id')}`);
  const mainTabSelect = mainBossTab.find('.potential_scale_select');
  const isMain = $(this).attr('id') === mainTabSelect.attr('id');
  if(isMain) {
    const mainSelectedScale = mainBossTab.find('.potential_scale_select').val();
    bossFormsWithoutRemove.slice(1).each(function () {
      const formTabId = $(this).data('id');
      $(`#${formTabId}`).find('.potential_scale_select').val(mainSelectedScale).change();
      $(`#${formTabId}`).find('.potential_scale_select').find(`option[value="${mainSelectedScale}"]`).prop('selected', 'selected');
    });
  }
};

const disableSecondaryBossPotentialScales = () => {
  const formWithOutRemove = $('.nested-fields').not('.removed').has('.select-steps-active option:selected');
  const stepBossId = $('[data-kind="boss"]').data('id');
  const bossFormsWithoutRemove = formWithOutRemove.filter(function () {
    return ($(this).
      find('.select-steps-active option:selected').
      toArray().
      filter(opt => opt.value === `${stepBossId}`)
      .length > 0);
  });
  const otherFormsWithoutRemove = formWithOutRemove.filter(function () {
    return ($(this).
      find('.select-steps-active option:selected').
      toArray().
      filter(opt => opt.value === `${stepBossId}`).
      length === 0);
  });
  const mainBossForm = bossFormsWithoutRemove.first();
  const mainBossTab = $(`#${mainBossForm.data('id')}`);
  const mainBossSelect = mainBossTab.find('.potential_scale_select');
  mainBossSelect.prop('disabled', !mainBossTab.find('.potential-scale-section').is(':checked')).addClass('activable');
  bossFormsWithoutRemove.slice(1).each(function () {
    const formTabId = $(this).data('id');
    const tab = $(`#${formTabId}`);
    tab.find('.potential_scale_select').
      prop('disabled', true).
      removeClass('activable').
      val(mainBossSelect.val()).
      change();
  });
  otherFormsWithoutRemove.each(function () {
    const formTabId = $(this).data('id');
    const tab = $(`#${formTabId}`);
    tab.find('potential_scale_select').
      prop('disabled', !tab.find('.potential-scale-section').is(':checked')).
      addClass('activable');
  });
};

onmount('[data-evaluation-process-form]', async function (obj) {
  const { loadCKeditor, CKeditorVersion, loadCKeditor5 } = await import('@assets/javascripts/components/ckeditor/load');
  if (CKeditorVersion() === '4') {
    await loadCKeditor(this, obj);
  }
  const form = $(this);
  const steps = form.find('[data-step]');
  const sequentialChecbox = $('#evaluation_process_sequential');
  const btnSummary = $('[data-id=summary]');
  const listItemForm = $('[data-item-form=true]');
  const divAlertModalForm = $('[data-modal-errors=true]');
  const btnPublishOptions = $('[data-config-publish-options]');

  const countActiveEvaluation = () => $('.activate-draggable:checked').length;

  // guarda la opción seleccionada al abrir el modal de opciones de publicación
  btnPublishOptions.on('click', function () {
    const optionSelected = $("input[data-boss-publish]:checked").val();
    $(this).data('option-selected', optionSelected);
  });

  // al presionar el botón cancelar en el modal de opciones de publicación selecciona la opción que tenía antes de abrir el modal
  $('[data-btn-cancel-publish-options]').on('click', function () {
    const lastOption = btnPublishOptions.data('option-selected');
    $(`input[data-boss-publish][value=${lastOption}]`).prop('checked', lastOption);
  });

  $('input[type="number"][name$="[weight]"]').change(function () {
    const inputNumber = $(this);
    const value = parseInt(inputNumber.val());
    const maxValue = parseInt(inputNumber.attr('max'));
    const minValue = parseInt(inputNumber.attr('min'));
    inputNumber.val(Math.min(Math.max(value, isNaN(minValue) ? -Infinity : minValue), isNaN(maxValue) ? Infinity : maxValue));
  });

  $('.activator').each(function () {
    const activator = $(this);
    const inputWeight = $(`#weight_${activator.attr('id')}`);
    const scale = $(`select[data-checkbox="${activator.attr('id')}"]`);
    const inputId = $(`#id_${activator.attr('id')}`);
    const goalsIntegrationActivator =
      $(`.activator-goals-tracking-integration[data-section="${activator.attr('id')}"]`);
    if (activator.prop('checked')) {
      inputWeight.attr('disabled', false);
      inputId.attr('disabled', false);
      scale.attr('disabled', false);
      goalsIntegrationActivator.attr('disabled', false);
      if (goalsIntegrationActivator.prop('checked')) {
        const scaleSelector =
          $(`.section_scale_select[data-section-id=${goalsIntegrationActivator.data('section-id')}]`);
        scaleSelector.prop('disabled', true);
        $(`#child_section_${goalsIntegrationActivator.data('section')} :checkbox`).attr('disabled', true);
        $(`#child_section_${goalsIntegrationActivator.data('section')} input:checkbox`).prop('checked', false);
        $(`#child_section_${goalsIntegrationActivator.data('section')} :input[type="number"]`).attr('disabled', true);
        $(`#child_section_${goalsIntegrationActivator.data('section')} :input[type="number"]`).val('');
      }
    }
    else {
      inputWeight.attr('disabled', true);
      inputId.attr('disabled', true);
      scale.attr('disabled', true);
      goalsIntegrationActivator.attr('disabled', true);
    }
  });

  $(document).on('change', '.activator', function () {
    const activator = $(this);
    const state = $(`#weight_${activator.attr('id')}`).attr('disabled') || activator.prop('checked');
    const inputWeight = $(`#weight_${activator.attr('id')}`);
    inputWeight.attr('disabled', !state);

    // Habilita checkbox de integración de objetivos
    const goalsIntegrationActivator =
      $(`.activator-goals-tracking-integration[data-section="${activator.attr('id')}"]`);
    goalsIntegrationActivator.attr('disabled', !state);

    if (!state) {
      inputWeight.val('');
      goalsIntegrationActivator.prop('checked', false);
    }
    $(`#id_${activator.attr('id')}`).attr('disabled', !state);
    $(`#name_${activator.attr('id')}`).attr('disabled', !state);
    if (activator.hasClass('parent-section')) {
      const divChild = $(`#child_section_${activator.attr('id')}`);
      if (activator.prop('checked')) {
        divChild.removeClass('hide');
        $(`#child_section_${activator.attr('id')} :checkbox`).attr('disabled', false);
        divChild.find('input.activable').prop('disabled', false);
      }
      else {
        divChild.addClass('hide');
        $(`#child_section_${activator.attr('id')} :input[type="number"]`).attr('disabled', true);
        $(`#child_section_${activator.attr('id')} input:checkbox`).prop('checked', false);
        divChild.find('input.activable').prop('disabled', true);
      }
    }
  });

  const changeStatusListItemForm = () => {
    if (countActiveEvaluation() === 0) {
      listItemForm.removeClass('active');
      listItemForm.addClass('disabled');
      return;
    }
    listItemForm.removeClass('disabled');
  };

  const isMultipleFormsChecked = () => ($('input[type=radio][name=multiple_form]:checked').val() === 'true');

  const multipleForm = () => $('.nested-fields').not('.removed').find('.evaluation_form_name').length > 1;

  const stepAssignedToForm = (stepId) => {
    const formWithOutRemove = $('.nested-fields').not('.removed');
    const stepSelected = formWithOutRemove.find('.select-steps-active option:selected').toArray().map((item)=> parseInt(item.value));
    return stepSelected.includes(stepId);
  };

  const validateStepAssignedToForm = (activableStepCheckbox) => {
    if (!isMultipleFormsChecked()) return;
    if (stepAssignedToForm(activableStepCheckbox.data('id'))) {
      activableStepCheckbox.prop('checked', true);
      warning(`Hay formularios que tienen esta evaluación asociada
      y se deben desvincular antes de poder desactivar el tipo de evaluación`);
      return true;
    }
  };

  const statusSectionPotential = (disabled) => {
    const formDefaultTab = $('a[data-form-default=true]').attr('href');
    const sectionsFormDefault = $(formDefaultTab);
    const potentialCheckbox = sectionsFormDefault.find('[data-section=potential]');
    potentialCheckbox.attr('disabled', disabled);
    const divChild = $(`#child_section_${potentialCheckbox.attr('id')}`);
    const scaleSelector = divChild.parent().parent().find('.section_scale_select');
    if (potentialCheckbox.prop('checked') && !potentialCheckbox.prop('disabled')) {
      divChild.removeClass('hide');
      scaleSelector.prop('disabled', false);
    }
    else {
      divChild.addClass('hide');
      scaleSelector.prop('disabled', true);
    }
  };

  steps.each(function (_i, step) {
    step = $(step);
    const kind = step.data('step');
    //formName es el nombre que Rails le entrega a los inputs al usar 'fields_for'
    const formName = step.data('name');

    const checkbox = step.find(`[name="${formName}[active]"]`);
    const stepInputs = step.find('input:visible:not([data-id])');
    const stepConfiguration = form.find(`[data-step-configuration="${kind}"]`);

    checkbox.change(function () {
      changeStatusListItemForm();
      if(this.checked) {
        stepConfiguration.show();
        stepInputs.attr('disabled', null);
        if (step.find('[data-kind]').data('kind') === 'boss' && !multipleForm()) {
          statusSectionPotential(!this.checked);
        }
      }
      else {
        if (validateStepAssignedToForm($(this))) return;
        stepConfiguration.hide();
        stepInputs.attr('disabled', 'disabled');
        if (step.find('[data-kind]').data('kind') === 'boss' && !multipleForm()) {
          statusSectionPotential(!this.checked);
        }
      }
    });
  });

  const moveItem = (item, to, position) => {
    to.append(item);
    item.find('.position').val(position);
  };

  const showStep = () => {
    if (countActiveEvaluation() == 0) return;
    for (let index = 1; index <= countActiveEvaluation(); index++) {
      $(`#step_${index}`).removeClass('hide');
    }
  };

  // Lo que hace esta parte es buscar los text area de la seccion de configuracion para eliminar sus ckeditor
  // y crearlos de nuevo ya que cuando estos se mueven en sortable.js quedan deshabilitados.
  $(".sortable[data-group='evaluations']").on('nestedFormSortableChangeElement', async function (_e) {
    const { RICH_EDITOR_OPTIONS } = await import('@assets/javascripts/components/ckeditor/custom_configs');
    const item = $(this);
    const textareas = $(item.find('textarea'));
    if (textareas) {
      textareas.each(async function () {
        const textAreaId = this.id;
        if (CKeditorVersion() === '4') {
          CKEDITOR.instances[textAreaId].destroy();
          CKEDITOR.replace(textAreaId, SIMPLE_EDITOR_OPTIONS());
        }
        else {
          await loadCKeditor5(this, _e, RICH_EDITOR_OPTIONS());
        }
      });
    }
  });

  const setDraggable = () => {
    $('.activate-draggable:checked').parents('.item-draggable').addClass('drag').removeClass('no-draggable');
  };

  if (sequentialChecbox.prop('checked')) {
    showStep();
    setDraggable();
  }

  sequentialChecbox.on('change', function () {
    const textWithoutAsign = $('#text_without_asign');
    if (sequentialChecbox.prop('checked')) {
      const sortableSteps = $('#sortable_steps');
      sortableSteps.toggleClass('sortable');
      textWithoutAsign.removeClass('hide');
      showStep();
      setDraggable();
    }
    else {
      textWithoutAsign.addClass('hide');
      $('.step').children('div').each(function () {
        moveItem($(this), $('.without-asign'), "0");
      });
      $('.drag').toggleClass('drag').toggleClass('no-draggable');
      $('.step').addClass('hide');
    }
  });

  $('.activate-draggable').on('change', function () {
    if (!sequentialChecbox.prop('checked')) return;
    const itemDraggable = $(this).closest(".item-draggable");
    if ($(this).prop('checked')) {
      $(`#step_${countActiveEvaluation()}`).removeClass('hide');
      itemDraggable.addClass('drag').removeClass('no-draggable');
    }
    else {
      const stepToRemove = $(`#step_${countActiveEvaluation() + 1}`);
      stepToRemove.addClass('hide');
      moveItem(itemDraggable, $('.without-asign'), "0");
      stepToRemove.children('div').each(function () {
        moveItem($(this), $(`#step_${countActiveEvaluation()}`), $(`#step_${countActiveEvaluation()}`).data('position'));
      });
      itemDraggable.removeClass('drag').addClass('no-draggable');
    }
  });

  const getActiveSteps = () => $('.activate-draggable:checked');

  const removeOptionSelect = (select) => {
    select.find('option').remove();
  };

  const optionExist = (select, optionValue) => select.find('option').toArray().map((item)=> parseInt(item.value)).includes(optionValue);

  const removeEmptyQuestios = () => {
    const writableQuestions = $('.open-questions .nested-fields, .potential-questions .nested-fields');
    writableQuestions.each(function () {
      if ($(this).find('input[type="text"]').val().length === 0) {
        $(this).find('a.remove_fields').click();
      }
    });
  };

  const addActiveStepSelect = ({ select, event, removeAllFirst = true }) => {
    if (getActiveSteps().length === 0) {
      warning('Seleccione un tipo de evaluación');
      if (event) event.stopPropagation();
      return;
    }
    if (removeAllFirst) removeOptionSelect(select);
    getActiveSteps().each(function () {
      if (optionExist(select, parseInt($(this).data('id')))) return;
      select.append(`<option value="${$(this).data('id')}">${$(this).data('name')}</option>`);
    });
  };

  const changeStatusInputInside = (selector, state) => {
    $(`${selector}>:input`).prop('disabled', state);
  };

  const toggleHide = (checkbox, selector) => {
    const element = $(selector);
    if (checkbox.is(':checked')) {
      element.removeClass('hide');
      changeStatusInputInside(selector, false);
    }
    else {
      element.addClass('hide');
      changeStatusInputInside(selector, true);
    }
  };

  const randomInt = () => Math.floor(Math.random() * (10000 - 1) + 1);

  const addIds = (inputs) => {
    const randomId = randomInt();
    let actualId;
    inputs.each(function () {
      actualId = $(this).attr('id');
      if (actualId) $(this).attr('id', `${actualId}_${randomId}`);
    });
  };

  const changeTabMessageIds = (configurationSection) => {
    const randomId = randomInt();
    configurationSection.find('#tab-introduction-message').attr('id', `tab-introduction-message-${randomId}`);
    configurationSection.find('li a[href="#tab-introduction-message"]').attr('href', `#tab-introduction-message-${randomId}`);
    configurationSection.find('#tab-end-message').attr('id', `tab-end-message-${randomId}`);
    configurationSection.find('li a[href="#tab-end-message"]').attr('href', `#tab-end-message-${randomId}`);
  };

  const updateQuestionTemplates = (insertedItem) => {
    ['open', 'potential'].forEach(function (type) {
      //Actualiza los id's del template para preguntas abiertas
      const questionWrapper = insertedItem.find(`.${type}-questions .child-section`);
      const actualId = questionWrapper.attr('id');
      const newOpenQuestionButton = questionWrapper.find('.add_fields');
      newOpenQuestionButton.data('association-insertion-node', `#${actualId} .${type}-questions-list`);

      let newQuestionTemplate = newOpenQuestionButton.data('association-insertion-template');
      //newQuestionTemplate = newQuestionTemplate.replace(new RegExp(`data-copy-from="([^"]*)"`, 'g'), 'data-copy-from="[]"');
      newQuestionTemplate = newQuestionTemplate.replace(new RegExp(`data-copy-from="([^"]*)"`, 'g'), `data-copy-from="#${actualId} [name=&quot;${type}_question_name&quot;]"`);
      newOpenQuestionButton.data('association-insertion-template', newQuestionTemplate);

      //Actualiza el selector del importador para importar preguntas de forma masiva
      const questionImportButton = questionWrapper.find(`[data-import="${type}-question"]`);
      const currentLink = questionImportButton.attr('href');
      const newLink = currentLink.replace(/([^=]*container=)(.*)/g, '$1'+encodeURIComponent(`#${actualId}`));

      questionImportButton.attr('href', newLink);
    });
  };

  $('#evaluation_forms').on('cocoon:before-insert', function (_e, insertedItem, originalEvent) {
    const newNameForm = $('#new_name_form');
    if (!newNameForm.val()) {
      warning('Por favor escriba un nombre para el formulario');
      originalEvent.preventDefault();
    }
    $('.evaluation_form_name').each(function () {
      if ($(this).val() === newNameForm.val()) {
        warning('Ya existe un formulario con ese nombre');
        originalEvent.preventDefault();
        return false;
      }
    });

    insertedItem.find('[data-persisted="false"]').each(function () {
      const input = $(this);
      const pathIc = input.attr('ic-post-to').split('?');
      const pathParams = pathIc[1];
      const paramsObject = queryString.parse(pathParams);
      const newFormId = input.attr('name').split('][')[1];

      insertedItem.find(`.progress-bar-form-sections-${paramsObject.form}`).addClass(`progress-bar-form-sections-${newFormId}`);
      paramsObject.form = newFormId;
      input.attr("ic-target",`.progress-bar-form-sections-${newFormId}`);
      input.attr("ic-post-to",`${pathIc[0]}?${queryString.stringify(paramsObject)}`);
    });

  });

  $('#evaluation_forms').on('cocoon:after-insert', function (_e, insertedItem, _originalEvent) {
    const newNameForm = $('#new_name_form');
    const elementId = insertedItem.find('.evaluation_form_name')
      .attr('id')
      .replace('talent_evaluation_process_evaluation_forms_attributes_', '')
      .replace('_name', '');
    insertedItem.find('.evaluation_form_name').attr('value', newNameForm.val());
    insertedItem.find('.sections').addClass(elementId);
    insertedItem.find('.advanced-configurations').addClass(elementId);
    insertedItem.data('id', `tab-${elementId}`);
    newNameForm.val('');
    addActiveStepSelect({ select: insertedItem.find('.select-steps-active') });
    toggleHide($('#asign_role_familiy'), '.select-role-families');
    addIds(insertedItem.find('.sections input, .child-section'));
    addIds(insertedItem.find('.advanced-configurations textarea'));
    addIds(insertedItem.find('.advanced-configurations input[type="checkbox"]'));
    changeTabMessageIds(insertedItem.find('.advanced-configurations'));
    updateQuestionTemplates(insertedItem);
  });

  $('#evaluation_forms').on('cocoon:after-remove', function (_e, removedItem, _originalEvent) {
    removedItem.addClass('removed');
    removedItem.find('[name$="[_destroy]"]').attr('value', 'true');
  });

  $('#action-config').on('click', function (e) {
    toggleHide($('#asign_role_familiy'), '.select-role-families');
    $('.select-steps-active').each(function () {
      addActiveStepSelect({ select: $(this), event: e, removeAllFirst: false });
    });
  });

  const changeStateDestroy = (state) => {
    $('.nested-fields').not('.default').each(function () {
      $(this).addClass('hide removed');
      $(this).find('[name$="[_destroy]"]').attr('value', state);
    });
  };

  $('input[type=radio][name=multiple_form]').change(function () {
    $('#config_forms').toggleClass('hide');
    if ($(this).val() === 'false') {
      divAlertModalForm.addClass('hide');
    }
    else {
      divAlertModalForm.removeClass('hide');
    }
  });

  $('#asign_role_familiy').change(function () {
    toggleHide($(this), '.select-role-families');
  });

  if (multipleForm()) {
    $('#multiple_form').prop('checked', true);
    divAlertModalForm.removeClass('hide');
  }
  else {
    $('#simple_form').prop('checked', true);
    divAlertModalForm.addClass('hide');
  }

  const tabExist = (selector) => $(`[href$='${selector}']`).length > 0;

  const updateTabName = (selector, name) => {
    $(`[href$='${selector}']`).text(name);
  };

  const removeTabs = (all = false) => {
    const formNames = [];
    $(`.nested-fields`).not('.removed').find('.evaluation_form_name').each(function () {
      formNames.push($(this).val());
    });
    $('ul.nav-tabs li a[data-form-default=false]').each(function () {
      if (formNames.includes($(this).text().trim()) && !all) {
        return;
      }
      else {
        $(this).remove();// remove tab
        $($(this).attr('href')).remove(); // remove tab-content
      }
    });
  };

  const removeAllForm = () => {
    changeStateDestroy('true');
    removeTabs(true);
  };

  const generateTabs = (ulElement) => {
    $('#config-forms #nested-forms > .nested-fields').not('.removed').each(function () {
      const inputNameValue = $(this).find('.evaluation_form_name').val();
      if (tabExist(`#${$(this).data('id')}`)) {
        updateTabName(`#${$(this).data('id')}`, inputNameValue.trim());
        return;
      }
      const elementId = $(this).data('id').replace('tab-', '');

      ulElement.append($('<li></li>')
        .addClass('nav-item')
        .append($('<a></a>')
          .attr({
            href: `#tab-${elementId}`,
            role: 'tab',
            'data-toggle': 'tab',
            'data-form-default': false,
          })
          .addClass('nav-link')
          .html(inputNameValue)));

      $(`.tab-content[data-id=${ulElement.data('id')}]`)
        .append($('<div></div>')
          .addClass('tab-pane')
          .attr({
            id: `tab-${elementId}`,
            'aria-labelledby': `tab-${elementId}`,
          }));

      const sections = $(`div.hide.sections.${elementId}`);
      $(`#tab-${elementId}`).append(sections);
      sections.removeClass('hide');

      const configuration = $(`div.advanced-configurations.${elementId}`);
      $(`#tab-${elementId}`).append(configuration);
      configuration.removeClass('hide');
      $(this).data('id', `tab-${elementId}`);
    });
  };

  const hasRoleFamily = () => $('#asign_role_familiy:checked').length > 0;

  // Funciones para mostrar y remover errores en inputs.
  const addInputError = (element, msg) => {
    if (element.hasClass('has-error')) return;
    element.addClass('has-error');
    element.append(`<span class="help-block">${msg}</span>`);
  };

  const removeInputError = (element) => {
    element.removeClass('has-error');
    element.find('span[class="help-block"]').remove();
  };

  //al menos un step asignado al formulario
  const atLeastOneStep = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    formWithOutRemove.find('.select-steps-active').each(function () {
      removeInputSelect2Error($(this));
      if ($(this).find('option:selected').length === 0) {
        addInputSelect2Error($(this), 'Seleccione al menos un tipo de evaluación');
        ok = false;
      }
    });
    if (!ok) errors.push('Se debe asignar al menos un tipo de evaluación por formulario');
    return ok;
  };

  //validar que todos los formularios tengan nombre
  const validateFormNames = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    formWithOutRemove.find('.evaluation_form_name').each(function () {
      removeInputError($(this).parent('div'));
      if ($(this).val() === '') {
        addInputError($(this).parent('div'), 'Se debe asignar un nombre al formulario');
        ok = false;
      }
    });
    if (!ok) errors.push('Se debe asignar un nombre al formulario');
    return ok;
  };

  // no se pueden repetir los tipos de evaluación para los distintos formularios si no tiene familias de cargo seleccionadas
  const repeatedSteps = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    const message = 'No se puede asignar el mismo tipo de evaluación a más de un formulario';
    const stepSelected = formWithOutRemove.find('.select-steps-active option:selected').toArray()
      .map((item)=> parseInt(item.value));
    stepSelected.forEach(function (stepId) {
      const inputSelectStepSelected = formWithOutRemove.find(`.select-steps-active option:selected[value="${stepId}"]`).parent();
      if (inputSelectStepSelected.length > 1) {
        ok = false;
        addInputSelect2Error(inputSelectStepSelected, message);
      }
    });
    if (!ok) errors.push(message);
    return ok;
  };

  const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);

  // no se pueden repetir los tipos de evaluación y familias de cargo para los distintos formularios
  const repeatedStepsWithRoleFamilies = (errors) => {
    let ok = true;
    const formWithOutRemove = $('.nested-fields').not('.removed');
    const message = 'No se puede asignar el mismo tipo de evaluación y familia de cargo a más de un formulario';
    const roleFamiliesSelected = formWithOutRemove.find('[data-model=role-families] option:selected')
      .toArray().map((item)=> parseInt(item.value));
    removeInputSelect2Error($('[data-model=role-families]'));
    const roleFamilyEmpty = formWithOutRemove.find('[data-model=role-families]').filter(function () {
      return !this.value;
    });
    // usamos 0 para los select de familias de cargo que no tienen ninguna seleccionada
    if (roleFamilyEmpty.length > 1) roleFamiliesSelected.push(0);
    roleFamiliesSelected.forEach(function (roleFamilyId) {
      let inputSelectRoleFamilySelected = formWithOutRemove
        .find(`[data-model=role-families] option:selected[value="${roleFamilyId}"]`).parent();
      if (roleFamilyId === 0) inputSelectRoleFamilySelected = roleFamilyEmpty;
      if (inputSelectRoleFamilySelected.length > 1) {
        const optionStepSelected = inputSelectRoleFamilySelected.closest('.nested-fields').not('.removed')
          .find('.select-steps-active option:selected');
        const stepSeleted = optionStepSelected.toArray().map((item)=> parseInt(item.value));
        const duplicateSteps = findDuplicates(stepSeleted);
        if (duplicateSteps.length > 0) ok = false;
        duplicateSteps.forEach(function (stepId) {
          addInputSelect2Error(optionStepSelected.parent()
            .find(`option:selected[value="${stepId}"]`).parent(), message);
          addInputSelect2Error(optionStepSelected.parent().find(`option:selected[value="${stepId}"]`).parent()
            .closest('.nested-fields').find('[data-model=role-families]'), message);
        });
      }
    });
    if (!ok) errors.push(message);
    return ok;
  };

  const validateConfigForm = (eventSave) => {
    const errors = [];
    let atLeastOneStepResult = false;
    atLeastOneStepResult = atLeastOneStep(errors);
    validateFormNames(errors);
    if (hasRoleFamily()) {
      repeatedStepsWithRoleFamilies(errors, atLeastOneStepResult);
    }
    else {
      repeatedSteps(errors, atLeastOneStepResult);
    }
    if (errors.length === 0) {
      removeAlert(divAlertModalForm);
      return;
    }
    createAlertError(errors, divAlertModalForm);
    eventSave.stopPropagation();
  };

  $('#save-forms').on('click', function (e) {
    const stepBoss = $('[data-kind=boss]').parent();
    const status = !stepBoss.find('[data-kind=boss]').is(':checked');
    (multipleForm()) ? statusSectionPotential(false) : statusSectionPotential(status);
    if (isMultipleFormsChecked()) {
      validateConfigForm(e);
      generateTabs($('.nav-tabs-custom[data-scrolling-tabs=true]').find('ul.nav.nav-tabs'));
      btnSummary.removeClass('hide');
    }
    else {
      removeAllForm();
      btnSummary.addClass('hide');
    }
    disableSecondaryBossPotentialScales();
    removeTabs();
    $.onmount();
  });

  $('[name="talent_evaluation_process[score_type]"]').on('change', function () {
    const original = $('#original');
    const other = $('#other');
    const maxValue = $('[data-max-score]');
    const value = maxValue.val();
    const otherValue = maxValue.data('other-value');

    if(original.hasClass('hide')) {
      other.addClass('hide');
      other.find(':input').prop('disabled', true);

      original.removeClass('hide');
      original.find(':input').prop('disabled', false);
    }
    else{
      original.addClass('hide');
      original.find(':input').prop('disabled', true);

      other.removeClass('hide');
      other.find(':input').prop('disabled', false);
    }

    maxValue.val(otherValue).data('other-value', value);
  });

  //logic button summary

  if (!isMultipleFormsChecked()) {
    btnSummary.addClass('hide');
  }

  const getFormbySteps = (stepName) => {
    const forms = [];
    $('.nested-fields').not('.removed').find('.select-steps-active option:selected').each(function () {
      if ($(this).text().trim() === stepName) {
        forms.push($(this).closest('.nested-fields').find('.evaluation_form_name').val());
      }
    });
    return forms;
  };

  const getRoleFamilies = (formName) => {
    if (!hasRoleFamily()) return [];
    let roleFamilies = [];
    $('.nested-fields').not('.removed').find('.evaluation_form_name').each(function () {
      if ($(this).val() === formName) {
        roleFamilies = $(this).closest('.nested-fields').not('.removed')
          .find('[data-model=role-families] option:selected').toArray().map((item)=> item.text);
      }
    });
    return roleFamilies.length > 0 ? roleFamilies : ['Todas'];
  };

  btnSummary.on('click', function (e) {
    const activeSteps = getActiveSteps();
    if (activeSteps.length === 0) {
      warning('No hay evaluaciones activas');
      e.stopPropagation();
      return;
    }
    const titleRoleFamilies = $('#title-role-families');
    if (hasRoleFamily()) {
      titleRoleFamilies.removeClass('hide');
    }
    else {
      titleRoleFamilies.addClass('hide');
    }

    const contentRowHtml = (col1, col2) => `<div class="row">
      <div class="col-md-6 ${hasRoleFamily() ? 'border-right' : ''}">${col1}</div>
      <div class="col-md-6">${col2.join(', ')}</div>
      </div>`;

    const generateContentByStep = (forms, step) => {
      const body = $(`.summary-body[data-id=${step.data('id')}]`);
      body.empty();
      forms.forEach(function (nameForm) {
        body.append(contentRowHtml(nameForm, getRoleFamilies(nameForm)));
      });
    };

    $('.evaluator-step-summary').addClass('hide');
    activeSteps.each(function () {
      $(`.evaluator-step-summary[data-id=${$(this).data('id')}]`).removeClass('hide');
      generateContentByStep(getFormbySteps($(this).data('name')), $(this));
    });
  });

  /**
   * Configurar preguntas enumeradas al crear/cargar preguntas libres / preguntas de potencial
   */
  function updateQuestionLabelNumbering(labels) {
    labels.each(function (i, label) {
      label.textContent = label.textContent.replace('placeholder_question_number', `${i + 1}`);
    });
  }

  onmount('.open-questions-list, .potential-questions-list', function () {
    $(this).on('cocoon:after-insert', function (_e, insertedItem, _originalEvent) {
      const labels = $(insertedItem).parent().find('label:visible');
      updateQuestionLabelNumbering(labels);
    });

    $(this).on('cocoon:after-remove', function (e, _insertedItem, _originalEvent) {
      const labels = $(e.target).find('label:visible');

      updateQuestionLabelNumbering(labels);
    });
  });

  onmount('[data-maximum-evaluators="true"]', function () {
    const regexNumberOrBlank = '^$|^[1-9]';
    const regexNumber = '^[1-9]';
    $(this).on('keyup', function () {
      const input = $(this);
      if (!input.val().match(regexNumberOrBlank)) {
        input.val(1);
      }
      else if (input.val() === '') {
        input.val('');
      }
    });
    $(this).on('change', function () {
      const input = $(this);
      if (!input.val().match(regexNumber)) {
        input.val(1);
      }
    });
  });

  const disableCheckbox = (checked, checkbox) => {
    if (checked) {
      checkbox.attr('disabled', false);
    }
    else {
      checkbox.attr('disabled', true);
      checkbox.prop('checked', false);
    }
  };

  onmount('[data-name="Evaluación Descendente"]', function () {
    const calibrationCheckbox = $('#talent_evaluation_process_has_calibration_stage');
    const feedbackCheckbox = $('#talent_evaluation_process_has_feedback_stage');
    const checkbox = $(this);
    disableCheckbox(checkbox.prop('checked'), calibrationCheckbox);
    disableCheckbox(checkbox.prop('checked'), feedbackCheckbox);
    checkbox.on('change', function () {
      disableCheckbox(checkbox.prop('checked'), calibrationCheckbox);
      disableCheckbox(checkbox.prop('checked'), feedbackCheckbox);
    });
  });

  onmount('#talent_evaluation_process_has_feedback_stage', function () {
    const appealCheckbox = $('#talent_evaluation_process_has_appeal_stage');
    const checkbox = $(this);
    disableCheckbox(checkbox.prop('checked'), appealCheckbox);
    checkbox.on('change', function () {
      disableCheckbox(checkbox.prop('checked'), appealCheckbox);
    });
  });

  onmount('select.section_scale_select', function () {
    const selectScale = $(this);
    const maxPercentageContainer = selectScale.parents(`:has(.max-percentage):first`).find('.max-percentage');
    const checkbox = selectScale.parents(`:has(.scale-section):first`).find('.scale-section');
    const wrapper = selectScale.parent();

    if  (checkbox.is(':checked') && selectScale.val() === '') {
      addInputError(wrapper, 'Se debe seleccionar una escala');

      $(checkbox).change(function () {
        removeInputError(wrapper);
      });

      $(selectScale).change(function () {
        removeInputError(wrapper);
      });
    }

    $(checkbox).change(function () {
      if(selectScale.hasClass('activable')) selectScale.attr('disabled', !checkbox.is(':checked'));
      maxPercentageContainer.find('input').attr('disabled', !checkbox.is(':checked'));
    });

    const addMaxPercentageInput = (select) => {
      const value = select.val();
      if (value == "percentage") {
        maxPercentageContainer.find('span').show();
        maxPercentageContainer.find('input').show();
      }
      else {
        maxPercentageContainer.find('span').hide();
        maxPercentageContainer.find('input').hide();
      }
    };
    addMaxPercentageInput(selectScale);
    selectScale.on('change', function () {
      addMaxPercentageInput(selectScale);
    });
  });

  onmount('.form-group.has-error', function () {
    const parent = $(this);
    const config = { attributes: true };
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if(mutation.attributeName === 'disabled') {
          removeInputError(parent);
        }
      });
    });
    $(this).find('input.form-control').each(function () {
      observer.observe(this, config);
    }).change(function () {
      removeInputError(parent);
    });
  });

  form.submit(function () {
    removeEmptyQuestios();
  });

  onmount('[data-toogle-input]', function () {
    const self = $(this);
    const target = self.data('toogleInput');
    const elementTarget = self.parents(`:has(${target}):first`).find(target);
    self.on('click', function () {
      if (self.prop('checked')) {
        elementTarget.show('slow');
      }
      else {
        elementTarget.hide('slow');
      }
    });
  });

  //validaciones de total de pesos

  // Suma de pesos de categorias de competencias
  onmount('[data-controller="capacitiy-weight-track"]', function () {
    const capacitiyWeightTrack = $(this);
    const globalDiv = capacitiyWeightTrack.find('.global');
    const globalWeight = globalDiv.find('.weight');
    const specificDiv = capacitiyWeightTrack.find('.specific');
    const specificWeight = specificDiv.find('.weight');
    const errorDiv = capacitiyWeightTrack.find('.error');

    const section = capacitiyWeightTrack.parents(`:has('.count-weight-global'):first`);

    const globalCheckbox = section.find('.count-weight-global[type="checkbox"]:not([name*="no_global"])');
    const specificCheckbox = section.find('.count-weight-global[type="checkbox"][name*="no_global"]');
    const globalNumber = section.find('.count-weight-global[type="number"]:not([name*="no_global"])');
    const specificNumber = section.find('.count-weight-global[type="number"][name*="no_global"]');

    function changeCapacitiyWeightTrack() {
      if (globalCheckbox.is(":checked") || specificCheckbox.is(":checked")) {
        let weightSum = 0;
        if (globalCheckbox.is(":checked")) {
          weightSum = parseFloat(globalNumber.val() || 0);
          globalWeight.text(`${globalNumber.val() || "0"}%`);
          globalDiv.show();
        }
        else {
          globalDiv.hide();
        }
        if (specificCheckbox.is(":checked")) {
          specificDiv.show();
          weightSum += parseFloat(specificNumber.val()|| 0);
          specificWeight.text(`${specificNumber.val() || "0"}%`);
        }
        else {
          specificDiv.hide();
        }
        capacitiyWeightTrack.show();
        if (weightSum === 100) {
          errorDiv.hide();
        }
        else {
          errorDiv.show();
        }
      }
      else {
        capacitiyWeightTrack.hide();
      }
    }
    globalCheckbox.click(changeCapacitiyWeightTrack);
    specificCheckbox.click(changeCapacitiyWeightTrack);
    globalNumber.focusout(changeCapacitiyWeightTrack);
    specificNumber.focusout(changeCapacitiyWeightTrack);

  });

  onmount('[data-controller="count-weight-child-section"]', function () {
    const spanWeight = $(this);
    const section = spanWeight.parents(`:has(.child-section):first`).find('.child-section');

    function changeChildSectionWeightTrack(trigger) {
      const triggerInput = $(trigger);
      const disabledInput = triggerInput.parents(`:has([id^="weight_"]):first`).find('[id^="weight_"]')[0];

      let totalWeight = 0;
      section.find('input[type="number"]:not(:disabled)').toArray().forEach(function (weightInput) {
        if (disabledInput != weightInput) {
          totalWeight += parseFloat(weightInput.value || 0);
        }
      });
      if (disabledInput.value && triggerInput.is("[type='checkbox']:checked, [type='number']")) {
        totalWeight += parseFloat(disabledInput.value || 0);
      }
      const color = totalWeight == 100 ? '#3c8dbc' : '#a94442';
      spanWeight.text(`${totalWeight}%`).parent().css('color', color);
    }

    section.find('input[type="number"]').focusout(function () {
      changeChildSectionWeightTrack(this);
    });
    section.find('input[type="checkbox"]').click(function () {
      changeChildSectionWeightTrack(this);
    });
  });

  $(document).on('change', '.activator-goals-tracking-integration', function () {
    const activator = $(this);
    const section = activator.data('section-id');
    const scaleSelector = $(`#section-scale-${section}`).find(`.section_scale_select`);
    if (activator.prop('checked')) {
      scaleSelector.val('percentage');
      scaleSelector.find(`option[value='percentage']`).prop('selected', 'selected');
      scaleSelector.trigger('change');
      scaleSelector.prop('disabled', true);
      $(`#child_section_${activator.data('section')} :checkbox`).attr('disabled', true);
      $(`#child_section_${activator.data('section')} input:checkbox`).prop('checked', false);
      $(`#child_section_${activator.data('section')} :input[type="number"]`).attr('disabled', true);
      $(`#child_section_${activator.data('section')} :input[type="number"]`).val('');
    }
    else {
      scaleSelector.prop('disabled', false);
      $(`#child_section_${activator.data('section')} :checkbox`).attr('disabled', false);
      $(`#child_section_${activator.data('section')} input:checkbox`).prop('checked', false);
    }
  });
});


// validaciones para vista de participantes y evaluadores
onmount("[data-participants-form='true']", function () {
  const divAlertlForm = $('[data-modal-errors]');
  const activeStepIds = $('[data-steps]').find('option').toArray().map((item) => parseInt(item.value));
  const tableParticipant = $('[data-table-participants]').dataTable();
  const stepBossId = parseInt($('#step_boss').val());

  const validateForms = (errors) => {
    let ok = true;
    $('[data-select-form]').each(function () {
      const selectForm = $(this);
      removeInputSelect2Error(selectForm);
      if ($(this).val() === '') {
        ok = false;
        addInputSelect2Error(selectForm);
      }
    });
    if (!ok) errors.push('Se deben asignar todos los formularios por tipo de evaluación');
  };

  const atLeastOneSelected = (selectInputs) => selectInputs.find('option:selected').toArray().map((item) => item.value).filter(e => e !== '').length > 0;

  // agrupa inputs por su value
  const groupByEvaluators = (inputs) => groupBy(inputs, function (element) {
    return $(element).val();
  });

  const RepeteadEvaluatorOk = (object) => {
    let ok = true;
    for (const [key, value] of Object.entries(object)) {
      if (value.length > 1 && key !== '') {
        ok = false;
        $(value).each(function (_l, select) {
          select = $(select);
          addInputSelect2Error(select);
        });
      }
    }
    return ok;
  };

  const addErrorInputValidator = (selectValidator) => {
    if (atLeastOneSelected(selectValidator)) return false;
    addInputSelect2Error(selectValidator);
    return true;
  };

  if ($('[data-verificator]').data('verificator')) {
    // mostrar alerta si existen verificadores sin asignar
    let verificatorOk = true;
    const errorVerificator = 'Existen participantes que no tienen verificadores asignados';
    tableParticipant.$('tr').each(function (_i, trEmployee) {
      trEmployee = $(trEmployee);
      const verificator = trEmployee.find(`[data-verificator=${trEmployee.data('id')}]`);
      if (!atLeastOneSelected(verificator)) {
        verificatorOk = false;
        // marcamos el selector si no tiene seleccionado verificador
        addInputSelect2Error(verificator);
      }
    });
    if (!verificatorOk) createAlertError([errorVerificator], divAlertlForm);
  }

  const requireBossValidation = () => $('[data-validator]').length > 0;
  // valida evaluadores, validadores
  const validateEvaluators = (errors) => {
    const evaluationProcessStatusActive = $('[data-process-active]').data('process-active');
    let evaluatorOk = true;
    let validatorOk = true;
    let duplicateEvaluatorOk = true;
    const atLeastOneEvaluatorMsg = 'Se debe asignar al menos un evaluador por tipo de evaluación';
    const errorValidatorMsg = 'Se debe asignar al menos un validador por evaluador asignado';
    const duplicateEvaluator = `No se puede asignar a un participante
      el mismo evaluador más de una vez en un tipo de evaluación.`;
    removeInputSelect2Error($('[data-evaluator]'));
    removeInputSelect2Error($('[data-validator]'));
    tableParticipant.$('tr').each(function (_i, trEmployee) {
      trEmployee = $(trEmployee);
      activeStepIds.forEach(function (stepId) {
        const evaluatorsSelectByStep = trEmployee.find(`[data-employee=${trEmployee.data('id')}]`).filter(`[data-step=${stepId}]`);
        if (!atLeastOneSelected(evaluatorsSelectByStep) && evaluationProcessStatusActive) {
          evaluatorOk = false;
          // si no tiene seleccionado ningun evaluador por tipo de evaluación marcamos el evaluador 1
          addInputSelect2Error(evaluatorsSelectByStep.filter('[data-evaluator=1]'));
        }
        duplicateEvaluatorOk = RepeteadEvaluatorOk(groupByEvaluators(evaluatorsSelectByStep)) && duplicateEvaluatorOk;
        if (stepId !== stepBossId) return;
        if (!requireBossValidation()) return;
        // validación para los validadores que tienen evaluador seleccionado
        evaluatorsSelectByStep.each(function (_k, selectEvaluator) {
          selectEvaluator = $(selectEvaluator);
          if (atLeastOneSelected(selectEvaluator)) {
            const selectValidator = trEmployee.find(`[data-validator=${selectEvaluator.data('evaluator')}]`)
              .filter(`[data-employee=${selectEvaluator.data('employee')}]`);
            if (addErrorInputValidator(selectValidator)) validatorOk = false;
          }
        });
      });
    });
    if (!duplicateEvaluatorOk) errors.push(duplicateEvaluator);
    if (!evaluatorOk) errors.push(atLeastOneEvaluatorMsg);
    if (!validatorOk) errors.push(errorValidatorMsg);
  };

  $(this).submit(function (e) {
    const submitButton = $(this).find('#add-employees-to-process-button');
    const btnLabel = submitButton.find('.btn-label');
    const defaultText = btnLabel.text();

    const errors = [];

    submitButton.prop('disabled', true);
    btnLabel.text(submitButton.data('savingText'));

    validateForms(errors);
    validateEvaluators(errors);

    if (errors.length > 0) {
      createAlertError(errors, divAlertlForm);
      e.preventDefault();
      submitButton.prop('disabled', false);
      btnLabel.text(defaultText);
      return;
    }
    removeAlert(divAlertlForm);
  });
});

// validaciones y acciones para modal "Añadir Evaluador"
onmount('[data-add-evaluator-form]', function () {
  const formAddEvaluator = $(this);
  let action = formAddEvaluator.attr('action').split('/');
  action = action.splice(0,action.length-2).join('/');
  const divAlertModalForm = formAddEvaluator.find('[data-modal-errors=true]');

  const checkEvaluatorAndValidator = (event) => {
    const selectEvaluator = $('.selector-evaluator');
    const selectValidator = $('.selector-validator');
    if (selectEvaluator.val() === selectValidator.val()) {
      event.preventDefault();
      createAlertError(['El evaluador y el validador no pueden ser la misma persona'], divAlertModalForm);
      addInputSelect2Error(selectValidator);
    }
    else {
      removeInputSelect2Error();
      removeAlert();
    }
  };

  const updateAction = () => {
    const selectEvaluation = $('.selector-evaluation');
    formAddEvaluator.attr('action', `${action}/${selectEvaluation.val()}/add_evaluator`);
  };

  formAddEvaluator.submit(function (event) {
    updateAction();
    checkEvaluatorAndValidator(event);
  });

  onmount('[data-dependents-select]', function () {
    const principalSelect = $(this);
    const dependentsInputs = $(principalSelect.data('dependents-select'));
    dependentsInputs.attr('disabled', principalSelect.val() === '');

    principalSelect.on('change', function () {
      dependentsInputs.attr('disabled', principalSelect.val() === '');
      const needValidator = principalSelect.find('option:selected').data('validator') === true;
      $('.select-validator, .selector-validator').toggle(needValidator).attr('required', needValidator).attr('disabled', !needValidator);
    });
  });
});

onmount('[data-max-score="true"]', function () {
  var maxLimit = $('[data-max-score="true"]');
  var last = $('#original .nested-fields:last-child').find('[data-result-scale-field="max"]');

  maxLimit.on('click', function () {
    checkLimit();
  });

  last.on('click', function () {
    checkLimit();
  });

  function monitor() {
    if (last !== undefined) {
      last.off('change');
      last.parents('.form-group').removeClass('has-error');
    }

    last = $('#original .nested-fields:last-child').find("[data-result-scale-field='max']");
    checkLimit();
    last.change(function () {
      checkLimit(last);
    });
  }

  function checkLimit() {
    const valor = parseFloat(last[0].value);
    if (parseFloat(maxLimit[0].value) < valor) {
      maxLimit.parents('.form-group').addClass('has-error');
      last.parents('.form-group').addClass('has-error');
    }
    else {
      maxLimit.parents('.form-group').removeClass('has-error');
      last.parents('.form-group').removeClass('has-error');
    }
  }

  const observer = new MutationObserver(function (mutationsList) {
    mutationsList.forEach(function (mutation) {
      mutation.addedNodes.forEach(function () {
        monitor();
      });
      mutation.removedNodes.forEach(function () {
        monitor();
      });
    });
  });

  observer.observe(document.querySelector('#original'), { subtree: false, childList: true });
});

onmount('.balance-weights', function () {
  const formButton = $('.button-form-category_weight');
  const alert = $('.alert-category-weights');
  const self = this;
  const lastInput = $(self).find('input.weight_to_balance[type=number]:last');
  // if data option disable-only-persisted is true, only persiste
  const noDisablePersisted = !lastInput.data('no-disable-persisted') ||
  $(self).find('input.weight_to_balance[type=number]').length > 1;
  lastInput.prop('readonly', true);
  if ($(self).find('input.weight_to_balance[type=number]').length === 1) {
    lastInput[0].value = 100.0;
  }
  if((noDisablePersisted && lastInput.val() === '0.0') || lastInput.val() === '') {
    calculateLastInput();
  }
  $(self).on('change mouseup keyup', 'input.weight_to_balance[type=number]:not(:last)', function () {
    calculateLastInput();
  });
  function calculateLastInput() {
    let sum = 0;
    $(self).find('input.weight_to_balance[type=number]:not(:last)').each(function () {
      sum += +$(this).val();
    });
    if (lastInput.data('round') === 2) {
      lastInput.val(Math.round((100 - sum) * 100) / 100);
    }
    else {
      lastInput.val(100 - sum);
    }
    if (lastInput.val() < 0 || lastInput.val() > 100) {
      alert.show();
      formButton.prop('disabled', true);
    }
    else {
      alert.hide();
      formButton.prop('disabled', false);
    }
  }
});

onmount('[data-target^="#category-weights-modal"]', function () {
  const button = $(this);
  $('[data-open-goal-period]').on('select2:select', function () {
    const select = $(this);
    if (parseInt(select.val()) === select.data('open-goal-period')) {
      button.removeClass('disabled');
    }
    else {
      button.addClass('disabled');
    }
  });
});

// animar btn comenzar ev
onmount('#start-button-performance', function () {
  $(this).find('i').addClass('fa-fw fa-spin');
});

function validEmails(emails) {
  const ammountCommas = countOccurrences(emails, ',');
  const ammountAtSign = countOccurrences(emails, '@');
  if (ammountAtSign > 1 && (ammountAtSign !== (ammountCommas + 1))) {
    warning('Los correos electrónicos deben estar separados por coma');
    return false;
  }
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const invalidEmails = emails.split(',').filter(email => !regex.test(email.trim()));
  if (invalidEmails.length > 0) {
    warning(`El correo electrónico ingresado no es válido`);
    return false;
  }
}

function countOccurrences(string, char) {
  const regex = new RegExp(char, 'g');
  const matches = string.match(regex);
  return matches ? matches.length : 0;
}

onmount('#confirm-feedback-form', async function (obj) {
  const form = $(this);
  const selector = form.find('#talent_employee_evaluation_feedback_approved');
  const commentLabel = form.find('#label-comentario');

  await import('../../../../../../../app/assets/javascripts/vendor/select2.js');
  const { CKeditorVersion, loadCKeditor } = await import('@assets/javascripts/components/ckeditor/load');
  if (CKeditorVersion() === '4') await loadCKeditor(this, obj);
  selector.select2({ minimumResultsForSearch: Infinity, width: '50%' });

  selector.change(function () {
    const option = selector[0].options[selector[0].selectedIndex].value;
    option === 'true' ? commentLabel.html('Comentario') : commentLabel.html('Comentario *');
  });

  form.submit(function () {
    const option = selector[0].options[selector[0].selectedIndex].value;
    const text = CKeditorVersion() === '4' ? CKEDITOR.instances['talent_employee_evaluation_message'].getData() :
      $('talent_employee_evaluation_message').val();
    if (option == 'false' && text == '') {
      warning('El campo comentario es obligatorio');
      return false;
    }
    const emails = form.find('#talent_employee_evaluation_appeal_recipients').val();
    if (emails !== '') {
      return validEmails(emails);
    }
  });

  selector.on('change', (event) => {
    const { value } = event.target;
    $(this).find("input[name='talent_employee_evaluation[appeal_status]']").attr('disabled', value === 'true');
    $(this).find("input[name='talent_employee_evaluation[appeal_status]']").prop('checked', false);
    if (value === 'true') {
      $(this).find('#feedback-appeal-data').css('display', 'none');
    }
  });
});

onmount('#confirm-appeal-form', async function (obj) {
  const { loadCKeditor, CKeditorVersion } = await import('@assets/javascripts/components/ckeditor/load');
  if (CKeditorVersion() === '4') await loadCKeditor(this, obj);

  const form = $(this);
  const selector = form.find('#talent_employee_evaluation_appeal_approved');
  const commentLabel = form.find('#label-comentario');

  form.submit(function () {
    const option = selector[0].options[selector[0].selectedIndex].value;
    const text = CKeditorVersion() === '4' ? CKEDITOR.instances['talent_employee_evaluation_message'].getData() :
      $('talent_employee_evaluation_message').val();
    if (option === 'false' && text === '') {
      warning("El campo comentario es obligatorio para la opción 'No conforme'");
      return false;
    }
    const emails = form.find('logbook_appeal_resolution_recipients').val();
    if (emails !== '') {
      return validEmails(emails);
    }
  });

  selector.on('change', (event) => {
    const { value } = event.target;
    if (value === 'true') {
      commentLabel.removeClass('required');
      commentLabel.text('Comentario');
    }
    else {
      commentLabel.addClass('required');
      commentLabel.text('Comentario *');
    }
  });
});

onmount('.potential_scale_select', function () {
  disableSecondaryBossPotentialScales();
  $(this).change(propagateBossPotentialScales);
});

onmount('form.evaluation_process_form', function () {
  $(this).on('submit', function () {
    $(this).find('.potential_scale_select').each(function () {
      const sectionName = `[type="checkbox"][name="${$(this).prop('name').replace('[talent_scale_id]', '[active]')}"]`;
      const isSectionChecked = $(sectionName).is(':checked');
      $(this).prop('disabled', !isSectionChecked);
    });
    const goalsIntegrationActivator = $(this).find('.activator-goals-tracking-integration');
    if (goalsIntegrationActivator.prop('checked')) {
      $(`.section_scale_select[data-section-id=${goalsIntegrationActivator.data('section-id')}]`)
        .prop('disabled', false);
    }
  });
});

onmount('#edit_attempt', function () {
  const completionData = $('#completion-data').data('completion-data');
  const calculator = new CompletionPercentageCalculator(this, completionData);
  calculator.target($('.progress-bar')[0], function (completionPercentage) {
    $('.progress-bar').css('width', `${completionPercentage}%`);
    $('.progress-bar-primary').text(`${completionPercentage.toFixed(1)}%`);
  }).listen();
});

// Usado para esconder y mostrar el botón de configuración avanzada de resultados
onmount('.sections-forms-container', function () {
  const $self = $(this);
  const capacitiesCheckbox = $self.find('.active-capacities-checkbox');
  const goalsCheckbox = $self.find('.active-goals-checkbox');

  // Chequea si hay al menos un form que evalua competencias y objetivos al mismo tiempo
  // usando los checkbox
  function checkEvaluateCapacitiesAndGoals() {
    const allCapacitiesCheckboxes = $('.active-capacities-checkbox');
    let toReturn = false;
    allCapacitiesCheckboxes.each(function (_index, otherCapacitiesCheckbox) {
      const formId = otherCapacitiesCheckbox.attributes['ic-target'].value.split('-').at(-1);
      const otherGoalsCheckbox = $(`.active-goals-checkbox[ic-target$=${formId}]`);
      if (otherGoalsCheckbox.is(':checked') && otherCapacitiesCheckbox.checked) {
        toReturn = true;
        return;
      }
    });
    return toReturn;
  }

  // Esconde el botón según los checkbox
  function toggleResultsSettingsForm(clickedCheckbox, otherCheckbox) {
    const advancedConfigButton = $('#results-advanced-config');

    if (otherCheckbox.is(':checked') && clickedCheckbox.is(':checked')) {
      advancedConfigButton.removeClass('d-none');
    }
    else if (!(clickedCheckbox.is(':checked'))) {
      if (!checkEvaluateCapacitiesAndGoals()) advancedConfigButton.addClass('d-none');
    }
  }

  capacitiesCheckbox.on('click', function () {toggleResultsSettingsForm(capacitiesCheckbox, goalsCheckbox);});
  goalsCheckbox.on('click', function () {toggleResultsSettingsForm(goalsCheckbox, capacitiesCheckbox);});
});

onmount('.limit-decimal-length', function () {
  const $self = $(this);

  $self.on('change', function () {
    $self.val(parseFloat($self.val()).toFixed(2));
  });
});

onmount("div[id^='sortable-capacity-']", function () {
  const $self = $(this);
  const checkbox = $self.find("input[type='checkbox']");
  const draggableDiv = $self.find('.col-12');
  const checkedCapacitiesContainer = $self.parent().parent().find('#sortable-capacities-checked');
  const uncheckedCapacitiesContainer = $self.parent().parent().find('#sortable-capacities-unchecked');
  const sortableIcon = $self.find('#sortable-points-icon');

  checkbox.on('click', function () {
    if (checkbox.is(':checked')) {
      draggableDiv.removeClass('no-draggable');
      $self.detach().appendTo(checkedCapacitiesContainer);
      sortableIcon.removeClass('display-none');
    }
    else {
      draggableDiv.addClass('no-draggable');
      $self.detach().appendTo(uncheckedCapacitiesContainer);
      sortableIcon.addClass('display-none');
    }
  });
});

onmount("[data-submit-per-page='true']", function () {
  const $editParticipantsForm = $(this);
  onmount('[data-submit-per-page] .page-link', function () {
    $(this).click(async function () {
      $.ajax({
        type: 'POST',
        url: $editParticipantsForm.attr('action'),
        data: $editParticipantsForm.serialize().replace(/&evaluator%5B%5D=|&validator%5B%5D=/gm, ''),
        success: (data) => {
          notify.clear();
          const saved = data.status === 200;
          saved ?
            notify.notice(data.message) :
            notify.error(data.message);
        },
      });
    });
  });
});

function prepareAndEnableExport() {
  const canvas = document.getElementById('talent-evaluation-process-capacities-map-graph');
  const inputField = document.getElementById('screenshot-field');
  const exportButton = document.getElementById('export-evaluation-report-button');

  if (canvas && inputField && exportButton) {
    canvas.toBlob(function (blob) {
      const reader = new FileReader();
      reader.onload = function () {
        const base64Image = reader.result.split(',')[1];
        inputField.value = base64Image;
        if (inputField.value) {
          exportButton.disabled = false;
        }
      };
      reader.readAsDataURL(blob);
    }, 'image/png');
  }
}

onmount('#talent-evaluation-process-capacities-map-graph', function () {
  setTimeout(prepareAndEnableExport, 3000);
});

onmount("[data-goals-validation='true']", function () {
  const form = $(this);
  const submitBtn = form.find("button[type='submit']");
  const categoryInput = form.find("select[data-disable-if-changed='true']");
  const weightInput = form.find("input[data-disable-on-validation='true']");
  const initialCategory = categoryInput.val();
  const initialWeight = weightInput.val();
  const swalTitle = form.attr('data-validation-swal-title');
  const swalMessage = form.attr('data-validation-swal-message');
  weightInput.on('change keyup focus unfocus', function () {
    submitBtn.attr('disabled', !(parseFloat($(this).val()) <= 100.0 && parseFloat($(this).val()) >= 0.0));
  });
  categoryInput.change(function () {
    const isInitial = categoryInput.val() === initialCategory;
    weightInput.val('0');
    weightInput.attr('disabled', !isInitial);
  });
  form.on('submit', async function (e) {
    if(initialWeight === weightInput.val() || parseFloat(weightInput.val()) === 0.0) return true;
    e.preventDefault();
    await swalActionMessage(
      swalTitle,
      swalMessage
    );
    form.off('submit');
    form.submit();
  });
});

onmount('.limit-by-area', function () {
  const checkbox = $(this).find('input[type="checkbox"]');
  const destroyField = $(this).find('input[data-destroy-input="true"]');
  const selector = $(this).find('div.area-select');

  checkbox.on('change', () => {
    if(checkbox.is(':checked')) {
      selector.find('select').removeAttr('disabled');
      selector.show('slow');
      destroyField.val(false);
    }
    else {
      selector.find('select').attr('disabled', true);
      selector.hide('slow');
      destroyField.val(true);
    }
  });
});
