/** @file send-liquidacciones: se encarga de enviar notificaciones a empleados seleccionados por el usuario */
import onmount from 'onmount';

onmount('#enviar_liquidaciones', async function () {
  const signableCheckbox = $('#signable_checkbox');
  const container = $('#enviar_liquidaciones');
  const employeesSelectionType = $('#employees_selection_type');
  const sendBtn = $('#submit_liquidaciones');
  const employeeCheckBox = '[name="employee_ids[]"]';
  const signableAlertOriginalText = sendBtn.attr('data-confirm-swal');
  const signableAlertTemplateText = sendBtn.attr('data-confirm-swal-template');

  signableCheckbox.on('change', function () {
    if (this.checked) {
      $('#signable').val(true);
      alertText();
    }
    else {
      $('#signable').val(false);
      alertDisable();
    }
  });

  employeesSelectionType.on('change', function () {
    if (this.value === 'all_employees') {
      sendBtnEnabled();
    }
    else if (getCheckedIds()?.length === 0) {
      sendBtnDisabled();
    }
    alertText();
  });

  container.on('change', employeeCheckBox, (e) => {
    const employeeId = e.target.value;
    e.target.checked
      ? addEmployeeIdToSelectedIds(employeeId)
      : removeEmployeeIdToSelectedIds(employeeId);
    const employeeIds = getCheckedIds();
    const employeesSelected = employeeIds.length;
    employeesSelected > 0 ? sendBtnEnabled() : sendBtnDisabled();
    $('#to_send_selected_employees span').text(`${employeesSelected}`);
    alertText();
  });

  function alertEnable() {
    sendBtn.removeAttr('data-confirm-swal-disable');
  }
  function alertDisable() {
    sendBtn.attr('data-confirm-swal-disable', true);
  }
  function allEmployeesSelected() {
    return employeesSelectionType.val() === 'all_employees';
  }
  function sendBtnEnabled() {
    sendBtn.removeAttr('disabled');
  }
  function sendBtnDisabled() {
    sendBtn.attr('disabled', 'disabled');
  }
  function getCheckedIds() {
    return (
      $('#employee_ids')
        .val()
        ?.split(',')
        .filter((e) => e !== '') || []
    );
  }

  function unsignableAndCheckedLength() {
    const checkedIds = getCheckedIds();
    const cantSignEmployeIds = $('#cant_sign_employee_ids')
      .val()
      ?.split(',');
    return cantSignEmployeIds?.filter((id) => checkedIds.includes(id)).length;
  }

  function allEmployeesAlertText() {
    sendBtn.attr('data-confirm-swal', signableAlertOriginalText);
    alertEnable();
  }
  function selectedEmployeesAlertText() {
    const signableAlertText = signableAlertTemplateText.replace(
      '<employees_without_user_activated_count>',
      unsignableAndCheckedLength()
    );
    sendBtn.attr('data-confirm-swal', signableAlertText);
    alertEnable();
  }

  function alertText() {
    if (signableCheckbox.prop('checked')) {
      if (allEmployeesSelected()) {
        allEmployeesAlertText();
      }
      else if (unsignableAndCheckedLength() > 0) {
        selectedEmployeesAlertText();
      }
      else {
        alertDisable();
      }
    }
  }

  function addEmployeeIdToSelectedIds(employeeId) {
    const employeeIds = getCheckedIds();
    employeeIds.push(employeeId);
    $('#employee_ids').val(employeeIds.join(','));
  }

  function removeEmployeeIdToSelectedIds(employeeId) {
    const employeeIds = getCheckedIds();
    const newValues = employeeIds.filter((e) => e !== employeeId);
    $('#employee_ids').val(newValues.join(','));
  }

  (function () {
    function removeAccents(data) {
      if (data.normalize) {
        // Use I18n API if avaiable to split characters and accents, then remove
        // the accents wholesale. Note that we use the original data as well as
        // the new to allow for searching of either form.
        return (
          data + ' ' + data.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        );
      }

      return data;
    }

    var searchType = jQuery.fn.DataTable.ext.type.search;

    searchType.string = function (data) {
      return typeof data === 'string' ? removeAccents(data) : data;
    };

    searchType.html = function (data) {
      return typeof data === 'string'
        ? removeAccents(data.replace(/<.*?>/g, ''))
        : data;
    };
  })();

  $(document).ready(function () {
    $('#employees_with_email').dataTable();
  });
});
