/** @file TODO: documentar */
var template = `
  <div class="row buk-notice">
	  <div class="col-md-12">
      <div class="alert text-left alert-dismissible fade show alert__scroll" role="alert" id="alert-main-notice">
        <div class="alert_container d-flex">
          <div class="alert_container__icon">
            <span class="material-icons alert_icon" aria-hidden="true">check_circle_outline</span>
          </div>
          <div class="alert_container__content">
            <h4 class="alert_container__title"></h4>
            <span class="msg"></span>
          </div>
        </div>
        <button type="button" class="close alert_close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">
            <span class=" material-icons" aria-hidden="true">close</span>
          </span>
        </button>
      </div>
    </div>
  </div>
`;

/**
 * Notifica un mensaje al usuario.
 *
 * @param {string} type - El tipo de notificación (danger, warning, success, info).
 * @param {string} icon - El ícono de la notificación.
 * @param {string} message - El mensaje de la notificación.
 * @param {string} [prependTo='.content-wrapper > .content'] - El selector del elemento donde se agregará la notificación.
 * @param {string} [titleAlert=''] - El título de la notificación.
 * @returns {jQuery} El objeto jQuery de la notificación agregada.
 */
function notify(type, icon, message, prependTo = '.content-wrapper > .content', titleAlert = '') {
  const $tmpl = $(template);

  if(titleAlert === '') {
    $tmpl.find('.alert_container__title').remove();
  }
  else {
    $tmpl.find('.alert_container__title').html(titleAlert);
  }
  $tmpl.find('.alert').addClass('alert_' + type);
  $tmpl.find('.alert_icon').addClass(icon);
  $tmpl.find('.alert_icon').html(icon);
  $tmpl.find('.msg').html(message);

  $(prependTo).prepend($tmpl);
  return $tmpl;
}

/**
 * Limpia todas las notificaciones.
 */
export function clear() {
  $('.buk-notice').fadeOut(function () {
    $(this).remove();
  });
}

/**
 * Muestra una notificación de error.
 *
 * @param {string} msg - El mensaje de error.
 * @param {string} [titleAlert=''] - El título de la notificación.
 * @param {string} [prependTo] - El selector del elemento donde se agregará la notificación.
 * @returns {jQuery} El objeto jQuery de la notificación agregada.
 */
export function error(msg, titleAlert, prependTo) {
  return notify('danger', 'error_outline', msg, prependTo, titleAlert);
}

/**
 * Muestra una notificación de advertencia.
 *
 * @param {string} msg - El mensaje de advertencia.
 * @param {string} [titleAlert=''] - El título de la notificación.
 * @param {string} [prependTo] - El selector del elemento donde se agregará la notificación.
 * @returns {jQuery} El objeto jQuery de la notificación agregada.
 */
export function warning(msg, titleAlert, prependTo) {
  return notify('warning', 'warning_amber', msg, prependTo, titleAlert);
}

/**
 * Muestra una notificación de aviso.
 *
 * @param {string} msg - El mensaje de aviso.
 * @param {string} [titleAlert=''] - El título de la notificación.
 * @param {string} [prependTo] - El selector del elemento donde se agregará la notificación.
 * @returns {jQuery} El objeto jQuery de la notificación agregada.
 */
export function notice(msg, titleAlert, prependTo) {
  return notify('success', 'check_circle_outline', msg, prependTo, titleAlert);
}

/**
 * Muestra una notificación informativa.
 *
 * @param {string} msg - El mensaje informativo.
 * @param {string} [titleAlert=''] - El título de la notificación.
 * @param {string} [prependTo] - El selector del elemento donde se agregará la notificación.
 * @returns {jQuery} El objeto jQuery de la notificación agregada.
 */
export function info(msg, titleAlert, prependTo) {
  return notify('info', 'info_outline', msg, prependTo, titleAlert);
}
