/** @file
 * Función global para inicializar select2 en selectores de Fiji
 * Se debe usar el atributo data-fiji-select2-filter en los select que se quieran inicializar
*/
import onmount from 'onmount';

const selector = 'select[data-fiji-select2-filter]';

const setDropdownBehavior = (element) => {
  const selectedValue = element.value;
  const select2Element = element.nextSibling;
  const arrowElement = select2Element.querySelector('.select2-selection__arrow');
  const clearSelectionElement = select2Element.querySelector('.select2-selection__clear');
  if (selectedValue === '') { // si es no está seleccionada ninguna opción
    arrowElement.hidden = false;
  }
  else { // si es que está seleccionada alguna opción esconder flecha
    arrowElement.hidden = true;
    if (clearSelectionElement) {
      clearSelectionElement.hidden = false;
      clearSelectionElement.classList.add('clear-selection-element');
    }
  }
};

const initializeSelect2 = async () => {
  await import('../../../../../../../app/assets/javascripts/vendor/select2');
  $(selector).each(function () {
    const numberOfOptions = $(this).find('option').length;
    $(this).select2({
      width: '100%',
      allowClear: true,
      minimumResultsForSearch: numberOfOptions > 10 ? 0 : Infinity,
    }).on('change.select2', function (e) {
      if (e.originalEvent) {
        $(this).trigger('change');
      }
    }).on('change', function () {
      setDropdownBehavior(this);
    }).on('select2:opening', function (e) {
      if ($(this).data('unselecting')) {
        $(this).removeData('unselecting');
        e.preventDefault();
        return;
      }
    }).on('select2:unselecting', function () {
      $(this).data('unselecting', true);
    }).on('select2:open', (e) => {
      // Por defecto select2 deshabilita el scroll de toda la
      // página cuando esta abierto. Esto lo deshabilita.
      // https://stackoverflow.com/questions/55440004/js-select2-plugin-not-scrolling-when-options-are-open
      const evt = 'scroll.select2';
      $(e.target).parents().off(evt);
      $(window).off(evt);
    });
    setDropdownBehavior(this);
  });
};

const destroySelect2 = function () {
  if ($.fn.select2 && $(this).data('select2')) {
    $(this).select2('destroy');
  }
};

onmount(selector, initializeSelect2, destroySelect2);

export { initializeSelect2 };
