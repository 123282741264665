/* eslint-disable no-console */
/** @file
 * Configura los steps del modal de upselling del nuevo Menú
 */
import onmount from 'onmount';

onmount('#pagination_nav', () => {
  const previousButton = document.querySelector('#prevBtn');
  const nextButton = document.querySelector('#nextBtn');
  const steps = document.querySelectorAll('.pagination_step');
  let currentStep = 0;
  previousButton.disabled = true;
  steps[currentStep].classList.remove('hidden');

  nextButton.addEventListener('click', (event) => {
    event.preventDefault();
    steps[currentStep].classList.add('hidden');
    steps[currentStep + 1].classList.remove('hidden');
    steps[currentStep + 1].classList.add('active');
    currentStep += 1;

    updateStatusDisplay();
    console.log(steps[currentStep].length);
  });

  previousButton.addEventListener('click', (event) => {
    event.preventDefault();
    steps[currentStep].classList.add('hidden');
    steps[currentStep - 1].classList.remove('hidden');
    steps[currentStep - 1].classList.add('active');
    currentStep -= 1;

    updateStatusDisplay();
  });

  function updateStatusDisplay() {
    if (currentStep === steps.length - 1) {
      nextButton.disabled = true;
      previousButton.disabled = false;
    }
    else if (currentStep === 0) {
      nextButton.disabled = false;
      previousButton.disabled = true;
    }
    else {
      nextButton.disabled = false;
      previousButton.disabled = false;
    }
  }
});
