/** @file
 * Genera el dropdown en base al body de tablas fiji para evitar que  se recorte.
 */
import onmount from 'onmount';

onmount('.dropdown_actions', function () {
  const $dropdown = $(this);
  const dropdownMenu = $dropdown.children('.dropdown-menu');
  const $fijiBody = $('.fiji-body');

  // Valida que sea para vistas Fiji
  if ($fijiBody.length === 0) {
    return;
  }

  // Limpia eventos anteriores
  $dropdown.off('show.bs.dropdown hide.bs.dropdown');

  $dropdown.on('show.bs.dropdown', function () {
    const $parent = $dropdown.parent();
    if ($parent.hasClass('table-actions')) {
      dropdownMenu.appendTo($fijiBody);
      // Hacer que las opciones sean flex para que no queden en horizontal
      dropdownMenu.addClass('p-0');
      //Estilos para el dropdown ya que se renderiza fuera de su contenedor inicial
      dropdownMenu.children().addClass('d-flex font-size-14 py-2 px-3');
      dropdownMenu.children('a.text-danger-400').on('mouseenter', function () {
        $(this).addClass('bg-primary-100');
      }).on('mouseleave', function () {
        $(this).removeClass('bg-primary-100');
      });
    }
  });

  $dropdown.on('hide.bs.dropdown', function () {
    // Devuelve el dropdownMenu a su lugar original en el DOM cuando se oculta
    dropdownMenu.appendTo($dropdown);
  });
});
