/** @file TODO: documentar */
import onmount from 'onmount';

onmount('[data-area-roles-details]', function () {
  const $self = $(this);

  $self.on('change', function () {
    var areaId = $('#area_id').val();
    var roleId = $self.val();
    if (!roleId || !areaId) {
      return;
    }

    $.ajax({
      method: 'GET',
      dataType: 'json',
      url: '/area_roles/details',
      data: {
        area: areaId,
        role: roleId,
      },
    }).then(function (data) {

      let $target;
      Object.keys(data).forEach(targetIdSuffix => {
        $target = $(`[id$=${targetIdSuffix}]`);
        if ($target.length) {
          if ($target.is(':input')) {
            $target.val(data[targetIdSuffix]);
          }
          else {
            $target.text(data[targetIdSuffix]);
          }
        }
      });
    });
  });
});
