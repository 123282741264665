/**
 * @file Muestra u oculta elementos asociados a las opciones escogidas/removidas en un selector múltiple.
 * Al remover los elementos también se remueven del selector múltiple.
 *
 * Modo de Uso:
 * - El selector múltiple debe tener el atributo data-form-toggle-multiple: true
 * - Deben coincidir los ids de los elementos con los valores de las opciones del selector.
 *
 * Adicional cada elemento que se muestra/oculta puede tener un botón/link/ícono que lo remueva del selector.
 * Modo de uso:
 * - El botón/link/ícono debe tener la clase `remove-option`
 * - El elemento que se muestra/oculta debe tener el atributo data `remove-select-option` con el id del selector múltiple.
 *
 * <%= select_tag :campos_seleccionables,
                [["ficha_1", "Juan Pablo F1"],["ficha_2", "Juan Pablo F2"]],
                class: 'form-control',
                data: {
                  provide: :select2,
                  form_toggle_multiple: '#fichas',
                },
                multiple: true,

    %>

    <div id="fichas">
      <div id="ficha_1" data-remove-select-option="#campos_seleccionables">
        <p>Ficha numero 1</p>
        <button class="remove-option">Remover ficha</button>
      </div>
      <div id="ficha_2" data-remove-select-option="#campos_seleccionables">
        <p>Ficha numero 2</p>
        <button class="remove-option">Remover ficha</button>
      </div>
    </div>
 */

import onmount from 'onmount';

/*
* Teniendo consistentes las opciones de un selector múltiple con los ids de contenedores html, permite mostrar/ocultar
* un contenedor de acuerdo a la opción seleccionada en el selector múltiple.
*/
onmount('[data-form-toggle-multiple]', function () {
  const $self = $(this);
  $self.on('select2:select', function (e) {
    var targetId = e.params.data.id;
    var element = document.getElementById(targetId);
    $(element).show(400);
  });

  $self.on('select2:unselect', function (e) {
    var targetId = e.params.data.id;
    var element = document.getElementById(targetId);
    $(element).hide(400);
  });
});

/*
* Dado un conjunto de elementos contenedores de opciones con un boton de clase remove-option, se permite que al
* pulsar este botón se elimine la opción del selector múltiple con la que se relaciona y se oculte el contenedor.
*/
onmount('[data-remove-select-option]', function () {
  const $self = $(this);
  const target = $self.data('removeSelectOption');
  const $target = $(target);

  $self.find(".remove-option").click(function () {
    const fieldId = $self[0].id;
    const newVal = $target.val().filter(v => v !== fieldId);
    $target.val(newVal);
    $target.trigger('change');

    $self.hide(400);
  });

});
