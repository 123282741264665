/** @file TODO: documentar */
import onmount from 'onmount';
import VisibilityTracker from '../lib/buk/visibility-tracker.js';
import load from '../lib/buk/load';

const tracker = new VisibilityTracker((element) => load(element, element.getAttribute('data-render-async')));

onmount('[data-render-async]', function () {
  tracker.watch(this);
}, function () {
  tracker.unwatch(this);
});
