/** @file Aqui se define la funcionalidad del aside copilot
 */

// Esto deberias invocarlo en algun metodo del archiv
import onmount from 'onmount';

const initialTemplate = document.getElementById('aside_copilot')?.innerHTML;

// create a div with the message
export const createMessage = async (
  message,
  typeUser,
  fuente,
  route,
  loading = false,
  noAnimation = false,
  getHistory = false
) => {
  const {
    createMaterialIcon,
    createCopilotIcon,
    SendToBottom,
    typeWriterEffect,
  } = await import(/* webpackChunkName: "buk-copilot" */ './helpers_copilot');
  const messageContainer = document.createElement('div');
  const messageContent = document.createElement('div');
  const iconMessage = typeUser === 'user' ? createMaterialIcon('person_pin') : createCopilotIcon();
  const copyToIcon = createMaterialIcon('content_copy', 'copy-icon');
  const thumbUpIcon = createMaterialIcon('thumb_up', 'thumb-up-icon');
  const thumbDownIcon = createMaterialIcon('thumb_down', 'thumb-down-icon');
  const linkContainer = document.createElement('div');
  const linkContainerRouter = document.createElement('div');
  const isRouteValid = route !== 'N/A' && route !== '' && route !== null;

  linkContainer.classList.add('link-container');
  linkContainerRouter.classList.add('link-container');
  messageContent.classList.add('message__content');
  messageContainer.classList.add(`bubble__${typeUser}-message`);

  if (loading) {
    messageContent.innerHTML = message;
    messageContainer.appendChild(iconMessage);
    messageContainer.appendChild(messageContent);
    messageContainer.classList.add('max-content-width-message');
  }

  function createLink(source) {
    const link = document.createElement('a');
    link.href = source;
    link.target = '_blank';
    link.innerHTML = source;
    return link;
  }

  function addLinkToContainer(containerLink, source) {
    const singleLinkContainer = document.createElement('div');
    singleLinkContainer.classList.add('text-truncate');
    const link = createLink(source);
    singleLinkContainer.innerHTML = `&bull; ${link.outerHTML}`;
    containerLink.appendChild(singleLinkContainer);
  }

  if (typeUser === 'copilot' && !loading) {
    messageContainer.appendChild(iconMessage);
    // concatenar a message una fuente tipo link
    if (Array.isArray(fuente) && !$.isEmptyObject(fuente)) {
      linkContainer.innerHTML = fuente.length > 1 ? 'Referencias: ' : 'Referencia: ';
      fuente.forEach((source) => {
        addLinkToContainer(linkContainer, source);
      });
    }
    if (isRouteValid) {
      linkContainerRouter.innerHTML = 'URL de indicación: ';
      addLinkToContainer(linkContainerRouter, route);
    }
    if (typeUser === 'copilot' && !loading && !getHistory) {
      const messageContentElement = document.getElementsByClassName('message__content');
      const copilotStreamContainer = document.getElementsByClassName('bubble__copilot-message');
      const messageContentIndex = messageContentElement.length - 1;
      const copilotStreamIndex = copilotStreamContainer.length - 1;

      if (isRouteValid) {
        messageContentElement[messageContentIndex].appendChild(linkContainerRouter);
        linkContainerRouter.style.paddingLeft = '0';
      }
      if (Array.isArray(fuente) && !$.isEmptyObject(fuente)) {
        messageContentElement[messageContentIndex].appendChild(linkContainer);
        linkContainer.style.paddingLeft = '0';
      }
      const iconsContainer = document.createElement('div');
      iconsContainer.classList.add('icons-container');
      iconsContainer.appendChild(copyToIcon);
      iconsContainer.appendChild(thumbUpIcon);
      iconsContainer.appendChild(thumbDownIcon);
      copilotStreamContainer[copilotStreamIndex].classList.remove('max-content-width-message');
      copilotStreamContainer[copilotStreamIndex].appendChild(iconsContainer);
      const typeWriterSelector = document.getElementById('typeWriter');
      if (typeWriterSelector) typeWriterSelector.remove();
      message = '<br/>';
      return 'stream copilot';
    }
    messageContainer.appendChild(messageContent);
    typeWriterEffect(messageContent, message, noAnimation).then(() => {
      if (isRouteValid) messageContainer.appendChild(linkContainerRouter);
      if (Array.isArray(fuente) && !$.isEmptyObject(fuente)) messageContainer.appendChild(linkContainer);
      const iconsContainer = document.createElement('div');
      iconsContainer.classList.add('icons-container');
      iconsContainer.appendChild(copyToIcon);
      iconsContainer.appendChild(thumbUpIcon);
      iconsContainer.appendChild(thumbDownIcon);
      messageContainer.appendChild(iconsContainer);
      SendToBottom();
    });
  }

  if (typeUser === 'user' && !loading) {
    messageContent.innerHTML = message;
    messageContainer.appendChild(iconMessage);
    messageContainer.appendChild(messageContent);
  }

  return messageContainer;
};

// insert creteMessage in aside__body
export const insertMessage = async (message, typeUser, fuente, route, noAnimation = false, getHistory = false) => {
  const asideBody = document.querySelector('.aside__body');
  if (typeUser === 'error') {
    asideBody.insertAdjacentHTML('beforeend', message);
    return;
  }
  if (typeUser === 'loading') {
    const messageContainer = await createMessage(message, 'copilot', fuente, route, true);
    asideBody.appendChild(messageContainer);
    return;
  }
  // solo permitir <br> en los mensajes, otras etiquetas html mostrar como texto
  if (!message.includes('<br/>')) {
    if (!message.includes('>>')) {
      message = message.replace(/</g, ' ');
      message = message.replace(/>/g, ' ');
    }
  }
  if (message.includes('\n')) {
    message = message.replace(/\n/g, '<br/>');
  }
  const messageContainer = await createMessage(message, typeUser, fuente, route, false, noAnimation, getHistory);
  if (messageContainer === 'stream copilot') {
    return;
  }
  asideBody.appendChild(messageContainer);
};

export const showBukLoader = (state) => {
  const bukLoader = '<div class="text-center"><div class="buk-loader"></div></div>';
  const asideBody = document.querySelector('.aside__body');
  if (asideBody) {
    if (state) {
      asideBody.innerHTML = bukLoader;
    }
    else {
      asideBody.innerHTML = initialTemplate;
    }
  }
};

export const createAlert = (message) => {
  const msjError = 'Algo hizo que pierda mi conexión, por favor abre un nuevo chat.';
  const alert =
  `<div class="alert alert_danger text-left alert-dismissible fade show alert__scroll" role="alert">
    <div class="alert_container d-flex">
      <div class="alert_container__icon">
        <span class=" material-icons " aria-hidden="true">wifi_off</span>
      </div>
      <div class="alert_container__content">
        <h4 class="alert_container__title">¡Ups, Perdí conexión!</h4>
        ${msjError || message}
      </div>
    </div>
  </div>`;

  return alert;
};

export const loadingAnimationMsg = () =>
  `<div class="dots-container">
    <div class="snippet" data-title="dot-flashing">
      <div class="stage">
        <div class="dot-flashing"></div>
      </div>
    </div>
  </div>
  `;

export const enableNewChat = (state) => {
  if (state === false) {
    $('#newchat_btn').prop('disabled', true);
  }
  else{
    $('#newchat_btn').prop('disabled', false);
  }
};

export const enableInput = (state) => {
  if (state === false) {
    $('#input-copilot').prop('disabled', true);
    $('#input-copilot').css({
      'background-color': '#f0f0f0',
    });
    $('#send_message_btn').css({
      'background-color': '#f0f0f0',
    });
  }
  else{
    $('#input-copilot').prop('disabled', false);
    $('#input-copilot').css({
      'background-color': '#fff',
    });
    $('#send_message_btn').css({
      'background-color': '#2F4DAA',
    });
  }
};

onmount('#copilot-nav', async function () {
  const { isTouchDevice } = await import('./helpers_copilot');
  // se deshabilita funcionalidades en mobile
  if(isTouchDevice()) {
    return;
  }

  const { ModalFeedback } = await import(/* webpackChunkName: "buk-copilot" */  './modal_feedback_copilot');
  const {
    fetchCopilotData,
    fetchGetHistory,
    fetchResetContext,
  } = await import(/* webpackChunkName: "buk-copilot" */ './fetch_copilot_api');

  const {
    createMaterialIcon,
    copyToClipboard,
    SendToBottom,
  } = await import(/* webpackChunkName: "buk-copilot" */ './helpers_copilot');

  let firstRender = ($('[data-toggle="show-aside-copilot"]').hasClass('copilot-general-active'));
  let newConversation = false;
  let lastMessage = '';
  let isLoading = false;

  const CleanBody = () => {
    const asideBody = document.querySelector('.aside__body');
    asideBody.innerHTML = initialTemplate;
    newConversation = true;
    lastMessage = '';
    fetchResetContext();
    return;
  };

  const initialState = () => {
    if (firstRender) {
      showBukLoader(true);
      fetchGetHistory().then(({ data }) => {
        if (data === null) return showBukLoader(false);
        if (data.length > 0) {
          const asideBody = document.querySelector('.aside__body');
          showBukLoader(false);
          asideBody.innerHTML = '';
          data.forEach((obj) => {
            if (obj.role === 'user') {
              insertMessage(obj.content, obj.role);
              lastMessage = obj.content;
            }
            if (obj.role === 'system') {
              const source = obj.source?.length > 0 ? obj.source : 'N/A';
              const route = obj.route?.length > 0 ? obj.route : 'N/A';
              insertMessage(obj.content, 'copilot', source, route, true, true);
            }
          }
          );
        }
        firstRender = false;
        newConversation = false;
        SendToBottom();
      });
    }
  };

  initialState({ initial: true });
  $('#send_message_btn').on('click', function (e) {
    e.preventDefault();
    const textArea = document.getElementById('input-copilot');
    const message = $('#input-copilot').val();
    textArea.style.height = '34px';
    if (message !== '') {
      // funcion firstRender limpia o carga el estado inicial del aside
      if (firstRender || newConversation) {
        const asideBody = document.querySelector('.aside__body');
        asideBody.innerHTML = '';
        firstRender = false;
        newConversation = false;
      }
      insertMessage(message, 'user');
      SendToBottom();
      $('#input-copilot').val('');
      this.blur();
      insertMessage(loadingAnimationMsg(), 'loading');
      SendToBottom();
      isLoading = true;
      fetchCopilotData(message).then(({ data, fuente, route, sourceData }) => {
        lastMessage = message;
        // remplazar animacion de carga por el mensaje
        const containerDots = $('.bubble__copilot-message:has(.dots-container)');
        containerDots?.remove();
        const copilotMessage = data;
        const articleReference =  (fuente === null || fuente === '') ? 'Sin Referencia' : fuente.join(', ');
        insertMessage(copilotMessage, 'copilot', fuente, route);
        $(this).trigger('amplitudeTracking', {
          message: 'Ask_Copilot',
          data: {
            answered: 'True',
            conversation_messages: 'Pregunta: ' + message + '\n' + 'Respuesta: ' + copilotMessage,
            source_data: sourceData,
            source: 'NavBar',
            article_reference: articleReference,
          },
        });
        SendToBottom();
        isLoading = false;
        enableInput(true);
        enableNewChat(true);
      }).catch((error) => {
        insertMessage(createAlert(error), 'error');
        $(this).trigger('amplitudeTracking', {
          message: 'Ask_Copilot',
          data: {
            answered: 'False',
            conversation_messages: 'Pregunta: ' + message + '\n' + 'Respuesta: ' + 'Sin respuesta',
            source: 'NavBar',
          },
        });
        SendToBottom();
        isLoading = false;
        enableInput(true);
        enableNewChat(true);
      });
    }
  });

  $('#newchat_btn').on('click', function (e) {
    e.preventDefault();
    $('#input-copilot').val('');
    CleanBody();
    newConversation = true;
    firstRender = true;
    this.blur();
    $(this).trigger('amplitudeTracking', {
      message: 'NewChat_Copilot',
      data: {
        source: 'NavBar',
      },
    });
  });

  $('#regenerate_btn').on('click', function (e) {
    e.preventDefault();
    if (lastMessage) {
      insertMessage(loadingAnimationMsg(), 'loading');
      SendToBottom();
      enableNewChat(false);
      fetchCopilotData(lastMessage).then(({ data, fuente, route }) => {
        // remplazar animacion de carga por el mensaje
        const containerDots = $('.bubble__copilot-message:has(.dots-container)');
        containerDots?.remove();
        const copilotMessage = data;
        insertMessage(copilotMessage, 'copilot', fuente, route);
        SendToBottom();
        enableNewChat(true);
      }).catch((error) => {
        insertMessage(createAlert(error), 'error');
      });
    }
  });

  $('#input-copilot').on('keydown', function (e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      $('#send_message_btn').click();
      // if isLoading disable input
      if (isLoading) {
        enableInput(false);
        enableNewChat(false);
      }
    }
  });

  $('#aside_copilot').on('click', '.copy-icon', function (e) {
    e.preventDefault();
    const message = $(this).parent().parent().text().replace(/thumb_down|thumb_up|content_copy/g, '');
    copyToClipboard(message);
    $(this).text('done');
    setTimeout(() => {
      $(this).text('content_copy');
    }, 2000);
  });

  $('#aside_copilot').on('click', '.thumb-up-icon', function (e) {
    e.preventDefault();
    $(this).trigger('amplitudeTracking', {
      message: 'Feedback_Assistant_Copilot',
      data: {
        feedback_type: 'Positive',
        source: 'NavBar',
      },
    });
    const input = $(this).parent().parent().prevAll('.bubble__user-message:first').text().replace('person_pin', '');
    const output = $(this).parent().parent().text().replace(/thumb_down|thumb_up|content_copy/g, '');
    const thumbUpIcon = createMaterialIcon('thumb_up', 'thumb-up-icon');
    const question = '¿Qué te gusta de la respuesta entregada por Buk copilot?';
    ModalFeedback(thumbUpIcon, question, input, output);
  });

  $('#aside_copilot').on('click', '.thumb-down-icon', function (e) {
    e.preventDefault();
    $(this).trigger('amplitudeTracking', {
      message: 'Feedback_Assistant_Copilot',
      data: {
        feedback_type: 'Negative',
        source: 'NavBar',
      },
    });
    const input = $(this).parent().parent().prevAll('.bubble__user-message:first').text().replace('person_pin', '');
    const output = $(this).parent().parent().text().replace(/thumb_down|thumb_up|content_copy/g, '');
    const thumbDownIcon = createMaterialIcon('thumb_down', 'thumb-down-icon');
    const question = '¿Cuál fue el problema con la respuesta? ¿Cómo podemos mejorarlo?';
    ModalFeedback(thumbDownIcon, question, input, output);
  });
});
