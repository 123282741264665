/** @file TODO: documentar */
import onmount from 'onmount';

async function alert(title, message, type) {
  const { default: swal } = await import('sweetalert2/dist/sweetalert2.js');
  return await swal.fire(title, message, type);
}
/**
 * @param {string} message
 * @param {string} title
 * Funcion que muestra un sweetalert de tipo warning
 */
export function warning(message, title = 'Advertencia') {
  return alert(title, message, 'warning');
}
/**
 * @param {string} message
 * @param {string} title
 * Funcion que muestra un sweetalert de tipo error
 */
export function error(message, title = 'Error') {
  return alert(title, message, 'error');
}
/**
 * @param {string} message
 * @param {string} title
 * Funcion que muestra un sweetalert de tipo info
 */
export function info(message, title = 'Información') {
  return alert(title, message, 'info');
}
/**
 * @param {string} message
 * @param {string} title
 * Funcion que muestra un sweetalert de tipo success
 */
export function success(message, title = 'Éxito') {
  return alert(title, message, 'success');
}

async function alertAction(title, message, confirmButtonText, cancelButtonText, icon, html, customConfirmButton,
  swalInput, swalInputAttributes) {
  const { default: swal } = await import('sweetalert2/dist/sweetalert2.js');
  let swalAttributes = {
    title: title ? title : message,
    text: title ? message : null,
    icon: icon,
    html: html,
    confirmButtonText: confirmButtonText,
    showCancelButton: cancelButtonText != null,
    cancelButtonText: cancelButtonText,
    showCloseButton: true,
    closeButtonHtml: '<span class="material-icons">close</span>',
    reverseButtons: true,
    keydownListenerCapture: true,
    customClass: {
      confirmButton: customConfirmButton ? customConfirmButton : '',
      popup: swalInput === 'true' && swalInputAttributes.inputValidator ? 'input-swal2-popup' : '',
    },
  };
  if (swalInput === 'true' && swalInputAttributes.inputValidator) {
    swalAttributes = Object.assign(swalAttributes, swalInputAttributes);
  }
  return await swal.fire(swalAttributes);
}

/** Muestra un alert con solo un mensaje en forma de titulo. */
export function action(message = null, confirmButtonText = 'Aceptar', cancelButtonText = null, icon = null, html = null,
  customConfirmButton = null, swalInput = false, swalInputAttributes = {}) {
  return alertAction(message, null, confirmButtonText, cancelButtonText, icon, html, customConfirmButton, swalInput,
    swalInputAttributes);
}

/** El alert se muestra con titulo y mensaje separados */
export function actionMessage(title, message, confirmButtonText = 'Aceptar', cancelButtonText = null, icon = null,
  customConfirmButton = null, swalInput = false, swalInputAttributes = {}) {
  return alertAction(title, message, confirmButtonText, cancelButtonText, icon, null, customConfirmButton, swalInput,
    swalInputAttributes);
}

onmount('.content > .alert', function () {
  const alertToMove = $(this);
  const bodyOuterBox = $('.box-generals').find('.box-body.box-height-screen');
  if(bodyOuterBox.length > 0) {
    onmount(bodyOuterBox, function () {
      alertToMove.prependTo(this);
      alertToMove.removeClass('hide');
    });
  }
  setTimeout(() => {
    alertToMove.removeClass('hide');
  }, 100);
});

/**
 * @param {Object} options
 * @param {string} element - Elemento del DOM donde se renderizará el widget de alerta.
 * @param {string} alertType - Tipo de alerta a mostrar.
 * @param {boolean} alertList - Indica si la alerta es tipo lista.
 * @param {boolean} alertClose - Indica si la alerta se puede cerrar.
 * @param {string} alertTitle - Título de la alerta.
 * @param {string} alertMessage - Mensaje de la alerta.
 * @param {array} items - Lista de items a mostrar en la alerta.
 * Función para mostrar un alert en el DOM con los parametros proporcionados generando un widget de alerta desde el ERB.
 */
export function renderAlertWidget({ element,
  alertType,
  alertList = false,
  alertClose = false,
  alertTitle,
  alertMessage,
  items }) {
  const PERMITED_ALERT_TYPES = ['success', 'info', 'warning', 'danger'];
  if (!PERMITED_ALERT_TYPES.includes(alertType)) {
    throw new Error(`El tipo de alerta "${alertType}" no es permitido`);
  }
  $.ajax({
    url: '/widgets/alerts',
    method: 'GET',
    data: {
      alert_type: alertType,
      alert_list: alertList,
      alert_close: alertClose,
      alert_title: alertTitle,
      alert_message: alertMessage,
      items: items,
    },
    success: function (html) {
      // Inserta el HTML en el elemento del DOM deseado
      $(element).html(html);
    },
  });
}
