/** @file TODO: documentar */
import onmount from 'onmount';
import { formatNumberDecimal } from '../../../../../../../../app/assets/javascripts/lib/buk/format-number';

onmount('.employee-chart-summary', async function () {
  const chartData = $(this).data('set');

  const { Chart } = await import('../../../../../../../../app/assets/javascripts/vendor/charts');

  new Chart($(this), {
    type: 'line',
    data: {
      labels: Object.keys(chartData),
      datasets: [{
        data: Object.values(chartData),
        borderColor: 'rgba(11, 98, 164, 0.8)',
        pointBackgroundColor: 'rgba(255, 255, 255, 1)',
        borderWidth: 2
      }]
    },
    options: {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true,
            // Return an empty string to draw the tick line but hide the tick label
            // Return `null` or `undefined` to hide the tick line entirely
            userCallback: formatNumberDecimal
          }
        }]
      }
    }
  });
});
