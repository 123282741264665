/** @file
 * Permite imprimir partes del DOM a un PDF utilizando el dialogo de impresión del navegador
 * Para utilizarlo se debe añadir el id="print-button" y añadir el atributo `target` con el
 * selector de los elementos que se deben incluir en la impresión
 *
 * Cuando se imprimen graficos de Chartjs que están ocultos en tabs del tabs_widget, el script
 * hara el intento de renderizar esos graficos para que aparezcan en la impresión.
 */
import onmount from 'onmount';

// TODO: cambiar selector por el genérico para no tener ids repetidos
onmount('#print-button, [data-behavior="print-elements"]', function () {
  var elementsToPrint = $(this).data('target');

  $(this).on('click', async function () {
    await import('print-this');
    const { Chart } = await import('../../../../../../../app/assets/javascripts/vendor/charts.js');

    var charts = Object.keys(Chart.instances).length;

    //Intentamos renderizar los charts que aún no se han renderizado
    for (let i = 0; i < charts; i++) {
      if ($(Chart.instances[i].canvas).parents(elementsToPrint) && Chart.instances[i].canvas.width === 0) {
        Chart.instances[i].canvas.width = '1178';
        Chart.instances[i].canvas.height = '706';
        Chart.instances[i].canvas.style.height = '706px';
        Chart.instances[i].canvas.style.width = '1178px';
        Chart.instances[i].height = 706;
        Chart.instances[i].width = 1178;
        Chart.instances[i].chartArea = { left: 0, top: 32, right: 1178, bottom: 706 };
        Chart.instances[i].update();
        Chart.instances[i].render();
      }
    }

    $(`${elementsToPrint}`).printThis({
      canvas: true,
    });
  });
});

