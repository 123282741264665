/** @file TODO: documentar */
import onmount from 'onmount';
import { once } from 'lodash-es';

const loadEditable = once(async function loadEditable() {
  import('x-editable/dist/bootstrap3-editable/css/bootstrap-editable');
  await import('../vendor/datepicker');
  await import('x-editable/dist/bootstrap3-editable/js/bootstrap-editable');

  //turn to inline mode
  $.extend($.fn.editable.defaults, {
    mode: 'inline',
    send: 'always',
    params: function (params) {
      var data = {};
      data['id'] = params.pk;
      data[params.name] = params.value;
      return data;
    },
  });
});

onmount('.editable', async function () {

  const $this = $(this);
  const link = $this.get(0);

  const options = {
    ajaxOptions: {
      type: $this.data('editable-method') || 'post',
    }
  };

  if ($this.data('target')) {
    options.display = function (_value, response) {
      $($this.data('target')).html(response);
    };
  }
  await loadEditable();
  $this.editable(options);

  link.addEventListener('click', function (event) {
    event.preventDefault();

    setTimeout(() => {
      const emergentButtons = $('.editable-buttons');
      const footerTable = $('.dataTables_paginate');
      const emergentButtonsRect = emergentButtons[0]?.getBoundingClientRect();
      const footerTableRect = footerTable[0]?.getBoundingClientRect();
      emergentButtons[0].style.position = emergentButtonsRect.bottom > footerTableRect?.top ? 'relative' : '';
    }, 10);
  });
});

export default loadEditable;
