/** @file Archivo utilizado para validar el monto a solicitar */
import onmount from 'onmount';

// Flujo a través de los modals
onmount('#adelantos_auth_code', function () {
  $('#adelantos_auth_code').on('keyup input', validateCodeLength);
});

function validateCodeLength() {
  const value = $(this).val();
  const isValueValid = value.length === 6;

  enableButtonAdelantos(isValueValid);
}

function enableButtonAdelantos(isAmountValid) {
  $('#advances-confirm-button-next').prop('disabled', !isAmountValid);
}
