/** @file TODO: documentar */
import onmount from 'onmount';

onmount('[data-submit-only]', function () {
  $(this).click(function () {
    var $self = $(this);
    var form = this.form;
    if (!form) {
      return;
    }
    var elements = $(form.elements)
      .not($self.data('submitOnly'))
      .not(this)
      .not('[name="authenticity_token"]')
      .not('[name="_method"]');

    if ($self.data('confirm')) {
      $self.on('confirm:complete', function (event) {
        const [answer] = event.detail;
        if (answer) {
          disableInputs();
        }
      });
    }
    else {
      disableInputs();
    }

    function disableInputs() {
      elements.prop('disabled', true);
      if (!form.checkValidity()) {
        setTimeout(() => {
          if (form.reportValidity) {
            form.reportValidity();
          }
          elements.prop('disabled', false);
        }, 0);
      }
    }
  });
});
