/** @file TODO: documentar */
import onmount, { $ } from 'onmount';
import { warning } from '../../../../../../../app/assets/javascripts/lib/buk/alert';

$(document).on('change', '[data-polymorphic-select]', function () {
  const type = $(this).find("option:selected").data('type');
  $($(this).data('polymorphic-select')).val(type);
});

onmount('#action-plans-table', function () {
  const globalGraph = $('#global-metrics');
  if (!globalGraph.length) return;

  let alreadyLoaded = false;

  $(this).on('draw.dt', function () {
    if (alreadyLoaded) {
      $.get({
        url: $(this).data('url') + '/filter_global_metrics',
      })
        .done(response => globalGraph.html(response))
        .fail(() => warning('No fue posible actualizar el filtro'));
    }
    else {
      alreadyLoaded = true;
    }
  });
});
