/** @file Permite la visualización de un gráfico de tipo barra, linea o torta
 *
 * Para usar debe seguir la siguiente estructura:
 *
 * 1. Un elemento tipo canvas con el atributo data-unobtrusive-chart.
 * 2. (opcional) Para habilitar la opcion de cambiar de tipo de gráfico:
 *               Debe tener un elemento padre o contenedor. El plugin
 *               considerará a cada elemento descendiente de clase
 *               'chart-type-selector' como un tipo de gráfico seleccionable.
 *               Al hacer click sobre cualquiera de estos elementos se
 *               desencadena la accion de cambiar a ese tipo de gráfico.
 *
*/

import onmount, { $ } from 'onmount';
import { numberToCurrency } from '../lib/buk/format-currency.js';

onmount('[data-unobtrusive-chart]', async function () {
  const { Chart } = await import('../vendor/charts.js');
  await import('chartjs-plugin-funnel');
  const options = $(this).data('unobtrusiveChart');
  const extraOptions = buildExtraOptions(options);
  let chart = new Chart(this, $.extend(true, options, extraOptions));
  const chartContainer = $(this).parent();

  /*
   * Función para cambiar el tipo de gráfico
   */
  const change = (newType) => {
    const ctx = this.getContext('2d');
    const config = $(this).data('unobtrusiveChart');

    // Remover el gráfico anterior
    if (chart) {
      chart.destroy();
    }

    // Crear nuevo gráfico con el tipo elegido
    var newOptions = $.extend(true, config, buildExtraOptions(newType));
    newOptions.type = newType;

    chart = new Chart(ctx, newOptions);
  };

  chartContainer.find('.chart-type-selector').click(function (evt) {
    const chartType = $(evt.target).data('chartType');
    change(chartType);
  });

  function buildExtraOptions(settings) {
    const currencyType = settings.currencyType;
    if (settings.type === 'line' || settings.type === 'bar') {
      return {
        options: {
          scales: {
            yAxes: [{
              ticks: {
                callback: function (value) {
                  /* - Condicion asegura que la separacion de miles y signo $ afecte a gastos en columna Y,
                        parametro 'format_type' asegura que solo afecte al grafico que indique este parametro.
                      - numberToCurrency formatea y valida tanto para inputs con decimales como enteros,
                        recibe por parametros nombrados numberToCurrency({value:valor,type:'uf','sol','utm'} por defecto es $);
                        */
                  if(settings.format_type === 'formatCurrency')
                    return numberToCurrency({ value: value, type: currencyType });
                  else
                    return Number(value);
                },
                beginAtZero: true,
                precision: settings.yAxisScalePrecision,
                maxTicksLimit: settings.maxTicksLimit,
              },
            }],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                /* - Condicion asegura que la separacion de miles y signo $ afecte solo a gastos en tooltip */
                if(settings.format_type === 'formatCurrency')
                  return numberToCurrency({ value: tooltipItem.yLabel, type: currencyType });
                else
                  return Number(tooltipItem.yLabel);
              },
            },
          },
        },
      };
    }
    if (settings.type === 'pie' || settings.type === 'doughnut') {
      return {
        options: {
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                /* Retorna el tootip, como la cantidad de la porción del grafico de torta seleccionado */
                return settings.data.datasets[0].data[tooltipItem.index];
              },
            },
          },
        },
      };
    }
    if (settings.type === 'funnel') {
      return {
        options: {
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                callback: function (value, index) {
                  return `${value} (${settings.data.datasets[0].data[index]})`;
                },
              },
            }],
          },
        },
      };
    }

  }

});
