/** @file TODO: documentar */
import { isObject, isArray, each } from 'lodash-es';
import onmount from 'onmount';

//Cuidado al editar este plugin. Si se cambia la manera en que se obtiene el valor
//o se cambia el evento que se escucha para hacer el toggle, areaselect dejaria de considerarse para form-toggle
//Esto porque areaselect emula un input en el div contenedor agregando la propiedad .value con el valor del area
//y lanzando un evento 'change' cuando el valor del areaselect cambia

const toggleFunctions = {
  display: {
    show: function (target) {
      target.show('slow');
    },
    hide: function (target) {
      target.hide('slow');
    },
  },
  visibility: {
    show: function (target) {
      target.addClass('visible').removeClass('invisible');
    },
    hide: function (target) {
      target.addClass('invisible').removeClass('visible');
    },
  },
  hidden: {
    show: function (target) {
      target.removeClass('hidden');
    },
    hide: function (target) {
      target.addClass('hidden');
    },
  },
  'instant-display': {
    show: target => target.show(),
    hide: target => target.hide(),
  },
  disabled: {
    show: function (target) {
      target.prop('disabled', false);
    },
    hide: function (target) {
      target.prop('disabled', true);
    },
  },
};

function getToggleOptions(element) {
  return {
    operator: element.data('operator') || '==',
    isDate: element.data('provide') === 'datepicker',
    skipEnable: element.data('skipEnable'),
    skipDisable: element.data('skipDisable'),
    groupClass: element.data('groupClass') || '.form-group',
    toggleMethod: toggleFunctions[element.data('toggleMethod')] || toggleFunctions['display'],
  };
}

function toggleGroup(target, options, state) {
  // Esto retorna el mismo elemento si este es de la misma clase que options.groupClass
  const $target = $(target);
  const group = $target.closest(options.groupClass);
  const method = state ? 'show' : 'hide';
  const finalTarget = group.length ? group : $target;
  let skipSelector = '';
  if (state && options.skipEnable) {
    //Se esta habilitando los input
    skipSelector = ':not(' + options.skipEnable + ')';
  }
  else if (!state && options.skipDisable) {
    //Se esta deshabilitando los input
    skipSelector = ':not(' + options.skipDisable + ')';
  }

  options.toggleMethod[method](finalTarget);
  finalTarget.find(':input' + skipSelector).prop('disabled', !state);
  //Este evento es para indicar que se intenta habilitar/deshabilitar el control
  $target.trigger('form-toggle-enable', state);
  if (state) {
    //Si se esta habilitando, los form toggle interiores verifican si deben cambiar sus inputs asociados
    finalTarget.find('[data-form-toggle]').trigger('form-toggle-check');
  }
}

function getValue($element) {
  if ($element.is(':radio,:checkbox')) {
    return $element.is(':checked') ? $element.val() : '';
  }
  else {
    return $element.val();
  }
}

/**
 * Se encarga de mostrar/esconder campos en base a un checkbox
*/
export function formToggle() {
  const $self = $(this);
  const target = $self.data('formToggle');
  const options = getToggleOptions($self);
  const $target = $(target);

  if ($target.length === 0) {
    // El form toggle es manejado por el calendario en el caso de las fechas y periodos
    // por lo que no se debe lanzar error si el target no existe en el primer intento.
    if (target.match(/date/g)) return;
    throw new Error(`form-toggle target '${target}' does not exist`);
  }
  const toggleValue = $self.data('formToggleValue') || '';
  let previousState = (getValue($self) == toggleValue);

  $self.on('change', doToggle);
  $self.on('form-toggle-check', function () {
    toggleGroup(target, options, getValue($self) != toggleValue);
  });

  function doToggle() {
    const state = (getValue($self) == toggleValue);
    if (state === previousState) return;
    previousState = state;
    // al revés porque se esconde cuando es igual/vacío
    toggleGroup(target, options, !state);
  }
}

onmount('[data-form-toggle]', formToggle);

/**
 * Listener encargado de mostrar/esconder campos en base al valor de otro campo
 */
onmount('[data-form-switch]', async function () {
  const { default: moment } = await import('moment');
  const $self = $(this);
  const targets = $self.data('formSwitch');
  const options = getToggleOptions($self);
  if (!(isObject(targets) && !isArray(targets))) {
    throw new Error('form-toggle must be an object');
  }

  function compareDate(post, operator, value) {
    post = moment(post, 'DD-MM-YYYY');
    value = moment(value, 'DD-MM-YYYY');
    switch (operator) {
      case '>': return post.isAfter(value);
      case '<': return post.isBefore(value);
      case '>=': return post.isSameOrAfter(value);
      case '<=': return post.isSameOrBefore(value);
      case '==': return post.isSame(value);
      case '!=': return !post.isSame(value);
      default: throw new Error('operator must be one of the list above');
    }
  }

  function compareValues(post, operator, value) {
    switch (operator) {
      case '>': return parseInt(post) > parseInt(value);
      case '<': return parseInt(post) < parseInt(value);
      case '>=': return parseInt(post) >= parseInt(value);
      case '<=': return parseInt(post) <= parseInt(value);
      case '==': return post == value;
      case '!=': return post != value;
      case '===': return post === value;
      case '!==': return post !== value;
      case 'include': return value.includes(post);
      default: throw new Error('operator must be one of the list above');
    }
  }

  $self.on('change', doToggle);

  function doToggle() {
    const curVal = getValue($self);
    const toggles = {};
    toggles[true] = new Set();
    toggles[false] = new Set();
    const compare = (options.isDate) ? compareDate : compareValues;
    // eslint-disable-next-line lodash/preferred-alias
    each(targets, (selector, toggleValue) => {
      const $target = $(selector);
      const state = compare(toggleValue, options.operator, curVal);
      $target.each(function () { toggles[state].add(this); });
    });

    toggles[false].forEach(i => {
      // no escondamos si vamos a mostrar
      if (toggles[true].has(i)) {
        return;
      }
      toggleGroup(i, options, false);
    });
    toggles[true].forEach(i => {
      toggleGroup(i, options, true);
    });
  }
  if($self.data('formSwitchInit')) {
    $self.trigger('change');
  }
});

/**
 * Alterna la visibilidad del elemento dependiendo de si esta seleccionado el checkbox 'onlyCheck'
 * o si al menos 'atLeastChecked' checkboxes están seleccionados
 * la dependencia está invertida respecto a form-toggle
 */
export function toggleBy() {
  const $self = $(this);
  const targets = $self.data('toggleBy');
  const atLeastChecked = $self.data('atLeastChecked') || targets.length;
  const onlyCheck = $self.data('onlyCheck') || '';
  const options = getToggleOptions($self);
  let toggles = targets.map(function (t) {
    return getValue($(t).find(':input'));
  });

  let toggleValue = calculateToggleValue();
  let previousState = toggleValue;

  targets.forEach(function (target) {
    const $target = $(target);
    if ($target.length === 0) {
      throw new Error(`toggle-by target '${$target}' does not exist`);
    }
    $target.on('change', doToggle);
  });

  function doToggle() {
    toggles = targets.map(function (t) {
      return getValue($(t).find(':input'));
    });

    toggleValue = calculateToggleValue();

    const state = toggleValue;

    if ((state === previousState)) return;

    previousState = state;
    toggleGroup($self, options, state);
  }

  function calculateToggleValue() {
    let value = atLeastNChecked(toggles, atLeastChecked);
    if (onlyCheck) {
      value = value && onlyChecked(toggles, onlyCheck);
    }
    return value;
  }

  function atLeastNChecked(checkboxes, n) {
    return checkboxes.filter(checkbox => checkbox !== '').length >= n;
  }

  function onlyChecked(checkboxes, value) {
    if (Array.isArray(value)) {
      return checkboxes.filter(checkbox => checkbox !== '').every(checkbox => value.includes(checkbox));
    }
    else {
      return (
        checkboxes.filter(checkbox => checkbox !== '').length === 1 &&
        checkboxes.find(checkbox => checkbox !== '') === value
      );
    }
  }
}

onmount('[data-toggle-by]', toggleBy);
