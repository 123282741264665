/** @file Se incluyen métodos útiles para trabajar con fechas */

/** Recibe un valor del tipo MM YYYY y devuelve una fecha de javascript */
/** El día de la fecha es el último del mes */
/**
* @param period, El string a formatearse como fecha
* @param separator, El string que se utiliza para delimitar el año y  mes
* @return Date
*/
export function periodStringtoDate(period, separator) {
  const numbers = period.split(separator).map(element => element * 1);
  if (numbers.some(e => Number.isNaN(e))) return null;
  return new Date(numbers[1], numbers[0], 0);
}
