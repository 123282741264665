/** @file
 * Maneja los eventos de la vista partner complaint
 */

import onmount, { $ } from 'onmount';

onmount('#new_partner_complaint_ticket, #partner_complaint_ticket_partner_fields_content > div', function () {
  const $self = $(this);
  const MODULE_PATH = 'partner_complaint_ticket';

  execToggleInputs();

  /**
   * Función para ocultar o mostrar inputs dinámicamente
   */
  function execToggleInputs() {
    const items = $self.find(`[data-element-hidden][data-value-to-show-input]`);
    items.each(function () {
      const input = $(this);
      toggleInput.bind(input)();
      input.on('change', toggleInput.bind(input));
    });

    function toggleInput() {
      if (this.is(':radio') && !this.is(':checked')) return;
      const inputHidden = this.data('element-hidden');
      const showInput = this.val() === `${this.data('value-to-show-input')}`;
      let elementToHide = $(`.${MODULE_PATH}_${inputHidden}`);
      if (elementToHide.length === 0) {
        elementToHide = $(`.${inputHidden}`);
      }
      elementToHide[showInput ? 'removeClass' : 'addClass']('hidden');
    }
  }

});

onmount('.new_partner_complaint_type, .edit_partner_complaint_type', function () {
  const MODULE_PATH = 'partner_complaint_type';
  const $self = $(this);
  const checkEmail = $self.find(`#${MODULE_PATH}_email_required`);
  const checkName = $self.find(`#${MODULE_PATH}_complainant_required`);
  const checkDocument = $self.find(`#${MODULE_PATH}_document_required`);

  checkName.on('change', function () {
    if (this.checked) { return; }
    checkEmail.prop('checked', false);
    checkDocument.prop('checked', false);
  });

  checkEmail.on('change', function () {
    if (this.checked) {
      checkName.prop('checked', true);
    }
  });

  checkDocument.on('change', function () {
    if (this.checked) {
      checkName.prop('checked', true);
    }
  });
});
