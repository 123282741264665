/** @file TODO: documentar */
import { toCode } from '../../../../../../../app/assets/javascripts/lib/string';
import onmount from 'onmount';

class SlugifyPlugin {
  constructor(target, from) {
    this.target = $(target);
    this.from = $(from);
    this.previousValue = this.from.val();

    this.from.on('keyup change', () => this.updateValue());
  }
  get previousSlug() {
    return toCode(this.previousValue);
  }
  get currentSlug() {
    return toCode(this.from.val());
  }
  updateValue() {
    if (this.previousSlug != this.target.val()) {
      return;
    }
    this.target.val(this.currentSlug);
    this.previousValue = this.from.val();
  }
}


onmount('[data-slugify-from]', function () {
  const $self = $(this);
  const from = $($self.data('slugifyFrom'));
  if (from.length == 0) {
    throw new Error(`slugify from '${$self.data('slugifyFrom')}' not found`);
  }

  new SlugifyPlugin($self, from);
});
