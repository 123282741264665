/** @file
 * La clase VisibilityTracker permite ejecutar un callback la primera
 * vez que el elemento es visible en el dom.
 * @param callback: Funcion a ejecutar al ser visisble el elemento.
 */

import { filter } from 'lodash-es';
/**
 * @type {MutationObserverInit}
 */
const ObserveParams = {
  attributes: true,
  attributeOldValue: false,
  subtree: false,
};

/** @param {Callback} callback */
class VisibilityTracker {
  constructor(callback) {
    this._callback = callback;
    this._watchedElements = new Set();
    this._observer = new MutationObserver(this._onMutation.bind(this));
  }
  /** @param {Element} element */
  watch(element) {
    if (this._isVisible(element)) {
      this._trigger(element);
    }
    else {
      this._watchedElements.add(element);
      this._observer.observe(this._getLastInvisible(element), ObserveParams);
    }
  }
  /** @param {Element} element */
  unwatch(element) {
    this._watchedElements.delete(element);
    this._resetObserver();
  }
  _resetObserver() {
    this._observer.disconnect();
    if (this._watchedElements.size > 0) {
      this._watchedElements.forEach(element => {
        this._observer.observe(this._getLastInvisible(element), ObserveParams);
      });
    }
  }
  /** @param {Element} element */
  _getLastInvisible(element) {
    let tmp = element;
    while(tmp.parentElement && !this._isVisible(tmp.parentElement)) {
      tmp = tmp.parentElement;
    }
    return tmp;
  }
  /** @param {Element} element */
  _isVisible(element) {
    if (element.offsetParent !== null) {
      return true;
    }
    const rects = element.getClientRects();
    if (rects.length > 0) {
      return true;
    }
    return false;
  }
  /** @param {Element} element */
  _actIfVisible(element) {
    if (this._isVisible(element)) {
      this._trigger(element);
      this.unwatch(element);
    }
  }
  /**
   *
   * @param {MutationRecord[]} changes
   */
  _onMutation(changes) {
    filter(changes, { type: 'attributes' }).forEach(record => {
      this._watchedElements.forEach(element => {
        if (record.target.contains(element)) {
          this._actIfVisible(element);
        }
      });
    });
  }
  /** @param {Element} element */
  _trigger(element) {
    this._callback(element);
  }
}

export default VisibilityTracker;
