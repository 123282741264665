/**
 * @file Este archivo gestiona la interfaz de usuario para la épica de sincronización entre Buk y Buk Asistencia.
 * Ofrece funciones y métodos para facilitar la comunicación y la interacción entre los dos sistemas, permitiendo
 * una sincronización fluida de datos.
 */
import onmount from 'onmount';

const NUMBER_OF_MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;

const stringToDate = dateString => {
  const parts = dateString.split('-');
  return new Date(parts[2], parts[1] - 1, parts[0]);
};

const dateDiff = (a, b) => {
  if (typeof a === 'string') a = stringToDate(a);
  if (typeof b === 'string') b = stringToDate(b);
  return Math.abs(a - b);
};

const dateDiffInDays = (a, b) =>
  Math.ceil(dateDiff(a, b) / NUMBER_OF_MILLISECONDS_IN_A_DAY);

onmount('#bukas-run-process', async () => {
  const { BukasWorkerStatus } = await import('../services/bukas_worker_status');
  const bukasWorkerStatus = new BukasWorkerStatus();
  await bukasWorkerStatus.checkWorkerStatus();

  $('#bukas_run_start_date').on('change', function () {
    const $this = $(this);
    const startDate = $this.val();
    const trackingData = $('#btn-send-information').data('amplitudeTracking');
    const parts = startDate.split('-');
    trackingData.data.start_day = parts[0];
    trackingData.data.dif_start_date_sync = dateDiffInDays(new Date(), startDate) - 1;
    trackingData.data.dif_dates_sync = dateDiffInDays($('#bukas_run_end_date').val(), startDate);
    $('#btn-send-information').data('amplitudeTracking', trackingData);
  });

  $('#bukas_run_end_date').on('change', function () {
    const $this = $(this);
    const endDate = $this.val();
    const trackingData = $('#btn-send-information').data('amplitudeTracking');
    const parts = endDate.split('-');
    trackingData.data.end_day = parts[0];
    trackingData.data.dif_end_date_sync = dateDiffInDays(new Date(), endDate);
    trackingData.data.dif_dates_sync = dateDiffInDays($('#bukas_run_start_date').val(), endDate);
    $('#btn-send-information').data('amplitudeTracking', trackingData);
  });
});

onmount('#modal_receiving_absences', function () {
  const context = $(this);
  const submitBtn = $('button.btn:submit', '#modal_receiving_absences');
  const handlerFormValidation = () => {
    // $('#add-participants-select').val()
    const countChecboxUnchecked = $('input:checkbox:checked', context).length;
    const countParticipantsSelected = $('#add-participants-select').val().length;

    submitBtn.prop('disabled', countChecboxUnchecked === 0 || countParticipantsSelected === 0);
  };

  $('input:checkbox[data-traking-name]', context).on('change', function () {
    const syncConcepts = [];
    $('input:checkbox[data-traking-name]:checked').each(function () {
      syncConcepts.push($(this).data('trakingName'));
    });

    const trackingData = submitBtn.data('amplitudeTracking');
    trackingData.data.sync_concepts = syncConcepts;
    submitBtn.data('amplitudeTracking', trackingData);
  });

  $('input:checkbox', context).on('change', handlerFormValidation);
  $('#add-participants-select', context).on('change', handlerFormValidation);

  handlerFormValidation();
});
