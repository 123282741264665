/** @file TODO: documentar */
export default function (element) {

  const editor = CKEDITOR.replace(element, {
    fullPage: false,
    toolbar: [
      ['Bold','Italic','Underline','Strike','-','Undo','Redo','-','Cut','Copy','Paste','Find','Replace','-','-'],
      ['NumberedList','BulletedList','-','JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'],
      '/',
      ['base64image','Table','-','Link','TextColor','BGColor'], ['Styles','Format','FontSize', 'Source', 'Maximize']
    ],
    extraPlugins: 'base64image,copyformatting,colorbutton,justify,find,font',
    versionCheck: false,
  });

  $(element).closest("form").submit(function () {
    const content = editor.getData().toLowerCase();
    if(content.indexOf('<html>') >= 0 || content.indexOf('<head>') >= 0 || content.indexOf('<body>') >= 0) {
      // TODO: usar un modal en vez de alert
      alert("El contenido no puede tener elementos como <html> <body> o <head>");
      return false;
    }
  });
  return editor;
}
