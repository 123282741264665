/** @file TODO: documentar
 *
 */
import onmount from 'onmount';
import { once } from 'lodash-es';
export { getOrgChartData };

export const loadOrgChart = once(async function loadOrgChart() {
  import('orgchart/dist/css/jquery.orgchart');
  await import('orgchart/dist/js/jquery.orgchart');
});

function climateOrgChartTemplate(data) {
  let color, icon, confidentiality;
  const customGroup = data.custom_group;
  const orgChart = $('#orgchart-container-v2');
  const confidentialityLevel = orgChart.attr('data-confidentiality-level');
  if (data.participants === 0) {
    color = 'disabled';
    icon = '';
    confidentiality = 'No afecta';
  }
  else if (data.confidentiality) {
    color = 'success';
    icon = 'check';
    confidentiality = 'Cumple';
  }
  else{
    color = 'danger';
    icon = 'close';
    confidentiality = 'No cumple';
  }
  return `
    <div class="title title-${color}">
      <div data-toggle="tooltip" data-placement="top" data-boundary="window" title="${data.name}">
        ${customGroup ? '<span class="material-icons">group_add</span>' : ''}  ${data.name}
      </div>
    </div>
    <div class="content content-${color} participants">
      ${data.confidentiality && data.mode === 'report' ? `<span>Nota: ${data.result}</span><br>` : ''}
      <span>Participantes: ${data.participants}</span>
      <br>
      ${data.mode === 'configuration' ? '' : `<span>Respuestas${data.confidentiality ? `: ${data.answers}` : ` < ${confidentialityLevel}`}</span><br>`}
      <div class="chip badge badge-${color}">
        <span class="material-icons font-size-17 font-weight-bolder" aria-hidden="true">${icon}</span>
        <span> ${confidentiality}</span>
      </div>
    </div>
  `;
}

async function getOrgChartData(url) {
  const csrfToken = $('meta[name="csrf-token"]').content;
  const responseData = await $.ajax({
    method: 'GET',
    dataType: 'json',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
  });
  return responseData;
}

onmount('#orgchart-container-v2', async function () {
  const $self = $(this);
  const $loader = $self.find('#buk-loader-orgchart');
  $loader.removeClass('d-none');
  await loadOrgChart();
  const data = await getOrgChartData($self.data('url'));
  $loader.addClass('d-none');
  $self.orgchart({
    'data': data,
    'pan': true,
    'zoom': true,
    'direction': 't2b',
    'zoomoutLimit': 0.25,
    'nodeTemplate': climateOrgChartTemplate,
  });
});
