/** @file
 * Este componente toma todos los campos dentro de un formulario y los asigna como
 * parámetros al href del link con la propiedad data-assign-form-params
 * Para usar el componente se debe colocar la propiedad en cuestión al link deseado, ejemplo:
 * data: { assign_form_params: true }
 * Tener en cuenta que el link debe estar colocado dentro del formulario
 */
import onmount from 'onmount';

onmount('a[data-assign-form-params]', function () {
  $(this).on('click', () => {
    const form = $(this).closest('form');
    const values = form.serializeArray();

    var newUrl = new URL($(this).attr('href'));

    values.forEach(function (elm) {
      newUrl.searchParams.set(elm['name'], elm['value']);
    });

    $(this).attr('href', newUrl);
  });
});
