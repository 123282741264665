/**
 * @file
 * Permite seleccionar o desmarcar un checkbox automáticamente basado en el valor de un campo de input.
 *
 * - El comportamiento está basado en el atributo `data-check-id` en los campos de input.
 *   El valor de este atributo debe contener el ID del checkbox asociado.
 *
 * - Si el valor del input cambia (evento `input`), se verifica si es mayor que 0:
 * - Si el valor es mayor que 0, el checkbox correspondiente será marcado automáticamente.
 * - Si el valor es 0 o se borra, el checkbox se desmarcará automáticamente.
 *
 * Uso:
 * 1. Agrega el atributo `data-check-id` a cualquier campo de input. Este atributo debe contener
 *    el ID de un checkbox que deseas asociar con dicho campo.
 * 2. El checkbox se seleccionará o desmarcará en tiempo real según el valor del input.
 *
 * Ejemplo de HTML:
 * <input type="number" data-check-id="checkboxA" />
 * <input type="checkbox" id="checkboxA" />
 *
 */

import onmount from 'onmount';

onmount('input[data-check-id]', function () {
  const amountField = this;
  const checkBoxId = amountField.getAttribute('data-check-id');
  const checkBox = document.getElementById(checkBoxId);

  if (checkBox) {
    amountField.addEventListener('input', function (event) {
      checkBox.checked = event.target.value && event.target.value > 0;
    });
  }
});
