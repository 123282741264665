/** @file TODO: documentar */
import onmount from 'onmount';

onmount('#job_form, #job_form_peru', function () {
  if($('#job_country').val() === 'colombia') { return; }
  const supervisorText = 'Supervisor *';
  const contraparteText = 'Contraparte';
  const replaceText = (element, textToReplace, replacementText) => {
    element
      .contents()
      .filter(function () {return this.nodeType === 3;})
      .each(function () {
        this.textContent = this.textContent.replace(textToReplace, replacementText);
      });
  };

  const $datepicker = $('#job_start_date');
  const jobEndDate = $datepicker.data('jobEndDate');
  let oldSelectValue = $('#job_tipo_contrato').val();
  const clearDatepicker = async () => {
    await import('../../../../../../../../app/assets/javascripts/vendor/datepicker');
    $datepicker.val('').datepicker('update');
  };

  $('#job_form').on('change', '#job_termino_contrato, #job_termino_contrato_2', function () {
    showAlertTerminoContrato(this.dataset.variableAbierta,
      [getTerminoContrato('job_termino_contrato'), getTerminoContrato('job_termino_contrato_2')]);
  });

  function showAlertTerminoContrato(variableAbiertaArray, fechaSelector) {
    if (fechaSelector.some(r => variableAbiertaArray.indexOf(r) > 0) &&
      $('#job_tipo_contrato').val() === 'Contrata') {
      $('#alert-va-termino-contrato').show();
    }
    else $('#alert-va-termino-contrato').hide();
  }

  function getTerminoContrato(id) {
    return $(`#${id}`).val().slice(3);
  }

  function startDateLimitTipoContrato() {
    const newStartingDate = new Date(jobEndDate);
    if ($('#job_tipo_contrato').val() === 'A honorarios') {
      $('.select2-selection__clear').show();
      $('#supervisor label span').show();
      replaceText($('#supervisor label'), supervisorText, contraparteText);
      if (jobEndDate !== undefined) {
        newStartingDate.setDate(newStartingDate.getDate() + 1);
        $datepicker.datepicker('setStartDate', newStartingDate);
      }
    }
    else {
      $('.select2-selection__clear').hide();
      $('#supervisor label span').hide();
      replaceText($('#supervisor label'), contraparteText, supervisorText);
      if (jobEndDate !== undefined) {
        const selectedDate = $datepicker.datepicker('getDate');
        newStartingDate.setDate(1);
        newStartingDate.setMonth(newStartingDate.getMonth() + 1);
        if ((oldSelectValue === 'A honorarios') && selectedDate < newStartingDate) {
          clearDatepicker();
        }
        $datepicker.datepicker('setStartDate', newStartingDate);
      }
    }
  }

  $('#job_form_peru').on('change', '#job_tipo_contrato', function () {
    const selectedStartDate = $datepicker.datepicker('getDate');
    const nextMonthFromJobEndDate = new Date(jobEndDate);

    if (jobEndDate !== undefined) {
      nextMonthFromJobEndDate.setDate(1);
      nextMonthFromJobEndDate.setMonth(nextMonthFromJobEndDate.getMonth() + 1);

      if (selectedStartDate < nextMonthFromJobEndDate) {
        clearDatepicker();
      }
    }

    startDateLimitTipoContrato();
    return;
  });

  onmount('#job_tipo_contrato', function () {
    if ($('#job_tipo_contrato').val() === 'A honorarios') {
      $('.select2-selection__clear').show();
      $('#supervisor label span').show();
      replaceText($('#supervisor label'), supervisorText, contraparteText);
    }
    else{
      $('.select2-selection__clear').hide();
      $('#supervisor label span').hide();
      replaceText($('#supervisor label'), contraparteText, supervisorText);
    }

    if($('#job_tipo_contrato').val() === 'Plazo fijo' || $('#job_tipo_contrato').val() === 'Contrata' ||
      $('#job_tipo_contrato').val() === 'Renovación Automática' ||
      $('#job_tipo_contrato').val() === 'Contrato Determinado o Sujeto a Modalidad' ||
      ($('#job_tipo_contrato').val() === 'Reemplazo' &&
      ($('#job_sistema_remuneracion').val() === 'estatuto_atencion_primaria' ||
       $('#job_sistema_remuneracion').val() === 'estatuto_asistentes_educacion'))) {
      $('#job_termino_contrato,#job_termino_contrato_2').parent().show();
      $('#job_termino_contrato,#job_termino_contrato_2').prop('disabled', false);
    }
    else {
      $('#job_termino_contrato,#job_termino_contrato_2').parent().hide();
      $('#job_termino_contrato,#job_termino_contrato_2').prop('disabled', true);
    }

    if($('#job_tipo_contrato').val() === 'A honorarios' &&
        ($('#job_sistema_remuneracion').val() === 'estatuto_docente' ||
         $('#job_sistema_remuneracion').val() === 'codigo_del_trabajo' ||
         $('#job_sistema_remuneracion').val() === 'estatuto_asistentes_educacion')) {
      $('#job_termino_contrato,#job_termino_contrato_2').parent().show();
      $('#job_termino_contrato,#job_termino_contrato_2').prop('disabled', false);
    }
    else if($('#job_tipo_contrato').val() === 'Reemplazo' &&
      ($('#job_sistema_remuneracion').val() === 'estatuto_docente' ||
       $('#job_sistema_remuneracion').val() === 'estatuto_atencion_primaria' ||
       $('#job_sistema_remuneracion').val() === 'estatuto_asistentes_educacion')) {
      $('#job_termino_contrato,#job_termino_contrato_2').parent().show();
      $('#job_termino_contrato,#job_termino_contrato_2').prop('disabled', false);
    }

    if($('#job_tipo_contrato').val() === 'Aprendiz con predom. en CFP-Prácticas Pre Profesionales' ||
        $('#job_tipo_contrato').val() === 'Prácticas Profesionales' ||
        $('#job_tipo_contrato').val() === 'Aprendiz con predom. en la Empresa' ||
        $('#job_tipo_contrato').val() === 'Capacitación Laboral Juvenil' ||
        $('#job_tipo_contrato').val() === 'Pasantía en la Empresa' ||
        $('#job_tipo_contrato').val() === 'Pasantía de Docentes y Catedráticos' ||
        $('#job_tipo_contrato').val() === 'Actualización para la Reinserción Laboral' ||
        $('#job_tipo_contrato').val() === 'Secigrista') {
      $('#seguro_fola_peru_id,#plan_fola_peru_id').parent().show();
      $('#seguro_fola_peru_id,#plan_fola_peru_id').prop('disabled', false);
    }
    else {
      $('#seguro_fola_peru_id,#plan_fola_peru_id').parent().hide();
      $('#seguro_fola_peru_id,#plan_fola_peru_id').prop('disabled', true);
    }
  });

  $('#job_form').on('change', '#job_tipo_contrato', function () {
    $('#job_obra').parent().hide();
    $('#job_servicio').parent().hide();
    $('#job_intermitente').parent().hide();
    $('#job_temporada').parent().hide();
    $('#job_personal_extranjero').parent().hide();
    $('#job_exportacion_productos_no_tradicional').parent().hide();
    $('#job_termino_contrato,#job_termino_contrato_2').parent().hide();
    $('#job_termino_contrato,#job_termino_contrato_2').prop('disabled', true);
    $('#seguro_fola_peru_id,#plan_fola_peru_id').parent().hide();
    $('#seguro_fola_peru_id,#plan_fola_peru_id').prop('disabled', true);

    if($('#job_tipo_contrato').val() === 'Plazo fijo' || $('#job_tipo_contrato').val() === 'Contrata' ||
      $('#job_tipo_contrato').val() === 'Renovación Automática' ||
      $('#job_tipo_contrato').val() === 'Contrato Determinado o Sujeto a Modalidad') {
      $('#job_termino_contrato,#job_termino_contrato_2').parent().show();
      $('#job_termino_contrato,#job_termino_contrato_2').prop('disabled', false);
    }
    else if($('#job_tipo_contrato').val() === 'Obra determinada' || $('#job_tipo_contrato').val() === 'Obra') {
      $('#job_obra').parent().show();
      $('#job_servicio').parent().show();
      $('#job_intermitente').parent().show();
      $('#job_temporada').parent().show();
      $('#job_personal_extranjero').parent().show();
      $('#job_exportacion_productos_no_tradicional').parent().show();
    }
    else if($('#job_tipo_contrato').val() === 'Aprendiz con predom. en CFP-Prácticas Pre Profesionales' ||
              $('#job_tipo_contrato').val() === 'Prácticas Profesionales' ||
              $('#job_tipo_contrato').val() === 'Aprendiz con Predom. en la Empresa' ||
              $('#job_tipo_contrato').val() === 'Capacitación Laboral Juvenil' ||
              $('#job_tipo_contrato').val() === 'Pasantía en la Empresa' ||
              $('#job_tipo_contrato').val() === 'Pasantía de Docentes y Catedráticos' ||
              $('#job_tipo_contrato').val() === 'Actualización para la Reinserción Laboral' ||
              $('#job_tipo_contrato').val() === 'Secigrista') {
      $('#seguro_fola_peru_id,#plan_fola_peru_id').parent().show();
      $('#seguro_fola_peru_id,#plan_fola_peru_id').prop('disabled', false);
    }

    if($('#job_tipo_contrato').val() === 'A honorarios' &&
        ($('#job_sistema_remuneracion').val() === 'estatuto_docente' ||
         $('#job_sistema_remuneracion').val() === 'codigo_del_trabajo' ||
         $('#job_sistema_remuneracion').val() === 'estatuto_asistentes_educacion')) {
      $('#job_termino_contrato,#job_termino_contrato_2').parent().show();
      $('#job_termino_contrato,#job_termino_contrato_2').prop('disabled', false);
      $('#job_periodicidad').prop('disabled', true);
    }
    else if($('#job_tipo_contrato').val() === 'Reemplazo' &&
      ($('#job_sistema_remuneracion').val() === 'estatuto_docente' ||
      $('#job_sistema_remuneracion').val() === 'estatuto_atencion_primaria' ||
      $('#job_sistema_remuneracion').val() === 'estatuto_asistentes_educacion')) {
      $('#job_termino_contrato,#job_termino_contrato_2').parent().show();
      $('#job_termino_contrato,#job_termino_contrato_2').prop('disabled', false);
    }

    const sistemaRemuneracion = $('#job_sistema_remuneracion').val();
    const tipoContrato = $('#job_tipo_contrato').val();

    const trabajoFormLabels = ['label[for="termino-contrato"]', 'label[for="job_grado_sector_publico_chile"]',
      'label[for="job_estamento_sector_publico_chile"]', 'label[for="job_termino_contrato"]'];
    const trabajoFormIds = ['#termino-contrato', '#job_grado_sector_publico_chile',
      '#job_estamento_sector_publico_chile', '#job_termino_contrato'];

    function updateTrabajoForm(required) {
      trabajoFormIds.forEach((id) => {
        $(id).prop('required', required);
      });
      trabajoFormLabels.forEach((label) => {
        $(label).text($(label).text().replace(' *', '') + (required ? ' *' : ''));
      });
    }

    function updateRequiredField(forAttribute, shouldAddAsterisk) {
      const label = $(`label[for="${forAttribute}"]`);
      label.text(label.text().replace(' *', '') + (shouldAddAsterisk ? ' *' : ''));
      $(`#${forAttribute}`).prop('required', shouldAddAsterisk);
    }

    if (['estatuto_administrativo', 'estatuto_municipal', 'estatuto_atencion_primaria', 'estatuto_asistentes_educacion']
      .includes(sistemaRemuneracion)) {
      if (tipoContrato === 'A honorarios') {
        updateTrabajoForm(false);
      }
      else if ((sistemaRemuneracion === 'estatuto_atencion_primaria'
        || sistemaRemuneracion === 'estatuto_asistentes_educacion')
        && tipoContrato === 'Reemplazo') {
        updateTrabajoForm(false);
        updateRequiredField('job_grado_sector_publico_chile', true);
        updateRequiredField('job_estamento_sector_publico_chile', true);
      }
      else if (['Contrata', 'Plazo fijo', 'Planta', 'Reemplazo', 'Indefinido'].includes(tipoContrato)) {
        if ($('label[for="termino-contrato"]').text().slice(-1) !== '*') {
          updateTrabajoForm(true);
        }
      }
    }
    else if (['codigo_del_trabajo'].includes(sistemaRemuneracion)) {
      if (tipoContrato === 'A honorarios') {
        updateTrabajoForm(false);
      }
      else if (['Plazo fijo', 'Renovación Automática'].includes(tipoContrato)) {
        if ($('label[for="job_termino_contrato"]').text().slice(-1) !== '*') {
          updateTrabajoForm(true);
        }
      }
    }
    else if (['estatuto_docente'].includes(sistemaRemuneracion)) {
      if (tipoContrato === 'A honorarios' || tipoContrato === 'Reemplazo') {
        updateTrabajoForm(false);
      }
      else if (['Contrata'].includes(tipoContrato)) {
        if ($('label[for="job_termino_contrato"]').text().slice(-1) !== '*') {
          updateTrabajoForm(true);
        }
      }
    }

    if (oldSelectValue === '' && jobEndDate !== undefined) {
      clearDatepicker();
    }

    startDateLimitTipoContrato();
    oldSelectValue = $('#job_tipo_contrato').val();
  });
});

// Para renovar contratos peruanos, se debe especificar el termino del mismo
// excepto para los contratos tipo indeterminado, donde se esconde el input de fecha de término
// y el contrato parcial, donde es opcional ingresar la fecha de termino.
onmount('#renovar_contrato_form_peru', () => {
  $(document).on('change', '#renovar_contrato_tipo_contrato', () => {
    var divTerminoContrato = document.getElementById('termino_contrato_peru');
    if($('#renovar_contrato_tipo_contrato').val() === 'Tiempo Parcial') {
      $('#termino_contrato_peru').find(':input').attr('required', false);
      divTerminoContrato.style.display = '';
    }
    else if ($('#renovar_contrato_tipo_contrato').val() === 'Contrato a Plazo Indeterminado') {
      $('#termino_contrato_peru').find(':input').attr('required', false);
      divTerminoContrato.style.display = 'none';
    }
    else {
      $('#termino_contrato_peru').find(':input').attr('required', true);
      divTerminoContrato.style.display = '';
    }
  });
});
