/** @file TODO: documentar */

import onmount from 'onmount';
import { success } from '../lib/buk/alert';

onmount('.data_import_employee', function () {
  $(this).on('click', function () {
    const table = document.getElementById('data-table'),
      rows = Array.from(table.rows),
      cells = rows.map(row => Array.from(row.cells).map(cell => cell.textContent.slice(1, -1).split(': ')));
    cells.shift();
    const tableString = JSON.stringify(cells.flat());
    const tableJSON = JSON.parse(tableString);
    const jsonData = {};
    const removeAccents = str =>
      str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    for (const [key, value] of tableJSON) {
      jsonData[removeAccents(key)] = value;
    }
    $('#person_first_name').val(jsonData['nombre']);
    $('#person_last_name').val(jsonData['apellido paterno']);
    $('#person_segundo_apellido').val(jsonData['apellido materno']);
    $('#person_rut').val(jsonData['dni']);
    const date = new Date(jsonData['fecha de nacimiento'].replace(' ', '-'));
    const realDate = new Date(date.toISOString().slice(0, -1));
    $('#person_date_of_birth').datepicker().datepicker('setDate', realDate);
    $('#person_estado_civil').val(jsonData['estado civil']);
    $('#person_direccion').val(jsonData['direccion']);
    //Trigger especial para género femenino
    const gender = jsonData['genero'].charAt(0);
    if (gender === 'F') {
      const doc = document.getElementsByClassName(
        'form-group boolean optional person_madre_con_responsabilidad_familiar_peru col-md-3'
      )[0];
      if (!(doc === undefined)) {
        doc.style.display = 'block';
      }
    }
    $('#person_sexo').val(gender);
    success('Importado con éxito', 'Estado');
  });
});
