/** @file Comportamientos del index de nomina electrónica **/

import onmount from 'onmount';

onmount('#datatable-nomina-electronica-eventos-employee', function () {
  const exportButton = document.getElementById('export_history_events');
  const monthSelector = document.getElementById('nomina-electronica-month-selector');
  const value = monthSelector.options[monthSelector.selectedIndex].value;
  exportButton.href = exportButton.href.replace(/mes=[0-9]+/, `mes=${value}`);
});
