/** @file
 * Maneja los eventos del modulo Onboarding
 */
import onmount, { $ } from 'onmount';
import { loadCKeditor, CKeditorVersion } from '@assets/javascripts/components/ckeditor/load';

onmount('#onboarding_task_template_form', async function () {
  if (CKeditorVersion() === '4') {
    await loadCKeditor();
    CKEDITOR.instances.task_template_description.on('change', function () {
      $('#task_template_description').val(CKEDITOR.instances.task_template_description.getData());
    });
  }
});

onmount('#onboarding_task_form', async function () {
  if (CKeditorVersion() === '4') {
    await loadCKeditor();
    CKEDITOR.instances.task_description.on('change', function () {
      $('#task_description').val(CKEDITOR.instances.task_description.getData());
    });
    /**
     * Selecciona como destino nueva ventana, al crear enlace con CKEDITOR
     */
    CKEDITOR.on('dialogDefinition', function (ev) {
      try {
        var dialogName = ev.data.name;
        var dialogDefinition = ev.data.definition;

        if(dialogName === 'link') {
          var informationTab = dialogDefinition.getContents('target');
          var targetField = informationTab.get('linkTargetType');
          targetField['default'] = '_blank';
        }
      }
      catch(exception) {
        alert('Error' + ev.message);
      }
    });
  }
});

onmount('#edit_status_multiple_tasks', async function () {
  const statusCode = { pending: 0, finished: 1 };
  const $form = $(this);
  $form.find('.dropdown-menu > a').on('click', function () {
    const status = $(this).data('status');
    if (!['pending', 'finished'].includes(status)) {
      return;
    }
    const action = $form.attr('action').split('?')[0];
    $form.attr('action', action + `?status=${statusCode[status]}`);
  });
});

/*
 * Comportamiento para mostrar y esconder checkbox de visibilidad para
 * Nuevo Empleado cuando este es o no seleccionado como responsable de tarea
 * en un proceso o plantilla de proceso de onboarding.
*/

function showVisibleToNewEmployeeCheckbox(assigneesSelect, visibleToNewEmployeeCheckbox, targetSelectedValue) {
  if (assigneesSelect.val().includes(targetSelectedValue)) {
    visibleToNewEmployeeCheckbox.addClass('d-none');
  }
}

function hideVisibleToNewEmployeeCheckbox(assigneesSelect, visibleToNewEmployeeCheckbox, targetSelectedValue) {
  if (!assigneesSelect.val().includes(targetSelectedValue)) {
    visibleToNewEmployeeCheckbox.removeClass('d-none');
  }
}

onmount('#task_template_assignee_ids', function () {
  const checkbox = $('#task_template_visible_to_new_employee_input');
  $(this).on('select2:select', () => {
    showVisibleToNewEmployeeCheckbox($(this), checkbox, 'onboarded');
  });

  $(this).on('select2:unselect', () => {
    hideVisibleToNewEmployeeCheckbox($(this), checkbox, 'onboarded');
  });
});

onmount('#task_assignee_ids', function () {
  const checkbox = $('#task_visible_to_new_employee_input');
  const optionsList = Array.from($(this).get(0).options);
  let targetValue;
  optionsList.forEach(opt => {
    if (opt.textContent.includes('[Nuevo')) {
      targetValue = opt.value;
    }
  });

  $(this).on('select2:select', () => {
    showVisibleToNewEmployeeCheckbox($(this), checkbox, targetValue);
  });

  $(this).on('select2:unselect', () => {
    hideVisibleToNewEmployeeCheckbox($(this), checkbox, targetValue);
  });
});
