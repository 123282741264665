/** @file TODO: documentar */
import { error } from '../../lib/buk/alert.js';
import onmount from 'onmount';

onmount('.ckeditor_textinput', async function (obj) {
  const { loadCKeditor, CKeditorVersion, loadCKeditor5 } = await import('./load.js');
  const { RICH_EDITOR_OPTIONS } = await import('@assets/javascripts/components/ckeditor/custom_configs');

  if (CKeditorVersion() === '4') {
    await loadCKeditor();
    var editor = null;
    const self = this;
    const form = $(this).closest('form');
    const submit = form.find(':submit');

    if ($(this).val()) {
      crearEditor();
    }
    $(this).one('click', crearEditor);

    function crearEditor() { // eslint-disable-line no-inner-declarations
      if (editor) {
        return;
      }
      editor = CKEDITOR.replace(self, {
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'StrikeThrough', '-', 'Undo',
            'Redo', '-', 'Cut', 'Copy', 'Paste', 'Find', 'Replace', '-', '-'],
          ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
          '/',
          ['base64image', 'Table', '-', 'Link', 'TextColor', 'BGColor'],
          ['Styles', 'Format', 'FontSize', 'Source', 'Maximize'],
        ],
        extraPlugins: 'base64image',
        startupFocus: true,
        versionCheck: false,
        language: 'es',
      });

      editor.on('change', syncEditor);
      editor.on('blur', syncEditor);
    }

    function syncEditor(ev) { // eslint-disable-line no-inner-declarations
      editor.updateElement();
      submit.prop('disabled', editor.getData().trim().length === 0);
      $(self).trigger(ev.name);
    }

    $(this).on('change', function () {
      var size = this.value.size / 1024 / 1024;
      if (size > 0.3) {
        error('El archivo debe pesar menos de 300kB. Por favor elegir otra imagen.');
      }
    });
  }
  else {
    await loadCKeditor5(this, obj, RICH_EDITOR_OPTIONS());
  }
});
