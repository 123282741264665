/** @file Archivo utilizado para cambiar el contenido del modal de confirmación */
import onmount from 'onmount';

onmount('#adelantos_confirm_request', function () {
  $('#boton-confirmar-adelantos').on('click', function () {
    enableStep(2);
    generateCode();
  });
});

onmount('#adelantos_confirm_code', function () {
  $('#boton-cancelar-adelantos').on('click', function () {
    enableStep(1);
  });
});

function enableStep(stepNumber) {
  if (stepNumber === 1) {
    displayModal('adelantos_confirm_request', true);
    displayModal('adelantos_confirm_code', false);
  }
  if (stepNumber === 2) {
    displayModal('adelantos_confirm_request', false);
    displayModal('adelantos_confirm_code', true);
  }
}

function displayModal(modalName, show) {
  const element = document.getElementById(modalName);

  if (show === true) {
    element.classList.remove('d-none');
    if (modalName === 'adelantos_confirm_code') element.classList.add('d-flex');
  }
  if (show === false) {
    element.classList.add('d-none');
    if (modalName === 'adelantos_confirm_code') element.classList.remove('d-flex');
  }
}

function generateCode() {
  return $.ajax({
    method: 'POST',
    dataType: 'json',
    url: '/authorization_code',
    data: { title: 'solicitud de adelantos' },
  });
}
