/** @file TODO: documentar */
import onmount from 'onmount';
import { debounce } from 'lodash-es';
import { countCheckbox } from '../../../../../../../app/assets/javascripts/components/multi-select-common.js';

onmount('[data-submit-on-change="true"]', function () {
  $(this).on('change', function () {
    $(this).parents("form").submit();
  });
});

onmount('[data-submit-on-click]', function () {
  $(this).on('click', function () {
    const target = $(this).data("submit-on-click");
    $(target).submit();
  });
});

onmount('[data-value-change-input]', function () {
  $(this).on('keyup', function () {
    const target = $(this).data("value-change-input");
    $(target).val($(this).val());
  });
});

/* Lanza evento de conteo de checkboxes para el contenedor con atributo 'data-count-checkboxes' */
onmount('[data-count-checkboxes]', function () {
  const boxesContainer = $(this);
  boxesContainer.on('change', 'input[type=checkbox]', debounce(function () {
    countCheckbox(boxesContainer);
  }, 5));
});
