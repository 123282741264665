/** @file TODO: documentar */
/**
 * Plugin para widget con workers que corren. Deja de correr si hay
 * 5 intentos seguidos de obtener workers sin ningun job.
 *
 * Gatillar evento worker-status-check en document para reiniciarlo.
 */
import { keys, union, has, keyBy, isPlainObject } from 'lodash-es';
import onmount from 'onmount';
import { loadVue } from '../../../../../../../app/assets/javascripts/vendor/vue-load';

// Numero de intentos a preguntar cuando hay 0 workers
const RETRY_ATTEMPTS = 5;

class NetError extends Error {
  constructor(url, status, jqXHR) {
    super(`WorkerStatus request to ${url} failed with status ${status}`);
    this.url = url;
    this.status = status;
    this.jqXHR = jqXHR;
  }
  get error500() {
    return this.status >= 500 && this.status < 600;
  }
  get unauthorized() {
    // para efectos de esto no nos importa si se deslogueo o no tiene permisos
    return this.status == 401 || this.status == 403;
  }
  get canceled() {
    return this.status === 0;
  }
}

class InvalidResponseError extends Error {
}

class WorkerNotification {
  static instance = null;
  constructor(url, jobs, Vue) {
    if (WorkerNotification.instance) {
      return WorkerNotification.instance;
    }
    this.url = url;
    this.jobs = jobs;
    this.running = false;
    this.timeout = null;
    this.retryAttempts = RETRY_ATTEMPTS;
    this.Vue = Vue;
    this.observers = [];
    WorkerNotification.instance = this;
  }
  /** @return {String[]} */
  get jobKeys() {
    return keys(this.jobs);
  }
  start() {
    if (this.running) {
      return;
    }
    if (localStorage.discardedJobs === undefined) { //TODO: descartar si el array es muy grande tambien
      localStorage.setItem("discardedJobs", JSON.stringify(new Array));
    }
    if (localStorage.runningJobs === undefined) {
      localStorage.setItem("runningJobs", JSON.stringify(new Array));
    }
    this.running = true;
    this.retryAttempts = RETRY_ATTEMPTS;
    this.__doRun();
  }
  stop() {
    this.running = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.__updateJobs({});
  }
  async __doRun() {
    this.timeout = null;
    let checkEmpty = true;
    try {
      await this.__getJobStatuses();
    }
    catch (e) {
      checkEmpty = false;
      this.retryAttempts -= 1;
      this.__notifyUpdateError(e);
    }

    if (checkEmpty) {
      this.__checkEmptyJobs();
    }
    this.__reschedule();
  }
  __notifyUpdateError(e) {
    const Sentry = window.Sentry;
    if (!Sentry) {
      // No podemos reportar
      console.error(e); /*eslint-disable-line no-console */
      return;
    }
    if (e instanceof NetError && (e.error500 || e.canceled || e.unauthorized)) {
      // Ignoramos el error: veremos el sentry por el otro lado
      // Esperemos se arregle pronto, excepto unauthorized que detenemos el polling
      if (e.unauthorized) {
        this.retryAttempts = 0;
        this.running = false;
      }
    }
    else if (e instanceof Error) {
      Sentry.captureException(e);
    }
    else {
      Sentry.withScope(scope => {
        scope.setExtra('error', e);
        Sentry.captureMessage('WorkerStatus unknown error');
      });
    }
  }
  __reschedule(timeout = 5000) {
    if (this.retryAttempts <= 0) {
      this.running = false;
    }
    if (this.running) {
      this.timeout = setTimeout(() => this.__doRun(), timeout);
    }
  }
  __checkEmptyJobs() {
    if (this.jobKeys.length === 0) {
      this.retryAttempts -= 1;
    }
    else {
      // Reseteamos el counter
      this.retryAttempts = RETRY_ATTEMPTS;
    }
  }
  async __getJSON() {
    let responseText;
    try {
      responseText = await $.get(this.url);
    }
    catch (e) {
      if (typeof (e.status) === 'number') {
        throw new NetError(this.url, e.status, e);
      }
      else {
        throw e;
      }
    }
    if (isPlainObject(responseText)) {
      // Jquery ya lo parseo por nosotros
      return responseText;
    }
    else if (typeof (responseText) === 'string') {
      try {
        return JSON.parse(responseText);
      }
      catch (e) {
        throw new InvalidResponseError(`Invalid json ${responseText}`);
      }
    }
    else {
      throw new InvalidResponseError(`Invalid response (${typeof (responseText)}): ${responseText}`);
    }
  }
  async __getJobStatuses() {
    if (!this.running) {
      return;
    }
    const data = await this.__getJSON();
    this.notify(data, '__getJSON');
    const filterData = data['worker_statuses'].filter((job) =>
      (job.private === true && job.mine === true || !job.private)
    );
    const newJobs = keyBy(filterData, 'job_id');
    this.__updateJobs(newJobs);
  }
  async __filterFinishedJobs(jobsKeys) {
    const prevRuningJobs = JSON.parse(localStorage.getItem("runningJobs"));
    localStorage.setItem("runningJobs", JSON.stringify(jobsKeys));
    if (!prevRuningJobs.length) {
      return [];
    }
    const finishJobsKeys = prevRuningJobs.filter(job => !jobsKeys.includes(job));
    // Ignoramos los que no podemos obtener
    const finishJobPromises = finishJobsKeys.map(jobId => $.get(`${this.url}/${jobId}`).catch(_ => null));
    const finishJobStatus = await Promise.all(finishJobPromises);
    return finishJobStatus.filter(Boolean); // dejamos fuera los que capturamos con catch
  }
  __updateFinishJobs(finishJobs) {
    finishJobs.forEach((job) => {
      job.finished = true;
      this.Vue.set(this.jobs, job.job_id, job);
    });
  }

  async __updateJobs(newJobs) {
    const jobs = this.jobs;
    const discardedJobs = keyBy(JSON.parse(localStorage.discardedJobs), "jobId");
    const filterNewJobs = keys(newJobs).filter(jobId => {
      if (jobId in discardedJobs) {
        if (newJobs[jobId].status === discardedJobs[jobId].status) {
          return false;
        }
      }
      return true;
    });
    const allKeys = union(this.jobKeys, filterNewJobs);
    allKeys.forEach(jobId => {
      if (has(jobs, jobId)) {
        if (has(newJobs, jobId)) {
          if (newJobs[jobId].status !== 'completed') {
            jobs[jobId] = newJobs[jobId];
          }
        }
        else {
          this.Vue.delete(jobs, jobId);
        }
      }
      else {
        this.Vue.set(jobs, jobId, newJobs[jobId]);
      }
    });
    const finishJobsData = await this.__filterFinishedJobs(keys(newJobs));
    if (finishJobsData.length) {
      this.notify(finishJobsData, 'finishJobStatus');
      this.__updateFinishJobs(finishJobsData);
    }
  }

  suscribe(observer, type) {
    this.observers.push([observer, type]);
  }

  unsuscribe(observer, type) {
    this.observers = this.observers.filter(obs => obs[0] !== observer && obs[1] !== type);
  }

  notify(data, type) {
    this.observers.filter(obs => obs[1] === type).forEach(observer => observer[0](data));
  }

  static getInstance(url, jobs, Vue) {
    if (!WorkerNotification.instance) {
      WorkerNotification.instance = new WorkerNotification(url, jobs, Vue);
    }
    return WorkerNotification.instance;
  }
}

window.workerNotificationPromise = new Promise((resolve, _reject) => {
  onmount('[data-worker-status-notifications]', async function (obj) {
    const Vue = await loadVue();
    const { default: WorkerStatus } = await import('./worker-status');
    const WorkerStatusClass = Vue.extend(WorkerStatus);
    const target = $('<span>').appendTo(this);
    const widget = new WorkerStatusClass({
      propsData: {
        jobs: {},
      },
    });
    const workerNotification = WorkerNotification.getInstance(
      $(this).data('workerStatusNotifications'),
      widget.jobs,
      Vue);
    widget.$mount(target.get(0));

    obj.widget = widget;
    obj.workerNotification = workerNotification;
    resolve(workerNotification);
    workerNotification.start();

    $(document).on(`worker-status-check.${obj.id}`, () => workerNotification.start());
  }, function (obj) {
    $(document).off(`.${obj.id}`);
    if (obj.workerNotification) {
      obj.workerNotification.stop();
    }
    if (obj.widget) {
      obj.widget.$destroy();
    }
  });
});
