/** @file Permite mostrar un preview de un documento PDF */
import onmount from 'onmount';

onmount('#external-pdf-viewer', async function () {
  const pdfViewer = await import('pdfjs-dist/legacy/build/pdf');
  await import('pdfjs-dist/legacy/build/pdf.worker.entry');

  const selector = $(this);
  const pdfData = selector.data('pdf');
  const loadingTask = pdfViewer.getDocument(pdfData);
  loadingTask.promise.then(function (pdf) {
    // Fetch the first page
    const pageNumber = 1;
    pdf.getPage(pageNumber).then(async function (page) {
      const viewport = page.getViewport({ scale: 1.5 });
      // Prepare canvas using PDF page dimensions
      const canvas = selector.find('canvas')[0];
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
      selector.find('.buk-loader').hide();
      selector.find('.canvas-content').show();
    });
  }, function (reason) {
    // PDF loading error
    throw new Error(reason);
  });
});
