/** @file Cambiamos el label para #item_transfer_form_bono_id dependiendo del valor en el select de #item_transfer_form_transfer_type */
import onmount from 'onmount';

onmount('#item_transfer_form_transfer_type[data-user-country="peru"]', function () {
  $(this).on('change', function () {
    const $element = $('label[for="item_transfer_form_bono_id"]');
    let labelText = $(this).val() === 'pago_cts_semestral' ? 'Item intangible' : 'Item';
    if ($element.hasClass('required')) {
      labelText += ' *';
    }
    $element.text(labelText);
  });
});

onmount('#item_transfer_form_empresa_id', function () {
  const btn = $('.btn-disabled');
  const element = $('#advance_payment_dates-container');
  $(this).on('change', function () {
    element.empty();
    var newOptions = $('#' + $(this).val() + '.advance_payment_dates_option option');
    $.each(newOptions, function () {
      element.append($('<option></option>').attr('value', $(this).val()).text($(this).text()));
    });
    const disableOption =  element.val() === '-1';
    btn.prop('disabled', disableOption);
  });
});

onmount('#item_transfer_form_transfer_type[data-user-country="chile"]', function () {
  const inputPaymentDates = $('#advance_payment_dates-container');
  const btn = $('.btn-disabled');
  $(this).on('change', function () {
    if ($(this).val() === 'advance_payment_report' && inputPaymentDates.val() === '-1') {
      btn.prop('disabled', true);
    }
    else {
      btn.prop('disabled', false);
    }
  });
});
