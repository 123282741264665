/** @file TODO: documentar */
import onmount from 'onmount';
import { info } from '../../../../../../../../app/assets/javascripts/lib/buk/alert';

let notified = false;
onmount('[data-alert-multiple-variables]', function () {
  if (notified) {
    return;
  }
  notified = true;
  const content = $(this).data('alertMultipleVariables');
  if (!content) {
    return;
  }
  info(content, 'Múltiples periodos abiertos');
});
