/** @file TODO: documentar */
import onmount from 'onmount';

function resetProgressbar() {
  $('#progress .progress-bar').css('width', '0%');
  $('#progress .progress-bar').addClass('active');
  $('#progress .progress-bar').text('');
}

function getRut(file) {
  var rut;
  if(file.name.includes(".jpg")) {
    rut = file.name.replace('.jpg', '');
  }
  else if(file.name.includes(".jpeg")) {
    rut = file.name.replace('.jpeg', '');
  }
  else{
    rut = file.name.replace('.png', '');
  }
  return rut;
}

function notFound(data) {
  $.each(data.files, function (_index, file) {
    var div = $('.uploaded-files');
    $(`<div class='col-md-3'><a href="#" class="thumbnail"><img src= "/images/photo_error.jpg" alt="add_photo_name"></a>
    <div class="caption"><h4 class='text-danger'>Error persona con rut: ${ getRut(file) } no existe</h4></div></div>`).appendTo(div);
  });
}

function unsupportedMediaType(data) {
  $.each(data.files, function (_index, file) {
    var div = $('.uploaded-files');
    $(`<div class='col-md-3'><a href="#" class="thumbnail"><img src= "/images/photo_error.jpg" alt="add_photo_name"></a>
    <div class="caption"><h4 class='text-danger'>Error persona rut: ${ getRut(file) } con tipo de archivo incorrecto</h4></div></div>`).appendTo(div);
  });
}

onmount('#fileupload', function () {
  //initializer
  $('#fileupload').fileupload({
    dataType: 'json',
    url: 'people/upload_picture',
    type: 'POST',
    sequentialUploads: true,
    autoUpload: true,
    acceptFileTypes: /(\.|\/)(jpe?g|png)$/i
  });

  $('#fileupload').fileupload({
    dataType: 'json',
    drop: function () {
      resetProgressbar();
    },
    change: function () {
      resetProgressbar();
    },
    done: function (_e, data) {
      // mostramos la foto subida
      $.each(data.result.files, function (_index, file) {
        var div = $('.uploaded-files');
        $(`<div class='col-md-3'><a href="#" class="thumbnail"><img src= ${ file.url } alt="add_photo_name"></a>
        <div class="caption"><h4>${ file.full_name }</h4></div></div>`).appendTo(div);
      });
    },
    fail: function (_e, data) {
      if(data.errorThrown == "Not Found") {
        notFound(data);
      }
      else if(data.errorThrown == "Unsupported Media Type") {
        unsupportedMediaType(data);
      }
    },
    progressall: function  (_e, data) {
      // e es el evento progress
      if (data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        $('#progress .progress-bar').css('width', progress + '%');
        $('#progress .progress-bar').removeClass('active');
        $('#progress .progress-bar').text(progress + '%');
      }
    }
  });
});
