/** @file TODO: documentar */
import { debounce } from 'lodash-es';

import onmount from 'onmount';

$.onmount = onmount;

// hacemos un debounce de 0 segundos porque intercooler podria llamar multiples veces en un loop
// asi que lo hacemos que se ejecute una sola vez en el siguiente event loop
const f = () => onmount();
const debounced = debounce(f, 0);

const events = [
  'ready',
  'shown.bs.modal',
  'closed.bs',
  'load',
  'page:change',
  'turbolinks:load',
  'nodesProcessed.ic',
  'buk:load-content',
  'cocoon:after-insert',
  'cocoon:after-remove',
].join(' ');

$(document).on(events, debounced);

$(document).on('turbolinks:before-cache', function () {
  onmount.teardown();
});

$(document).on('draw.dt', debounced);

/** Obtiene una descripción en texto del elemento
 * @param {Element} el
 */
function getElementDescription(el) {
  if (el.id) {
    return `${el.nodeName}#${el.id}`;
  }
  else if (el.name) {
    return `${el.nodeName}[name="${el.name}"]`;
  }
  else {
    // El nodo completo
    let html = el.outerHTML;
    if (el.innerHTML) {
      // Pero sin el contenido
      html = html.replace(el.innerHTML, '');
    }
    return html;
  }
}

/** Obtiene el prototipo de la clase interna Behavior de onmount
 *
 * Como la clase no es pública hay que hacer una maroma para poder hacerle
 * monkey patch
 *
 * @returns {Object}
 */
function getBehaviorsPrototype() {
  // Puede que a esta altura no tengamos ningun behavior registrado
  // Si no hay, creamos uno dummy
  if (onmount.behaviors.length > 0) {
    return onmount.behaviors[0].constructor.prototype;
  }
  else {
    onmount('document', () => {});
    const proto = onmount.behaviors[0].constructor.prototype;
    onmount.reset();
    return proto;
  }
}

// HACK: patcheamos onmount para agregar contexto del elemento que gatilló el problema
const onmountBehaviorProto = getBehaviorsPrototype();

// Cuando cargamos el behavior, agregamos el sentry context al elemento,
// esto porque o si no al hacer exit, el elemento ya esta fuera del DOM
// y no podemos ir a buscar los padres
function setExtraSentryContext(element) {
  if (typeof element.__extraSentryContext !== 'undefined') {
    return;
  }
  const res = {};

  res['current'] = getElementDescription(element);
  const parent = $(element).parent();
  const parentWithId = parent.closest('[id]').get(0);
  if (parentWithId) {
    res['parentWithId'] = getElementDescription(parentWithId);
  }
  const parentWithName = parent.closest('[name]').get(0);
  if (parentWithName) {
    res['parentWithName'] = getElementDescription(parentWithName);
  }
  element.__extraSentryContext = res;
}

function notifyToSentry(error, element) {
  const Sentry = window.Sentry;
  if (!Sentry) {
    return;
  }
  Sentry.withScope(function (scope) {
    if (element.__extraSentryContext) {
      scope.setContext('element', element.__extraSentryContext);
    }
    Sentry.captureException(error);
  });
}

// Invoca la funcion pasada como argumento, wrapeandola con sentry (si ya existe)
function callWithSentry(oldFunc) {
  return async function (...args) {
    const el = this;
    setExtraSentryContext(el);
    try {
      let res = oldFunc.apply(el, args);
      if (typeof res === 'object' && typeof res.catch === 'function') {
        res = res.catch((err) => notifyToSentry(err, el));
      }
      return res;
    }
    catch (err) {
      notifyToSentry(err, el);
      return false;
    }
  };
}

const oldRegister = onmountBehaviorProto.register;
onmountBehaviorProto.register = function register() {
  const oldInit = this.init;
  this.init = callWithSentry(oldInit);
  if (this.exit) {
    this.exit = callWithSentry(this.exit);
  }
  oldRegister.call(this);
};
