/** @file Habilita/Deshabilita los inputs dentro de un elemento cuando se colapsa/descolapsa.
*
*  - Si algún input tiene la clase .skip-disable, no será deshabilitado.
*  - Si algún input es el hidden "_destroy" que se usa en Rails para marcar que el registro debe ser destruido,
*    se le asignará el valor 1 cuando se colapse y 0 cuando se descolapse.
*
*  !Este comportamiento es similar al de un `form-toggle` pero es enfocado en un link `<a>`.
*  !Si quieres manejar la habilitación/deshabilitación de inputs a partir de la elección de un radio button,
*  un checkbox o un select prefiere `form-toggle` o `form-switch`.
*
*  @example
*
*    <a href="#contenedor" data-toggle="collapse">Abrir/Cerrar</a>
*
*    <div id= "contenedor" class="collapse show" data-collapsing>
*
*      <input type="text" name="foo" />
*
*      <!-- Estos inputs no serán deshabilitados al colapsarse su padre-->
*      <input type="text" name="bar" class="skip-disable" />
*      <input type="hidden" value="false" class="skip-disable"  name="my_form[0][_destroy]">
*
*    </div>
*/
import onmount from 'onmount';

onmount('[data-collapsing]', function () {
  const inputForDestroy = $(this).find('input[type=hidden][name*="_destroy"]');
  const inputsForDisabling = $(this).find(':input:not(.skip-disable)');

  $(this).on('hidden.bs.collapse', function () {
    inputsForDisabling.prop?.('disabled', true);
    inputForDestroy?.val('1');
  });

  $(this).on('shown.bs.collapse', function () {
    inputsForDisabling.prop?.('disabled', false);
    inputForDestroy?.val('0');
  });
});
