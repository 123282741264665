/** @file Al ir desde una vista cualquiera a la vista del portal en mobile, se obtiene el body del portal
 * cacheado antes de mostrarlo en la pantalla, se cierra la pestaña que estaba abierta antes, y se
 * abre la pestaña que el usuario seleccionó ahora. De esta manera, la experiencia con el caché es
 * más fluida, y no se abre la pestaña incorrecta por medio segundo antes de cargar la correcta.
*/

import onmount from 'onmount';

onmount('.nav-link[role="tab"]', function () {
  $('.nav-link[role="tab"]').on('click', function () {
    $('.content-wrapper').scrollTop(0);

    var elementTabId = $(this).attr('id').slice(0, -4);

    if (elementTabId === 'feed') {
      window.setTimeout(() => {
        $(document).trigger('hideContentEvent');
      }, 200);
    }

    if (elementTabId === 'menu') {
      elementTabId = 'feed';
    }

    $(document).on('turbolinks:before-render', function (event) {
      var cachedPortal = $(event.originalEvent.data.newBody);

      // Esconder el tab del portal que estaba seleccionado antes, y abrir el tab que se acaba
      // de seleccionar en la navbar inferior.
      if (window.location.href.includes('static_pages/portal')) {
        var cachedTabsContent = cachedPortal.find('#pills-tabContent');
        cachedTabsContent.find('.active.show[role="tabpanel"]').removeClass('active show');
        cachedTabsContent.find(`#${elementTabId}`).addClass('active show');

        var cachedNavbar = cachedPortal.find('.menu_mobile');
        cachedNavbar.find('.nav-link.active').removeClass('active');
        cachedNavbar.find(`#${elementTabId}-tab`).addClass('active');
      }

      // Borrar cualquier modal que estaba abierto en la vista cacheada.
      const $modalPresent = document.querySelector('.modal.show');
      if ($modalPresent) {
        cachedPortal.find('.modal').modal('hide');
        cachedPortal.find('.modal-backdrop').remove();
      }
    });
  });
});
