/** @file TODO: documentar */
import onmount from 'onmount';

onmount('[data-remote-validation]', function () {
  const input = $(this);
  input.popover({trigger: 'manual', placement: 'auto'});

  input.focusout(function () {
    const validationUrl = this.getAttribute('data-remote-validation');
    const param = this.getAttribute('data-remote-param');

    $.get({
      url: validationUrl + '.json',
      data: {[param]: this.value},
      success: function (res) {
        input.attr('data-content', res.message);
        if (res.message.length == 0) {
          input.popover('hide');
        }
        else {
          input.popover('show');
        }
      }
    });
  });
});
