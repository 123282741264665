/** @file
* Archivo de marcas de amplitude para flujos de desempeño
*/
import onmount from 'onmount';

onmount('[id^=answer_performance_attempt]', function () {
  const attemptButton = $(this);
  const attemptData = {
    'evaluation_type': attemptButton.data('kind'),
    'action_source': attemptButton.data('source'),
  };
  attemptButton.on('click', function () {
    attemptButton.trigger('amplitudeTracking', { message: 'Open_Evaluation_Questionnaire', data: attemptData });
  });
});

onmount('#performance-attempt-form-paginator', function () {
  const attemptPaginator = $(this);
  const paginatorSelect = attemptPaginator.find('#attempt_id');
  paginatorSelect.on('change', function () {
    const selectedOption = paginatorSelect.find(':selected');
    const attemptData = {
      'evaluation_type': selectedOption.data('kind'),
      'action_source': selectedOption.data('source'),
    };
    paginatorSelect.trigger('amplitudeTracking', { message: 'Open_Evaluation_Questionnaire', data: attemptData });
  });
});

onmount('[id=massive_delete_participants]', function () {
  const deleteButton = $(this);
  deleteButton.on('data-multi-select-swal-confirmed', function () {
    const amountMatch = deleteButton.html().match(/\((\d+)\)/);
    if (!amountMatch) { return; }

    const deleteData = {
      'source_delete_evaluation_participant': deleteButton.data('source'),
      'deleted_participants': Number(amountMatch[1]),
    };
    deleteButton.trigger('amplitudeTracking', { message: 'Delete_Participant', data: deleteData });
  });
});
