/** @file TODO: documentar */
import { memoize } from 'lodash-es';

const getDateTimePicker = memoize(async function getDateTimePicker() {
  const { default: datetimepicker } = await import('eonasdan-bootstrap-datetimepicker');
  $.extend(datetimepicker.defaults, {
    // format: 'YYYY-MM-DD HH:mm', old
    format: 'DD-MM-YYYY HH:mm',
    locale: 'es',
    // para que funcionen los iconos de MD debe seguir la estructura 'material-icons <nombre>'
    icons: {
      time: 'material-icons time',
      date: 'material-icons date',
      up: 'material-icons keyboard_arrow_up',
      down: 'material-icons keyboard_arrow_down',
      previous: 'material-icons previous',
      next: 'material-icons next',
      today: 'material-icons today',
      clear: 'material-icons clear',
      close: 'material-icons close',
    },
  });
  return datetimepicker;
});

function insertIcons($this) {
  const timepickerClass = $this.parent().find('.timepicker');
  const spanTags = timepickerClass.find('span');
  spanTags.each(function () {
    const nameMatch = this.className.match(/material-icons\s(.*)/);
    const name  = nameMatch ? nameMatch[1] : null;
    if (name) {
      $(this).text(name);
    }
  });
}

$(document).on('click.datetimepicker', 'input[data-provide="datetimepicker"]', async function () {
  var $this = $(this);
  if ($this.data('DateTimePicker')) {
    insertIcons($this);
    return;
  }
  const datetimepicker = await getDateTimePicker();
  // component click requires us to explicitly show it
  datetimepicker.call($this);
  datetimepicker.call($this, 'show');
  insertIcons($this);
});
