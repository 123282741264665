/** @file popover-ninebox.js
 * En la versiones del ninebox.
 */

import onmount from 'onmount';
import { isUndefined } from 'lodash-es';
import Intercooler from '../../../../../../../app/assets/javascripts/lib/intercooler.js';

const BASE_TEMPLATE = `
<div>
  <div class='popover-bar-top'>
    <h3 class='popover-title'></h3>
    <div class='popover-navigator'></div>
  </div>
  <div class="popover-content"></div>
</div>
`;

const NAVIGATOR_TEMPLATE = `
<div>
  <h5 id='popover-counter'></h5>
  <div id='popover-navigator-buttons'></div>
</div>
`;

function buildTemplate(title, content, initialEmployee, employeeIds, urlParams) {
  const templ = $(BASE_TEMPLATE);
  templ.find('.popover-title').text(title);
  templ.find('.popover-content').html(content);

  const $popoverNavigator = templ.find('.popover-navigator');
  $popoverNavigator.html(buildNavigatorTemplate(initialEmployee, employeeIds, urlParams));
  $popoverNavigator.css('display', 'flex');
  return templ[0].outerHTML;
}

function buildNavigatorTemplate(currentEmployeeId, employeeIds, urlParams) {
  const templ = $(NAVIGATOR_TEMPLATE);
  const currentEmployeeIndex = employeeIds.indexOf(parseInt(currentEmployeeId));
  const previousEmployeeId = employeeIds[currentEmployeeIndex - 1];
  const nextEmployeeId = employeeIds[currentEmployeeIndex + 1];
  const canNavigateLeft = previousEmployeeId !== undefined;
  const canNavigateRight = nextEmployeeId !== undefined;
  templ.find('#popover-counter').text(`${currentEmployeeIndex + 1}/${employeeIds.length}`);
  templ.find('#popover-navigator-buttons').html(
    `
    <button class="btn btn-arrow" data-popover-arrow ${canNavigateLeft ? '' : 'disabled'}
            ic-get-from="/employees/${previousEmployeeId}/preview?${urlParams}"
            ic-target=".popover-content"
            ic-src="/employees/${previousEmployeeId}/preview?${urlParams}"
            ic-push-url="true"
            ic-push-params=
            ic-trigger-on="click"
            ic-deps="ignore">
      <i class="fas fa-arrow-left"></i>
    </button>
    <button class="btn btn-arrow" data-popover-arrow ${canNavigateRight ? '' : 'disabled'}
            ic-get-from="/employees/${nextEmployeeId}/preview?${urlParams}"
            ic-target=".popover-content"
            ic-src="/employees/${nextEmployeeId}/preview?${urlParams}"
            ic-trigger-on="click"
            ic-deps="ignore">
      <i class="fas fa-arrow-right"></i>
    </button>
    `
  );
  return templ[0].innerHTML;
}

function setIfExist(options, prop, value) {
  if (!isUndefined(value)) {
    options[prop] = value;
  }
}

function loadOptionsFromData(el) {
  const $self = $(el);
  const options = {};
  const title = $self.attr('title');
  const content = $self.data('content');
  const trigger = $self.data('trigger') || 'click';
  const initialEmployee = $self.data('initial-employee');
  const employeeIds = $self.data('employee-ids');
  const urlParams = $(content).data('render-async').split('?')[1];
  if (content) {
    options['content'] = buildTemplate(title, content, initialEmployee, employeeIds, urlParams);
  }
  // hover es el trigger por defecto en tippy y no funciona si se pone explicito.
  if (trigger !== 'hover') {
    setIfExist(options, 'trigger', $self.data('trigger'));
  }

  setIfExist(options, 'placement', $self.data('placement'));
  setIfExist(options, 'allowHTML', $self.data('html'));
  const appendTo = $($self.data('appendTo'));
  setIfExist(options, 'appendTo', appendTo.length ? appendTo[0] : undefined);
  return options;
}

onmount('[data-toggle="popover-ninebox"]', async function (obj) {
  const { default: tippy } = await import('tippy.js');
  const options = {
    arrow: true,
    interactive: true,
    theme: 'light-border popover',
    onMount(instance) {
      $(instance.popper).trigger('buk:load-content');
    },
  };
  obj.tippy = tippy(this, $.extend(options, loadOptionsFromData(this)));
});

onmount('button[data-popover-arrow]', function () {
  const $self = $(this);
  Intercooler.ready(function () {
    const $participantContainer = $self.parents('.extra-participants');
    const $navigatorContainer = $participantContainer.find('.popover-bar-top .popover-navigator');
    const $contentContainer = $participantContainer.find('.popover-content');
    const $profilePhotoContainer = $participantContainer.find('a');

    const currentEmployeeId = parseInt($contentContainer.find('input').prop('value'));
    const employeeIds = $participantContainer.find('a').data('employee-ids') || undefined;
    const urlParams = $($profilePhotoContainer.data('content')).data('render-async').split('?')[1] || undefined;

    if (employeeIds !== undefined && employeeIds.length > 1) {
      $navigatorContainer.html(buildNavigatorTemplate(currentEmployeeId, employeeIds, urlParams));
    }
  });
});
