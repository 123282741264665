/**
 * @file
 * Permite multiplicar el valor de N <input> y el valor resultante se setea en un <input> "resultado"
 * Los inputs multiplicadores deben tener un atributo "data-multiplication-multiplier"
 * Los inputs multiplicadores deben tener un atributo "data-multiplication-result"
 *
 * Al incluir el atributo `data-multiplication-wrapper` se podrá modificar el wrapper que contiene
 * la multiplicación, por defecto es un <div>. Esto se utiliza para no mezclar multiplicadores diferentes
 * que podrían estar dentro de la misma vista.
 *
 * @example
 * <div data-multiplication="true" data-multiplication-wrapper="div">
 *    <input data-multiplication-multiplier="true" ></input>
 *    <input data-multiplication-multiplier="true" ></input>
 *    <input data-multiplication-result="true" ></input>
 * </div>
 */

import onmount from 'onmount';

onmount('[data-multiplication]', function () {
  $(this).on("change", "input", function () {
    const wrapperTagName = $('[data-multiplication]').data("multiplicationWrapper");
    const wrapper = $(this).closest(wrapperTagName || "div");
    const result = wrapper.find('[data-multiplication-result]')[0];
    const multipliers = wrapper.find('[data-multiplication-multiplier]').get();
    result.value = 1;
    multipliers.forEach(function (nMultiplier) {
      result.value *= nMultiplier.value;
    });
  });
});
