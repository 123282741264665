/**
 * @file Permite validar grupos de checkbox obligatorios
 * Modo de Uso:
 * Grupos de checkbox.
 * - <input type='checkbox' name='group-name' checkbox-group-check=true />
 * - nombre único por grupo de checkboxes
 * - basta con que un checkbox por grupo tenga  'checkbox-group-check=true'
 */

import onmount from 'onmount';

const dummyCheckbox = document.createElement('input');
dummyCheckbox.type = 'checkbox';
dummyCheckbox.required = true;
const requiredMessage = dummyCheckbox.validationMessage;

function atLeastOneChecked(checkboxes) {
  for (const element of checkboxes) {
    if (element.checked) return true;
  }
  return false;
}

function checkGroupValidity(checkboxes) {
  if (atLeastOneChecked(checkboxes)) {
    checkboxes[0].setCustomValidity('');
  }
  else {
    checkboxes[0].setCustomValidity(requiredMessage);
  }
}

function addGroupListeners(checkboxes) {
  for (const element of checkboxes) {
    element.addEventListener('input', function () {
      checkGroupValidity(checkboxes);
    });
  }
  checkGroupValidity(checkboxes);
}

onmount('input[checkbox-group-check=true]', function () {
  addGroupListeners(document.getElementsByName($(this).attr('name')));
});

function atLeastOneChoice() {
  const anyChecked = Array.from(document.getElementsByClassName('check_boxes')).some((checkBox) => checkBox.checked);
  document.getElementsByName('commit')[0].disabled = !anyChecked;
}

onmount('[data-at-least-one-choice]', function () {
  addEventListener('change', function () {
    atLeastOneChoice();
  });
  atLeastOneChoice();
});
