/** @file Representa el cálculo del componente de tipo sueldo base */
import onmount from 'onmount';

onmount('.sueldo-base-row', function () {
  const pq = $(this).data('pq');
  const qv = $(this).data('qv');

  const cantidad = $(this).find('input[name$="[cantidad]"]');
  const valor = $(this).find('input[name$="[valor]"]');
  const precio = $(this).find('input[name$="[precio]"]');

  $(this).find('input[name$="[valor]"]').on('input', function (e) {
    if (qv) {
      if (cantidad.val() !== 0) {
        precio.val(+(e.target.value / cantidad.val()).toFixed(3));
      }
    }
  });

  $(this).find('input[name$="[precio]"]').on('input', function (e) {
    if (pq) {
      valor.val(Math.round(cantidad.val() * e.target.value));
    }
  });

  $(this).find('input[name$="[cantidad]"]').on('input', function (e) {
    if (pq) {
      valor.val(Math.round(e.target.value * precio.val()));
    }
    else if (qv) {
      if (e.target.value === 0) {
        precio.val(0);
      }
      else {
        precio.val(+(valor.val() / e.target.value).toFixed(3));
      }
    }
  });
});
