/** @file
 * Este componente permite limitar los colores seleccionables por el usuario
 * sin la opción otros que trae el selector de colores por defecto
 */

import onmount from 'onmount';

let instance = null;
let colors = [];
let colorPickerType = '';
const colorPickersList = [];
const colorsMap = new Map();
const COLORS_LIMIT_EVALUATION_SCALE = 15;
const selectedColor = {
  color: '',
  index: COLORS_LIMIT_EVALUATION_SCALE - 1,
};

async function getPickrInstance() {
  if (!instance) {
    const { default: Pickr } = await import('@simonwep/pickr');
    instance = Pickr;

    /**
   * Extiende el prototipo de la instancia Pickr para agregar funciones relacionadas con los colores de muestra (swatches).
   * Este código es un workaround basado en el ejemplo proporcionado en la documentación oficial de Pickr.
   * [Enlace a la documentación oficial]{@link https://github.com/simonwep/pickr/blob/master/EXAMPLES.md#extending-pickr-to-add--remove-a-list-of-swatches-241}
   *
   */
    instance.prototype.getSwatches = function () {
      return this._swatchColors.reduce((arr, swatch) => {
        arr.push(swatch.color.toRGBA().toString(0));
        return arr;
      }, []);
    };

    instance.prototype.setSwatches = function (swatches) {
      if (!swatches.length) return;
      for (let i = this._swatchColors.length - 1; i > -1; i--) {
        this.removeSwatch(i);
      }
      swatches.forEach((swatch) => this.addSwatch(swatch));
    };
  }

  return instance;
}

function updateColorPickers({ pickerList, color, limit }) {
  pickerList.forEach((picker) => {
    const lastColors = picker.getSwatches();

    if (lastColors.length < limit) {
      colorsMap.set(color, lastColors.length);

      picker.setSwatches([...lastColors, color]);

      selectedColor.color = color;

      return;
    }

    lastColors.splice(selectedColor.index, 1, color);

    colorsMap.delete(selectedColor.color);

    colorsMap.set(color, selectedColor.index);

    picker.setSwatches([...lastColors]);

    selectedColor.color = color;
  });
}

function updateColors(colorList, key) {
  localStorage.setItem('color-picker.' + key, JSON.stringify(colorList));
}

function getColors(key) {
  return JSON.parse(localStorage.getItem('color-picker.' + key)) || [];
}

const initializeColorPicker = async function (element, customConfig, isDynamicSwatches = false, limit) {
  let colorsContainer;
  const pickerInstance = await getPickrInstance();

  const config = Object.assign(
    {
      el: element,
      theme: 'nano',
      useAsButton: true,
      defaultColor: '#717479',
      swatches: [
        '#717479',
        '#49821C',
        '#095733',
        '#2B6EDD',
        '#562FAA',
        '#D31FA1',
        '#E44982',
        '#AD1F1F',
        '#D33C0D',
        '#936A2A',
      ],
      components: {
        preview: false,
        opacity: false,
        hue: false,
        interaction: {
          hex: false,
          rgba: false,
          hsva: false,
          input: false,
          clear: false,
          save: false,
        },
      },
    },
    customConfig
  );

  if (element.value) {
    element.style.backgroundColor = element.value;
  }
  else {
    element.value = config.defaultColor;
    element.style.backgroundColor = config.defaultColor;
  }

  const colorPickerElement = pickerInstance.create(config);
  colorPickersList.push(colorPickerElement);

  colorPickerElement.on('show', () => {
    selectedColor.index = limit - 1;
    colorsContainer = document.querySelector('.pcr-app.visible .pcr-swatches');

    setCustomColors(colorsContainer);

    colorsContainer.addEventListener('click', setClickedColor);
  });

  colorPickerElement.on('hide', () => {
    colorsContainer.removeEventListener('click', setClickedColor);
  });

  // Set events
  colorPickerElement.on('change', () => {
    element.value = colorPickerElement.getColor().toRGBA().toString(0);
    element.style.backgroundColor = element.value;
  });

  if (isDynamicSwatches) {
    colorPickerElement.on('changestop', () => {
      element.value = colorPickerElement.getColor().toRGBA().toString(0);

      // Verifica si el color ya existe en la lista de swatches
      if (!colorPickerElement.getSwatches().includes(element.value)) {
        // Agrega el color seleccionado a la lista de swatches solo si no existe previamente y actualiza los demás color pickers si existen
        updateColorPickers({ pickerList: colorPickersList, color: element.value, limit });
        updateColors(colorPickerElement.getSwatches(), colorPickerType);
      }
    });
  }
};

onmount('[data-colorpicker-type]', async function () {
  colorPickerType = document.querySelector('.colorpicker').getAttribute('data-colorpicker-type');
  colors = getColors(colorPickerType);

  // Configuración custom para las etiquetas de Beneficios
  const benefitTagsConfig = {
    theme: 'nano',
    useAsButton: true,
    defaultColor: '#717479',
    swatches: [
      '#717479',
      '#49821C',
      '#095733',
      '#2B6EDD',
      '#562FAA',
      '#D31FA1',
      '#E44982',
      '#AD1F1F',
      '#D33C0D',
      '#936A2A',
    ],
  };

  // Configuración custom para las escalas de Evaluaciones de desempeño
  const evaluationsScaleConfig = {
    theme: 'nano',
    appClass: 'pb-3',
    useAsButton: true,
    default: '#116F0C',
    swatches: colors.length ? [...colors] : ['#116F0C', '#074608', '#B77E01', '#A44401', '#B72C3A'],
    components: {
      palette: true,
    },
    interaction: {
      save: true,
    },
  };

  switch (colorPickerType) {
    case 'benefits-tags':
      initializeColorPicker(this, benefitTagsConfig);
      break;
    case 'evaluations-scale':
      initializeColorPicker(this, evaluationsScaleConfig, true, COLORS_LIMIT_EVALUATION_SCALE);
  }
});

/**
 * Obtiene una el valor de una variable CSS de un elemento
 * @param element - Elemento que contiene la variable CSS
 * @param variable - Nombre de la variable CSS
 * @returns valor de una variable CSS
 */
function getCssVariable(element, variable) {
  return getComputedStyle(element).getPropertyValue(variable);
}

/**
 * Obtiene los custom color del picker y los guarda en un map junto
 * a la posicion del color
 * @param colorsContainer - Elemento que contiene los colores custom
 */
function setCustomColors(colorsContainer) {
  const customOptions = colorsContainer.querySelectorAll('button');

  customOptions.forEach((option, index) => {
    const color = getCssVariable(option, '--pcr-color');

    colorsMap.set(color, index);

    if(index === COLORS_LIMIT_EVALUATION_SCALE - 1)
      selectedColor.color = color;
  });
}

/**
 * Almacena los valores relacionados con el color seleccionado
 * en el color picker
 * @param event - Evento que dispara la funcion
 */
function setClickedColor(event) {
  const clickedOption = event.target;
  const colorValue = getCssVariable(clickedOption, '--pcr-color');

  selectedColor.index = colorsMap.get(colorValue);
  selectedColor.color = colorValue;
}
