/** @file Comportamientos del formulario de creación/edición de cargas */
import onmount from 'onmount';

onmount('#cargas-form', function () {
  const self = this;
  const cargaSimple = $(this).find('#carga_carga_simple');
  setBehaviourForPeruAttributes(false, this);
  toggleChecked();
  $(this).find('.detalle-cargas')
    .on('cocoon:after-insert', function () {
      $(self).find(':input').enableClientSideValidations();
      toggleChecked();
    })
    .on('cocoon:before-remove', function (_e, nestedContainer) {
      $(this).data('remove-timeout', 1000);
      nestedContainer.fadeOut('slow');
      if (!!$(nestedContainer).find('input[type=checkbox][id*="carga_legal"]:checked').length &&
      + cargaSimple.val() > 0) {
        const value = +cargaSimple.val() - 1;
        $(self).find('#carga_carga_simple').val(value);
      }
    });

  function toggleChecked() {
    $(self).off('change.cargas', 'input[type=checkbox][id*="carga_legal"]');
    $(self).on('change.cargas', 'input[type=checkbox][id*="carga_legal"]', function () {
      let value = 0;
      if (this.checked) {
        value = +cargaSimple.val() + 1;
      }
      else {
        if (+cargaSimple.val() > 0) {
          value = +cargaSimple.val() - 1;
        }
      }
      cargaSimple.val(value);
    });
  }

  // Manejo de errores: Debido a que replaceWith reemplaza (duh) los elementos, perdemos todos
  // los eventos anclados al documento anterior. Debido a esto, solo reemplazamos el div del mensaje de
  // error, con lo que mantenemos todas las funcionalidades jquery presentes en el formulario.
  $(this).on('ajax:error', function (event) {
    const xhr = event.detail[2];
    if (xhr.status === 422) {
      $('.error-cell').replaceWith($(xhr.response).find('.error-cell'));
      $('.error-cell').get(0).scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  });
});

/**
 * @param  {boolean} employeeView
 * @param  {HTMLElement} form
 */
export function setBehaviourForPeruAttributes(employeeView, form) {
  let employeeCountry = '';
  if (employeeView) {
    employeeCountry = $('#employee-country').data('collection');
  }
  else {
    employeeCountry = $('#carga_type').prop('value');
    const cargaSimple = $('#carga_carga_simple');
    $(form).on('change', '.nested-fields select[name*=relation]', function () {
      if (employeeCountry.includes('Peru')) {
        const cargasPeru = ['minor_child', 'student_adult_child', 'disability_adult_child'];
        const idCheckbox = $(this).prop('id').replace('relation', 'carga_legal');
        const checkbox = $(`input[type=checkbox]:input[id*=${idCheckbox}]`);
        const cargaSeleccionada = $(this).val();
        if (cargasPeru.includes(cargaSeleccionada)) {
          checkbox.prop('checked', 1);
        }
        else {
          checkbox.prop('checked', 0);
        }

        cargaSimple.val(
          $('input[type=checkbox][id*="carga_legal"]:checked').length
        );
      }
    });
  }
  // Si cambia el campo relation a `conyugue` o `concubino(a)`, ocultamos el campo
  // expiration_date, y habilitamos el campo conception_month. Si el campo
  // deja de serlo, revertimos.
  $(form).on('change', '.nested-fields select[name*=relation]', function () {
    if (employeeCountry.includes('Peru')) {
      const cargaSeleccionada = $(this).val();
      const idExpirationDate = $(this).prop('id').replace('relation', 'expiration_date');
      const idConceptionDate = $(this).prop('id').replace('relation', 'conception_month');
      if(['spouse', 'concubine', 'pregnant'].includes(cargaSeleccionada)) {
        $(`#${idExpirationDate}`).val(null).change();
        $(`#${idExpirationDate}`).parent().parent().hide();
        $(`#${idConceptionDate}`).parent().parent().show();
      }
      else if(['minor_child', 'student_adult_child', 'disability_adult_child'].includes(cargaSeleccionada)) {
        $(`#${idConceptionDate}`).val(null).change();
        $(`#${idConceptionDate}`).parent().parent().hide();
        $(`#${idExpirationDate}`).parent().parent().show();
      }
      else{
        $(`#${idExpirationDate}`).val(null).change();
        $(`#${idConceptionDate}`).val(null).change();
        $(`#${idExpirationDate}`).parent().parent().hide();
        $(`#${idConceptionDate}`).parent().parent().hide();
      }
    }
  });

  // Si cambia el campo relation a `hijo mayor de edad estudiante` o  `hijo mayor de edad discapacitado`,
  // borramos el campo fecha de termino, debido a que solo setteamos un valor por defecto
  // cuando es un hijo menor de edad.
  //
  // Cuando el campo cambia a `hijo menor de edad`, entonces setteamos el valor por defecto del campo
  // de expiración (fecha de cumpleaños + 18 años).
  $(form).on('change', '.nested-fields select[name*=relation]', function () {
    if (employeeCountry.includes('Peru')) {
      const cargaSeleccionada = $(this).val();
      const idExpirationDate = $(this).prop('id').replace('relation', 'expiration_date');
      if (['student_adult_child', 'disability_adult_child'].includes(cargaSeleccionada)) {
        $(`#${idExpirationDate}`).val(null).change();
      }

      if (cargaSeleccionada === 'minor_child') {
        const idCumpleanos = $(this).prop('id').replace('relation', 'birthday');
        const birthdayString = $(`#${idCumpleanos}`).val();
        const birthdayPlus18Years = getBirthdayPlus18String(birthdayString);
        const idExpDate = $(this).prop('id').replace('relation', 'expiration_date');
        const expDate = $(`#${idExpDate}`);
        expDate.val(birthdayPlus18Years).change();
      }
    }
  });

  // Si la carga tiene relación de "Discapacitado", marcamos automaticamente el
  // checkbox asociado.
  $(form).on('change', '.nested-fields select[name*=relation]', function () {
    if (employeeCountry.includes('Peru')) {
      const cargasPeru = ['disability_adult_child'];
      const idCheckbox = $(this).prop('id').replace('relation', 'disability');
      const checkbox = $(`input[type=checkbox]:input[id*=${idCheckbox}]`);
      const cargaSeleccionada = $(this).val();
      if (cargasPeru.includes(cargaSeleccionada)) {
        checkbox.prop('checked', 1);
      }
      else {
        checkbox.prop('checked', 0);
      }
    }
  });

  $(form).on('change', 'input[name*=birthday]', function () {
    if (employeeCountry.includes('Peru')) {
      const idCargaSeleccionada = $(this).prop('id').replace('birthday', 'relation');
      const cargaSeleccionada = $(`#${idCargaSeleccionada}`).val();
      if (cargaSeleccionada === 'minor_child') {
        const birthdayString = $(this).val();
        const birthdayPlus18Years = getBirthdayPlus18String(birthdayString);
        const idExpDate = $(this).prop('id').replace('birthday', 'expiration_date');
        const expDate = $(`#${idExpDate}`);
        expDate.val(birthdayPlus18Years).change();
      }
    }
  });

  // Cuando agregamos motivo de baja para un detalle_carga peruano,
  // deja de ser una carga legal para efectos de la asignación familiar
  $(form).on('change', '.nested-fields select[name*=motivo_de_baja_peru]', function () {
    const country = $(form).find('input[type=hidden]:input[id*=carga_type]').prop('value');
    const cargaSimple = $('#carga_carga_simple');
    if(country.includes('Peru')) {
      const idCheckbox = $(this).prop('id').replace('motivo_de_baja_peru', 'carga_legal');
      const checkbox = $(form).find(`input[type=checkbox]:input[id*=${idCheckbox}]`);
      checkbox.prop('checked', 0);

      cargaSimple.val(
        $('input[type=checkbox][id*="carga_legal"]:checked').length
      );
    }
  });

  // Cuando agregamos fecha de baja para un detalle_carga peruano,
  // deja de ser una carga legal para efectos de la asignación familiar
  $(form).on('change', '.nested-fields input[name*=fecha_de_baja_peru]', function () {
    const country = $(form).find('input[type=hidden]:input[id*=carga_type]').prop('value');
    const cargaSimple = $('#carga_carga_simple');
    if(country.includes('Peru')) {
      const idCheckbox = $(this).prop('id').replace('fecha_de_baja_peru', 'carga_legal');
      const checkbox = $(form).find(`input[type=checkbox]:input[id*=${idCheckbox}]`);
      checkbox.prop('checked', 0);

      cargaSimple.val(
        $('input[type=checkbox][id*="carga_legal"]:checked').length
      );
    }
  });

  // Método que recibe un string de cumpleaños en formato dd-mm-yyyy, y retorna un string en el mismo
  // formato, pero de la fecha de cumpleaños en 18 años en el futuro.
  function getBirthdayPlus18String(birthdayString) {
    if (birthdayString === '' || birthdayString === null || birthdayString === undefined) { return; }
    const birthdaySplit = birthdayString.split('-');
    const newDay = birthdaySplit[0];
    const newMonth = birthdaySplit[1];
    const newYear = (parseInt(birthdaySplit[2]) + 18);
    return `${newDay}-${newMonth}-${newYear}`;
  }
}
