/** @file Muestra y oculta botones, junto con el contenido asociado a cada uno de ellos.
 * El comportamiento al mostrar/ocultar botones o contenido actúa de forma 'binaria', es decir
 * que aquello que se encuentra visible, se oculta y viceversa.
 *
 * Modo de uso:
 * - Definir un contenedor <div data-toggle-buttons> que contenga los botones a emplear
 * - Para cada botón dentro del contenedor, definir lo siguiente:
 *    1. Aplicar la clase 'default-inactive-button' para aquellos botones que deben permanecer ocultos en primera instancia
 *    2. 'data-toggle-content': Contenido a mostrar por el botón
 * - Definir la clase 'd-none' para aquellos contenedores asociados a cada botón que deben permanecer ocultos
 *   en primera instancia.
 *
 * Ejemplo:
 * <div data-toggle-buttons>
      <%= btn_secondary 'Ver como cuadrante',
                        icon: 'th-large',
                        class: 'default-inactive-button',
                        data: {
                          'toggle-content': '#ninebox-content-quadrant'
                        } %>
      <%= btn_secondary 'Ver como lista',
                        icon: 'list',
                        data: {
                          'toggle-content': '#ninebox-content-list'
                        } %>
    </div>

    <div id='ninebox-content-quadrant' class='col-md-12'>
      <p>Mi primer contenido</p>
    </div>
    <div id='ninebox-content-list' class='col-md-12 d-none'>
      <p>Mi segundo contenido</p>
    </div>
 */
import onmount from 'onmount';

onmount('[data-toggle-buttons]', function () {
  const $self = $(this);
  const $buttons = $self.children();

  $buttons.each(function () {
    if ($(this).hasClass('default-inactive-button')) $(this).hide();
  });

  $self.on('click', function () {
    $buttons.each(function () {
      const content = this.getAttribute('data-toggle-content');
      $(this).toggle();
      $(content).toggle(400);
    });
  });
});

onmount('.toggle_buttons', function () {
  const inputBtn = $(this).find('input[type="radio"]');
  const deselectable = $(this).data('deselectable') === true;
  $(inputBtn).on('click', function () {
    // si el elemento ya esta seleccionado, quitar selección, solo en el padre directo
    if(deselectable && $(this).parent().hasClass('active')) {
      $(this).parent().removeClass('active');
      $(this).prop('checked', false);
      return;
    }
    //buscar todos los inputs que contengan la clase active y removerla
    const toggleComponent = $(this).closest('.toggle_buttons');
    toggleComponent.find('label').removeClass('active');
    // agregar clase active si el input está checked
    $(this).parent().addClass('active');

  });
});
