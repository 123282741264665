/** @file Permite actualizar un tooltip segun la opción seleccionada en un select
 *
 * data-tooltip-change="select" se usa en el contenedor del tooltip, del select
 * y de las opciones de texto para el tooltip. Las opciones del tooltip segun el
 * select deben tener el atributo [data-text-tooltip="${value}"].
 * Se pasa el html contenido dentro de la opción.
 *
 * Si los hints se usan para varios tooltips se puede pasar un selector del div
 * contenedor en data-hints-target
 *
 * @example
 *  <div data-tooltip-change="select">
 *    <div class="hide">
 *      <div data-text-tooltip="">texto opción blank </div>
 *      <div data-text-tooltip="1">texto opción 1</div>
 *      <div data-text-tooltip="2">texto opción 2</div>
 *      <div data-text-tooltip="3">texto opción 3</div>
 *    </div>
 *    <span data-toggle="tooltip" title="" data-original-title="texto original">
 *      <i class=" fas fa-question-circle fa-fw" aria-hidden="true"></i>
 *    </span>
 *    <select>
 *      <option selected="selected" value="">Seleccionar</option>
 *      <option value="1">Opción 1</option>
 *      <option value="2">Opción 2</option>
 *      <option value="3">Opción 3</option>
 *    </select>
 *  </div>
 *
*/
import onmount from 'onmount';

onmount('[data-tooltip-change="select"]', function () {
  let container = $(this);
  const select = container.find('select');
  const tooltipElement = container.find('[data-toggle="tooltip"]');

  if (container.data('hints-target')) {
    container = $(container.data('hints-target'));
  }
  select.on('change', function () {
    const value = $(this).val();
    const newTooltipValue = container.find(`[data-text-tooltip="${value}"]`);
    tooltipElement.attr('data-original-title', newTooltipValue.html());
    if (newTooltipValue.length) {
      tooltipElement.tooltip('show');
      setTimeout(() => tooltipElement.tooltip('hide'), 2000);
    }
  });
});
