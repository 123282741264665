/** @file Archivo utilizado para redirigir la vista a la sección de adelantos basado en los query_params */
import onmount from 'onmount';

onmount('#tabs-profile', function () {
  if (window.location.href.indexOf('section=adelantos') > -1) {
    document.getElementById('tabs-profile').scrollIntoView();
  }
});

onmount('#adelantos', function () {
  if (window.location.href.indexOf('section=adelantos') > -1) {
    document.getElementById('adelantos').scrollIntoView();
  }
});
