/** @file TODO: documentar */
import onmount from 'onmount';
import numeral from 'numeral';

onmount('[data-cotizacion-adicional]', function () {
  const $self = $(this);
  const cotizacionBase = $self.data('cotizacionBase');
  if (!cotizacionBase) {
    throw new Error("No hay cotizacion base!");
  }

  const target = $self.data('cotizacionAdicional');

  function setValue() {
    // cotizacion base esta en distinta base que la adicional... sigh...
    const total = numeral(+$self.val() + cotizacionBase);
    $(target).text("Total Mutual + Ley SANNA: " + total.format('0.000') + '%');
  }

  $self.on('change input', setValue);
  setValue();
});
