/** @file TODO: documentar */
import onmount from 'onmount';

onmount('.toogletoken', function () {
  $(this).on('click', function () {
    const $self = $(this);
    const id = $self.data('toggleToken');
    const target = $(`[data-toggle-token=${id}][data-token]`);
    const tmp1 = target.data('token');
    const tmp2 = target.text();
    const icon = $self.find('.material-icons');
    let tooltipText = 'Mostrar';
    target.text(tmp1);
    target.data('token', tmp2);

    if (icon.text() === 'visibility') {
      icon.text('visibility_off');
      tooltipText = 'Ocultar';
    }
    else {
      icon.text('visibility');
      tooltipText = 'Mostrar';
    }

    icon.attr('data-original-title', tooltipText).tooltip('hide').tooltip('show');
  });
});
