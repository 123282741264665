/** @file Scorecard */
import onmount, { $ } from 'onmount';

let capacities = [];

const fetchCompetencias = (roleId) => {
  const url = document.querySelector('#capacity_indicators').getAttribute('role-capacities-url');

  return new Promise((resolve, reject) => {
    $.ajax({
      url,
      method: 'POST',
      data: { role_id: roleId },
      success: function (response) {
        resolve(response);
      },
      error: function (_jqXHR, _textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
};

$.onmount('#capacity_indicators', async function () {
  // eslint-disable-next-line import/no-unresolved
  await import('../../../../../../../../app/assets/javascripts/vendor/select2.js');
  const { createCapacityQuestionRow } = await import('./scorecard_template');
  // eslint-disable-next-line max-len
  const { applyReactiveBehaviour } = await import('../../../../../../../../app/assets/javascripts/components/select2/reactive-options.js');
  // eslint-disable-next-line max-len
  const { buildCreateOption, buildTemplateResult } = await import('../../../../../../../../app/assets/javascripts/components/select2/create-option.js');

  let jsonDataHtml;

  // Intenta parsear el contenido del elemento #scorecard-json-data como un objeto JSON
  try {
    jsonDataHtml = document.querySelector('#scorecard-json-data').innerHTML;
    const { allCapacities, capacitiesProcess } = JSON.parse(jsonDataHtml);

    const processCapacities = (capacitiesProcess ?? []).reduce((acc, { capacity, question }) => [
      ...acc,
      [
        capacity?.name ?? question.question_text,
        capacity?.id ?? question.question_text,
        question.extended_text,
      ],
    ], []);

    // Si el parseo es exitoso, se crea un array de objetos de competencias
    capacities = [...allCapacities, ...processCapacities].reduce((acc, curr) => {
      // Si el objeto de competencia ya existe en el array, no se agrega de nuevo
      if (acc.some((capacity) => capacity.id === curr[1])) return acc;

      acc.push({ id: curr[1], text: curr[0], description: curr[2] });

      return acc;
    }, []);
  }
  catch (error) {
    capacities = [];
  }

  /**
   * Instancia un elemento select2 con opciones personalizadas.
   *
   * @param {HTMLElement} selectElement - El elemento select a instanciar.
   */
  const instantiateSelectTwo = (selectElement) => {
    $(selectElement).select2({
      width: '100%',
      tags: true,
      createTag: buildCreateOption('new-question-'),
      templateResult: buildTemplateResult,
    });

    applyReactiveBehaviour($(selectElement));
  };

  /**
   * Crea una nueva fila para una pregunta de competencia con un menú desplegable select2.
   *
   * @param {Array} capacities - Un array de objetos de capacidad para poblar el menú desplegable.
   * @param {string} selectedValue - El valor para preseleccionar en el menú desplegable.
   */
  const newRowCapacityQuestion = (capacitiesList, selectedCapacity = null) => {
    const capacityRow = createCapacityQuestionRow(capacitiesList, selectedCapacity?.id);

    if (selectedCapacity) {
      capacityRow.querySelector('input.form-control').value = selectedCapacity.description;
    }

    $('#capacity_indicators').append(capacityRow);

    instantiateSelectTwo(capacityRow.querySelector('select.scorecard-capacities'));
  };

  // Evento que se gatilla al apretar el botón para agregar una nueva fila de competencia
  $('#btn-add-capacity-question').on('click', () => {
    newRowCapacityQuestion(capacities);
  });

  // Evento que se gatilla al apretar el botón borrar una fila de competencia
  $('#capacity_indicators').on('click', '.btn-destroy-capacity', (event) => {
    $(event.target).parents('.capacity-question-row').find('select').trigger('select2:selecting');

    $(event.target).parents('.capacity-question-row').remove();
  });

  $('#capacity_indicators').on('select2:select', 'select.scorecard-capacities', (event) => {
    // Id del elemento seleccionado
    const { id } = event.params.data;

    const data = capacities.find((capacity) => capacity.id === Number(id));

    $(event.target).parents('.capacity-question-row').find('input.form-control').val(data.description);
  });

  // Interceptor para identificar cuando se cambia un cargo y cargar sus competencias
  $('#role_id').on('change', async (event) => {

    if (!event.target.value || !event.target.textContent) return;

    const roleCapacities = await fetchCompetencias(event.target.value);
    $('#capacity_indicators').empty();

    for (const currCapacity in roleCapacities) {
      newRowCapacityQuestion(capacities, roleCapacities[currCapacity]);
    }
  });

  // Cargar las competencias del proceso de seleccion
  try {
    const { capacitiesProcess } = JSON.parse(jsonDataHtml);

    capacitiesProcess.forEach(({ capacity, question }) => {
      const formattedCapacity = {
        id: capacity?.id ?? question.question_text,
        text: capacity?.name ?? question.question_text,
        description: question.extended_text,
      };

      newRowCapacityQuestion(capacities, formattedCapacity);
    });
  }
  catch (error) {
    // TODO: Manejar error
  }
});

onmount('#process_stages_selection', function () {
  const originalXHROpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function (method, url) {
    this.addEventListener('load', function () {
      try {
        if(url.includes('/rapidfire/surveys/')) {
          const responseData = JSON.parse(this.responseText);
          if ('score' in responseData) {
            const score = responseData.score.toFixed(1);
            setScoreScorecard(score);
          }
        }
        if ((url.includes('/recruiting/scorecard')) && (method === 'POST')) {
          const score = $(this.responseText).find('#banner-scorecard h2')[0].textContent;
          setScoreScorecard(score);
        }
      }
      catch(e) {
        // TODO: Manejar error
      }
    });
    originalXHROpen.apply(this, arguments);
  };
});

function setScoreScorecard(score) {
  if (isNaN(score)) return;
  const applicationId = $('#attempt_application_id')[0].value;
  // Actualizamos el score del modal del postulante
  $('#banner-scorecard').find('h2')[0].textContent = score;
  // Actualizamos el card de la postulación
  $(`#score-data-${applicationId}`)[0].textContent = score;
  // Actualizamos el card de la postulación cuando se hace click en guardar
  $(`.scorecard-score.scorecard-score-card-${applicationId}`).removeClass('d-none');
}
