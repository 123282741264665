/** @file Archivo utilizado para cambiar el contenido del modal de confirmación */

import onmount from 'onmount';

// Step 1: Solicitar adelanto
onmount('#advances-request-amount', function () {
  $('#advances-request-button-next').on('click', function () {
    enableStep(2);
  });
});

// Step 2: Ver resumen
onmount('#advances-summary', function () {
  $('#advances-summary-button-back').on('click', function () {
    enableStep(1);
  });
  $('#advances-summary-button-next').on('click', function () {
    enableStep(3);
    generateCode();
  });
});

// Step 3: Confirmar código
onmount('#advances-confirm-code', function () {
  $('#advances-confirm-button-back').on('click', function () {
    enableStep(1);
  });
});

/** Habilita el siguiente paso en el flujo de adelantos */
export function enableStep(stepNumber) {
  if (stepNumber === 0) {
    displayModal('advances-terms-and-conditions', true);
    displayModal('advances-request-amount', false);
    displayModal('advances-summary', false);
    displayModal('advances-confirm-code', false);
  }
  if (stepNumber === 1) {
    displayModal('advances-terms-and-conditions', false);
    displayModal('advances-request-amount', true);
    displayModal('advances-summary', false);
    displayModal('advances-confirm-code', false);
  }
  if (stepNumber === 2) {
    displayModal('advances-terms-and-conditions', false);
    displayModal('advances-request-amount', false);
    displayModal('advances-summary', true);
    displayModal('advances-confirm-code', false);
  }
  if (stepNumber === 3) {
    displayModal('advances-terms-and-conditions', false);
    displayModal('advances-request-amount', false);
    displayModal('advances-summary', false);
    displayModal('advances-confirm-code', true);
  }
}

function displayModal(modalName, show) {
  const element = document.getElementById(modalName);

  if (!element) return;

  if (show === true) {
    element.classList.remove('d-none');
  }
  if (show === false) {
    element.classList.add('d-none');
  }
}

function generateCode() {
  return $.ajax({
    method: 'POST',
    dataType: 'json',
    url: '/authorization_code',
    data: { title: 'solicitud de adelantos' },
  });
}
