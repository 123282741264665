/** @file Maneja el comportamiento de los links de acciones de la tabla de documentos */
import onmount from 'onmount';
import { action } from '../lib/buk/alert';

onmount('.document-signatures', function () {
  const $massDocumentsActions = $('.mass_documents_action');
  const $signAllAction = $('#sign_all_action');
  const $downloadDocumentsAction = $('#download_documents_action');
  const $recyclingBinAllAction = $('#mass-to-recycling-bin-action-link');
  const $deleteAllAction = $('#delete_all_action');
  const $hardDestroyAllAction = $('#hard-destroy-all-action');
  const $massRestoreAction = $('#mass-restore-action-link');
  const $massSigneeNotification = $('#mass-signee-notification-action-link');
  const $massReviewNotification = $('#mass-review-notification-action-link');
  const $recyclingBinTab = $('a[href$="recycling-bin-tab"]');
  const $hideDocumentsAction = $('#mass-hide-action-link');
  const $unhideDocumentsAction = $('#mass-unhide-action-link');
  const $massNotificationAction = $('#mass-notification-action-link');
  const $sendToRecyclingBinAction = $('#mass-to-recycling-bin-action-link');
  const $generateDocumentsActionLink = $('#generate-documents-action-link');

  const reviewerTabName = '#reviewer-tab';
  const pendingTabName = '#pending-signee-tab';
  const inboxTabName = '#tab-bandeja-de-entrada';
  const allPendingTabName = '#all-pending-tab';
  const allDocumentsTabName = '#all-documents-tab';
  const recyclingBinTabName = '#recycling-bin-tab';

  const reviewerTabActions = [
    $downloadDocumentsAction,
  ];

  const recyclingBinActions = [
    $massRestoreAction,
    $hardDestroyAllAction,
  ];
  const otherActions = [
    $generateDocumentsActionLink,
    $signAllAction,
    $massSigneeNotification,
    $massNotificationAction,
    $downloadDocumentsAction,
    $sendToRecyclingBinAction,
    $hideDocumentsAction,
    $unhideDocumentsAction,
  ];

  const pendingTabActions = [
    $signAllAction,
    $downloadDocumentsAction,
    $generateDocumentsActionLink,
  ];
  const allPendingTabActions = [
    $generateDocumentsActionLink,
    $massNotificationAction,
    $massSigneeNotification,
    $downloadDocumentsAction,
    $hideDocumentsAction,
    $unhideDocumentsAction,
    $sendToRecyclingBinAction,
    $massReviewNotification,
  ];
  const allDocumentsTabActions = [
    $generateDocumentsActionLink,
    $downloadDocumentsAction,
    $hideDocumentsAction,
    $unhideDocumentsAction,
    $sendToRecyclingBinAction,
  ];
  const allActions = [
    $generateDocumentsActionLink,
    $signAllAction,
    $massSigneeNotification,
    $massNotificationAction,
    $downloadDocumentsAction,
    $sendToRecyclingBinAction,
    $hideDocumentsAction,
    $unhideDocumentsAction,
    $massReviewNotification,
    $massRestoreAction,
    $hardDestroyAllAction,
  ];

  const $alert = $('.alert.alert_info.text-left.alert__scroll');
  const $actionsBtn = $('#cont_botones_compuestos');

  // Comportamiento al cargar: no tenemos nada seleccionado
  updateActions(0);
  activeActions();

  // Se adjuntan las funciones que manejarán las distintas acciones
  $massDocumentsActions.on('confirm:complete', (e) =>
    preventIfDisabled(e, processMassAction)
  );
  $signAllAction.on('click', (e) => preventIfDisabled(e, signDocuments));
  $downloadDocumentsAction.on('click', (e) =>
    preventIfDisabled(e, downloadDocuments)
  );
  $recyclingBinAllAction.on('click', (e) =>
    preventIfDisabled(e, softDeleteDocuments)
  );
  $deleteAllAction.on('click', (e) => preventIfDisabled(e, deleteDocuments));
  $hardDestroyAllAction.on('click', (e) =>
    preventIfDisabled(e, deleteDocuments)
  );
  $massRestoreAction.on('click', (e) => preventIfDisabled(e, restoreDocuments));
  $massSigneeNotification.on('click', (e) => {
    preventIfDisabled(e, notificateSignees);
    analyticsTrackingMassNotify(e, 'Firmantes');
  });
  $massReviewNotification.on('click', (e) => {
    preventIfDisabled(e, notificateReivewers);
    analyticsTrackingMassNotify(e, 'Revisores');
  });
  $massNotificationAction.on('click', (e) =>{
    analyticsTrackingMassNotify(e, 'Colaboradores');
  });
  $hideDocumentsAction.on('click', (e) => preventIfDisabled(e, hideDocuments));
  $unhideDocumentsAction.on('click', (e) =>
    preventIfDisabled(e, unhideDocuments)
  );

  // Funciones de Analytics que manejarán las marcas
  $recyclingBinTab.on('click', (e) =>
    preventIfDisabled(e, analyticsTrackingRecyclingBinTab)
  );
  $massRestoreAction.on('click', (e) =>
    preventIfDisabled(e, analyticsTrackingMassRestoreAction)
  );
  $hardDestroyAllAction.on('click', (e) =>
    preventIfDisabled(e, analyticsTrackingMassDestroyAction)
  );

  // Registro de marcas de Amplitude para botones de firma individual.
  $('body div#tab-bandeja-de-entrada').on(
    'click',
    '#document_sign_button',
    (e) => {
      const documentUrl = e.target.parentNode.href;
      const documentId = getIdFromUrl(documentUrl);
      const currentRow = e.target.closest('tr');
      const documentType = getDocTypeFromRow(currentRow);
      const documentSigner = getDocSignerFromRow(currentRow);
      sendSignedButtonClicked(e, 'Documentos', [documentId], [documentType], [documentSigner]);
    }
  );

  $('body div#pending-signee-tab').on(
    'click',
    '#document_sign_button',
    (e) => {
      const documentUrl = e.target.parentNode.href;
      const documentId = getIdFromUrl(documentUrl);
      const currentRow = e.target.closest('tr');
      const documentType = getDocTypeFromRow(currentRow);
      const documentSigner = getDocSignerFromRow(currentRow);
      sendSignedButtonClicked(e, 'Documentos', [documentId], [documentType], [documentSigner]);
    }
  );

  // Tenemos que refrescar la validez de las acciones al cargar/cambiar de tab
  $(this).on('shown.bs.tab', () => {
    activeActions();
    updateActions(getSelectedIds().length);
    positionActionsBtn();
  });

  // y también cuando se selecciona/deselecciona algún documento
  $(this).on('dtDataChange', (event) => {
    updateActions(event.selectedIds.length);
  });

  $(window).on('resize', () => {
    positionActionsBtn();
  });

  $('body div#recycling-bin-tab').on(
    'click',
    '#individual-restore-action',
    (e) => {
      var element =
        e.target.tagName === 'SPAN' ? e.target.parentNode : e.target;
      // eslint-disable-next-line max-len
      $(e.target).trigger('amplitudeTracking', {
        message: 'restore_documents_confirmation',
        data: { quantity: 1, ids: [element.getAttribute('data-id')] }
      });
      restoreOneDocument(element);
    }
  );

  /** Es necesario mostrar el flujo de los modales a continuación por lo que es necesario usar JS. El elemento en la tabla se
      renderea async por lo que hay que ir a buscarlo así, y el tarjet puede cambiar dependiento del click, si es en ícono o
      en el boton, ya que el id del documento viene solo en uno de estos elementos. Se quizo usar el mismo request de las acciones
      masivas, aplicando lo mismo para el boton de restaurar. */

  /** Destruye un documento de forma individual */
  $('body div#recycling-bin-tab').on(
    'click',
    '#individual-destroy-action',
    (e) => {
      var element = e.target.tagName === 'I' ? e.target.parentNode : e.target;
      // eslint-disable-next-line max-len
      $(e.target).trigger('amplitudeTracking', {
        message: 'hard_delete_initiation',
        data: { quantity: 1, ids: [element.getAttribute('data-id')] }
      });
      element = $(e.currentTarget);
      var documentId = element.data('id');
      var documentUrl = element.data('url');

      post(documentUrl, [documentId], 'delete');

    }
  );

  $(function () {
    const tabsToHideButtons = ['#recycling-bin-tab'];
    function toggleBotonesCompuestos(selectedTab) {
      const shouldHide = tabsToHideButtons.includes(selectedTab);
      $('#botones-compuestos').toggle(!shouldHide);
    }
    const activeTab = $('.document-signatures .nav-tabs a.active').attr('href');
    toggleBotonesCompuestos(activeTab);
    $('.document-signatures .nav-tabs a[data-toggle="tab"]').on('click', (e) => {
      const selectedTab = $(e.target).attr('href');
      toggleBotonesCompuestos(selectedTab);
      activeActions();
    });
  });

  function activeActions() {
    const activeLink = $('.document-signatures').find('.nav-link.active').attr('href');
    hideActions(allActions);
    switch(activeLink) {
      case recyclingBinTabName:
        showActions(recyclingBinActions);
        break;
      case reviewerTabName:
        showActions(reviewerTabActions);
        break;
      case inboxTabName:
      case pendingTabName:
        showActions(pendingTabActions);
        break;
      case allPendingTabName:
        showActions(allPendingTabActions);
        break;
      case allDocumentsTabName:
        showActions(allDocumentsTabActions);
        break;
      default:
        showActions(otherActions);
        break;
    }
  }

  function showActions(actions) {
    actions.forEach((e) => {
      $(e).show();
    });
  }

  function hideActions(actions) {
    actions.forEach((e) => {
      $(e).hide();
    });
  }

  function positionActionsBtn() {
    if (
      $('.document-signatures')
        .find('.nav-link.active')
        .attr('href') === '#recycling-bin-tab'
    ) {
      const topPos =
        $alert.outerHeight(true) +
        $('.nav.nav-tabs').outerHeight(true) +
        parseInt($('.tab-content').css('padding-top')) +
        16;
      $actionsBtn.css('top', topPos);
    }
    else {
      $actionsBtn.css(
        'top',
        $('.nav.nav-tabs').outerHeight(true) +
          parseInt($('.tab-content').css('padding-top'))
      );
    }
  }

  /** Desabilita el boton de restaruar de las acciones si solo hay seleccionados elementos no restaurables */
  function disableRestoreAction() {
    const restorableSelectedIds = getRestorableIds();
    if (restorableSelectedIds.length === 0) {
      disable($massRestoreAction);
    }
    else {
      enable($massRestoreAction);
    }
  }

  /** Actualiza el comportamiento de las acciones dependiendo de la cantidad de elementos seleccionados*/
  function updateActions(selectedCount) {
    const updateFn = selectedCount > 0 ? enable : disable;
    const inboxTabSelected = $(`a[href="${inboxTabName}"]`).hasClass(
      'active'
    );
    const inboxTabPendingSigneeSelected = $(`a[href="${pendingTabName}"]`).hasClass(
      'active'
    );
    const recyclingBinTabSelected = $(`a[href="${recyclingBinTabName}"]`).hasClass(
      'active'
    );
    Array.from($massDocumentsActions).forEach(function (element) {
      if (element.id === 'sign_all_action' && !(inboxTabSelected || inboxTabPendingSigneeSelected)) {
        // Sólo tiene sentido la firma masiva en la pestaña "Bandeja de Entrada"
        disable(element);
      }
      else {
        updateFn(element);
      }
    });
    if (recyclingBinTabSelected) {
      disableRestoreAction();
    }
  }

  /** Evita que se gatillen las funciones asociadas a los links si están deshabilitados */
  function preventIfDisabled(e, fn) {
    e.preventDefault();
    if (
      $(e.target)
        .parent()
        .hasClass('disabled')
    ) {
      return false;
    }

    return fn(e);
  }

  /** Envía los ids de los documentos seleccionados al backend para ser firmados */
  function signDocuments(e) {
    $('#sign_all_modal')
      .find('.steps')
      .html('<div class="text-center"><div class="buk-loader"></div></div>');
    $('#sign_all_modal')
      .find('.message-signature')
      .hide();
    const documentIds = getSelectedIds();
    const documentTypes = getSelectedDocTypes();
    const documentSigners = getSelectedDocSignerRole();

    /* marca el evento en amplitude */
    sendSignedButtonClicked(e, 'Documentos', documentIds, documentTypes, documentSigners);

    post($(e.target).data('url'), documentIds);
  }

  /** Envía los ids de los documentos seleccionados al backend para ser eliminados */
  function deleteDocuments(e) {
    $('#delete_all_modal')
      .find('.steps')
      .html('<div class="text-center"><div class="buk-loader"></div></div>');
    $('#delete_all_modal')
      .find('.message-signature')
      .hide();
    const documentIds = getSelectedIds();
    post($(e.target).data('url'), documentIds, 'delete');
  }

  /** Envía los ids de los documentos seleccionados al backend para ser enviados a la papelera */
  function softDeleteDocuments(e) {
    const documentIds = getSelectedIds();
    const deleteableIds = getDeleteableIds();
    var nonDeleteableCount = documentIds.length - deleteableIds.length;
    // Si existen documentos no eliminables
    // eslint-disable-next-line max-len
    $(e.target).trigger('amplitudeTracking', {
      message: 'send_to_paperbin_button_clicked',
      data: {
        source: 'documentos',
        quantity: documentIds.length,
        ids: documentIds
      }
    });
    if (nonDeleteableCount > 0) {
      const nonDeleteableIds = documentIds.filter(
        (x) => !deleteableIds.includes(x)
      );
      // eslint-disable-next-line max-len
      $(e.target).trigger('amplitudeTracking', {
        message: 'send_to_paperbin_unsuccessful_for_signed_document',
        data: { quantity: nonDeleteableIds.length, ids: nonDeleteableIds }
      });
      post($(e.target).data('url'), documentIds, 'soft_delete', {
        nonDeleteableCount: nonDeleteableCount,
        deleteableIds: deleteableIds
      });
    }
    else {
      post($(e.target).data('url'), documentIds, 'soft_delete');
    }
  }

  /** Restaura documentos de forma masiva */
  function restoreDocuments(e) {
    const documentIds = getSelectedIds();
    const restorableIds = getRestorableIds();
    const nonRestorableCount = documentIds.length - restorableIds.length;
    // Si existen documentos no restaurables
    if (nonRestorableCount > 0) {
      post($(e.target).data('url'), documentIds, 'restore', {
        nonRestorableCount: nonRestorableCount,
        restorableIds: restorableIds
      });
    }
    else {
      post($(e.target).data('url'), documentIds, 'restore');
    }
  }

  /** Restaura documentos de forma individual */
  function restoreOneDocument(element) {
    post($(element).data('url'), [element.getAttribute('data-id')], 'restore');
  }

  /** Envia los ids de los documentos seleccionados al backend para ser procesados */
  function processMassAction(e) {
    const [answer] = e.detail;
    if (answer) {
      const documentIds = getSelectedIds();
      post($(e.target).data('url'), documentIds);
    }
  }

  /** Envía los ids de los documentos seleccionados dentro del form en la pestaña de acciones */
  function downloadDocuments() {
    const form = $('#document-download');
    getSelectedCheckboxs()
      .clone()
      .hide()
      .appendTo(form);
    form[0].requestSubmit();
    // Eliminamos los inputs añadidos para no incluirlos en la siguiente descarga:
    form.children('[type="checkbox"]').remove();
  }

  /** Envía los ids de los documentos seleccionados dentro del form para ser marcados como no visibles */
  function hideDocuments(e) {
    const documentIds = getSelectedIds();
    post($(e.target).data('url'), documentIds, 'hide_documents');
  }

  /** Envía los ids de los documentos seleccionados dentro del form para ser marcados como visibles */
  function unhideDocuments(e) {
    const documentIds = getSelectedIds();
    post($(e.target).data('url'), documentIds, 'unhide_documents');
  }

  /** Modifica el elemento para que aparezca como deshabilitado en la interfaz */
  function disable(element) {
    const $element = $(element);
    $element.addClass('disabled');
    $element.attr('data-url-disabled', $element.attr('data-url'));
    $element.removeAttr('data-url');
    $element.attr('data-confirm-disabled', $element.attr('data-confirm'));
    $element.removeAttr('data-confirm');
  }

  /** Modifica el elemento para que aparezca como habilitado en la interfaz. */
  function enable(element) {
    const $element = $(element);
    $element.removeClass('disabled');
    $element.attr('data-url', $element.attr('data-url-disabled'));
    $element.removeAttr('data-url-disabled');
    $element.attr('data-confirm', $element.attr('data-confirm-disabled'));
    $element.removeAttr('data-confirm-disabled');
  }

  /** Analytics Functions */
  function analyticsTrackingRecyclingBinTab(e) {
    // eslint-disable-next-line eqeqeq
    if (
      $('.document-signatures')
        .find('.nav-link.active')
        .attr('href') !== recyclingBinTabName
    ) {
      $(e.target).trigger('amplitudeTracking', {
        message: 'paperbin_tab_clicked',
        data: {}
      });
    }
  }
  function analyticsTrackingMassRestoreAction(e) {
    const documentIds = getSelectedIds();
    $(e.target).trigger('amplitudeTracking', {
      message: 'restore_documents_confirmation',
      data: { quantity: documentIds.length, ids: documentIds }
    });
  }
  function analyticsTrackingMassDestroyAction(e) {
    const documentIds = getSelectedIds();
    $(e.target).trigger('amplitudeTracking', {
      message: 'hard_delete_initiation',
      data: { quantity: documentIds.length, ids: documentIds }
    });
  }
  function analyticsTrackingMassNotify(e, role) {
    const documentIds = getSelectedIds();
    $(e.target).trigger('amplitudeTracking', {
      message: 'Notify_Document',
      data: { notified_signer_role: role, document_batch: documentIds?.length },
    });
  }

  /** Retorna los ids de todos los documentos seleccionados en la tabla */
  function getSelectedIds() {
    const table = getActiveDatatable();
    const storeInput = table.find('[data-dt-saved-selects]');

    // si la tabla esta recien cargada no va a conseguir ningún input.
    const savedValue = storeInput.val();
    if (savedValue) {
      return JSON.parse(savedValue);
    }
    return [];
  }

  /** Retorna los checkbox de la tabla activa */
  function getSelectedCheckboxs() {
    const datatable = getActiveDatatable();
    const storeInput = datatable.find('[data-dt-saved-selects]');
    const checkboxName = storeInput.data('dtSavedSelects');
    return datatable.find(
      `input[name="${checkboxName}"][type=checkbox]:checked`
    );
  }

  /** Retorna el tipo de todos los documentos seleccionados en la tabla */
  function getSelectedDocTypes() {
    return getSelectedCheckboxs()
      .map(function () {
        return this.getAttribute('document_type');
      })
      .toArray();
  }

  /** Retorna el el rol de firmante del usuario de todos los documentos seleccionados en la tabla */
  function getSelectedDocSignerRole() {
    return getSelectedCheckboxs()
      .map(function () {
        return this.getAttribute('document_signer_role');
      })
      .toArray();
  }

  /** Desde los seleccionados retorna los eliminables */
  function getDeleteableIds() {
    return getSelectedCheckboxs()
      .map(function () {
        if (this.getAttribute('data-destroyable') === 'true') {
          return this.getAttribute('value').toString();
        }
      })
      .filter((id) => id !== undefined)
      .toArray();
  }

  /** Desde los seleccionados retorna los restaurables */
  function getRestorableIds() {
    return getSelectedCheckboxs()
      .map(function () {
        if (this.getAttribute('data-restorable') === 'true') {
          return this.getAttribute('value').toString();
        }
      })
      .filter((id) => id !== undefined)
      .toArray();
  }

  function getSigneeNotificableIds() {
    return getSelectedCheckboxs()
      .map(function () {
        if (this.getAttribute('signee_notificable') === 'true') {
          return this.getAttribute('value').toString();
        }
      })
      .filter((id) => id !== undefined)
      .toArray();
  }

  function getReviewNotificableIds() {
    return getSelectedCheckboxs()
      .map(function () {
        if (this.getAttribute('review_notificable') === 'true') {
          return this.getAttribute('value').toString();
        }
      })
      .filter((id) => id !== undefined)
      .toArray();
  }

  function notificateSignees(e) {
    const documentIds = getSelectedIds();
    const notificableIds = getSigneeNotificableIds();
    var nonNotificableCount = documentIds.length - notificableIds.length;
    post($(e.target).data('url'), documentIds, 'notificate_signees', {
      nonNotificableCount: nonNotificableCount,
      notificableIds: notificableIds
    });
  }

  function notificateReivewers(e) {
    const documentIds = getSelectedIds();
    const notificableIds = getReviewNotificableIds();
    const nonNotificableCount = documentIds.length - notificableIds.length;
    post($(e.target).data('url'), documentIds, 'notificate_reviewers', {
      nonNotificableCount: nonNotificableCount,
      notificableIds: notificableIds,
    });
  }

  /** Retorna la tabla del tab activo */
  function getActiveDatatable() {
    return $('div.tab-pane.active').find('table[data-dt-multi-select]');
  }

  /** Retorna el anchor actual para ser redirigido después del request */
  function currentAnchor() {
    return window.location.hash.replace('#', '');
  }

  /** Retorna la tabla del tab activo */
  function getPayloadKey(actionType, documentIds) {
    var anchor = currentAnchor();
    if (actionType === 'delete' || actionType === 'soft_delete') {
      return {
        mass_delete: {
          document_ids: documentIds,
          current_anchor: anchor
        }
      };
    }
    else if (actionType === 'restore') {
      return {
        mass_restore: {
          document_ids: documentIds,
          current_anchor: anchor
        }
      };
    }

    if (actionType === 'hide_documents') {
      return {
        mass_hide: {
          document_ids: documentIds,
          current_anchor: anchor
        }
      };
    }

    if (actionType === 'unhide_documents') {
      return {
        mass_unhide: {
          document_ids: documentIds,
          current_anchor: anchor
        }
      };
    }

    if (actionType === 'notificate_reviewers') {
      return {
        mass_review: {
          document_ids: documentIds,
        },
      };
    }
    return {
      mass_signature: {
        document_ids: documentIds,
        referer: 'documents',
      }
    };
  }

  /** Alerta de flujo de eliminación masiva e individual */
  function firstStepDeleteMessage(documentIds) {
    const documentCount = documentIds.length;
    const message = documentCount === 1
      ? `¿Deseas <a style="color:red">eliminar 1 documento</a> permanentemente?`
      : `¿Deseas <a style="color:red">eliminar ${documentCount} ${plural(documentCount, 'documento')}</a>` +
      ` permanentemente?`;

    return action(
      `<p style="text-align:center"> ${message} </p>`,
      'Eliminar',
      'Cancelar',
      'warning',
      `<p style="text-align:center">Esta acción es irreversible, ${
        documentCount === 1 ? 'el documento no se podrá recuperar' : 'los documentos no se podrán recuperar'
      }.</p>`,
      'btn-destructive'
    );
  }

  /** Segunda alerta de flujo de eliminación con ingreso de palabra de confirmación */

  /** Alerta en caso de error en palabra de confirmación */
  function retryDeleteMessage(documentIds) {
    return action(
      '<p style="text-align:center">Palabra incorrecta </p>',
      'Confirmar',
      'Cancelar',
      'warning',
      `<p style="text-align:center">Intenta nuevamente para <a style="color:red">eliminar
        ${documentIds.length} ${plural(
  documentIds.length,
  'documento'
)}</a> definitivamente. </p>
        <p style="text-align:center"><input class="form-control" type="text" id="retry-confirmation-word"></p>`,
      'btn-destructive'
    );
  }

  /** Primera alerta de flujo de enviar a la papelera */
  function softDeleteMessage(documentIds) {
    return action(
      `<p style="text-align:center"> ¿Estás seguro que deseas eliminar \
        ${documentIds.length} ${plural(
  documentIds.length,
  'documento'
)} ? </p>`,
      'Eliminar',
      'Cancelar',
      'warning',
      null,
      'btn-destructive'
    );
  }

  /** Alerta en caso de intentar eliminar elementos no eliminables*/
  function nonDeleteableMessage(countDoc, documentIds) {
    const allUndeleteable = countDoc === documentIds.length;
    return action(
      `<p style="text-align:center">
        ${countDoc} ${plural(countDoc, 'documento')} no se ${plural(
  countDoc,
  'puede'
)} enviar a la papelera, \
        ya que se ${plural(countDoc, 'encuentra')} ${plural(
  countDoc,
  'firmado'
)} en su totalidad. </p>`,
      'Continuar',
      'Cancelar',
      'warning',
      allUndeleteable
        ? null
        : '<p style="text-align:center">Presiona continuar para enviar a la papelera aquellos \
        documentos que si se podrán eliminar</p>',
      'btn-destructive'
    );
  }

  function nonRestorableMessage(countDoc, documentIds) {
    const allUnrestorable = countDoc === documentIds.length;
    return action(
      `<p style="text-align:center">
        ${countDoc} ${plural(countDoc, 'documento')} no ${plural(
  countDoc,
  'puede'
)} ser \
        ${plural(countDoc, 'restaurado')}, ya que tiene relación con ${plural(
  countDoc,
  'una entidad'
)} \
        ${plural(countDoc, 'eliminada')}</p>`,
      'Continuar',
      'Cancelar',
      'warning',
      allUnrestorable
        ? null
        : '<p style="text-align:center">Presiona continuar para restaurar aquellos \
        documentos que si pueden ser restaurados</p>',
      'btn-destructive'
    );
  }

  function nonNotificableMessage(countDoc, documentIds) {
    const allNonNotificable = countDoc === documentIds.length;
    return action(
      `<p style="text-align:center">
        Has seleccionado archivos con plantillas obsoletas o sin firmantes configurados`,
      'Continuar',
      'Cancelar',
      'warning',
      allNonNotificable
        ? null
        : '<p style="text-align:center">Presiona continuar para notificar aquellos \
        firmantes que si pueden ser notificados</p>',
      'btn-destructive'
    );
  }

  /** Valida que la palabra de confirmación sea correcta */
  function validateConfirmationWord(confirmationWord, requestParams) {
    if (confirmationWord.trim().toUpperCase() !== 'ELIMINAR') {
      $('html').trigger(
        'amplitudeTracking',
        // eslint-disable-next-line max-len
        {
          message: 'hard_delete_final_confirmation_error',
          data: {
            quantity: requestParams.documentIds.length,
            ids: requestParams.documentIds,
            word: confirmationWord
          }
        }
      );
      retryDeleteMessage(requestParams.documentIds).then(function (
        retryResponse
      ) {
        if (retryResponse.value) {
          const $retryConfirmationWord = $('#retry-confirmation-word');
          return validateConfirmationWord(
            $retryConfirmationWord.val(),
            requestParams
          );
        }
      });
    }
    if (confirmationWord.trim().toUpperCase() === 'ELIMINAR') {
      // eslint-disable-next-line max-len
      $('html').trigger('amplitudeTracking', {
        message: 'hard_delete_final_confirmation',
        data: {
          quantity: requestParams.documentIds.length,
          ids: requestParams.documentIds,
          word: confirmationWord
        }
      });
      doRequest(
        requestParams.url,
        requestParams.actionType,
        requestParams.documentIds
      ).done(function (data) {
        // eslint-disable-next-line max-len
        $('html').trigger('amplitudeTracking', {
          message: 'hard_delete_success',
          data: {
            quantity: requestParams.documentIds.length,
            ids: requestParams.documentIds,
            word: confirmationWord
          }
        });
        data;
      });
      return true;
    }
  }

  /** Pluraliza palabras */
  function plural(objectSize, word) {
    const plurals = {
      documento: 'documentos',
      puede: 'pueden',
      restaurarlo: 'restaurarlos',
      encuentra: 'encuentran',
      firmado: 'firmados',
      'una entidad': 'entidades',
      eliminada: 'eliminadas'
    };

    return objectSize > 1 ? plurals[word] : word;
  }

  function doRequest(url, actionType, documentIds) {
    return $.ajax({
      url: url,
      method: 'post',
      data: getPayloadKey(actionType, documentIds)
    });
  }

  /** Ejecuta el flujo de soft delete */
  function softDeleteFlow(url, documentIds, actionType) {
    softDeleteMessage(documentIds).then(function (res) {
      if (res.value) {
        // eslint-disable-next-line max-len
        $('html').trigger('amplitudeTracking', {
          message: 'send_to_paperbin_confirmation',
          data: {
            source: 'documentos',
            quantity: documentIds.length,
            ids: documentIds
          }
        });
        doRequest(url, actionType, documentIds).done(function (data) {
          // eslint-disable-next-line max-len
          $('html').trigger('amplitudeTracking', {
            message: 'send_to_paperbin_success',
            data: {
              source: 'documentos',
              quantity: documentIds.length,
              ids: documentIds
            }
          });
          data;
        });
      }
    });
  }

  /** Envía la información mediante POST */
  function post(url, documentIds, actionType = 'sign', extraData = {}) {
    if (actionType === 'delete') {
      firstStepDeleteMessage(documentIds).then(function (res) {
        if (res.value) {
          $('html').trigger('amplitudeTracking', {
            message: 'hard_delete_confirmation',
            data: { quantity: documentIds.length, ids: documentIds }
          });
          validateConfirmationWord('eliminar', {
            url: url,
            actionType: actionType,
            documentIds: documentIds,
          });
        }
      });
    }
    else if (actionType === 'soft_delete') {
      // si hay documentos no eliminables seleccionados se muestra el modal
      if (extraData.nonDeleteableCount > 0) {
        nonDeleteableMessage(extraData.nonDeleteableCount, documentIds).then(
          function (res) {
            // Cuando todos los elementos seleccionados son no eliminables no se sigue con el flujo
            if (
              res.value &&
              extraData.nonDeleteableCount !== documentIds.length
            ) {
              if (extraData.deleteableIds.length > 0) {
                // eslint-disable-next-line max-len
                $('html').trigger('amplitudeTracking', {
                  message: 'send_to_paperbin_confirmation_unsigned_documents',
                  data: {
                    source: 'documentos',
                    quantity: extraData.deleteableIds.length,
                    ids: extraData.deleteableIds
                  }
                });
              }
              softDeleteFlow(url, extraData.deleteableIds, actionType);
            }
          }
        );
      }
      else {
        softDeleteFlow(url, documentIds, actionType);
      }
    }
    else if (actionType === 'restore') {
      // si hay documentos no restaurables seleccionados se muestra el modal
      if (extraData.nonRestorableCount > 0) {
        nonRestorableMessage(extraData.nonRestorableCount, documentIds).then(
          function (res) {
            // Cuando todos los elementos seleccionados son no restaurables no se sigue con el flujo
            if (
              res.value &&
              extraData.nonRestorableCount !== documentIds.length
            ) {
              doRequest(url, actionType, extraData.restorableIds).done(function (
                data
              ) {
                $('html').trigger(
                  'amplitudeTracking',
                  // eslint-disable-next-line max-len
                  {
                    message: 'restore_documents_success',
                    data: {
                      quantity: extraData.restorableIds.length,
                      ids: extraData.restorableIds
                    }
                  }
                );
                data;
              });
            }
          }
        );
      }
      else {
        doRequest(url, actionType, documentIds).done(function (data) {
          $('html').trigger('amplitudeTracking', {
            message: 'restore_documents_success',
            data: { quantity: documentIds.length, ids: documentIds }
          });
          data;
        });
      }
    }
    else if (actionType === 'notificate_signees') {
      if (extraData.nonNotificableCount > 0) {
        nonNotificableMessage(extraData.nonNotificableCount, documentIds).then(
          function (res) {
            // Cuando todos los elementos seleccionados son no notificable no se sigue con el flujo
            if (
              res.value &&
              extraData.nonNotificableCount !== documentIds.length
            ) {
              doRequest(url, actionType, extraData.notificableIds);
            }
          }
        );
      }
      else {
        doRequest(url, actionType, documentIds);
      }
    }
    else {
      doRequest(url, actionType, documentIds).done(function (data) {
        data;
      });
    }
  }
});

/** Luego de que el componente de botones compuestos se cambió de lugar se debe cargar despues del filtro */
/** Para evitar que se muestre sobre otros elementos */
onmount('.filter-table', function () {
  const $alert = $('.alert.alert_info.text-left.alert__scroll');
  const $actionsBtn = $('#cont_botones_compuestos');
  $('#cont_botones_compuestos').removeClass('hidden');
  positionActionsBtn();

  function positionActionsBtn() {
    if (
      $('.document-signatures')
        .find('.nav-link.active')
        .attr('href') === '#recycling-bin-tab'
    ) {
      const topPos =
        $alert.outerHeight(true) +
        $('.nav.nav-tabs').outerHeight(true) +
        parseInt($('.tab-content').css('padding-top')) +
        16;
      $actionsBtn.css('top', topPos);
    }
    else {
      $actionsBtn.css(
        'top',
        $('.nav.nav-tabs').outerHeight(true) +
          parseInt($('.tab-content').css('padding-top'))
      );
    }
  }
});

/*Amplitude analitics*/
onmount('[id^="signature-document-"][data-status="signed"]', function () {
  const $signatureDocumentForm = $(this);
  const amplitude = $signatureDocumentForm.data('amplitude');

  if (amplitude) {
    $signatureDocumentForm.trigger('amplitudeTracking', {
      message: amplitude.event,
      data: amplitude.data,
    });
  }
});

// Sign success tracking
onmount('[id^="signature-document-"][data-status="signed"]', function () {
  const $signatureSuccess = $('#success-info');
  const amplitude = $signatureSuccess.data('amplitude');

  if (amplitude) {
    $signatureSuccess.trigger('amplitudeTracking', {
      message: amplitude.message,
      data: amplitude.data,
    });
  }
});

// Mail start signature flow tracking
$('#sign_document_modal').on('click.amplitude', function () {
  const $signatureDocumentForm = $('#data-amplitude');
  const amplitude = $signatureDocumentForm.data('amplitude');

  if (amplitude?.data?.source === 'mail') {
    $signatureDocumentForm.trigger('amplitudeTracking', {
      message: 'document_signed_button_clicked',
      data: amplitude.data,
    });
  }
  $('#sign_document_modal').off('click.amplitude');
});

$('#direct_signature_modal').on('click.amplitude', function () {
  const $signatureDocumentForm = $('#data-amplitude');
  const amplitude = $signatureDocumentForm.data('amplitude');

  if (amplitude?.data?.source === 'mail') {
    $signatureDocumentForm.trigger('amplitudeTracking', {
      message: 'document_signed_button_clicked',
      data: amplitude.data,
    });
  }
  $('#direct_signature_modal').off('click.amplitude');
});

/*Envia el marcaje a amplitude */
function sendSignedButtonClicked(e, source, documentIds, documentType, documentSigners = []) {
  // eslint-disable-next-line max-len
  $(e.target).trigger('amplitudeTracking', {
    message: 'document_signed_button_clicked',
    data: {
      source: source,
      document_batch: documentIds.length,
      document_type: documentType,
      document_signer_role: documentSigners,
    },
  });
}

/*Permite obtener el ID basandose en la url del target seleccionado*/
function getIdFromUrl(documentUrl) {
  return parseInt(documentUrl.split('/').pop());
}

function getDocTypeFromRow(row) {
  return row.getElementsByTagName('input')[0].attributes.document_type.value;
}

function getDocSignerFromRow(row) {
  return row.getElementsByTagName('input')[0]?.attributes?.document_signer_role?.value;
}

function reviewDocument() {
  $(this).on('click', function () {
    const button = $(this);
    const formId = button.data('form-id');
    const reviewValue = button.data('review-status');
    const hiddenInput = $('#employee_file_review_status');
    hiddenInput.val(reviewValue);
    document.getElementById(formId).requestSubmit();
  });
}

onmount('#review_approved_document_button', reviewDocument);
onmount('#review_rejected_document_button', reviewDocument);

onmount('#get-counts-url', function () {
  const mapKeys = {
    'pending-signee-tab': 'total_sign_pending',
    'tab-bandeja-de-entrada': 'total_sign_pending',
    'all-pending-tab': 'total_pending_documents',
    'reviewer-tab': 'total_review_pending',
  };
  const url = $(this).data('url');
  $.ajax({
    url,
    method: 'get',
    success(response) {
      Object.keys(mapKeys).forEach(tabId => {
        const key = mapKeys[tabId];
        let value = parseInt(response[key]) || 0;
        if (value) {
          if (value > 99) value = '99+';
          const badge = $('<div>').text(value);
          badge.addClass('chip badge badge-danger mx-1');
          $(`a[href="#${tabId}"]`).append(badge);
        }
      });
    },
  });
});

onmount('#document_signature_channel_type_email', function () {
  $(this).on('click', function () {
    $('#document_signature_selected_channel').val($(this).val());
  });
});

onmount('#document_signature_channel_type_sms', function () {
  $(this).on('click', function () {
    $('#document_signature_selected_channel').val($(this).val());
  });
});

function pad(num) {
  return num < 10 ? '0' + num : num;
}

onmount('#single_sign_resend_code_time_remaining', async function () {
  // Los elementos inician deshabilitados (opción Reenviar código y countdown)
  const disabledTextColor = 'text-basic-500';
  const timeToWaitInSeconds = 180; // 3 minutos

  var resendCodeLink = $('#resend_code_link_to');
  var resendCodeTimeRemaining = $('#resend_code_time_remaining_display');

  var url = resendCodeLink.attr('href');
  resendCodeLink.addClass(disabledTextColor);
  resendCodeLink.removeAttr('href');
  resendCodeTimeRemaining.addClass(disabledTextColor);

  var signatureCodeSendAtDate = new Date($('#signature_code_send_at').val());

  // Se muestra en pantalla un countdown con el tiempo que falta para poder reenviarse el código
  var countdDown = setInterval(countDownTimer, 1000);

  function countDownTimer() {
    try {
      var distance = new Date().getTime() - signatureCodeSendAtDate.getTime();
      distance = (timeToWaitInSeconds * 1000) - distance;
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      var displayTime = pad(minutes) + ':' + pad(seconds);
      resendCodeTimeRemaining.html('en ' + displayTime);

      // Si el tiempo que faltaba llegó a 0, se habilita la opción Reenviar código y se desaparece el countdown
      if (distance < 0) {
        resendCodeLink.removeClass(disabledTextColor);
        resendCodeLink.attr('href', url);
        resendCodeTimeRemaining.html('');
        clearInterval(countdDown);
      }
    }
    catch (e) {
      clearInterval(countdDown);
    }
  }
});
