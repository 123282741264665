/** @file TODO: documentar */
import onmount from 'onmount';

onmount(".toggle-linea-liq-form", function () {
  //inicializamos los input disabled para evitar errores de focusable cuando no hay linea extra.
  $("#lineas-liquidacion-form :input").attr("disabled", true);
  $(this).click(function () {
    var form = $("#lineas-liquidacion-form")[0];
    if (form.style.display === "none") {
      form.style.display = "block";
      $(this).attr('value', 'No agregar nueva linea');
      $("#lineas-liquidacion-form :input").attr("disabled", false);
      $(this).addClass('btn-destructive');
      $(this).removeClass('btn-primary');
    }
    else {
      form.style.display = "none";
      $("#lineas-liquidacion-form :input").attr("disabled", true);
      $(this).attr('value', 'Agregar Línea');
      $(this).addClass('btn-primary');
      $(this).removeClass('btn-destructive');
    }
  });
});
