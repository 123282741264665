/** @file Genera la descarga de un archivo generado asíncronamente */

import onmount from 'onmount';
import { isInDom } from '../../../../../../../../app/assets/javascripts/lib/interaction-functions';
import { isUndefined } from 'lodash-es';

onmount('[data-background-file-generation-progress]', async function () {
  const url = $(this).data('backgroundFileGenerationProgress');
  pollStatus(this, url);
});

/** pollea el status, y descarga el archivo si se completó */
function pollStatus(self, url) {
  $.getJSON({ url }).done((res) => {
    if (res?.status === 'completed') {
      setDownloadText(self, res);
      downloadFile(res.download_url);
    }
    else if (res?.status === 'failed') {
      setErrorMessage(self, res.error_message);
    }
    else if (isInDom(self)) { // dejamos de pollear si nos cerraron
      setTimeout(() => pollStatus(self, url), 3000);
    }
  });
}

/** descarga el archivo, usando la propiedad download de html5 */
function downloadFile(uri) {
  const link = document.createElement("a");
  link.href = uri;
  link.download = '';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function setErrorMessage(self, message) {
  toggleMessage(self, message);
  $(self).find('.result-link').addClass('alert alert-danger');
}

/** oculta el loader y muestra el texto de descarga */
function setDownloadText(self, res) {
  const downloadDiv = `<a href="${res.download_url}" download>${res.download_message}</a>.`;
  const message = `${res.success_message} ${downloadDiv}`;
  toggleMessage(self, message);
  if (!isUndefined(res.extra_message)) {
    toggleExtraMessage(self, res.extra_message);
  }
}

function toggleMessage(self, message) {
  $(self).find('.result-link').html(message).show();
  $(self).find('.buk-loader-message').hide();
  $(self).find('.buk-loader').hide();
}

function toggleExtraMessage(self, message) {
  $(self).find('.result-extra-message .alert_container__content').html(message);
  $(self).find('.result-extra-message').show();
}
