/** @file
* Archivo para interacciones responsive en solicitud vacaciones
*/
import onmount, { $ } from 'onmount';

onmount('#vacacion_vacation_type_id', function () {
  $(this).on('change', function () {
    var vacationSummaryIsCollapsed = $('#collapse-button').attr('aria-expanded');
    if (vacationSummaryIsCollapsed === 'false') {
      $('#collapse-button').trigger('click');
    }

    var selectedValue = $(this).find('option:selected').text().trim();
    var selectedValueIndex = $(this).find('option:selected').index();
    var hashCategorias = $('#alert_vacacion_dias_disponibles_categoria').data('dias')
      .replace(/\{|\}|"/gi, '')
      .split(',');

    var hashDiasPorCategoria = {};
    hashCategorias.forEach ((elemento) => {
      var [nombreCategoria, diasCategoria] = elemento.split('=>');
      hashDiasPorCategoria[nombreCategoria.trim()] = diasCategoria;
    });

    if (selectedValueIndex !== 0) {
      $('#alert_vacacion_dias_disponibles_categoria').find('b.numero-dias').text(hashDiasPorCategoria[selectedValue]);
    }
  });
});
