/** @file Archivo utilizado para validar el monto a solicitar */
import onmount from 'onmount';
import { formatCurrencyByCountry } from '../../../../../../../app/assets/javascripts/lib/buk/format-currency';

onmount('#request_amount', function () {
  $('#request_amount').on('keyup input', { source: 'liquidaciones' }, validateAmount);
  $('#request_amount').on('keyup input', { source: 'liquidaciones' }, fillRequestAdvanceAmount);
  $('#request_amount').on('keypress', key => disableSendForm(key));
});

onmount('#request_advance_amount', function () {
  $('#request_advance_amount').on('keyup input', { source: 'modal' }, validateAmount);
  $('#request_advance_amount').on('keypress', key => disableSendForm(key));
});

function fillRequestAdvanceAmount() {
  const value = $(this).val() * 1;

  $('#request_advance_amount').val(value);
}

function validateAmount(event) {
  const value = $(this).val() * 1;
  const min = $(this).attr('min');
  const max = $(this).attr('max');
  const step = $(this).attr('step');
  const country = $(this).attr('country');

  const isValueValid = value % step === 0;
  const isAmountInBounds = value >= min && value <= max;

  if (event.data.source === 'liquidaciones') {
    habilitarBotonSolicitar(isAmountInBounds && isValueValid);
  }

  updateAdvanceAmountConfirmation(value);
  updateAdvanceAmountSummary(value, country);
  enableNextButtons(isAmountInBounds && isValueValid);
  updateAuthorizationCodeAmountRequested(value);
}

function habilitarBotonSolicitar(isAmountValid) {
  $('#boton-solicitar-adelantos').attr('disabled', !isAmountValid);
}

function updateAdvanceAmountConfirmation(value) {
  $('#advance_amount').val(value);
}

function updateAdvanceAmountSummary(value, country) {
  const formatedCurrency = formatCurrencyByCountry(value, country);
  $('#advance-summary-requested-ammount').html(formatedCurrency);
}

function enableNextButtons(isAmountValid) {
  $('#advances-request-button-next').prop('disabled', !isAmountValid);
  $('#advances-summary-button-next').prop('disabled', !isAmountValid);
  $('#advances-confirm-button-back').prop('disabled', !isAmountValid);
}

function updateAuthorizationCodeAmountRequested(value) {
  $('#adelantos_amount').val(value);
}

function disableSendForm(key) {
  if (key.keyCode === 13) {
    return false;
  }
}
