/** @file
 * El archivo describe el comportamiento del componente paginador, compuesto de un selector y dos flechas de desplazamiento.
 * Las acciones que operan son:
 * 1. Avanzar o retroceder una posición con las flechas (cambia el contenido seleccionado para el Select)
 * 2. Detectar el cambio de selección y modificar el texto de la posición.
 * 3. Evaluar si se debe bloquear o desbloquear mientras se espera o recibe la carga de una página
 * 4. Bloquear los modificadores mientras se espera la carga de la página
 *
 * Para usar el componente se debe agregar la clase paginator-component
 */
import onmount, { $ } from 'onmount';

onmount('.paginator-component', function () {
  const $mainComponent = $(this);
  const elementSelector = $mainComponent.find('.paginator-select');
  let previousButton = $mainComponent.find('.paginator-previous-button button');
  let nextButton = $mainComponent.find('.paginator-next-button button');

  [previousButton, nextButton] = addExtraButtons($mainComponent, previousButton, nextButton);
  evaluateElementBlock($mainComponent, previousButton, nextButton);
  nextPage($mainComponent, nextButton, elementSelector);
  previousPage($mainComponent, previousButton, elementSelector);
  updatePosition($mainComponent, elementSelector);
  updateCurrentElementId($mainComponent, elementSelector);
  resyncUpdatePosition($mainComponent);
});

/**
 * Permite agregar nuevos botones de anterior y siguiente al componente mediante atributos data en el botón
 * que apunten al .paginator-component.
 *
 * para anterior: data-paginator-extra-previous-button="<selector css del .paginator-component>"
 * para siguiente: data-paginator-extra-next-button="<selector css del .paginator-component>"
 *
 * @param {Jquery} mainComponent Componente principal (.paginator-component)
 * @param {Jquery} previousButton Botón de anterior del componente principal
 * @param {JQuery} nextButton Botón de siguiente del comnponente principal
 *
 * @returns {Array<Jquery>} previousButton y nextButton actualizados.
 */
function addExtraButtons(mainComponent, previousButton, nextButton) {
  //Botones tipo 'Anterior'
  $('[data-paginator-extra-previous-button]').each(function (_, element) {
    const targetPaginator = $($(element).data('paginator-extra-previous-button'));
    if (targetPaginator[0] === mainComponent[0]) {
      $(element).addClass('disabled-request');
      previousButton = previousButton.add($(element));
    }
  });

  //Botones tipo 'Siguiente'
  $('[data-paginator-extra-next-button]').each(function (_, element) {
    const targetPaginator = $($(element).data('paginator-extra-next-button'));
    if (targetPaginator[0] === mainComponent[0]) {
      $(element).addClass('disabled-request');
      nextButton = nextButton.add($(element));
    }
  });

  return [previousButton, nextButton];
}

/**
 * Detecta el evento beforeSend.ic y complete.ic de Intercooler, para decidir si bloquear o desbloquear los elementos
 * gráficos mediante la función blockUI.
 *
 */
function evaluateElementBlock(mainComponent, previousButton, nextButton) {
  mainComponent.find('.disabled-request').on('beforeSend.ic', function () {
    validatRemoveContentByIndicator(mainComponent);
    blockUI(true, mainComponent, previousButton, nextButton);
  }).on('complete.ic', function () {
    blockUI(false, mainComponent, previousButton, nextButton);
  });
}

/**
 * Función que permite cambiar selección de elemento Select a la siguiente opción dentro de él
 * (siempre y cuando tenga una opción siguiente) y gatillar el evento change sobre el mismo.
 *
 * @param {Element} mainComponent Contenedor principal del componente
 * @param {Element} nextButton Elemento que permite desplazarse una posición hacia adelante en el selector
 * @param {Element} elementSelector Elemento selector de opciones
 * @returns {Event} Evento onChange sobre elemento selector, posterior a actualizar su posición.
 */
function nextPage(mainComponent, nextButton, elementSelector) {
  nextButton.on('click', function () {
    if(getCurrentElementPosition(mainComponent) !== parseInt(mainComponent.attr('data-stop-position'))) {
      const nextValue = mainComponent.find('.paginator-select option:selected').next().val();
      elementSelector.val(nextValue).trigger('change');
    }
  });
}

/**
 * Función que, por medio del status entregado, determina si debe bloquear o desbloquar componentes que gatillan acciones de cambio
 * Para el caso de un elemento que sea un input, bastará con la propiedad disabled:
 * - Para status 'true' deshabilita y para status 'false' habilita.
 * Para el caso de un elemento distinto a un input, se debe modificar el atributo pointer-events:
 * - Para status 'true' no permite eventos y para status 'false' permite todos.
 * @param {Boolean} status
 * @param {Jquery} mainComponent Componente principal (.paginator-component)
 * @param {Jquery} previousButton Botón de anterior del componente principal
 * @param {JQuery} nextButton Botón de siguiente del comnponente principal
 * @returns {void}
 */
function blockUI(status, mainComponent, previousButton, nextButton) {
  $('.disabled-request').each(function (_i, obj) {
    $(obj).prop('disabled', status);
    $(obj).css('pointer-events', status ? 'none' : 'all');
  });

  const firstPage = getCurrentElementPosition(mainComponent) === 1;
  previousButton.prop('disabled', firstPage || status);

  const lastPage = getCurrentElementPosition(mainComponent) === parseInt(mainComponent.attr('data-stop-position'));
  nextButton.prop('disabled', lastPage || status);

  /**
   * Para cualquier dependencia del paginador donde se necesite activar otra acción
   * al inicio o al final del paginador, se incluiran eventos para la primera pagina,
   * paginas intermedias y pagina final.
   */
  if (firstPage) {
    mainComponent.trigger("paginator.firstPage");
  }
  else if (lastPage) {
    mainComponent.trigger("paginator.lastPage");
  }
  else {
    mainComponent.trigger("paginator.middlePage");
  }
}

/**
 * Función que permite cambiar selección de elemento Select a la opción posterior dentro de él
 * (siempre y cuando tenga una opción anterior) y gatillar el evento change sobre el mismo.
 *
 * @param {Element} mainComponent Contenedor principal del componente
 * @param {Element} previousButton Elemento que permite desplazarse una posición hacia atrás en el selector
 * @param {Element} elementSelector Elemento selector de opciones
 * @returns {Event} Evento onChange sobre elemento selector, posterior a actualizar su posición.
 */
function previousPage(mainComponent, previousButton, elementSelector) {
  previousButton.on('click', function () {
    if(getCurrentElementPosition(mainComponent) > 1) {
      const previousValue = mainComponent.find('.paginator-select option:selected').prev().val();
      elementSelector.val(previousValue).trigger('change');
    }
  });
}

/**
 * Función que permite detectar evento onChange sobre el selector de opciones y, al hacerlo,
 * actualizará la posición actual de acuerdo a la opción que actualmente esté marcada como seleccionada.
 *
 * @param {any} mainComponent Contenedor principal del componente
 * @param {any} elementSelector Elemento selector de opciones
 * @returns {void}
 */
function updatePosition(mainComponent, elementSelector) {
  elementSelector.on('change', function () {
    resyncUpdatePosition(mainComponent);
  });
}

/**
 * Sincroniza la posicion actual
 * @param {Element} mainComponent Contenedor principal del componente
 * @returns {void}
 */
function resyncUpdatePosition(mainComponent) {
  mainComponent.attr('data-current-position', mainComponent.find('.paginator-select').prop('selectedIndex') + 1);
}

/**
 * Actualiza el id del elemento actual
 * @param {Element} mainComponent Contenedor principal del componente
 * @param {Element} elementSelector Elemento selector de opciones
 */
function updateCurrentElementId(mainComponent, elementSelector) {
  elementSelector.on('complete.ic', function () {
    mainComponent.find('#current_element_id').val($(this).val());
  });
}

/**
 * Funcion para obtener la posición del elemento actual
 * @param {Element} mainComponent Contenedor principal del componente
 * @returns {Int} Posición del elemento
 **/
function getCurrentElementPosition(mainComponent) {
  return parseInt(mainComponent.attr('data-current-position'));
}

/*
* Verifica si el ic-indicator existe y remueve el contenido del selector previo que es indicado por el ic-target
*/
function validatRemoveContentByIndicator(mainComponent) {
  const selectPaginator = mainComponent.find('select');
  const icIndicator = selectPaginator.attr('ic-indicator');
  const icTarget =  selectPaginator.attr('ic-target');
  if(icIndicator !== '.paginator-indicator') {
    $(icTarget).html('');
  }
}
