/** @file Permite configuracion custom de colorpicker en Parametros Generales
 * Se asigna el valor de colorpicker a los campos de color corresponidentes
 * Cuando se envía colorpicker por defecto, se cambia el valor debido a validaciones custom_color
 */

import onmount from 'onmount';

const colorDefault = '#000000';

function rgbToHex(rgb) {
  return (`#${rgb
    .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
    .slice(1)
    .map(n =>
      parseInt(n, 10)
        .toString(16)
        .padStart(2, '0')
    )
    .join('')}`);
}

const getContentRgb = (string) => {
  const regExp = /\(([^)]+)\)/;
  return regExp.exec(string)[1];
};

const menuVersion = $('body').hasClass('sidebar_v2') ? 'menu_v2' : 'menu_v1';

const asignarPickerColor = () => {
  const navBarColor = $('.navbar')?.css('background-color');
  const navBarContenido = $('.nav-link-text')?.css('color');
  const menuColor = $('.sidebar__menu')?.css('background');
  const menuContenido = $('.sidebar__menu__title')?.css('color');
  //variantes v1
  const menuColorV1 = $('.main-sidebar')?.css('background');
  const menuContenidoV1 = $('.sidebar-menu.tree > li a')?.css('color');

  if (menuVersion === 'menu_v1') {
    $('#custom_color_navbar_v2').val(rgbToHex(`rgb(${getContentRgb(navBarColor)})`));
    $('#custom_color_navbar_fonts_v2').val(rgbToHex(`rgb(${getContentRgb(navBarContenido)})`));
    $('#custom_color_menu_v2').val(rgbToHex(`rgb(${getContentRgb(menuColorV1)})`));
    $('#custom_color_menu_fonts_v2').val(rgbToHex(`rgb(${getContentRgb(menuContenidoV1)})`));
  }
  else{
    $('#custom_color_navbar_v2').val(rgbToHex(`rgb(${getContentRgb(navBarColor)})`));
    $('#custom_color_navbar_fonts_v2').val(rgbToHex(`rgb(${getContentRgb(navBarContenido)})`));
    $('#custom_color_menu_v2').val(rgbToHex(`rgb(${getContentRgb(menuColor)})`));
    $('#custom_color_menu_fonts_v2').val(rgbToHex(`rgb(${getContentRgb(menuContenido)})`));
  }
};

onmount('input[type="color"]', function () {
  asignarPickerColor();
  //cambia el valor para validacion de color por defecto
  $(this).on('change', function () {
    if ($(this).val() === colorDefault) {
      $(this).val('#010101');
    }
  });
});
