/** @file TODO: documentar */
/**
 * Muestra el contenido de acuerdo a la opción seleccionada de un elemento select.
 * Similar a bootstrap navs tabs https://getbootstrap.com/docs/4.6/components/navs/#javascript-behavior
 *
 * Cómo funciona:
 *
 * - Agregar data-provide="select-toggle" al select
 * - Cada option del select debe contener un atributo data-select-toggle-target con el id del elemento a mostrar
 * - Los elementos a mostrar deben tener clase tab-pane dentro de un elemento tab-content (como en bootstrap navs)
 *
 * Ejemplo:
 *
 * <select data-provide="select-toggle">
 *   <option data-select-toggle-target="target_1" value="val_1">One</option>
 *   <option data-select-toggle-target="target_2" value="val_2">Two</option>
 * </select>
 *
 * <div class="tab-content">
 *   <div id="target_1" class="tab-pane fade show active">Content for ONE</div>
 *   <div id="target_2" class="tab-pane fade">Content for TWO</div>
 * </div>
 */
import onmount from 'onmount';

onmount('[data-provide="select-toggle"]', async function () {
  $(this).on('change', function () {
    const options = $('option', this);
    const selectedOptionValue = options.eq(this.selectedIndex).val();

    options.each(function () {
      const option = $(this);
      const currentValue = option.val();
      const target =  $(`#${option.data('selectToggleTarget')}`);

      if (currentValue === selectedOptionValue) {
        target.addClass('show active');
      }
      else {
        target.removeClass('show active');
      }
    });
  });
});
