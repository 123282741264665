/**
 * @file Permite ordenar una lista usando list.js (librería externa) https://listjs.com/
 *
 * Para usar, debe seguirse la siguiente estructura en el HTML:
 *
 * 1. Un elemento con el atributo data-sort-list. Este debe contener todos los otros elementos mencionados abajo.
 * 2. El componente sort_widget. Este desplegará un selector y un boton para elegir por
 *    que campo ordenar y en que sentido ordenar
 * 3. Un elemento con la clase `list`. Este elemento debe contener la lista que nos interesa
 *    ordenar. Se considera cada hijo de este elemento, como un item en la lista.
 *
 * Este componente permite ordenar elementos ya mostrados en el front o que serán consultados en el back:
 *
 * Ejemplo para ordenamiento en el frontend
 * <div data-sort-list=""
 *   <%= sort_widget order: :asc do |sort|
 *     sort.selected("title") <- item-seleccionado
 *     sort.item(["Título", "title"]) <- item
 *     sort.item(["Solicitante", "request"]) <- item
 *   end %>
 *   <div class="list">
 *     <span id="elemento_1">
 *        <span class="title">
 *           elemento 1
 *        </span>
 *        <span class="request">
 *           Fernando Navajas
 *        </span>
 *     </span>
 *     ...
 *     ...
 *   </div>
 * </div>
 *
 * Ejemplo para ordenamiento en el backend.
 * (Se agregan los input del componente sort_widget a un formulario, el cúal envia la información al backend,
 * donde se debe aceptar los parametros "sort_by", "sort_order" para luego procesarlos)
 * <div data-sort-list="<%= {serverSort: "id_del_formulario"}.to_json %>"
 *   <%= sort_widget order: :asc do |sort|
 *     sort.selected("title") <- item seleccionado
 *     sort.item(["Título", "title"]) <- item
 *     sort.item(["Solicitante", "request"]) <- item
 *   end %>
 * </div>
 *
 * Para poder utilizar el ordenamiento, es importante pasar la opción `valueNames`, o si no list.js
 * no ordenara nada. Lo más simple es pasar un array de nombres. Cada nombre corresponde a una clase
 * utilizada en cada item, y list.js buscara en el contenido de ese elemento.
 * Si la lista a ordenar ya cuenta con la opción "ValueNames", no hay necesidad de volver a nombrarlos.
 * Más detalles en https://listjs.com/api/#valueNames
 *
 * Las opciones se pasan como json en el valor del atributo data-sort-list.
 * Ver https://listjs.com/api/ para todas las opciones
 *
 * La opción showOnLoad no es del list.js, es inventada acá, y es para renderizar
 * el html de la lista sin mostrar, y mostrar después de inicializar el list.js,
 * y así reducimos el trabajo que debe hacer el navegador.
 */

import onmount from 'onmount';
import { isInDom } from '../lib/interaction-functions';

onmount('[data-sort-list]', async function (obj) {
  const { default: List } = await import('list.js');
  if (!isInDom(this)) {
    return;
  }

  const $self = $(this),
    options = $self.data('sortList') || {},
    $sortOrderButton = $self.find('.sort_order'),
    $sortOrder = $self.find('input[name="sort_order"]'),
    $sortBy = $self.find('select[name="sort_by"]');

  // Aplicar el ordenamiento en el backend
  if (options.serverSort) {
    // Busca el formulario enviado en la opción "serverSort"
    const $form = $('#' + (options.serverSort));

    // Al seleccionar un item del selector, se envia el formulario

    $sortBy.on('change', function (evt) {
      $form.submit();
      return sortElements($form, evt, $(this), $sortOrderButton);
    });

    // Al cambiar el orden, cambia el valor del input oculto "sort_order" y envia el formulario
    $sortOrderButton.on('click', function (evt) {
      changeSortDirection($sortOrderButton, $sortOrder);
      return sortElements($form, evt, $sortBy, $(this));
    });
  }

  // Aplicar el ordenamiento en el frontend con List.js
  else {

    // Cargar lista ya instanciada por list.js
    if (window[options.id]) {
      obj.list = window[options.id];
    }

    // o crear una nueva lista
    else {
      // Cargar en valueNames las clases de los elementos a ordenar
      if (!options.valueNames) {
        options.valueNames = [];
        $self.find('select[name="sort_by"] option').map(function () {
          options.valueNames.push($(this).val());
        });
      }

      if (!this.id) {
        this.id = `list-container-${obj.id}`;
      }
      const container = $self.find(`.${options.listClass}`);

      // workaround https://github.com/javve/list.js/issues/685
      if (!container.attr('id')) {
        container.attr('id', `list-${obj.id}`);
      }
      obj.list = new List(this, options);
      window.list = obj.list;
    }
    // Si se desea ordenar los elementos antes de mostrarlos por primera vez
    // se debe agregar la opción "showOnLoad: true" a las opciones
    // junto con el campo "sort: title" y "sort_order: asc"
    if (!options.showOnLoad) {
      if (options.sort) {
        obj.list.sort(options.sort, { order: options.sort_order || 'desc' });
      }
      $self.show();
    }
    // Al seleccionar un item en el selector, se activa el ordenamiento.
    $sortBy.on('click', function (evt) {

      obj.list.sort($sortBy.val(), { order: $sortOrder.val() });

      evt.preventDefault();
      return false;
    });
    // Al cambiar el sentido del orden, se activa el ordenamiento.
    $sortOrderButton.on('click', function (evt) {
      changeSortDirection($sortOrderButton, $sortOrder);

      obj.list.sort($sortBy.val(), { order: $sortOrder.val() });

      evt.preventDefault();
      return false;
    });
  }
});

function changeSortDirection(button, hiddenInput) {
  hiddenInput.val(hiddenInput.val() === 'desc' ? 'asc' : 'desc');
  const iconHtml = button.find($('span'));
  iconHtml.html() === 'arrow_downward' ? iconHtml.html('arrow_upward') : iconHtml.html('arrow_downward');
}

function disableAndWaitingButton($button) {
  $button.attr('disabled', 'disabled');
  $button.find('span').html('more_horiz');
}

function disableAndWaitingSelect($input) {
  $input.attr('disabled', 'disabled');
  $input.prepend("<option value='' selected='selected'>Ordenando...</option>");
}

function sortElements($form, e, $select, $button) {
  $form.submit();
  e.preventDefault();
  disableAndWaitingSelect($select);
  disableAndWaitingButton($button);
  return false;
}
