/** @file TODO: documentar */
$(document).on('change', '.special-radio-button', function () {
  $(this).parents('.special-radio-group').find('.special-radio-button').map((_,e) => updateInput(e));
});

function updateInput(input) {
  const checked = $(input).find('input').is(":checked");
  const onClass = $(input).data('onclass');
  const offClass = $(input).data('offclass');
  $(input).removeClass(onClass).removeClass(offClass).addClass(checked ? onClass : offClass);
}
