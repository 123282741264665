/** @file Makes the avatar gallery scrollable with buttons */
import onmount from 'onmount';

onmount('.avatar-gallery', function () {

  // Display the buttons only if the container is overflown
  const isOverflown = () => {
    const container = $(this).children('.avatar-gallery__container');
    const containerElement = container.get(0);
    return (
      containerElement.scrollHeight > containerElement.clientHeight ||
      containerElement.scrollWidth > containerElement.clientWidth
    );
  };

  const updateButtonsVisibility = () => {
    if (isOverflown()) {
      $(this)
        .children('[class*="avatar-gallery__slider--"]')
        .removeClass('invisible');
    }
    else {
      $(this)
        .children('[class*="avatar-gallery__slider--"]')
        .addClass('invisible');
    }
  };

  updateButtonsVisibility();
  new ResizeObserver(updateButtonsVisibility).observe(this);

  // Code for the scrolling animation
  const N_AVATARS_TO_SCROLL = 4;
  const ANIMATION_TIME = 400;

  const container = $(this).children('.avatar-gallery__container');
  const width = container.children('.avatar-gallery__avatar').width();

  const buttonRight = $(this).children('.avatar-gallery__slider--right');
  const buttonLeft = $(this).children('.avatar-gallery__slider--left');

  addClickScrollListener(buttonLeft, 'left');
  addClickScrollListener(buttonRight, 'right');

  function addClickScrollListener(button, direction) {
    button.on('click', function (event) {
      // Stop the current animation before starting a new one
      event.stopPropagation();

      const sign = direction === 'left' ? 1 : -1;

      container
        .stop()
        .animate(
          { scrollLeft: container.scrollLeft() + sign * width * N_AVATARS_TO_SCROLL },
          ANIMATION_TIME
        );
    });
  }
});
