/** @file Archivo JS para reconocimientos
 *
 */
import onmount, { $ } from 'onmount';

onmount('#recognition-formulary-form', function () {
  const $form = $(this);
  const saveButtonsModal = $form.find('.modal .save-changes-image');
  const hiddenInputImageType = $form.find('.image_type');

  // copia la imagen seleccionada en el modal de predefinidas al badge del preview
  saveButtonsModal.on('click', function () {
    if ($(this).data('image') === 'select') {
      const tagImageSelected = $form.find('.modal#select-image-formulary [type=radio]:checked').siblings('img');
      const urlImageSelected = tagImageSelected.attr('src');
      $form.find('.recognition-badge').attr('src', urlImageSelected);
    }

    // Se debe llamar a trigger change para activar el intercooler del form
    hiddenInputImageType.val($(this).data('image'));
  });

  $form.find('#result_croppie_recognition').on('change', function () {
    $form.find('.recognition-badge').attr('src', this.value);
  });
});

onmount('.new-recognition-form', function () {
  const form = $(this);
  const firstStep = form.find('.modal-first-part');
  const secondStep = form.find('.modal-second-part');

  firstStep.find('[data-step]').on('click', function () {
    firstStep.addClass('hidden');
    secondStep.removeClass('hidden');
  });

  const observer = new MutationObserver(function () {
    secondStep.find('[data-step]').on('click', function () {
      secondStep.addClass('hidden');
      firstStep.removeClass('hidden');
    });
  });
  observer.observe(secondStep.get(0), { attributes: true });
});

onmount('.recognition-standalone-filtered-by-id', function () {
  this.click();
});
