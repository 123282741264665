/** @file
 * Archivo de marcas de amplitud para modelo de beneficios
 */
import onmount from 'onmount';

onmount('[data-amplitude="finiquito-simulate"]', async function () {
  const btnSimulate = $(this);
  btnSimulate.on('click', () => {
    btnSimulate.trigger('amplitudeTracking', { message: 'simulate_finiquito_button_clicked' });
  });
});

onmount('[data-amplitude="finiquito-terminate"]', async function () {
  const btnTerminate = $(this);
  btnTerminate.on('click', () => {
    btnTerminate.trigger('amplitudeTracking', { message: 'terminate_finiquito_button_clicked' });
  });
});
