/** @file Archivo general de js para encuestas libres
*/
import onmount from 'onmount';
import Turbolinks from 'turbolinks';
import { warning } from '../../../../../../../../app/assets/javascripts/lib/buk/alert.js';

// Recarga la pagina cuando se suben de forma masiva participantes
onmount('[data-import-action="massive_load_employees_open_survey_process"]', function () {
  $(this).on('fileuploaddone-success', function () {
    Turbolinks.visit();
  });
});

onmount('#intercooler-survey-questions-configuration', function () {

  const url = $(this).data('table-path');

  // Evento para cerrar el modal cuando una pregunta se carga exitosamente
  // Trigger una accion en la pag actual despues de que IC sea exitoso y retorne data (HTML page)
  $(document).on('after.success.ic', function (_event, elt, _data) {
    elt.parents('.modal').modal('hide');
  });

  // Recarga la tabla de preguntas cuando se cargan las preguntas de forma masiva por el importador
  onmount('[data-import-action="massive_load_questions"]', function () {
    $(this).on('fileuploaddone', function () {
      $.ajax({
        url: url,
        type: 'GET',
        data: {
          questions_table: true,
        },
        success: function (data) {
          $('#intercooler-survey-questions-configuration').html(data);
        },
      });
    });
  });
});

onmount('#table-employees-open-survey', function () {
  // se debe definir un contador debido a que el evento draw.dt se activa una vez solo con cargar la vista.
  if ($('#employees_graph').length === 0) return;
  let alreadyLoaded = false;
  $(this).on('draw.dt', () => {
    if (alreadyLoaded) {
      const rawIds = $('.employee-checkbox')[0] || null;
      const allIds = rawIds ? JSON.parse(rawIds.dataset.allIds) : rawIds;
      const graph = $('#employees_graph');
      const surveyId = $(this).data('survey-id').toString();
      const url = `${location.protocol}//${location.host}/talent/open_survey_processes/${surveyId}`;
      $.ajax({
        type: 'POST',
        url: `${url}/update_stat`,
        data: {
          'ids': JSON.stringify(allIds),
        },
        success: function (response) {
          graph.html(response);
        },
        error: function () {
          warning('No fue posible actualizar el filtro');
        },
      });
    }
    else {
      alreadyLoaded = true;
    }
  });
});

onmount('#type_hidden', function () {
  const simpleTypeValue = $('#type_hidden').val();

  $('#question_type').val(simpleTypeValue).trigger('change');
});

// === JS DE PREGUNTAS CONDICIONALES ===
// Mostrar los campos si la pregunta se marca como condicional
$(document).on('change', '.open_question_is_conditional', function () {
  $(this).is(':checked') ? $('#add_question_conditions').show() : $('#add_question_conditions').hide();
});

// Cargar opciones de alternativas en selector de condiciones en base a la pregunta seleccionada
$(document).on('change', '.input_parent_question_id', function () {
  const selectedQuestionId = this.value;
  const conditionId = $(this).attr('class').match(/\d+/)[0];

  $(`.conditions_answer_${conditionId} option`).hide();
  $(`.conditions_answer_${conditionId} option[data-id="${selectedQuestionId}"]`).show();
  $(`.conditions_answer_${conditionId}`)
    .val($(`.conditions_answer_${conditionId} option[data-id="${selectedQuestionId}"]:first`).val());
});

// Revisar las codiciones previas estan seleccionados correctamente
onmount('.input_parent_question_id', function () {
  const questionId = $(this).val();
  const conditionId = $(this).attr('class').match(/\d+/)[0];

  if (questionId === '') {
    $(`.conditions_answer_${conditionId} option`).hide();
  }
  else {
    $(`.conditions_answer_${conditionId} option`).hide();
    $(`.conditions_answer_${conditionId} option[data-id="${questionId}"]`).show();
  }
});

const questionConditionsAreMet = function (conditions) {
  // Retorna un arreglo de booleanos, dependiendo si se cumple cada condicion
  return conditions.map(k => {
    const radioInput = $(`input[name='attempt[${k.parent_id}][answer_text]']:checked`).val();
    const checkedBoxes = Object.values($(`[name='attempt[${k.parent_id}][answer_text][]']:checked`)).map(i => i.value);
    return (radioInput === k.parent_val || $.inArray(k.parent_val, checkedBoxes) !== -1);
  });
};

const showEnabledConditionalQuestions = function () {
  const conditionsByQuestion = $('#survey-attempt-form').data('question-conditions');
  for (const [questionId, conditions] of Object.entries(conditionsByQuestion)) {
    questionConditionsAreMet(conditions).some(Boolean) ? $(`.${questionId}`).show() : $(`.${questionId}`).hide();
  }
};

onmount('.single_choice_answer, .multiple_choice_answer', function () {
  showEnabledConditionalQuestions();
});

$(document).on('change', '.single_choice_answer, .multiple_choice_answer', function () {
  showEnabledConditionalQuestions();
});

// === JS DE RESPUESTAS RECURRENTES ===
$(document).on('change', '#talent_open_survey_process_recurrent_answers', function () {
  const messageOptions = $('#talent_open_survey_process_end_message, [data-message-options]').data('message-options');
  const currentEndMessage = $('[aria-describedBy="cke_170"]').contents().find('body').html();

  if (Object.values(messageOptions).includes(currentEndMessage)) {
    if ($('input[name="talent_open_survey_process[recurrent_answers]"]:checked').length) {
      $('[aria-describedBy="cke_170"]').contents().find('body').html(messageOptions.recurrent_answers);
    }
    else {
      $('[aria-describedBy="cke_170"]').contents().find('body').html(messageOptions.default);
    }
  }
});
