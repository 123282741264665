/** @file TODO: documentar */
import { defaults, identity } from 'lodash-es';
import onmount from 'onmount';
import { isInDom } from '../lib/interaction-functions';

function roundN(value, n) {
  return Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
}

function AutoSumOf(element, selectors, options) {
  const self = this;
  const $element = $(element);
  options = defaults(options || {}, {
    formatFunc: identity,
    useSigns: false,
    invertTotal: false,
    round: null,
    showResultSign: true,
    dataTableId: null,
    filterSelectedRows: false,
  });

  this.eventMap = {
    'change autosum input': doSum,
  };
  this.selectors = selectors;
  if (selectors) {
    $(document).on(this.eventMap, this.selectors);
    doSum();
  }

  function calculateDataTableSum() {
    const inputIds = selectors.split(',');
    const dataTable = $(options.dataTableId).DataTable();
    const rows = dataTable.rows().nodes().to$();
    let sum = 0;
    inputIds.forEach(function (selector) {
      const input = rows.find(selector).first();
      if(input.is(':disabled')) return;
      if(input.is(':checkbox')) return;
      if(input.data('autosumCheckboxId') && rows.find(input.data('autosumCheckboxId')).is(':not(:checked)')) return;
      if (options.useSigns && input.data('autosumOfSign') === '-') {
        sum -= self.getValue(input);
      }
      else{
        sum += self.getValue(input);
      }
    });
    return sum;
  }

  function calculateDomElementsSum() {
    var sum = 0;
    // Refilter selector in case new elements were added
    $(selectors).each(function () {
      if ($(this).is(':disabled')) return;
      if ($(this).is(':checkbox')) return;
      if ($(this).data('autosumCheckboxId') && !$($(this).data('autosumCheckboxId')).is(':checked')) return;
      if (options.useSigns && $(this).data('autosumOfSign') == '-') {
        sum -= self.getValue(this);
      }
      else {
        sum += self.getValue(this);
      }
    });

    return sum;
  }

  function doSum() {
    if (!isInDom($element[0])) {
      self.destroy();
      return;
    }
    let sum = options.dataTableId ? calculateDataTableSum() : calculateDomElementsSum();

    // el comportamiento por defecto es no redondear
    sum = options.round === null ? sum : roundN(sum, options.round);

    if (options.invertTotal) {
      sum = -sum;
    }
    if ($element.is(':input')) {
      $element.val(options.formatFunc(sum));
      if (!options.showResultSign) {
        $element.val(options.formatFunc(Math.abs(sum)));
      }
    }
    else {
      // Todo: install numeral and use here
      $element.text(options.formatFunc(sum));
    }
    $element.data('autoSumValue', sum).trigger('autosum').change();
  }
}

AutoSumOf.prototype.destroy = function () {
  $(document).off(this.eventMap, this.selectors);
};

AutoSumOf.prototype.getValue = function getValue(elem) {
  var $e = $(elem);
  if (typeof ($e.data('autoSumValue')) !== 'undefined') {
    return +$e.data('autoSumValue');
  }
  else if ($e.is(':input')) {
    return +$e.val();
  }
  return 0;
};

onmount('[data-autosum-of]', function (obj) {
  var $this = $(this),
    selectors = $this.data('autosumOf'),
    formatFunc = $this.data('autosumFormatFunc');

  if (typeof formatFunc === 'string') {
    formatFunc = window[formatFunc];
  }
  var autosums = [];
  var showResultSignData = $this.data('autosumOfShowResultSign');
  $this.each(function () {
    const options = {
      formatFunc: formatFunc,
      useSigns: typeof ($this.data('autosumOfUseSigns')) !== 'undefined',
      invertTotal: $this.data('autosumOfSign') === '-',
      round: $this.data('autosumOfRound'),
      showResultSign: showResultSignData || typeof (showResultSignData) === 'undefined',
      dataTableId: $this.data('dataTableId') ? '#' + $this.data('dataTableId') : null,
      filterSelectedRows: $this.data('autosumOfFilterSelectedRows'),
    };
    autosums.push(new AutoSumOf(this, selectors, options));
  });
  obj.autosums = autosums;
}, function (obj) {
  (obj.autosums || []).forEach(i => i.destroy());
});
