/**
 * @file Maneja la asignación de posición de elementos ordenables cuando son
 * removidos o agregados.
 *
 * Para usarlo se debe agregar la clase `sortable-nested-children` al mismo
 * elemento al que se agrega la clase `sortable`.
 */
import onmount from 'onmount';

onmount('.sortable-nested-children', function () {

  /**
   * Función que asigna las posiciones(de manera contigua) a los elementos ordenables.
   * Comienza desde la posicion 1.
   */
  const assignPositions = () => {
    const item = $(this).children(':visible').toArray();
    // actualiza el número de posición en la tabla
    item.forEach((e, idx) => {
      $(e).find('.position').text(idx + 1);
      $(e).find('.position').val(idx + 1);
    });
  };

  // Para elementos ordenables insertados con Cocoon
  $(this).on('cocoon:after-insert', function () {
    assignPositions();
  });

  // Para elementos ordenables a remover con Cocoon, se marcan para destrucción y su input como no requerido
  $(this).on('cocoon:before-remove', function (_, elementToRemove) {
    const $elementToRemove = $(elementToRemove);
    $elementToRemove.find('input[type=hidden][name*="_destroy"]').val('1');
    $elementToRemove.find("input, textarea").attr('required', false);
  });

  $(this).on('cocoon:after-remove', function () {
    assignPositions();
  });

  $(this).on('nestedFormSortableUpdatedElement', function (_e) {
    assignPositions();
  });
});
