/** @file TODO: documentar */
import onmount from 'onmount';
onmount("input[type='file'][data-img-preview]", function () {
  const $self = $(this);
  const minWidth = $self.data('min-width');
  const minHeight = $self.data('min-height');

  $self.on('change', function () {
    changePreview(this);
  });

  function changePreview(target) {
    if (target.files && target.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        $("[data-preview='true']").attr('src', e.target.result);
        $("[data-preview='true']").attr('style', "visibility='visible';");
      };
      reader.onloadend = function (e) {
        const img = new Image();
        img.src = e.target.result;
        checkValidImg(img);
      };
      reader.readAsDataURL(target.files[0]);
    }
  }

  function checkValidImg(image) {
    if ((image.naturalWidth <  minWidth) ||
        (image.naturalHeight < minHeight) ||
        (image.naturalWidth / image.naturalHeight !== 16 / 9)) {
      toggleAlert(true);
    }
    else {
      toggleAlert(false);
    }
  }

  function toggleAlert(activate) {
    const submitWithAlert = $("[data-form-preview-alert='true']");
    const submitWithoutAlert = $("[data-form-preview-alert='false']");
    if (activate) {
      submitWithAlert.removeClass('hidden');
      submitWithoutAlert.addClass('hidden');
    }
    else {
      submitWithAlert.addClass('hidden');
      submitWithoutAlert.removeClass('hidden');
    }
  }
});
