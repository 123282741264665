/** @file TODO: documentar */
import onmount from 'onmount';
import 'cocoon-rails';
import { numberToHumanSize } from '../lib/string';

// Se usa en conjunto a 'nested_forms.js'
// Funciones para el manejo de camos de archivos archivos anidados
onmount('#attachments-file-box', function () {
  const attachmentsBox = $(this);
  const noAttachmentsMessage = $('.no-attachments-message');

  attachmentsBox.on('cocoon:after-insert', function (_e, insertedItem, _originalEvent) {
    const inputFile = insertedItem.find('input[type="file"]');
    inputFile.click();

    // Funcion para actualizar el campo de tamaño y nombre de un archivo seleccionado.
    inputFile.on('change', function () {
      const selectInput = $(this);
      if (this.files.length > 0) {
        const parent = selectInput.parents('tr');
        const sizeTd = parent.find('.attachment-size');
        const sizeNm = parent.find('.attachment-name');
        sizeTd.text(this.files[0] ? numberToHumanSize(this.files[0].size) : '');
        sizeNm.text(this.files[0].name || '');
        noAttachmentsMessage.hide();
        parent.show();
      }
    });
  });

  // Al eliminar revisa si hay algun archivo, sino mostrar un mensaje de vacio.
  attachmentsBox.on('cocoon:before-remove', function (_e, _removido) {
    const inputsWithFiles = attachmentsBox.find('tr:visible').toArray();
    if (inputsWithFiles.length <= 1) {
      noAttachmentsMessage.show();
    }
  });

});
