/** @file
 * Archivo de marcas de amplitud para solicitar permisos
 */
import onmount from 'onmount';

onmount('[href="#asistencia"]', function () {
  const button = $(this);

  button.on('click', () => {
    button.trigger('amplitudeTracking', {
      message: 'View_Absence',
      data: {
        source: 'Ficha',
      },
    });
  });
});

onmount('[data-amplitude="Start_Request_Absence"]', function () {
  const button = $(this);
  const source = button.data('source');

  button.on('click', () => {
    button.trigger('amplitudeTracking', {
      message: 'Start_Request_Absence',
      data: {
        source,
      },
    });
  });
});

onmount('#new_licence', function () {
  const form = $(this);
  const source = form.data('amplitudeSource');

  if (source) {
    document.addEventListener('ajax:success', (event) => {
      const xhr = event.detail[2];
      const licenceType = $('#licence_licence_type_id').find(':selected').text();

      if (xhr.status === 200) {
        form.trigger('amplitudeTracking', {
          message: 'Request_Absence',
          data: {
            source,
            'absence_type': licenceType,
          },
        });
      }
    });
  }
});
