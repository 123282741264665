/** @file Limita a nivel de frontend un input numerico entre el máximo
 * y/o mínimo definidos, evitando valores fuera de estos limites
 *
 * @example
 * <input min="0" max="100" data-limit-number-input="true" ></input>
 *
*/
import onmount from 'onmount';

onmount('input[data-limit-number-input="true"]', function () {
  const input = $(this);
  input.on('keyup', function () {
    const value = parseFloat(input.val());
    if (input.attr('min')) {
      const min = parseFloat(input.attr('min'));
      if (value < min) {
        input.val(min);
        return;
      }
    }
    if (input.attr('max')) {
      const max = parseFloat(input.attr('max'));
      if (value > max) {
        input.val(max);
      }
    }
  });
});
