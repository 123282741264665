/** @file
*
* Se encarga sincronizar las columnas del header de la tabla fiji
* con el ancho de las celdas de la tabla
*/
import onmount from 'onmount';

const activeObservers = new Map();
const activeListeners = new Map();

onmount('.fiji-table-container', function () {
  const fijiView = this;
  const hasStickyHeader = fijiView.querySelector('.fiji-header');

  if (!hasStickyHeader) return;

  syncStickyHeader(fijiView);
}, function () {
  const fijiId = this.getAttribute('id');
  const hasStickyHeader = this.querySelector('.fiji-header');

  if (!hasStickyHeader) return;

  removeObserver(fijiId);
  removeScrollListener(fijiId);
});

/**
 * Sincroniza los eventos necesarios entre el header sticky y el body de la tabla
 * para imitar el comportamiento de una tabla nativa
 *
 * @param {HTMLElement} fijiView - Elemento principal de la vista Fiji
 */
function syncStickyHeader(fijiView) {
  const fijiId = fijiView.getAttribute('id');
  const table = fijiView.querySelector('.fiji-table');

  syncHeaderColumns({ fijiView, fijiId, tableBody: table });
  syncHeaderScroll({ fijiView, fijiId });
}

/**
 * Sincroniza el ancho de las columnas del header con las del body de la tabla,
 * detectando su redimensionamiento
 *
 * @param {Object} options - Objecto necesario para la sincronización.
 * @param {HTMLElement} options.fijiView - Elemento principal de la vista Fiji
 * @param {string} options.fijiId - ID de la vista para rastrear los observadores activos
 * @param {HTMLElement} options.tableBody - Elemento que contiene los registros de la tabla
 */
function syncHeaderColumns({ fijiView, fijiId, tableBody }) {
  const tableObserver = new ResizeObserver(() => {
    const firstTableRow = fijiView.querySelectorAll('tbody:not(.fiji-table-body--empty) > tr:first-of-type > td');
    const headerRow = fijiView.querySelectorAll(`.fiji-index .fiji-header .fiji-header__column`);

    setHeaderColumnsWidth({ tableRow: firstTableRow, headerRow });
  });

  tableObserver.observe(tableBody);

  // Se mapea el observer para eliminarlo cuando el componente se desmonta
  activeObservers.set(fijiId, {
    observer: tableObserver,
    targetElement: tableBody,
  });
}

/**
 * Sincroniza el scroll horizontal del body de la tabla con el header sticky
 *
 * @param {Object} options - Objecto necesario para la sincronización.
 * @param {HTMLElement} options.fijiView - Elemento principal de la vista Fiji
 * @param {string} options.fijiId - ID de la vista para rastrear los observadores activos
 */
function syncHeaderScroll({ fijiView, fijiId }) {
  const headerContainer = fijiView.querySelector('.fiji-header');
  const bodyContainer = fijiView.querySelector('.table-responsive');

  // Se crea referencia a la funcion que sincroniza los scroll
  // para luego ser removida cuando el componente se desmonte
  const syncScrollFromBody = () => {
    headerContainer.scrollLeft = bodyContainer.scrollLeft;
  };

  const syncScrollFromHeader = () => {
    bodyContainer.scrollLeft = headerContainer.scrollLeft;
  };

  bodyContainer.addEventListener('scroll', syncScrollFromBody);
  headerContainer.addEventListener('scroll', syncScrollFromHeader);

  activeListeners.set(fijiId, [
    { listener: syncScrollFromBody, targetElement: bodyContainer },
    { listener: syncScrollFromHeader, targetElement: headerContainer },
  ]);
}

/**
 * Elimina un observador activo basado en su ID.
 *
 * @param {string} observerId - El ID del observador que se quiere eliminar.
 */
function removeObserver(observerId) {
  const activeObserver = activeObservers.get(observerId);

  if(!activeObserver) return;

  const { observer, targetElement } = activeObserver;

  observer.unobserve(targetElement);
}

/**
 * Elimina el eventListener asociado al scroll basado en su ID.
 *
 * @param {string} listenerId - El ID del eventListener que se quiere eliminar.
 */
function removeScrollListener(listenerId) {
  const activeListener = activeListeners.get(listenerId);

  if(!activeListener) return;

  for (const eventListener of activeListener) {
    const { listener, targetElement } = eventListener;

    targetElement.removeEventListener('scroll', listener);
  }
}

/**
 *
 */
function updateColumnsByFormSubmit(fijiId) {
  const fijiView = document.querySelector(`#${fijiId} .fiji-table-container`);
  const firstTableRow = fijiView.querySelectorAll('tbody:not(.fiji-table-body--empty) > tr:first-of-type > td');
  const headerRow = fijiView.querySelectorAll(`.fiji-index .fiji-header .fiji-header__column`);
  setHeaderColumnsWidth({ tableRow: firstTableRow, headerRow });
}

/**
 * Establece el ancho de las columnas del header para que coincidan con las celdas de la tabla
 *
 * @param {Object} options - Objecto necesario para la sincronizacion de anchos de las columnas de la tabla
 * @param {NodeListOf<HTMLElement>} options.tableRow - Fila del body de la tabla que representa las celdas
 * @param {NodeListOf<HTMLElement>} options.headerRow - Las columnas del header que deben ajustarse
 */
function setHeaderColumnsWidth({ tableRow, headerRow }) {
  for (let index = 0; index < tableRow.length; index++) {
    const cell = tableRow[index];
    const headerColumn = headerRow[index];

    const cellWidth = cell.getBoundingClientRect().width;
    if(Math.floor(cellWidth) > 0) {
      headerColumn.style.width = `${cellWidth}px`;
    }
  }
}

window.updateColumnsByFormSubmit = updateColumnsByFormSubmit;
