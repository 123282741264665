/** @file Maneja el comportamiento de los links de acciones de la tabla de documentos */
import onmount from 'onmount';

onmount('#executable_logs_filtered', function () {
  window.workerNotificationPromise.then((workerNotification) => {
    workerNotification.suscribe(updateDataTable, '__getJSON');
    workerNotification.suscribe(updateFinishJobs, 'finishJobStatus');
    workerNotification.start();
  });
}, function () {
  window.workerNotificationPromise.then((workerNotification) => {
    workerNotification.unsuscribe(updateDataTable, '__getJSON');
    workerNotification.unsuscribe(updateFinishJobs, 'finishJobStatus');
  });
});

// Llevamos al caché los jobs que se están procesando (enqueud, running)
function updateDataTable(data) {
  const newJobs = data['worker_statuses'].reduce((acc, current) => {
    acc[current.job_id] = {
      'executable_caller': current.executable_caller,
      'executable_status': current.executable_status,
      'title': current.process_name,
      'step': current.step,
      'progress': current.progress,
      'sheet_name': current.sheet_name,
      'custom_progress': current.custom_progress,
      'process_name': current.process_name,
    };
    return acc;
  }, {});
  updateCache(newJobs);
}

// Llevamos al caché los jobs que terminaron (failed, finished)
function updateFinishJobs(_data) {
  const deletedJobs = _data.reduce((acc, item) => {
    if (item.job_id !== undefined) {
      acc[item.job_id] = item;
    }
    return acc;
  }, {});
  updateCache(deletedJobs);
}

// Actualizamos el caché de jobs en la vista
function updateCache(newJobs) {
  const cache = JSON.parse($('#executable_logs_filtered_workers').attr('data-workers'));
  const newCache = {
    ...cache,
    ...newJobs,
  };
  $('#executable_logs_filtered_workers').attr('data-workers', JSON.stringify(newCache));
  updateProgress(newCache);
}

function updateProgress(jobs) {
  // agrupamos por cluster los jobs recibidos
  const groupedByCaller = groupByCallerId(jobs);
  Object.entries(groupedByCaller).forEach(([callerId, jobDetailsArray]) => {
    // Revisamos si el cluster termino para no hacer el update
    if (jobDetailsArray.every((element) => 'deleted_at' in element)) return;
    // obtenemos un resumen para el cluster, de esta manera podemos saber si el eatado general del proceso es failed, finished, etc
    const resumeCluster = resumeStatus(jobDetailsArray);

    const messageStructure = {
      estado: humanStatus(resumeCluster),
      badgeClass: statusColorClass(resumeCluster),
      badgeMessage: statusMessage(resumeCluster, jobDetailsArray),
    };

    printDetailMessage(callerId, messageStructure);
  });
}

// Método que agrupa los jobs según sus caller ids
function groupByCallerId(jobs) {
  const grouped = {};
  Object.entries(jobs).forEach(([_jobId, jobDetails]) => {
    const { executable_caller: executableCallerId } = jobDetails;
    if (!grouped[executableCallerId]) {
      grouped[executableCallerId] = [];
    }
    grouped[executableCallerId].push(jobDetails);
  });
  return grouped;
}

function resumeStatus(jobDetailsArray) {
  const totalCountStatus = jobDetailsArray.length;
  const allStatuses = ['queued', 'running', 'failed', 'finished'];
  const statusesByCaller = allStatuses.reduce((acc, status) => {
    acc[status] = jobDetailsArray.filter((job) => job['executable_status'] === status).length;
    return acc;
  }, {});

  if (totalCountStatus === statusesByCaller.queued) {
    return 'queued';
  }
  else if (totalCountStatus === statusesByCaller.finished) {
    return 'finished';
  }
  else if (totalCountStatus === statusesByCaller.failed) {
    return 'failed';
  }
  else if (totalCountStatus === statusesByCaller.failed + statusesByCaller.finished) {
    return 'finished_with_warnings';
  }
  else {
    return 'processing';
  }
}

function buildProgressMessage(jobDetailsArray) {
  return 'Procesando ' + jobDetailsArray
    .filter((job) => job.executable_status === 'running')
    .map((job) => job.custom_progress ?? job.process_name).join(' y ');
}

function buildErrorMessage(jobDetailsArray) {
  const failedJob = jobDetailsArray.find((job) => job.executable_status === 'failed' && job.exception);
  return failedJob?.exception ?? 'Importación fallida. Por favor, inténtalo de nuevo.';
}

function printDetailMessage(divId, message) {
  const statusDiv = document.getElementById(`detalle_carga_${divId}`);
  if (!statusDiv) {
    return;
  }
  statusDiv.innerHTML = '';
  const p = document.createElement('p');
  p.textContent = message.badgeMessage;
  statusDiv.appendChild(p);

  var parentTd = statusDiv.parentElement;

  var previousTdDiv = parentTd.previousElementSibling.querySelector('div');
  previousTdDiv.textContent = message.estado;
  previousTdDiv.className = `chip badge badge-${message.badgeClass}`;
}

// construye la clase para aplicar el estilo correcto segun el resumen de estado
function statusColorClass(status) {
  switch (status) {
    case 'queued':
    case 'processing':
      return 'info';
    case 'finished':
      return 'success';
    case 'failed':
      return 'danger';
    case 'finished_with_warnings':
      return 'warning';
    default:
      return '';
  }
}

// construye el mensaje a mostrar en columna Detalle, segun el resumen de estado
function statusMessage(status, jobDetailsArray) {
  switch (status) {
    case 'queued':
      return '';
    case 'processing':
      return buildProgressMessage(jobDetailsArray);
    case 'finished':
      return 'Proceso completado con éxito';
    case 'failed':
      return buildErrorMessage(jobDetailsArray);
    case 'finished_with_warnings':
      return 'Terminado con Advertencia';
    default:
      return '';
  }
}

// construye el mensaje a mostrar en Estado de Carga, segun el resumen de estado
function humanStatus(status) {
  switch (status) {
    case 'queued':
      return 'En cola';
    case 'processing':
      return 'Procesando';
    case 'finished':
      return 'Completada';
    case 'failed':
      return 'Error';
    case 'finished_with_warnings':
      return 'Terminado con advertencias';
    default:
      return '';
  }
}
