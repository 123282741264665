/** @file Cambiamos el label para #item_transfer_form_bono_id dependiendo del valor en el select de #item_transfer_form_transfer_type */
import onmount from 'onmount';

onmount('#nivel-select', function () {

  const btn = $('.btn-disabled');
  const element = $('#school-container');
  $(this).on('change', function () {
    element.empty();
    var newOptions = $('#' + $(this).val() + '.advance_school_option option');
    $.each(newOptions, function () {
      element.append($('<option></option>').attr('value', $(this).val()).text($(this).text()));
    });
    const disableOption =  element.val() === '-1';
    btn.prop('disabled', disableOption);
  });
});
