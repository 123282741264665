/** @file
 * Permite la navegación horizontal mediante botones personalizados.
*/
import onmount, { $ } from 'onmount';

onmount('.scroll-wrapper', function () {

  const containerId = $(this).attr('id-scroll-wrapper-container');
  const bodyContainer = $(this).find(`#${containerId}`)[0];
  const showMobile = $(this).attr('mobile') === 'true';
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent);

  // Verificar que el Id del contenedor pertenesca al scroll-wrapper
  if (!bodyContainer) return false;

  // Quitamos la funcionalidad si se ingresa desde un dispositivo pero no tiene activada la opcion mobile.
  if (isMobile && !showMobile) return false;

  const buttonsContainer = $(this).find('.scroll-wrapper__buttons')[0];
  const leftButton = $(this).find('.scroll-wrapper__button-left')[0];
  const rightButton = $(this).find('.scroll-wrapper__button-right')[0];
  const SCROLL_AMOUNT = isMobile ? 3 : 5;
  const SCROLL_INTERVAL_TIME = 5;
  let scrollIntervalId;

  // Aumenta o reduce el scroll del contenedor
  function startScrolling(amount) {
    scrollIntervalId = setInterval(() => {
      bodyContainer.scrollLeft += amount;
    }, SCROLL_INTERVAL_TIME);
  }

  // Verificar si el scrollbar está visible para mostrar los botones laterales
  function verifyScroll() {
    const existScrollbar = bodyContainer.scrollWidth > bodyContainer.clientWidth;
    leftButton.style.display = existScrollbar ? 'block' : 'none';
    rightButton.style.display = existScrollbar ? 'block' : 'none';
  }
  verifyScroll();

  // Verificar que el Id del contenedor pertenesca al scroll-wrapper
  buttonsContainer.style.display = 'flex';

  if(isMobile) {
    // Evento para empezar el scroll del contenedor
    leftButton.addEventListener('touchstart', () => startScrolling(-SCROLL_AMOUNT));
    rightButton.addEventListener('touchstart', () => startScrolling(SCROLL_AMOUNT));

    // Evento para parar y limpiar los eventos creados para el scroll
    leftButton.addEventListener('touchend', () => clearInterval(scrollIntervalId));
    rightButton.addEventListener('touchend', () => clearInterval(scrollIntervalId));
  }
  else {
    // Evento para empezar el scroll del contenedor
    leftButton.addEventListener('mousedown', () => startScrolling(-SCROLL_AMOUNT));
    rightButton.addEventListener('mousedown', () => startScrolling(SCROLL_AMOUNT));

    // Evento para parar y limpiar los eventos creados para el scroll
    const buttonsScroll = [leftButton, rightButton];
    for (const buttonScroll of buttonsScroll) {
      buttonScroll.addEventListener('mouseup', () => clearInterval(scrollIntervalId));
      buttonScroll.addEventListener('mouseleave', () => clearInterval(scrollIntervalId));
    }
  }

  // Evento para el cambio de tamaño del navegador
  window.addEventListener('resize', verifyScroll);

});
