/** @file Archivo JS para calibraciones,
 * Actualmente requiere del general 'talent_management' activo.
 *
*/
import onmount from 'onmount';
import Turbolinks from 'turbolinks';

onmount('.modal-redirect', function () {
  const $self = $(this);
  const isImported = $self.data('imported');

  $self.on('fileuploaddone-success', function () {
    if (isImported === false) { Turbolinks.visit(); }
    else { Turbolinks.visit('calibrations/redirect_imported'); }
  });
});

onmount('[data-change-option-text="true"]', function () {
  const $self = $(this);
  const $selector = document.getElementById('quadrant');

  $self.on('change', changeSelectOptionText);

  function changeSelectOptionText() {
    var optionIndex = $selector.selectedIndex;
    $selector.options[optionIndex].text = $self[0].value;
  }
});

/**
 * Este JS custom se generó dado que en los formularios cuando se emplea un selector con 'form-switch', este
 * último deshabilita aquellos 'inputs' que se han escondido a causa del 'form-switch', por lo que al enviar
 * los datos de un formulario con 'simple_form', no se envían todos los datos almacenados en los 'inputs'.
 *
 * Debido a lo anterior, se identifican aquellos 'inputs' asociados al selector y en el instante en que se
 * presiona el botón para enviar el formulario, se habilitan todos los necesarios (incluyendo los ocultos).
 *
 * Para el instante en que se generó este código, no sé identificó como evitar tal comportamiento empleando
 * el propio 'form-switch'.
*/
onmount('[data-submit-enable-inputs="true"]', function () {
  const $self = $(this);
  const $inputs = $('[id^="talent_ninebox"]').filter('[id*="ninebox_boxes_attributes"]');

  $self.on('click', function () {
    $inputs.each(function () {
      $(this).prop('disabled', false);
    });
  });
});

/**
 * Este JS se utiliza como una alternativa al form-switch, ya que, el form switch genera un comportamiento
 * no deseado cuando hay inputs disabled.
*/
onmount('#ninebox-config-selected-variable', function () {
  const $self = $(this);

  $self.on('change', function () {
    const notSelectedOptions = $('#ninebox-config-selected-variable option:not(:selected)');
    const selectedText = $self.find(':selected').text();

    notSelectedOptions.each(function () {
      const rangesConfigDiv = $(`[id='${$(this).text()}_ranges_config']`);
      rangesConfigDiv.fadeOut();
      rangesConfigDiv.addClass('display-none');
    });
    const selectedRangesConfig = $(`[id='${selectedText}_ranges_config']`);
    selectedRangesConfig.fadeIn();
    selectedRangesConfig.removeClass('display-none');
  });
});

onmount('#calibration-change-to-quadrants', function () {
  const $self = $(this);
  const activeContentInput = $('input#active_content');

  $self.on('click', function () {
    activeContentInput.val('quadrant');
  });
});

onmount('#calibration-change-to-list', function () {
  const $self = $(this);
  const activeContentInput = $('input#active_content');

  $self.on('click', function () {
    activeContentInput.val('list');
  });
});
