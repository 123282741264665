/**
 * @file Permite la habilitación de un paginador y buscador en listas arbitrarias.
 *
 * Para usar, debe seguirse la siguiente estructura:
 *
 * 1. Un con el atributo data-paginate-list. Este debe contener todos los otros elementos.
 * 2. Un elemento con la clase `list`. Este elemento debe contener la lista que nos interesa
 *    filtrar y paginar. El plugin considera cada hijo de este elemento como un item en la lista.
 * 3. Un elemento `ul.pagination`. Acá se cargará el paginador. Opcional
 * 4. Un elemento input.fuzzy-search. Este input hará la búsqueda entre los items. Opcional
 *
 * Para poder utilizar la búsqueda, es importante pasar la opción `valueNames`, o si no el plugin
 * no buscará nada. Lo más simple es pasar un array de nombres. Cada nombre corresponde a una clase
 * utilizada en cada item, y el plugin buscara en el contenido de ese elemento.
 * Más detalles en https://listjs.com/api/#valueNames
 *
 * Las opciones se pasan como json en el valor del atributo data-paginate-list.
 * Ver https://listjs.com/api/ para todas las opciones
 *
 * La opción showOnLoad no es del plugin, es inventada acá, y es para renderizar
 * el html de la lista sin mostrar, y mostrar después de inicializar el plugin,
 * y así reducimos el trabajo que debe hacer el navegador.
 */

import onmount from 'onmount';
import { isInDom } from '../lib/interaction-functions';

onmount('[data-paginate-list]', async function (obj) {
  const { default: List } = await import('list.js');
  if (!isInDom(this)) {
    return;
  }

  const $self = $(this),
    options = $self.data('paginateList') || {},
    searchText = options.searchText,
    currentPage = options.currentPage || 1;

  if (!options.pagination && $self.find('ul.pagination').length > 0) {
    options.pagination = {
      left: 1,
      right: 1,
    };
    if (!options.page) {
      options.page = 20;
    }
  }
  if (!options.listClass) {
    options.listClass = 'list';
  }
  if (!options.fuzzySearch && $self.find('input.fuzzy-search').length > 0) {
    options.fuzzySearch = {
      distance: 10000, // Para encontrar en cualiquier parte del texto
    };
  }

  if (!this.id) {
    this.id = `paginate-list-container-${obj.id}`;
  }
  const container = $self.find(`.${options.listClass}`);
  // workaround https://github.com/javve/list.js/issues/685
  if (!container.attr('id')) {
    container.attr('id', `paginate-list-${obj.id}`);
  }
  obj.list = new List(this, options);
  if (options.id) {
    window[options.id] = obj.list;
  }
  else {
    window.list = obj.list;
  }

  if(searchText) {
    obj.list.fuzzySearch(searchText);
  }

  if (options.showOnLoad) {
    // options.page retorna el número de elementos por página
    if (options.pagination) {
      obj.list.show((currentPage - 1) * options.page + 1, options.page);
    }
    $self.show();
  }

  if (options.pagination) {
    // Guardar la página actual
    obj.list.on('updated', function () {
      // index es el índice del primer elemento que se está mostrando en la lista al momento de actualizar la misma
      const index = obj.list.i,
        elementsByPage = obj.list.page;

      $self.find('#paginate-list-current-page').val(Math.ceil(index / elementsByPage));
    });
  }

});
