/** @file Integra Google Analytics para segmentación de usuarios buk en
 *  campañas de marketing en google ads.
 *  src: https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtm
*/

function parseJSON(json) {
  return JSON.parse(json || 'null');
}

const userInfo = parseJSON(document.getElementById('buk-user-info')?.text);

if (userInfo) {
  document.addEventListener('DOMContentLoaded', () => {
    const userId = btoa(userInfo.email);

    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    const userIdExists = window.dataLayer.some(item => item.userId === userId);
    if (!userIdExists) {
      window.dataLayer.push({
        event: 'userInfo',
        userId: userId,
      });
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          // eslint-disable-next-line no-negated-condition
          dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-5JRQ9MC');
    }
  });
}
