/** @file TODO: documentar */
import { debounce } from 'lodash-es';
import onmount from 'onmount';


onmount('[data-update-value]', function () {
  const $self = $(this);
  const targets = $self.data('updateValue');
  const defaultValue = $self.data('updateValueDefault');
  const type = $self.data('updateValueType') || 'input';
  const url = $self.data('updateValueUrl');
  const on = $self.data('updateValueOn') || 'change input';

  $(targets).on(on, debounce(update, 100));

  function update() {
    get().then(data => {
      const dataFinal = data ?? defaultValue;

      switch (type) {
        case 'text':
          $self.text(dataFinal);
          break;

        case 'input':
          $self.val(dataFinal);
          break;

        default:
          $self.val(dataFinal);
          break;
      }
    });
  }

  function get() {
    const values = $self.parents('form').serialize();

    return $.ajax({
      method: 'GET',
      dataType: 'json',
      url: url,
      data: values
    });
  }
});
