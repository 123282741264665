/** @file TODO: documentar */

import onmount from 'onmount';
import { isUndefined } from 'lodash-es';

const BASE_TEMPLATE = `
<div>
  <h3 class="popover-title"></h3>
  <div class="popover-content"></div>
</div>
`;

function buildTemplate(title, content, allowHtml) {
  const templ = $(BASE_TEMPLATE);
  templ.find('.popover-title').text(title);
  const method = allowHtml ? 'html' : 'text';
  templ.find('.popover-content')[method](content);
  return templ[0].outerHTML;
}

function setIfExist(options, prop, value) {
  if (!isUndefined(value)) {
    options[prop] = value;
  }
}

function loadOptionsFromData(el) {
  const $self = $(el);
  // El popover siempre tendra html. lo que hacemos es decidir insertar como texto o como html dentro del template
  const mobileWidth = window.matchMedia('(max-width: 768px)');
  const options = { allowHTML: true };
  const ignoreSafePlacement = Boolean($self.data('ignoreSafePlacement'));
  const placement = mobileWidth.matches && !ignoreSafePlacement ? 'auto' : $self.data('placement');
  const title = $self.attr('title') || $self.data('title');
  const content = $self.data('content');
  const trigger = $self.data('trigger') || 'click';
  const allowHtml = !!$self.data('html');
  const theme = $self.data('theme') || 'light-border popover';
  const arrow = $self.data('arrow') || typeof $self.data('arrow') === 'undefined';
  if (content) {
    options['content'] = buildTemplate(title, content, allowHtml);
  }
  // hover es el trigger por defecto en tippy y no funciona si se pone explicito.
  if (trigger === 'hover') {
    setIfExist(options, 'trigger', 'mouseenter');
  }
  setIfExist(options, 'theme', theme);
  setIfExist(options, 'arrow', arrow);
  setIfExist(options, 'placement', placement);
  const appendTo = $($self.data('appendTo'));
  setIfExist(options, 'appendTo', appendTo.length ? appendTo[0] : undefined);
  return options;
}

onmount('[data-toggle="popover"]', async function (obj) {
  const { default: tippy } = await import('tippy.js');
  const options = {
    arrow: true,
    interactive: true,
    theme: 'light-border popover',
    trigger: 'click',
    onMount(instance) {
      $(instance.popper).trigger('buk:load-content');
    },
  };
  obj.tippy = tippy(this, $.extend(options, loadOptionsFromData(this)));

  //si el popover está dentro de un dropdown, no cerrarlo al hacer click
  $(this).on('click', function (e) {
    const isDropdown = $(this).closest('.dropdown').length > 0;
    if (isDropdown) e.stopPropagation();
  });
});
