/** @file Permite deshabilitar un elemento al hacer click en el
 *
 * @example
 *   <button data-disable-on-click />
 *
 */

import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-disable-on-click]', function () {
  $(this).on('click', function () {
    $(this).attr('disabled', 'disabled').css("pointer-events", "none");
  });
});

onmount('[data-ensure-disable]', function () {
  const submitButton = this;

  submitButton.form.addEventListener('submit', function () {
    Rails.disableElement(submitButton);
  });

});
