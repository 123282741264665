/** @file Aquí se define la funcionalidad de layout header
 */

import onmount from 'onmount';

onmount('#myaccount-aside', ()=> {
  const currentLanguage = $('select#user_language').val();

  $('select#user_language').on('change', (e) => {
    const selectedLanguage = $(e.target).val();
    $(e.target).trigger('amplitudeTracking', {
      message: 'Change_Language',
      data: {
        'old_language': currentLanguage,
        'new_language': selectedLanguage,
      },
    });
  });
});
