/** @file TODO: documentar */
import ModalBuilder from '../../../../../../../app/assets/javascripts/lib/modal-builder';

$(document).on('click', 'a[href][data-modal]', executeModal);
$(document).on('click', 'div[href][data-modal]', executeModal);

function executeModal(ev) {
  // Si el link tiene un data-target, no se crea un modal, sino que abre el relacionado, esto es para levantar modales en table_actions + modal_widget
  if($(this).attr('data-target')) {
    return;
  }
  var $self = $(this);
  var url = $self.data('url') || $self.attr('href');
  var title = $self.attr('title') || $self.data('modalTitle');
  var icon = $self.attr('icon') || $self.data('modalIcon');
  const iconSource = $self.attr('iconSource') || $self.data('modalIconSource');
  var id = $self.data('id');
  var tabindex = $self.data('modalTabIndex') || -1;

  var size = $self.data('modalSize');
  var scroll = $self.data('modalScroll');
  var footer = $self.data('modalFooter');

  var modalBuilder = new ModalBuilder();
  if (!footer) {
    modalBuilder.setFooter('');
  }

  modalBuilder.setId(id);
  if($self.data('icon')) {
    modalBuilder.setIcon($self.data('icon'));
  }
  if(iconSource) {
    modalBuilder.setIconSource(iconSource);
  }

  modalBuilder.setSize(size).setScroll(scroll).show(tabindex).loadFromRemote(url, {}, title, null, icon, iconSource);
  modalBuilder.getModal().on('modal:reload', () => modalBuilder.loadFromRemote(url, {}, title, null, icon, iconSource));

  ev.preventDefault();
  return false;
}
