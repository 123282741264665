/** @file
 * Al insertar un elemento con cocoon, permite rellenar los inputs del template insertado
 * con valores de otros lugares, para esto, cada input dentro del template debe tener un
 * atributo `data-copy-from` con un selector que apunte al target de donde se copiara el valor
 *
 * Al incluir el atributo `data-not-empty="mensaje"` se validara que el campo insertado no este vacío
 * y se mostrara el mensaje en caso de estarlo.
 *
 * En caso de que no todos los campos sean obligatorios se debe incluir `data-some-not-empty="identificadores"`
 * en conjunto a `data-not-empty`. Esto permitira elegir que elementos no pueden venir vacios.
 * Y en caso de que lo vengan se levanta la alerta con el mensaje anterior
 */
import onmount from 'onmount';
import { warning } from '../../../../../../../app/assets/javascripts/lib/buk/alert.js';

onmount('[data-copy-input]', function () {
  const $this = $(this);
  const emptyMessage = $this.data('not-empty');
  const elementsNotEmpty = $this.data('some-not-empty');

  if(emptyMessage) {
    $this.on('cocoon:before-insert', function (_e, insertedItem, originalEvent) {
      let inputsToCopy = $(insertedItem).find('[data-copy-from]');
      inputsToCopy.splice(-1);
      if (elementsNotEmpty) {
        inputsToCopy = $(insertedItem).find(elementsNotEmpty);
      }

      const someInputIsEmpty = inputsToCopy.toArray().some((input) => {
        const sourceInput = $(input.dataset['copyFrom']);
        return sourceInput.val().length == 0;
      });

      if (someInputIsEmpty) {
        warning(emptyMessage);
        originalEvent.preventDefault();
      }
    });
  }

  $this.on('cocoon:after-insert', function (_e, insertedItem, originalEvent) {
    //Si el originalEvent es gatillado por el fileuploaddone, eso quiere
    //decir que debemos respetar los valores que ese evento le entrego
    //al input
    if (originalEvent?.type === 'fileuploaddone') {
      return;
    }
    const inputsToCopy = $(insertedItem).find('[data-copy-from]');

    inputsToCopy.each(function (_i, input) {
      const sourceInput = $(input.dataset['copyFrom']);
      input.value = sourceInput.val();
      sourceInput.val('');
    });
  });
});
