/** @file Enviar datos a Amplitude para los eventos relacionados con reprocesos */
import onmount from 'onmount';

const methods = {
  subscribeCreatePayrollReprocessEvent,
  subscribeCheckPayrollReprocessEvent,
  subscribePayPayrollReprocessEvent,
  subscribeRemoveReprocessEvent,
};

function subscribeCreatePayrollReprocessEvent($event) {
  $event.on('click',
    (e) => {
      const data = {
        'payroll_process_id': +$(e.currentTarget).data('id'),
      };
      $(e.target).trigger('amplitudeTracking', { message: 'Create_Payroll_Reprocess', data: data });
    }
  );
}

function subscribeCheckPayrollReprocessEvent($event) {
  $event.on('click',
    (e) => {
      const data = {
        'payroll_process_id': +$(e.currentTarget).data('id'),
      };
      $(e.target).trigger('amplitudeTracking', { message: 'Check_Payroll_Reprocess', data: data });
    }
  );
}

function subscribePayPayrollReprocessEvent($event) {
  $event.on('click',
    (e) => {
      const data = {
        'payroll_process_id': +$(e.currentTarget).data('id'),
      };
      $(e.target).trigger('amplitudeTracking', { message: 'Pay_Payroll_Reprocess', data: data });
    }
  );
}

function subscribeRemoveReprocessEvent($event) {
  $event.on('click',
    (e) => {
      const reprocessId = $(e.currentTarget).data('id');
      if (reprocessId) {
        const data = {
          'payroll_process_id': +reprocessId,
        };
        $(e.target).trigger('amplitudeTracking', { message: 'Remove_Reprocess', data: data });
      }
    }
  );
}

onmount('#confirmar_reproceso_submit_button', async function () {
  subscribeCreatePayrollReprocessEvent($(this));
});

onmount('#revisar_nomina_button_reproceso', async function () {
  subscribeCheckPayrollReprocessEvent($(this));
});

onmount('#pagar_submit_button_reproceso', async function () {
  subscribePayPayrollReprocessEvent($(this));
});

onmount('#remove_reprocess_button', async function () {
  subscribeRemoveReprocessEvent($(this));
});

export default methods;
