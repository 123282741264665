/** @file
*
* Se encarga de mostrar el loader en las tablas de fiji cuando se inicia
* la solicitud. Esconde el body de la tabla y muestra el loader.
*/
import onmount from 'onmount';

const showFijiLoader = (tableId, loaderId) => {
  $(`#${tableId}`).addClass('d-none');
  $(`#${loaderId}`).removeClass('d-none');
};

function getFijiFieldsIds(element) {
  function fijiGetResourceId() {
    const tableBodyElement = element.closest('.fiji-index');
    return tableBodyElement.id.replace('index_', '');
  }

  function getFijiTableId(resourceId) {
    return `content-table_${resourceId}`;
  }

  function getFijiLoaderId(resourceId) {
    return `fiji-loader_${resourceId}`;
  }

  const resourceId = fijiGetResourceId(element);
  const tableId = getFijiTableId(resourceId);
  const fijiLoaderId = getFijiLoaderId(resourceId);
  return { resourceId, tableId, fijiLoaderId };
}

onmount('.fiji-sort-link', function () {
  const { tableId, fijiLoaderId } = getFijiFieldsIds(this);

  $(this).on('click', function () {
    showFijiLoader(tableId, fijiLoaderId);
  });
});

onmount('.fiji-pagination', function () {
  const { tableId, fijiLoaderId } = getFijiFieldsIds(this);

  const links = document.querySelectorAll('.fiji-pagination a');
  links.forEach(link => {
    link.addEventListener('click', () => {
      showFijiLoader(tableId, fijiLoaderId);
    });
  });
});

onmount('.fiji-body #data_filter_btn', function () {
  const { tableId, fijiLoaderId } = getFijiFieldsIds(this);

  $(this).on('click', function () {
    showFijiLoader(tableId, fijiLoaderId);
  });
});

window.showFijiLoader = showFijiLoader;
