/** @file TODO: documentar */
import ModalBuilder from '../lib/modal-builder';
import onmount from 'onmount';


// TODO: eliminar y reemplazar por modal-show

onmount('#modal_message', function () {
  const $self = $(this);
  const modalBuilder = new ModalBuilder();
  modalBuilder.setBody($self.html()).setTitle(' ').show();
});
