/** @file Archivo utilizado para filtrar la fecha de la tabla dependiendo de un selector */
import onmount from 'onmount';
import { formatCurrencyByCountry } from '../../../../../../../app/assets/javascripts/lib/buk/format-currency';

var regex = /\d+/g;

onmount('#select-filter', function () {
  // Se actualiza el valor del input con el valor inicial del selector
  updateInputValue($(this).val());
  updateTotalAmount($(this).val());

  $('#select-filter').on('change', function () {
    updateInputValue($(this).val());
    updateTotalAmount($(this).val());
  });
});

function updateInputValue(filterPeriod) {
  $('#input-filter').val(filterPeriod).trigger('input');
}

function updateTotalAmount(filterPeriod) {
  const table = document.getElementById('advances-table');
  const country =  table.getAttribute('data-employee-country');

  const informacionTabla = Array.from(table.rows).slice(1);
  if (informacionTabla[0].innerText === 'Ningún dato disponible en esta tabla') return;

  const total = informacionTabla.reduce((acc, row) => {
    const advanceDate = row.cells.item(0).innerHTML;
    const advanceStatus = row.cells.item(2).innerHTML;
    let amountRow = 0;

    if (advanceDate.includes(filterPeriod) && !advanceStatus.includes('Fallido')) {
      const amountRawValue = row.cells.item(1).innerHTML;
      const integers = amountRawValue.match(regex);

      const totalNumbers = integers.length;
      // eslint-disable-next-line arrow-body-style
      amountRow = integers.reduce((acumulator, value, index) => {
        return acumulator + value * Math.pow(1000, totalNumbers - 1 - index);
      }, 0);
    }
    return acc + amountRow;
  }, 0);

  $('#total-amount').html(formatCurrencyByCountry(total, country));
}
