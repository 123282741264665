/** @file Comportamientos del formulario de creación/edición de asignaciones */
import onmount from 'onmount';

onmount('#new_assign', function () {
  const employeeCountry = $('#assign_country').prop('value');
  if (employeeCountry.includes('Peru')) {
    setBehaviourForPeruAttributes(this);
  }
});

onmount('.edit_assign', function () {
  const employeeCountry = $('#assign_country').prop('value');
  if (employeeCountry.includes('Peru')) {
    setBehaviourForPeruAttributes(this);
  }
});

/**
 * @param  {boolean} employeeView
 * @param  {HTMLElement} form
 */
function setBehaviourForPeruAttributes(form) {
  $(form).on('change', '#assign_bono_id', function () {
    const bonoId = parseInt($('#assign_bono_id').prop('value'));
    const bonosArray = JSON.parse('[' + $('#assign_bonos_array').prop('value') + ']');
    const bono = bonosArray.find(item => item.id === bonoId);

    /* Cuando el bono posee monto total de crédito (lo que implica que el bono es distinto por persona), entonces bloqueamos el valor cuota y
    el monto total de la asignación, ya que el usuario solo puede decidir el numero de cuotas a trabajar. Con eso, el sistema automaticamente
    debe calcular cuanto es el valor cuota en base al input del usuario */
    if (bono.monto_total_credito > 0) {
      $('#assign_amount').prop('readonly', true);
      $('#assign_total_credit_amount').prop('readonly', true);
      $('#assign_total_credit_amount').val(bono.monto_total_credito);
      $('#assign_agregar_monto_total_credito').prop('checked', true);
      $('.assign_agregar_monto_total_credito').hide();

      $(form).updateAmount();
    }
    /* Cuando el bono es de tipo distinto por persona, y no posee monto total del creidto, se habilita un checkbox donde el usuario debe decidir que campo va
    a ingresar: valor por cuota, o el monto total. La cantidad de cuotas siempre debe ingresar, de forma que cuando el usuario
    ingresa 2 cuotas, calculamos la tercera con ella. */
    else if (bono.igual_para_todos === false && bono.monto_total_credito === 0) {
      $('#assign_amount').val(0);
      $('#assign_total_credit_amount').val(0);

      $('#assign_amount').prop('readonly', false);
      $('#assign_total_credit_amount').prop('readonly', true);

      $('#assign_agregar_monto_total_credito').prop('checked', false);
      $('.assign_agregar_monto_total_credito').show();
    }
    /* Cuando el bono es igual para todos, y no posee monto total del bono, debe seguir funcionando como lo hace actualmente:
    se guardar el monto del bono como cuota de la asignacion, se debe ocultar el checkbox, y el monto total del credito se calcula
    en base a los otros dos campos.*/
    else {
      $('#assign_amount').prop('value', bono.cuota);
      $('#assign_amount').prop('readonly', true);
      $('#assign_total_credit_amount').prop('readonly', true);
      $('#assign_agregar_monto_total_credito').prop('checked', false);
      $('.assign_agregar_monto_total_credito').hide();

      $(form).updateTotalCreditAmount();
    }
  });

  /* Al cambiar el checkbox "Agregar Monto Total de Crédito", se debe tener el siguiente comportamiento:
    - Si está marcado, entonces se debe bloquear el campo Cuota/Factor Mensual, y desbloquear el campo Monto Total del Cŕedito
    - Si está desmarcado, entonces se debe desbloquear el campo Cuota/Factor Mensual, y bloquear el campo Monto Total del Cŕedito

    En ambos casos, cuando el campo desbloqueado cambia, debemos cambiar el campo bloqueado por .js para ajustar ese valor.*/
  $(form).on('change', '#assign_agregar_monto_total_credito', function () {
    const isChecked = $('#assign_agregar_monto_total_credito').is(':checked');
    if (isChecked) {
      $('#assign_amount').prop('readonly', true);
      $('#assign_total_credit_amount').prop('readonly', false);
    }
    else {
      $('#assign_amount').prop('readonly', false);
      $('#assign_total_credit_amount').prop('readonly', true);
    }
  });

  /* Cuando cambia el campo Cuota/Factor Mensual, ajustamos el valor del Monto Total del Crédito, de forma que sea igual
  al producto de la cuota mensual con la cantitdad de cuotas.*/
  $(form).on('keyup', '#assign_amount', function () {
    $(form).updateTotalCreditAmount();
  });

  /* Cuando cambia el campo Monto Total del Crédito, ajustamos el valor de Cuota/Factor Mensual, de forma que sea igual
  a dividir el monto total entre la cantidad de cuotas (con dos decimales). */
  $(form).on('keyup', '#assign_total_credit_amount', function () {
    $(form).updateAmount();
  });

  /* Si cambia la cantidad de cuotas del crédito, vamos a revisar la el checkbox "Agregar Monto Total de Crédito" para ver
  que campo cambiamos. Si el checkbox está marcado, cambiamos el valor de "Cuota/Factor Mensual", si no está marcado, cambiamos
  el valor de "Monto Total del Crédito".*/
  $(form).on('keyup', '#assign_duracion', function () {
    const isChecked = $('#assign_agregar_monto_total_credito').is(':checked');

    const bonoId = parseInt($('#assign_bono_id').prop('value'));
    const bonosArray = JSON.parse('[' + $('#assign_bonos_array').prop('value') + ']');
    const bono = bonosArray.find(item => item.id === bonoId);

    if(bono.igual_para_todos) {
      $(form).updateTotalCreditAmount();
    }
    else if(isChecked) {
      $(form).updateAmount();
    }
    else{
      $(form).updateTotalCreditAmount();
    }
  });
}

$.fn.updateTotalCreditAmount = function () {
  const cuotas = parseFloat($('#assign_duracion').prop('value'));
  const valorCuota = parseFloat($('#assign_amount').prop('value'));

  if(isNaN(cuotas) || isNaN(valorCuota)) {
    $('#assign_total_credit_amount').val(0);
  }
  else {
    const monto = cuotas * valorCuota;
    $('#assign_total_credit_amount').val(monto);
  }
};

$.fn.updateAmount = function () {
  const cuotas = parseFloat($('#assign_duracion').prop('value'));
  const montoTotalCredito = parseFloat($('#assign_total_credit_amount').prop('value'));

  if(isNaN(cuotas) || isNaN(montoTotalCredito)) {
    $('#assign_amount').val(0);
  }
  else {
    const monto = (montoTotalCredito * 1.0 / cuotas).toFixed(2);
    $('#assign_amount').val(monto);
  }
};
