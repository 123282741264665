/** @file
 * Función global para usar morphing con Intercooler
 * Usar el atributo ic-on-success="morphElements" y el atributo ic-target="selector" para indicar el elemento raíz donde se hará morphing
 *
 */
import { Idiomorph } from 'idiomorph/dist/idiomorph.esm'; // eslint-disable-line buk/no-static-import
import onmount from 'onmount';

const shouldMorphElement = (oldNode, newNode) => {
  if (oldNode instanceof HTMLElement) {
    if (oldNode.hasAttribute('data-permanent')) {
      return false;
    }

    if (oldNode.hasAttribute('morph-replace')) {
      const parent = oldNode.parentElement;

      if (parent) {
        parent.replaceChild(newNode, oldNode);
      }

      return false;
    }
  }

  return true;
};

const morphElements = (elt, data) => {
  const target = elt[0].getAttribute('ic-target');

  const parser = new DOMParser();
  const html = parser.parseFromString(data, 'text/html');

  Idiomorph.morph(document.querySelector(target), html.querySelector(target), {
    ignoreActive: true,
    callbacks: { beforeNodeMorphed: shouldMorphElement },
  });

  // La llamada a onmount es idempotente, por lo que aquellos elementos que
  // ya han sido inicializados no se volverán a inicializar
  onmount();
  // Necesitamos reprocesar los nodos porque se pierden los event listeners de Intercooler al hacer morphing
  Intercooler.processNodes(document.querySelector(target)); // eslint-disable-line no-undef
  document.dispatchEvent(new CustomEvent('load-vue-components'));

  return false;
};

window.morphElements = morphElements;
