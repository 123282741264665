/** @file TODO: documentar */
import onmount from 'onmount';

function mostrar(element, required) {
  $(element).each(function () {
    $(this).attr('required', required);
    $(this).prop('disabled', false);
    $(this).prop('style', '');
    $(this).closest('div').show();
  });
}

function esconder(element) {
  $(element).each(function () {
    $(this).attr('required', false);
    $(this).prop('disabled', true);
    $(this).prop('style', 'display: none');
    $(this).closest('div').hide();
  });
}

onmount('#legal_agents_form,#company_banks_form,#variable_empresa_ccafs_form', function () {
  const $container = $(this);

  function legalAgentFlagCheckbox(vertical, horizontal) {
    const verticalSelector = $.escapeSelector(`[${vertical}]`);
    $container.on('change', `input[name$="${verticalSelector}"]`, function () {
      const self = this;
      $container.find(`input[name$="${verticalSelector}"]`).filter(function () {
        return this != self;
      }).prop('checked', false);
      const horizontalName = $.escapeSelector(this.name.replace(vertical, horizontal));
      $container.find(`input[name="${horizontalName}"]`).prop('checked', false);
    });
  }

  legalAgentFlagCheckbox('default', 'secondary');
  legalAgentFlagCheckbox('secondary', 'default');

  $('#empresa_pagar_con_banco_otra_empresa').on('change', function (event) {
    const { checked } = event.target;
    if(checked) {
      mostrar('#another_company_bank_payment_id', true);
      mostrar('#empresa_another_company_bank_payment_attributes_company_bank_id', true);
      esconder('.empresa_company_banks_custom_filename', true);
    }
    else {
      esconder('#another_company_bank_payment_id');
      esconder('#empresa_another_company_bank_payment_attributes_company_bank_id');
      mostrar('.empresa_company_banks_custom_filename');
    }
  });

  $('#company_banks_form').on('cocoon:after-insert', function () {
    const checked = $('#empresa_pagar_con_banco_otra_empresa').is(':checked');
    if(checked) {
      esconder('.empresa_company_banks_custom_filename', true);
    }
    else {
      mostrar('.empresa_company_banks_custom_filename');
    }
  });
});
