/** @file TODO: documentar */
import onmount from 'onmount';
import { error } from '../lib/buk/alert';
import { debounce } from 'lodash-es';
import { notyAlert } from '../lib/buk/noty-alert';

onmount(".sortable", async function () {
  const self = this;
  const proxy = $(self).data("input-proxy");
  const sortUrl = $(self).data("sort-url");
  const addUrl = $(self).data("url");
  const handle = $(self).data('handle');
  const group = $(self).data("group");
  const propDrag = $(self).data("use-drag");
  const sort = $(self).data("sort");
  const sortablePause = $(self).data('sortable_pause') || 0;
  const put = (data, url) => (
    $.ajax({
      method: 'PUT',
      dataType: 'json',
      url,
      data
    })
  );

  const updateOrderRemotely = function (order) {
    if (sortablePause) {
      notyAlert('Guardando', 500);
    }
    put({ order }, sortUrl)
      .done((response) => {
        if (response.error) error(response.message, 'Error de ordenamiento');
      })
      .fail(() =>
        error('Hubo un error al almacenar el orden. Por favor recargue la página e intente denuevo',
          'Error de ordenamiento')
      );
  };

  const debouncedUpdateOrderRemotely = debounce(updateOrderRemotely, sortablePause);

  const sortableOptions = {
    ...$(self).data(),
    animation: 150,
    preventOnFilter: false,
    filter: '.no-drag',
    onAdd: ({to, from, item}) => {
      // Se aplica la posicion de la lista en la que el elemento fue agregado
      const position = $(to).data('position');
      $(item).find('.position').val(position);

      // no hacer nada si no cambio de lista
      if (to == from) {
        return;
      }
      const items = $(to).children().toArray();
      const order = items.map((e) => $(e).data('id'));
      if (addUrl) {
        put({ order }, addUrl)
          .done((response) => {
            if (response.error) error(response.message, "Error de ordenamiento");
          })
          .fail(() =>
            error("Hubo un error al almacenar el orden. Por favor recargue la página e intente denuevo", "Error de ordenamiento")
          );
      }
    },
    onUpdate: () => {
      const item = $(self).children().toArray();
      const order = item.map((e) => $(e).data('id'));
      // actualiza el número de posición en la tabla
      item.forEach((e) => {
        $(e).find('.position').text($(e).index() + 1);
        $(e).find('.position').val($(e).index() + 1);
      });

      $(self).trigger('nestedFormSortableUpdatedElement');

      if (proxy) {
        const input = $(proxy);
        input.val(order.join('\\r\\n'));
        input.trigger('change');
      }

      if (sortUrl) {
        debouncedUpdateOrderRemotely(order);
      }
    },
    onChange: () => {
      // evento de actualizacion al sufrir cualquier cambio,
      // se usa para reactivar ckediors que se desactivan al ser reordenados.
      $(self).trigger('nestedFormSortableChangeElement');
    },
  };

  if (handle) sortableOptions.handle = handle;
  if (group) sortableOptions.group = group;
  if (propDrag) sortableOptions.draggable = '.drag';
  if (sort != undefined) sortableOptions.sort = sort;
  const  { default: Sortable } = await import('sortablejs');
  var sortable = Sortable.create(self, sortableOptions);
  $(self).data("sortable", sortable);
});
