/** @file Carga ckeditor cuando es necesario */

import pRetry from 'p-retry';
import { once } from 'lodash-es';

/**
 * Determina la versión del CKEditor según el FF :migracion_ckeditor5
 *
 * @return {String} Versión del ckeditor a cargar
 */
export function CKeditorVersion() {
  const url = $('#ckeditor-url').data('url');
  if (url.includes('ckeditor5')) return '5';
  return '4';
}

/**
 *
 * @param {String} src La url ;
 * @returns {Promise}
 */
function loadScript(src) {
  return new Promise(function (resolve, reject) {
    const s = document.createElement('script');
    let r = false;
    s.type = 'text/javascript';
    s.src = src;
    s.async = true;
    s.onerror = function (err) {
      reject(err, s);
    };
    s.onload = s.onreadystatechange = function () {
      // console.log(this.readyState); // uncomment this line to see which ready states are called.
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        resolve();
      }
    };
    const t = document.getElementsByTagName('script')[0];
    t.parentElement.insertBefore(s, t);
  });
}

/**
 * Carga el CKEditor en el tag especificado
 */
async function loadCKeditorScript() {
  const url = $('#ckeditor-url').data('url');
  await pRetry(() => loadScript(url), { retries: 3 });
}

export const loadCKeditor = once(loadCKeditorScript);

/**
 *
 * @params {String} texto que se quiere agregar el video a previsualizar
 */
function parseContentForLegacyVideos(text) {
  const html = document.createElement('html');
  html.innerHTML = text;
  const videoIframeList = html.querySelectorAll('.youtube-embed-wrapper > iframe');
  Array.from(videoIframeList).forEach(videoIframe => {
    videoIframe.parentElement.setAttribute('data-oembed-url', videoIframe.src);
  });
  return html.innerHTML;
}

function addDivWrapperWithCKContentClass(text) {
  if (text.includes('<div class="ck-content">')) return text;
  return `<div class="ck-content">${text}</div>`;
}

function parseLegacyTemplates(target) {
  let html = $(target).val();
  html = addDivWrapperWithCKContentClass(html);
  html = parseContentForLegacyVideos(html);
  return html;
}
/**
 * Carga el CKEditor en el tag y configuración que se le pasa como parámetro
 *
 * @param {String} Tag HTML donde se cargará el editor
 * @param {Object} Texto a insertar en el editor (se trae desde la base de datos)
 * @param {Object} Configuración del toolbar del editor
 * @returns {Object} Editor configurado
 */
export async function loadCKeditor5(target, obj, config) {
  await loadCKeditor();
  $(target).siblings('.ck.ck-reset.ck-editor.ck-rounded-corners').each((_, elem) => elem.remove());
  const { SIMPLE_EDITOR_OPTIONS } = await import('./custom_configs');
  if (!config) config = SIMPLE_EDITOR_OPTIONS();

  $(target).val(parseLegacyTemplates(target));

  return ClassicEditor.create(target, config) // eslint-disable-line no-undef
    .then(editor => {
      $(target).on('change', () => {
        if(editor.getData() !== $(target).val()) editor.setData($(target).val());
      });
      editor.model.document.on('change:data', () => {
        if(editor.getData() !== $(target).val()) {
          $(target).val(editor.getData());
          $(target).trigger('change');
        }
      });
      obj.editor = editor;
    });
}
