/** @file TODO: documentar */
import onmount from 'onmount';

// Para que se active el validation cuando levantamos un modal
onmount('form[data-client-side-validations]:visible', async function () {
  await import('@client-side-validations/client-side-validations');
  await import('@client-side-validations/simple-form');
  const self = this;
  $(self).enableClientSideValidations();

  // Esto se hace ya que los eventos de cocoon e intercooler tienen como target algo que esta dentro del form
  // entonces este onmount no se vuelve a ejecutar aunque exista app/assets/javascripts/vendor/onmount.js
  // El callback 'on' escucha tanto los eventos del elemento en si como de elementos
  // hijos, en cambio en onmount.js no se propagan las llamadas hacia arriba
  $(self).on('cocoon:after-insert cocoon:after-remove nodesProcessed.ic', function () {
    $(self).enableClientSideValidations();
  });
});
