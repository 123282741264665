/** @file TODO: documentar */
import onmount from 'onmount';

onmount('[data-excel-row-import]', function () {
  $(this).on('fileuploaddone', function (e, data) {
    const container = $(this).data('container-selector');
    const newElementButton = $(`${container} .add_fields_row, ${container} .add_fields`);

    const questions = data['_response'].result.importador_masivo;
    for (const question of questions) {
      const time = new Date().getTime();

      //Implementation for link to add row
      if (newElementButton.data('target')) {
        const regexp = new RegExp(newElementButton.data('id'), 'g');
        const target = newElementButton.data('target');
        const template = newElementButton.data('fields');
        const newElement = $(template.replace(regexp, time));
        newElement.appendTo(target).trigger('buk:load-content');
        newElement[0]['firstElementChild']['value'] = question;
      }

      //Implementation for cocoon
      else if (newElementButton.data('association-insertion-node')) {
        const target = newElementButton.data('association-insertion-node');
        const template = newElementButton.data('association-insertion-template');
        const assocs = newElementButton.data('associations');
        const insertionNode = $(target);

        const bracedReg = new RegExp('\\[new_' + assocs + '\\](.*?\\s)', 'g');

        const newElement = $(template.replace(bracedReg, `[${time}]$1`));

        //Duplicamos la logica de eventos de cocoon
        var beforeInsert = jQuery.Event('cocoon:before-insert');

        insertionNode.trigger(beforeInsert, [newElement, e]);
        if (!beforeInsert.isDefaultPrevented()) {
          newElement.appendTo(target).trigger('buk:load-content');
          newElement.find('input').val(question);

          insertionNode.trigger('cocoon:after-insert', [newElement, e]);
        }
      }
    }
  });
});
