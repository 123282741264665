/** @file
 * Permite eliminar un pedazo del DOM al hacer click en un elemento
 * Se puede definir el atributo data-target para definir el elemento más cercano que debe ser eliminado.
 *
 * Se ocupa usualmente en conjunto con link_to_remove_association cuando tenemos un nested attribute
 * que no se guarda como un registro en una tabla.
 *
*/
$(document).on('click', '[data-destroyable="true"] .destroy_record', function (event) {
  event.preventDefault();
  $(this).closest($(this).data('target') || 'tr').remove();
});
