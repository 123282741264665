/** @file
 * Transforma los tab tradicionales de bootstrap en scrollingTab.
 * Link de la libería: https://github.com/mikejacobson/jquery-bootstrap-scrolling-tabs
*/
import onmount from 'onmount';
import VisibilityTracker from '../lib/buk/visibility-tracker.js';

onmount('.nav-tabs-custom[data-scrolling-tabs=true]',  function () {
  const customTabs = $(this);
  customTabs.find('ul.nav.nav-tabs').scrollingTabs();

  // se guarda la variable tabs despues de haberse transformado en scrollingTabs.
  const tabs = customTabs.find('ul.nav.nav-tabs');
  const callback = (_element) => {
    tabs.scrollingTabs('refresh');
  };
  const tracker = new VisibilityTracker(callback);

  // en caso de estar en un contenedor collapsable o con cambios de visisblidad, se agregan trakers para refrescar los tabs.
  if(!$(tabs).is(':visible')) {
    tracker.watch(this);
    $(window).on('load', function () {
      tracker.watch(this);
    });
  }
});

function isTouchDevice() {
  /* eslint-disable-next-line no-undef */
  return true === ('ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch);
}

onmount('.nav-item.dropdown', function () {
  if(isTouchDevice()) {
    $('.nav-link.dropdown-toggle').on('click', function () {
      let dropdownMenu;
      setTimeout(() => {
        dropdownMenu = $(this).parent().find('.dropdown-menu.show');
        dropdownMenu.css('position', 'relative');
        dropdownMenu.css('margin', '8px 0');
        // Si se realiza un slide u otro gesto el menu se cierra
        addEventListener('touchmove', () => {
          if (dropdownMenu.hasClass('show')) {
            dropdownMenu.removeClass('show');
          }
        });
      }, 1);
    });
  }
});
