/** @file Comportamientos del formulario de creación / edición de servicios de boletas */
import onmount, { $ } from 'onmount';
import { toCode } from '../../../../../../../../app/assets/javascripts/lib/string.js';

$(document).on('turbolinks:load', function () {
  function handleNameChange() {
    $('#boleta_service_code').val(toCode($('#boleta_service_name').val()));
  }

  $('#boleta_service_name').keyup(handleNameChange);
});

onmount('.service-assign-fields', function () {
  /**
   * Listener para setear la cantidad de rows en base a los items seleccionados en el select2.
   */
  $('#service-assign-fields_source').click(function () {
    const numberOfOptionsSelected = $('option:selected', '#select2-service-assigns-service-id').length;
    $(this).data('count', numberOfOptionsSelected);
  });

  /**
   * Listener para evitar que el select2 ordene los items seleccionados y así saber cual fue el último agregado.
   * Fuente: https://stackoverflow.com/questions/31431197/select2-how-to-prevent-tags-sorting
   */
  $('#select2-service-assigns-service-id').on('select2:select', function (event) {
    const element = event.params.data.element;
    const $element = $(element);

    $element.detach();
    $(this).append($element);
    $(this).trigger('change');
  });

  const mutationObserver = new MutationObserver((mutationRecords) => {
    mutationRecords.forEach((mutationRecord) => {
      if (mutationRecord.type !== 'childList'
          && mutationRecord.type !== 'attributes'
          && mutationRecord.attributeName !== 'style') {
        return;
      }

      const noServicesMessage = $('.no-service-assign');
      const numberOfServices = $('#service-assign-fields>tr').toArray().reduce((accumulator, current) =>
        (current.style.display === 'none' || current.className.includes('no-service-assign'))
          ? accumulator : ++accumulator, 0);

      if ((mutationRecord.addedNodes.length > 0 || mutationRecord.attributeName === 'style') && numberOfServices > 0) {
        noServicesMessage.hide();
      }
      else if ((mutationRecord.removedNodes.length > 0 || mutationRecord.attributeName === 'style')
              && numberOfServices === 0) {
        noServicesMessage.show();
      }
    });
  });

  mutationObserver.observe(document.getElementById('service-assign-fields'),
    { childList: true, subtree: true, attributes: true, attributeFilter: ['style'] });

  /**
   *  Callback de la gema Cocoon que permite agregar/modificar un comportamiento antes
   *  de agregar los campos del formulario anidado. Aquí lo llamamos para que el formulario anidado
   *  se muestre con ciertos valores y atributos dependiendo del caso.
   *  Más detalles en: https://github.com/nathanvda/cocoon
   */
  $(this).on('cocoon:before-insert', async function (_e, insertedItem) {
    const fieldsToCopy = $(insertedItem).find('[data-copy-from]');
    const copyFunctionsByTag = {
      'SELECT-SELECT': copyToSelectFromSelect,
      'SPAN-SPAN': copyToSpanFromSpan,
      'INPUT-SPAN': copyToInputFromSpan,
      'SPAN-SELECT': copyToSpanFromSelect,
    };
    const previousIndex = $('#service-assign-fields_source').data('count');
    const index = previousIndex - 1;
    $('#service-assign-fields_source').data('count', index);

    insertedItem.hide();

    await Promise.all(fieldsToCopy.map((_, target) => {
      const source = $(target.dataset['copyFrom'])[0];
      const copyFunction = copyFunctionsByTag[target.tagName + '-' + source.tagName];
      return copyFunction(source, target);
    }));

    insertedItem.show();

    // Funciones auxiliares
    async function copyToSelectFromSelect(source, target) {
      const selectedOption = $('option:selected', source);
      const newOption = new Option(
        selectedOption[index].text,
        selectedOption[index].value,
        { defaultSelected: true, selected: true });
      target.append(newOption);
      return target;
    }

    async function copyToSpanFromSelect(source, target) {
      const selectedOption = $('option:selected', source);

      if (source.id === 'select2-service-assigns-service-id') {
        if (target.id === 'moneda_field') {
          $(target).attr('ic-get-from', (_index, value) => value?.replace(':id', selectedOption[index].value));
          $(target).attr('ic-src', (_index, value) => value?.replace(':id', selectedOption[index].value));
          return target;
        }
        // Reemplazamos el string :id por el id (real) en el URL del servicio.
        target.dataset['content'] = target.dataset['content']?.replace(':id', selectedOption[index].value);
      }

      target.innerHTML = selectedOption[index].text;
      return target;
    }

    async function copyToSpanFromSpan(source, target) {
      if (source.id === 'boleta-service-moneda') {
        const id = $('option:selected', $(fieldsToCopy[0].dataset['copyFrom'])[0])[index].value;
        const rawResponse = await fetch(`/boleta/services/${id}/moneda_field`);
        const response = await rawResponse.json();
        target.innerHTML = response.moneda;
        return target;
      }
      target.innerHTML = source.innerText;
      return target;
    }

    async function copyToInputFromSpan(source, target) {
      if (source.id === 'boleta-service-amount') {
        const id = $('option:selected', $(fieldsToCopy[0].dataset['copyFrom'])[0])[index].value;
        const rawResponse = await fetch(`/boleta/services/${id}/amount_field`);
        const response = await rawResponse.json();
        target.value = response.amount;
        (response.tipo_monto_unitario === 'unico') && target.setAttribute('readonly', true);
        return target;
      }
      target.value = source.innerText;
      return target;
    }
  });
});

/**
 * Para cambiar nombres en modal descuentos
 */
onmount('#secret-info-modal', function () {
  $('.modal-title')[1].innerText = $('#secret-info-modal').data('title');
  $('.title-h2')[0].innerText = $('#secret_detail').data('title');
  $('label[for=Servicios]')[0].innerText = $('#secret_search').data('title');
});
