/** @file TODO: documentar */
import onmount from 'onmount';

onmount('[data-control-variable-status]', function (state) {

  const self = $(this);
  // const $progreso = self.find('[data-behavior=progreso]');
  const $generarArchivos = self.find('[data-behavior=generar_archivos]');
  const $identifier = self.data('identifier');
  const $variableId = self.data('controlMes');
  const $headTools = '.head-tools';
  var $oldInput = [];

  state.intervals = [];

  function removeInterval(interval) {
    clearInterval(interval);
    const index = state.intervals.indexOf(interval);
    if (index !== -1) {
      state.intervals.splice(index, 1);
    }
  }

  function recoveryButtons(jobs) {
    if (jobs.length === 0) return;

    var stepCodesInUse = [];
    for (var jobKey in jobs) {
      var job = jobs[jobKey];
      if (job.step_code) stepCodesInUse.push(job.step_code);
    }
    for (var data in $oldInput) {
      var stepCode = data.split(",")[1];
      // En caso de no encontrarse significa que ya se genero por lo que se debe remplazar la vista
      if (stepCodesInUse.includes(stepCode)) break;
      location.reload();
    }
  }

  function exitLoop(interval) {
    removeInterval(interval);
    location.reload();
  }

  function handleGenerarPlanillasJob() {
    var progressElements = self.find('[data-step-code]');

    for (var i = 0; i <= progressElements.length - 1; i++) {
      var progressElement = progressElements[i];
      var stepCode = progressElement.getAttribute('data-step-code');
      var card = cardSelector($variableId, stepCode);
      handleProgress(card, 0);
    }
  }

  function cardSelector(variableId, stepCode) {
    return '#card-' + $identifier + "-" + variableId + '-' + stepCode + ' ';
  }

  async function handleProgress(card, progress) {
    const { default: numeral } = await import ('numeral');
    self.find(card + $headTools).html(numeral(progress).format('0 %'));
    self.find(card + '.dropdown-menu').hide();
    self.find(card + '.info-subtitle').html('Generando...');
    self.find(card + '.bux-card').addClass('disabled');
  }

  function handleCrearArchivoJob(job) {
    if (job.variable_id && $identifier === 'subprocess') return;
    const progress = getProgress(job);
    var card = cardSelector($variableId, job.step_code);
    var key = [$variableId, job.step_code];
    // Guardar el elemento para mostrarlo en caso de que termine
    if ($oldInput[key] === undefined) $oldInput[key] = self.find(card).html();
    handleProgress(card, progress);
  }

  function getProgress(job) {
    return job.progress ? job.progress / job.total : 0;
  }


  self.on('cerrando', function (_ev, url) {
    var env = self, interval;
    env;
    interval = setInterval(function () {
      $.ajax({
        url: url,
        success: function (jobs) {
          if (jobs.worker_statuses.length === 0) exitLoop(interval);
          for (var jobKey in jobs.worker_statuses) {
            var job = jobs.worker_statuses[jobKey];
            $generarArchivos.hide();
            if (job.error || job.status == 'failed') {
              var card = cardSelector($variableId, job.step_code);
              self.find(card + '.info-subtitle').html('Error: ' + job.step);
              self.find(card + '.bux-card').addClass('bux-card-error');
              continue;
            }

            if (isFinite(getProgress(job))) {

              if (job.step_class === 'crear_archivo_job' || job.step_class === 'generate_custom_report_job') {
                if (job.variable_id || job.buk_subprocess_id === $variableId) {
                  handleCrearArchivoJob(job);
                }
              }

              if (
                job.step_class === 'generar_planillas_job' &&
                jobs.worker_statuses.length === 1 &&
                !job.only_calculate
              ) {
                handleGenerarPlanillasJob();
              }
            }
          }
          recoveryButtons(jobs.worker_statuses);
        },
        error: function (err) {
          if (err.status !== 404) return;
          exitLoop(interval);
        },
      });
    }, 5000);

    state.intervals.push(interval);
  });
  const status = self.data('controlVariableStatus');
  if (status?.url) {
    self.trigger('cerrando', [status.url]);
  }
}, function (state) {
  if (state.intervals) {
    state.intervals.forEach(i => { clearInterval(i); });
    state.intervals.splice(0, state.intervals.length);
  }
});
