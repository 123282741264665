/**
 * @file Abre el contenido especificado en un aside-bar
 *
 * Existen 2 formas de mostrar el sidebar:
 * 1. Abrir el sidebar y cargar contenido remoto en él
 * 2. Utilizar el sidebar widget y mostrar el template disponible en data-open-aside-bar
 *
 * ## Sidebar Remoto
 *
 * Se debe marcar el atributo `open-aside-bar="link"` para especificar que se hara una carga remota.
 * El componente cargara el contenido desde el link especificado en el atributo `href` o desde un atributo `data-href`
 * ```ruby
 * link_to 'Ver resumen', some_path,
 *  data: {'open-aside-bar': 'link', 'aside-bar-title': 'Titulo'},
 *  class: 'btn btn-app'
 * ```
 *
 * ##Asidebar desde template
 *
 * Utilizar el widget `aside_bar` para construir un elemento que almacenara el template
 * en el atributo `data-open-aside-bar`
 *
 * El contenido no es sanitizado, puede ser html.
 */

import onmount from 'onmount';
import { mountVue } from '../vendor/vue-load';
import { getInteractiveAncestorTag } from '../lib/interaction-functions.js';

function buildRenderAsyncLoader(href) {
  return `
    <div data-render-async="${href}"
         class="selection-process-aside">
      <div class="container-loader">
        <div class="buk-loader loader-large"></div>
      </div>
    </div>
  `;
}

onmount('[data-open-aside-bar]', function (obj) {
  const self = this;
  $(this).off('click').on('click', async ev => {
    // Si el target tiene un padre que deba capturar el evento
    // no hacemos nada.
    const ancestor = getInteractiveAncestorTag(ev.target);
    if (ancestor.length > 0 && ancestor.attr('data-open-aside-bar') === undefined && ev.target !== ev.currentTarget) {
      return;
    }

    const title = $(self).data('aside-bar-title');
    const size = $(self).data('aside-bar-size');
    const asidebar = $(self).data('open-aside-bar');
    let slot = '';

    // Cuando asidebar es igual a "link" utilizamos el href del
    // elemento para cargar el contenido de forma remota
    if (asidebar === 'link') {
      slot = buildRenderAsyncLoader($(self).attr('href') || $(self).attr('data-href'));
    }
    else {
      slot = asidebar;
    }

    // Evitar que se monte mas de un sidebar a la vez
    if ($('.aside-base').length > 0) {
      $('.aside-base').remove();
    }

    const html = '<aside-bar></aside-bar>';
    const $bar = $(html);
    $('body').prepend($bar);

    ev.preventDefault();
    // Tenemos que evitar que en futuros click se siga el link
    $(self).on('click', event => event.preventDefault());
    obj.vue = await mountVue({
      el: $bar[0],
      props: {
        title: title,
        size: size,
        isOpen: true,
        triggerBtn: self,
        body: slot,
      },
    });
  });
}, function (obj) {
  if (obj.vue) {
    obj.vue.$destroy();
    // El elemento donde vue fue montado no lo elimina vue al destruirse, así
    // que lo debemos eliminar nosotros para que no vaya a pasar que el sidebar
    // quede abierto
    $(obj.vue.$el).remove();
  }
});

/**
 * Cierra el sidebar
 */
onmount('[data-close-asidebar]', function () {
  var asidebar = $(this).parents('.aside-base')[0];
  if (asidebar) {
    const close = asidebar.__vue__.close;
    $(this).on('click', close);
  }
});
