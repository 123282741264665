/** @file Archivo utilizado para mover las notificaciones de éxito o error dentro del container de adelantos */
import onmount from 'onmount';

const posibleAlertsMessages = ['alert-success', 'alert-danger'];

document.addEventListener('DOMContentLoaded', function () {
  if (window.location.href.indexOf('section=adelantos') > -1) {
    changeAlertsDisplay(false);
  }
});

onmount('#errores-solicitud-adelantos', function () {
  if (window.location.href.indexOf('section=adelantos') > -1) {
    changeAlertsPosition();
    changeAlertsDisplay(true);
  }
});

function changeAlertsDisplay(show) {
  posibleAlertsMessages.forEach((alertKind) => {
    const alertElements = document.getElementsByClassName(`${alertKind} alert alert-dismissible`);

    if (alertElements.length === 0) return;

    alertElements.item(0).style.display = show ? 'block' : 'none';
  });
}

function changeAlertsPosition() {
  posibleAlertsMessages.forEach((alertKind) => {
    const alertElements = document.getElementsByClassName(`${alertKind} alert alert-dismissible`);

    if (alertElements.length === 0) return;

    const divAdelantosMessage = document.getElementById('errores-solicitud-adelantos');
    divAdelantosMessage.before(alertElements.item(0));
  });
}
