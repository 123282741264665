
/** @file
 JS para pagos retroactivos.
 Verifica si el formulario con ID 'new_retroactive_job_change' es válido antes de enviar la solicitud.
 Si es válido, utiliza Intercooler para ejecutar la solicitud AJAX.
*/
import onmount from 'onmount';
import Intercooler from '../../../../../../../app/assets/javascripts/lib/intercooler';

onmount('#simulate-retroactive, #simulate-again', function () {
  const form = document.getElementById('new_retroactive_job_change');
  $(this).on('click', () => {
    if(form.reportValidity()) {
      Intercooler.triggerRequest(this);
    }
  });
});
