/** @file Comportamientos del index de órdenes de boleta **/

import onmount from 'onmount';

var eventTypes = {
  'unselect': toogleUnselect,
  'globalCheck': toogleGlobalCheck,
};

onmount('#base-user-config', function () {
  const moreInfoCheckbox = $('input[name=base_user_show_section_more_information]');
  const familyGroupCheckbox = $('input[name=base_user_show_family_group]');
  const seeSubordinatesSelect = $('select[name=base_user_see_subordinates]');
  const seeSubordinatesDocumentsCheckbox = $('input[name=base_user_see_subordinates_documents]');
  const seeSubordinatesExportersCheckbox = $('input[name=base_user_see_subordinates_exporters]');
  const seeAllowBossCalendarAccessCheckbox = $('input[name=base_user_allow_boss_calendar_access]');
  const seeSubordinatesVacationExporterCheckbox = $('input[name=base_user_see_subordinates_vacation_exporter]');
  const showDocumentsTabCheckbox = $('input[name=base_user_show_documents_tab][type=checkbox]');
  const showAutomanageRentCertificateCheckbox = $('input[name=base_user_show_automanage_rent_certificate]');
  const showAutomanageDocumentsCertificateCheckbox = $('input[name=base_user_show_automanage_documents]');

  const handleComponentDisable = async () => {
    await import('../../../../../../../../\
app/assets/javascripts/components/checkbox_dependant_disable'
    );
  };

  const dependencies = [{
    'trigger': seeSubordinatesSelect,
    'dependants': [
      seeSubordinatesDocumentsCheckbox,
      seeSubordinatesExportersCheckbox,
      seeAllowBossCalendarAccessCheckbox,
      seeSubordinatesVacationExporterCheckbox,
    ],
  }, {
    'trigger': showDocumentsTabCheckbox,
    'dependants': [
      showAutomanageRentCertificateCheckbox,
      showAutomanageDocumentsCertificateCheckbox,
    ],
  }, {
    'trigger': moreInfoCheckbox,
    'dependants': [
      familyGroupCheckbox,
    ],
  }];

  moreInfoCheckbox.change(function () {
    handleComponentDisable(moreInfoCheckbox, 'checkbox', dependencies);
  });
  seeSubordinatesSelect.change(function () {
    handleComponentDisable(seeSubordinatesSelect, 'select', dependencies);
  });
  showDocumentsTabCheckbox.change(function () {
    handleComponentDisable(showDocumentsTabCheckbox, 'checkbox', dependencies);
  });
  // Hacemos trigger para que al entrar a la vista esten deshabilitados los checkbox que deban estarlo
  moreInfoCheckbox.trigger('change');
  seeSubordinatesSelect.trigger('change');
  showDocumentsTabCheckbox.trigger('change');
});

$.onmount('.base-user-configuration-form-container', function () {
  const $container = $(this);
  const handleForceCheck = async () => {
    await import('../../../../../../../../\
app/assets/javascripts/components/checkbox_dependant_disable'
    );
  };

  $container.on('select2:select', '[id$="visible_fields_configuration"]', function (e) {
    var idSelected = e.params.data.id;
    var element = $container.find(`[data-form-field="${idSelected}"]`);
    element.show(400);
  });

  $container.on('select2:unselect', '[id$="visible_fields_configuration"]', function (e) {
    var idSelected = e.params.data.id;
    var element = $container.find(`[data-form-field="${idSelected}"]`);
    element.hide(400);
    toogleField(idSelected, $container, false, 'unselect');
  });

  changeEditableCheckbox();

  const paymentMethodEditableCheckbox = $('input[id=editable_payment_detail');
  const paymentMethodApprovalCheckbox = $('input[id=approval_payment_detail');

  const forceCheckDependencies = [{
    'trigger': paymentMethodEditableCheckbox,
    'dependants': [
      paymentMethodApprovalCheckbox,
    ],
  }, {
    'trigger': paymentMethodApprovalCheckbox,
    'dependants': [
      paymentMethodEditableCheckbox,
    ],
  },
  ];

  paymentMethodEditableCheckbox.change(function () {
    handleForceCheck(paymentMethodEditableCheckbox, forceCheckDependencies);
  });

  paymentMethodApprovalCheckbox.change(function () {
    handleForceCheck(paymentMethodApprovalCheckbox, forceCheckDependencies);
  });
});

function toogleField(idSelected, formContainer, checked, type) {
  var fieldContainer = formContainer.find(`[data-form-field="${idSelected}"]`);
  var checkbox = formContainer.find(`[id$="editable_${idSelected}"]`);
  var visible = $(fieldContainer).is(':visible');
  var disabled = checkbox.prop('disabled');

  var method = eventTypes[type];
  method(visible, disabled, checkbox, checked);
}

function toogleUnselect(_visible, _disabled, checkbox, checked) {
  checkbox.prop('checked', checked);
}

function toogleGlobalCheck(visible, disabled, checkbox, checked) {
  if (visible && !disabled)
    checkbox.prop('checked', checked);
}

function changeEditableCheckbox() {
  const wrapper = $('div[data-form-field="editable_fields"]');
  const rows = $('div[data-form-field]', wrapper);

  rows.each(function () {
    const row = $(this);
    $('.editable_check', row).on('change', function () {
      if ($(this).prop('checked') === false) {
        $('.approval_check', row).prop('checked', false);
      }
    });
    $('.approval_check', row).on('change', function () {
      if ($(this).prop('checked') === true) {
        $('.editable_check', row).prop('checked', true);
      }
    });
  });
}
