/** @file
 * Archivo de marcas de amplitud para el importadores
 */
import onmount from 'onmount';

onmount('div#importers-list-section', async function () {
  $('div#importers-list-section div.bux-card').on('click', function () {
    const targetValue = $(this).data('target');
    const element = $('div.bux-card[data-target="' + targetValue + '"]');
    const linkElement = element.find('a');
    const importData = {
      'loader_type': linkElement.data('type'),
      'loader_name': linkElement.data('name'),
      'loader_category': linkElement.data('category'),
      'loader_subcategory': linkElement.data('subcategory'),
    };
    importStorage(importData);
    sendAmplitudeTracking(element, 'View_Loader', importData);
  });
});

onmount('#modal_importer', async function () {
  $('button#donwload-template-button').on('click', function () {
    const element = $(this);
    const importData = JSON.parse(sessionStorage.getItem('importData'));
    sendAmplitudeTracking(element, 'Download_Template_Loader', importData);
  });
});

function sendAmplitudeTracking(element, message, data) {
  element.trigger('amplitudeTracking', {
    message: message,
    data: data
  });
}

function importStorage(imporData) {
  sessionStorage.setItem('importData', JSON.stringify(imporData));
}
