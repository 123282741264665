/** @file
 * comportamientos js para Rapidfire
 */
import onmount from 'onmount';

onmount('[id^="scale_unit_question_"]', function () {
  const inputElement = $(this);
  const inputAppend = inputElement.closest('.input-group').find('.input-group-append span');

  if (inputAppend.length) {
    const originalText = inputAppend.text();
    const windowWidth = $(window).width();
    const maxChars = Math.floor(-5 + windowWidth / 70);

    if (originalText.length > maxChars) {
      const truncatedText = originalText.substring(0, maxChars) + '...';
      inputAppend.text(truncatedText);

      // Add tooltip
      inputAppend.attr('title', originalText);
      inputAppend.attr('data-toggle', 'tooltip');
      inputAppend.attr('data-placement', 'top');
      inputAppend.tooltip({ boundary: 'window' });
    }
  }
});
