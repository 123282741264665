/** @file TODO: documentar */
import { defer, once } from 'lodash-es';

import onmount from 'onmount';
import Intercooler from '../../../../../../../../app/assets/javascripts/lib/intercooler.js';

let notificationsHtml = '';
/** @var Date */
let lastUpdate = null;
const forceUpdateTime = 1000 * 60 * 30; // 30 min

const initialize = once(function (element) {
  $(element).on('after.success.ic', (_evt, _elt, data) => {
    lastUpdate = Date.now();
    notificationsHtml = data;
  });
  Intercooler.triggerRequest(element);
});

onmount('#global-notifications', function () {
  initialize(this);
  if (notificationsHtml) {
    $(this).html(notificationsHtml);
    // No podemos llamar onmount altiro porque entramos en un loop infinito
    defer(onmount);
    if (lastUpdate && lastUpdate + forceUpdateTime < Date.now()) {
      Intercooler.triggerRequest(this);
    }
  }
});
