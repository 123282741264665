/** @file Permite a un elemento "reenviar" un click a otro elemento o mandar evento click al cargarse el elemento
 *
 * Para reenviar un click recibe como parámetro un selector jquery, sobre el cual se generará un evento click
 * @example
 *   <button data-clicker-target="#otro-boton" />
 *
 * Para crear un evento click al cargarse el elemento solo necesita el data attribute 'click-on-load' en true
 * @example
 *   <button data-click-on-load=true />
 *
*/

import onmount from 'onmount';

onmount('[data-clicker-target]', function () {
  $(this).on('click', event => {
    event.preventDefault();
    const target = $(this).data('clicker-target');
    $(target).click();
  });
});

onmount('[data-click-on-load=true]', function () {
  $(this).click();
});
