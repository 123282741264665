/** @file TODO: documentar */
import onmount from 'onmount';

function getMesKPI() {
  return $('#kpi_upload_form_variable_id,#kpi_upload_form_kpi_id');
}

function allFilled() {
  return getMesKPI().filter((_, e) => !e.value).length == 0;
}

function toggle(elem) {
  elem[allFilled() ? 'show' : 'hide' ]('slow');
}

onmount('#kpi_upload_form_variable_id', function () {
  const $form = $(this).parents('form'),
    $link = $form.find('.download-template-button'),
    $file = $form.find('input[type=file]').parents('div.form-group');
  getMesKPI().on('change', () => toggle($link));
  getMesKPI().on('change', () => toggle($file));

  toggle($file);
  toggle($link);
});
