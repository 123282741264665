/** @file Archivo que se encarga de centralizar los registros de amplitude del módulo de adelantos */

import onmount from 'onmount';

onmount('#advances-request-button-next', function () {
  $(this).on('click', (e) => {
    const source = $(this).data('source');
    $(e.target).trigger('amplitudeTracking', {
      message: 'adelantos_request_button_clicked',
      data: adelantosData(source),
    });
  });
});

onmount('#advances-summary-button-next', function () {
  $(this).on('click', (e) => {
    const source = $(this).data('source');
    $(e.target).trigger('amplitudeTracking', {
      message: 'adelantos_confirm_button_clicked',
      data: adelantosData(source),
    });
  });
});

onmount('#advances-confirm-button-next', function () {
  $(this).on('click', (e) => {
    const source = $(this).data('source');
    $(e.target).trigger('amplitudeTracking', {
      message: 'adelantos_finalize_button_clicked',
      data: adelantosData(source),
    });
  });
});

function adelantosData(source) {
  const requestedAmount = $('#request_advance_amount').val() * 1;
  const maxAllowed = $('#request_advance_amount').attr('max') * 1;
  const percentage = Math.round(requestedAmount * 100 * 100 / maxAllowed) / 100;

  return {
    source,
    requestedAmount,
    maxAllowed,
    percentage,
  };
}
