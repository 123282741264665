/** @file TODO: documentar */
import onmount from 'onmount';
import Turbolinks from 'turbolinks';

/* agrega la propiedad bloodhound a cada elemento */
async function createBloodhounds(resources) {
  const { default: Bloodhound } = await import('corejs-typeahead/dist/bloodhound.js');
  const tok = Bloodhound.tokenizers.obj.whitespace('name');
  for (const obj of resources) {
    obj.bloodhound = new Bloodhound({
      datumTokenizer: d => tok(d),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      identify: d => d.id,
      limit: 10,
      remote: {
        url: obj.url + '?term=%QUERY',
        wildcard: '%QUERY',
        transform: d => d.results,
      },
    });
  }
}

const headerTemplate = `
<div class="tt-header">{model}</div>
`;

const globalEmptyTemplate = headerTemplate + `
<div class="no-results text-center text-muted">No hay resultados</div>
`;

const globalTemplate = headerTemplate + `
<div class="no-results text-center text-muted">Buscando {model}...</div>
`;

const globalElementTemplate = `
<div class="tt-suggestion tt-selectable clearfix">
  {img}
  <div class="name text-truncate" title="{name}">{regex}</div>
  {label}
  {grupo}
</div>
`;

function typeaheadDataset(name, source) {
  return {
    display: 'name',
    limit: 10,
    async: true,
    source: source,
    templates: {
      header: headerTemplate.replace('{model}', name),
      pending: globalTemplate.replace('{model}', name).replace('{model}', name),
      empty: globalEmptyTemplate.replace('{model}', name),
      suggestion: e => {
        const query = e._query.split(' ').filter(q => !q.startsWith('en:')).join(' ');
        const nameRegex = e.name.replace(new RegExp('(' + query + ')', 'ig'), '<strong>$1</strong>');
        return globalElementTemplate
          .replace('{label}', e.status_code)
          .replace('{regex}', nameRegex)
          .replace('{name}', e.name)
          .replace('{grupo}', e.group)
          .replace('{img}', e.picture);
      },
    },
  };
}

onmount('.navbar', function () {
  $('.navbar').on('click', '[data-toggle="navbar"]', function (event) {
    const target = $(event.delegateTarget);
    target.toggleClass('search-open');
  });
});

onmount('#global_search', async function () {
  const $self = $(this);
  /** @type {Object[]}  resources */
  const resources = $self.data('resources');

  if (resources.length === 0) {
    return;
  }
  await import(/* webpackChunkName: "typeahead" */ 'corejs-typeahead/dist/typeahead.jquery');

  await createBloodhounds(resources);

  const dataSets = resources.map((({ name, bloodhound }) => typeaheadDataset(name, bloodhound)));

  $self.typeahead(
    {
      hint: false,
    },
    ...dataSets);
  $self.on('typeahead:select', (_ev, suggestion) => {
    Turbolinks.visit(suggestion.url);
  });

  // presionar enter nos envia a la misma pagina con el texto buscado
  $self.on('keydown', function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  });
});

document.addEventListener('keyup', function (e) {
  // CTRL + B
  if (e.ctrlKey && e.code === 'KeyB') {
    $('#global_search').trigger('focus');
  }
}, false);
