/** @file Métodos utilizados para dar formato de moneda */
import numeral from 'numeral';
import { attemptFormat } from './format-number';

/** Formatea numero con separador de miles y agrega simbolo */
export function formatCurrency(v, type = '$') {
  if (attemptFormat(v)) {
    return `${type} ` + numeral(v).format(`0,000`);
  }
  return v;
}

/** Formatea numero con formato de internalización según país */
export function formatCurrencyByCountry(value, country) {
  if (!attemptFormat(value)) return value;

  const COUNTRY_INFO = {
    'chile': {
      code: 'es-CL',
      currency: 'CLP',
    },
    'colombia': {
      code: 'es-CO',
      currency: 'COP',
    },
    'peru': {
      code: 'es-PE',
      currency: 'PEN',
    },
    'mexico': {
      code: 'es-MX',
      currency: 'MXN',
    },
  };

  const countryInformation = COUNTRY_INFO[country];

  return new Intl.NumberFormat(countryInformation.code, {
    style: 'currency',
    currency: countryInformation.currency,
  })
    .format(value);
}

/** Formatea numero con separador de miles con input de valor decimal y agrega simbolo */
export function numberToCurrency({ value, type = '$' }) {
  const isDecimal = value % 1 !== 0;
  let symbol = '$';
  switch(type.toLowerCase()) {
    case 'uf':
      symbol = 'UF';
      break;
    case 'utm':
      symbol = 'UTM';
      break;
    case 'sol':
      symbol = 'S/';
      break;
    case 'pen':
      symbol = 'PEN';
      break;
  }
  /* ternario es para asegurar que el formato lo aplique tanto a inputs con decimales como enteros */
  value = Number(value).toFixed(2).toString().replace(/\./g, ',');
  return isDecimal ?  symbol + ' ' + value.replace(/\d(?=(\d{3})+,)/g, '$&.') : formatCurrency(value, symbol);
}
