/** @file TODO: documentar */
import { noop, flowRight } from 'lodash-es';
import onmount from 'onmount';
import { error as notifyError } from '../../../../../../../../../app/assets/javascripts/lib/buk/notice';

const geovictoria = {
  syncCount: 0,
  $submit: null,
};

onmount('.geovictoria_utils', function () {
  const $form = $(this).find('form');
  geovictoria.$submit = $form.find('[type=submit]');
  geovictoria.syncCount = 0;

  $form.on('beforeSend.ic', function () {
    geovictoria.$submit.prop('disabled', true);
  });

  $form.on('error.ic', function () {
    geovictoria.$submit.prop('disabled', false);
    notifyError("Error iniciando el proceso");
  });

});

onmount('.geo_victoria_run_status', function () {
  const $container = $(this);
  const runStatusPath = $container.data('run-status-path');
  const $runStatusText = $container.find('.run_status_text');
  const $runConflictsText = $container.find('.run_conflicts_details');
  const $progressBar = $container.find('.progress .progress-bar');
  const $spinIcon = $container.find('.run_status i');
  geovictoria.syncCount++;

  let updateId = -1;

  function error(msg) {
    $progressBar.addClass('progress-bar-danger').css('width', '100%');
    msg = msg || 'Ha ocurrido un error';
    $runStatusText.html(msg);
    $spinIcon.removeClass('fa-spin');
    geovictoria.$submit.prop('disabled', false);
    deleteRun();
    geovictoria.syncCount--;
    clearInterval(updateId);
  }

  function updateProgress(jobStatus) {
    if(jobStatus == null) {
      jobStatus = {
        progress: 1,
        total: 1,
        status: 'completed',
        message: 'Completado'
      };
    }
    if(jobStatus.status == "failed") {
      error('Ocurrió un error al realizar la operación.');
    }
    else{
      const partialProgress = jobStatus.progress / jobStatus.total;
      $progressBar.css('width', ((100 * partialProgress) + '%'));
      $runStatusText.html(jobStatus.message);
      if(partialProgress == 1) {
        finishProgress(jobStatus);
      }
    }
  }

  function finishProgress(jobStatus) {
    $progressBar.addClass('progress-bar-success');
    $spinIcon.removeClass('fa-spin');
    $runStatusText.html('Completado.');
    geovictoria.$submit.prop('disabled', false);
    clearInterval(updateId);
    if(jobStatus.conflicts) {
      $progressBar.addClass('progress-bar-warning');
      $runConflictsText.removeClass('hidden');
    }
    else{
      deleteRun();
      geovictoria.syncCount--;
    }
    if(geovictoria.syncCount === 0) {
      setTimeout(function () {
        window.location.reload();
      }, 4000);
    }
  }

  function deleteRun() {
    $.ajax({
      url: runStatusPath,
      type: 'DELETE'
    });
  }

  function checkRunProgress() {
    $.ajax({
      url: runStatusPath,
      type: 'get'
    }).done(function (runStatus) {
      updateProgress(runStatus);
    }).fail(function () {
      error();
    });
  }

  updateId = setInterval(checkRunProgress, 750);

});

onmount('.geovictoria_config_form', function () {
  const form = $(this);
  const $chkActivar = form.find('.geovictoria_configs_activar :checkbox, .geo_victoria_config_activar :checkbox');
  const hiddenActivar = form.find('.geo_victoria_config_activar').find(':input(type=hidden)');
  const $inputs = form.find(':input:not([id$=activar])');
  const overTimeSelect = form.find('.geo_victoria_config_overtime_endpoint').find('select');
  const syncWorkedHoursDiv = form.find('.geo_victoria_config_sync_worked_hours');
  const checkboxSyncWorkedHours = syncWorkedHoursDiv.find('input.form-check-input');
  $chkActivar.click(function () {
    $inputs.prop('disabled', !this.checked);
    // Dejamos el hidden de activar, para que se deshabilite efectivamente el GeoVictoriaConfig
    hiddenActivar.prop('disabled', false);
  });
  $inputs.prop('disabled', !$chkActivar.prop('checked'));
  hiddenActivar.prop('disabled', false);
  // Cada vez que se instancia un form de geovictoria config le añadimos
  // la lógica para que el input geo_victoria_config_sync_worked_hours dependa
  // del select geo_victoria_config_overtime_endpoint
  behaviorForOvertimeSelect(overTimeSelect, syncWorkedHoursDiv, checkboxSyncWorkedHours);
  overTimeSelect.on('change', function () {
    behaviorForOvertimeSelect($(this), syncWorkedHoursDiv, checkboxSyncWorkedHours);
  });
});

function behaviorForOvertimeSelect(overtimeSelect, syncWorkedHoursDiv, checkboxSyncWorkedHours) {
  if (overtimeSelect.val() === 'overtime_list') {
    syncWorkedHoursDiv.hide('slow');
    // Obligamos a syncWorkedHours a ser true en este caso.
    checkboxSyncWorkedHours.prop('checked', true);
  }
  else {
    syncWorkedHoursDiv.show('slow');
  }
}
class GeoVictoriaSyncMonitor {

  constructor(statusUrl) {
    this.url = statusUrl;
    this.intervalTime = 4000;
  }

  getStatus(callback) {
    $.get(this.url).done(callback);
  }

  watch(callback) {
    const self = this;
    this.intervalId = setInterval(function () {
      self.getStatus(callback);
    }, this.intervalTime);
  }

  stop() {
    if(this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}

class GeoVictoriaSyncProcess {

  constructor(statusUrl) {
    const self = this;
    this.syncMonitor = new GeoVictoriaSyncMonitor(statusUrl);
    this.initializeHandler = noop;
    this.runningHandler = noop;
    this.failHandler = noop;
    this.completeHandler = noop;
    this.statusUpdateHandler = function (status) {
      if(status.status == "completed") {
        self.stop();
        self.completeHandler(status);
      }
      else if(status.status == "failed") {
        self.stop();
        self.failHandler(status);
      }
      return status;
    };
  }

  initialize() {
    const self = this;
    this.syncMonitor.getStatus(function (status) {
      self.initializeHandler();
      self.statusUpdateHandler(status);
      if(status.status == "queued" || status.status == "working") {
        self.syncMonitor.watch(self.statusUpdateHandler);
        self.runningHandler();
      }
    });
  }

  start(processUrl, data, errorHandler) {
    const self = this;
    $.ajax({url: processUrl, type: "post", data: data}).done(function () {
      self.syncMonitor.watch(self.statusUpdateHandler);
      self.runningHandler();
    }).fail(errorHandler);
  }

  stop() {
    this.syncMonitor.stop();
  }

  onInitialize(callback) {
    this.initializeHandler = callback;
  }

  onRunning(callback) {
    this.runningHandler = callback;
  }

  onComplete(callback) {
    this.completeHandler = callback;
  }

  onFail(callback) {
    this.failHandler = callback;
  }

  onUpdatedStatus(callback) {
    const updateCallback = function (status) {callback(status); return status; };
    this.statusUpdateHandler = flowRight(updateCallback, this.statusUpdateHandler);
  }

}

class GeoVictoriaConflictsHandler {

  constructor(conflictsUrl) {
    this.conflictsUrl = conflictsUrl;
    this.updateConflictsHandler = noop;
  }

  updateStatus() {
    $.ajax({url: this.conflictsUrl}).done(this.updateConflictsHandler);
  }

  onConflictsUpdate(callback) {
    this.updateConflictsHandler = callback;
  }

}

onmount('.geo_victoria_process', function () {
  const statusUrl = $(this).data('statusUrl');
  const $overlay = $(this).find('.overlay');
  const $progressQty = $(this).find('.info-box-number');
  const $progressText = $(this).find('.info-box-text');
  const $progressAnimation = $(this).find('i.fa-spin');
  const $progressCompleted = $(this).find('[role=progressbar]');
  const $initForm = $(this).find('form.process');
  const $initFormSubmit = $initForm.find("[type=submit]");
  const $syncRange = $(this).find('.process-summary .sync-range');
  const $overtimeSyncRange = $(this).find('.process-summary .overtime-sync-range');
  const $applicationPeriod = $(this).find('.process-summary .application-period');
  const $gvUsers = $(this).find('.process-summary .gv-users');
  const $conflicts = $(this).find('.conflicts');
  const conflictsUrl = $conflicts.data('conflictsUrl');
  const $conflictsBox = $conflicts.find('.box-body');
  const $review = $(this).find('.review');

  const process = new GeoVictoriaSyncProcess(statusUrl);
  let conflicts = null;
  if($conflicts.length) {
    conflicts = new GeoVictoriaConflictsHandler(conflictsUrl);
  }

  function progressStatus(status) {
    return (status.progress / status.total);
  }

  function updateStatus(status) {
    $gvUsers.text(status.gv_users);
    if(status.to_process) $progressQty.text(`${status.processed} / ${status.to_process}`);
    $progressText.text(status.text);
    $syncRange.text(status.sync_range);
    $overtimeSyncRange.text(status.overtime_sync_range);
    $applicationPeriod.text(status.application_period);
    $progressCompleted.css('width', `${progressStatus(status) * 100}%`);
  }

  process.onUpdatedStatus(updateStatus);

  if(conflicts) {
    conflicts.onConflictsUpdate(function (conflictsHtml) {
      $conflicts.removeClass('hidden');
      $conflictsBox.html(conflictsHtml);
    });
  }

  process.onInitialize(function () {
    $overlay.hide();

    $initForm.submit(function () {
      $initFormSubmit.prop('disabled', true);
      $conflicts.addClass('hidden');
      $review.addClass('hidden');
      $progressCompleted.css('width', 0);

      process.start($(this).attr('action'), $(this).serialize(), function (response) {
        if(response.responseJSON) {
          notifyError(response.responseJSON.message || "Ha ocurrido un error iniciando el proceso.");
        }
        else{
          notifyError("Ha ocurrido un error iniciando el proceso.");
        }
      });

      $initFormSubmit.prop('disabled', false);
      return false;
    });

    $initFormSubmit.prop('disabled', false);
  });

  process.onRunning(function () {
    $progressAnimation.removeClass('invisible');
    $progressCompleted.removeClass('progress-bar-danger progress-bar-success').addClass('progress-bar-primary');
    $initFormSubmit.prop('disabled', true);
  });

  process.onComplete(function (status) {
    $progressAnimation.addClass('invisible');
    $progressCompleted.removeClass('progress-bar-primary').addClass('progress-bar-success');
    $initFormSubmit.prop('disabled', false);
    if(conflicts && status.total_conflicts && status.total_conflicts > 0) {
      conflicts.updateStatus();
    }
    if(status.review) {
      $review.removeClass('hidden');
    }
  });

  process.onFail(function () {
    $progressAnimation.addClass('invisible');
    $progressCompleted.removeClass('progress-bar-primary').addClass('progress-bar-danger');
    $initFormSubmit.prop('disabled', false);
  });

  process.initialize();

});
