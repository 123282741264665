/** @file Métodos utilizados para dar formato de número */
import numeral from 'numeral';

/** Verificar si el valor dado puede ser interpretado como número */
export function attemptFormat(v) {
  return (typeof v === 'number') || (typeof v === 'string' && numeral.validate(v));
}

/** Formatea numero con separador de miles */
export function formatNumber(v) {
  if (attemptFormat(v)) {
    return numeral(v).format('0,000');
  }
  return v;
}

/** Formatea numero a decimal */
export function formatNumberDecimal(v) {
  if (attemptFormat(v)) {
    return numeral(v).format('0,000.00');
  }
  return v;
}
