/** @file
 * Archivo de marcas de amplitud para modelo filter query
 */
import onmount from 'onmount';

onmount('#new-filter-query-group-button', function () {
  $(this).on('click', (e) => {
    const event = $(this).data('event');
    const source = $(this).data('source');

    $(e.target).trigger('amplitudeTracking', {
      message: event,
      data: {
        source,
      },
    });
  });
});
