/** @file TODO: documentar */
import ModalBuilder from '../lib/modal-builder';

import {
  prepareLinkContainer,
  handleLinkContainer,
} from './multi-select-common.js';

import onmount from 'onmount';

onmount('[data-multi-select-modal]', async function () {
  const $source = $(this.getAttribute('data-multi-select-source'));
  const sourceIsDatatable = $source.data("DataTable");
  var inputType = 'input[type=checkbox]:checked';
  if (sourceIsDatatable) {
    await $source.waitDataTable();
    inputType = 'tbody input[type=checkbox]:checked';
  }
  const link = $(this);
  const listContainer = link.closest('li');

  prepareLinkContainer(listContainer);

  link.on('click', function (e) {
    e.preventDefault();
    var $self = $(this);
    if (listContainer.hasClass('disabled') || link.hasClass('disabled')) {
      return false;
    }
    var url = $self.attr('href');
    var title = $self.attr('title');
    var size = $self.data('modalSize');
    var method = $self.data('method');

    var params = $source.find(inputType).serializeHash();
    var modalBuilder = new ModalBuilder();
    modalBuilder.setSize(size).show().loadFromRemote(url, params, title, method);

    e.preventDefault();
    return false;

  });

  $source.on('checkboxCount', (event) => {
    handleLinkContainer(listContainer, event.count);
    link.find('span').text('(' + event.count + ')');
  });

});
