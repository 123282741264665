/** @file TODO: documentar */
import onmount from 'onmount';
import { once, merge } from 'lodash-es';

const windowLoad = $.Deferred();

$(window).on('load', () => windowLoad.resolve());

const addScript = once(function addScript() {
  const script = document.createElement('script');
  script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
  script.defer = true;
  script.async = true;

  document.body.appendChild(script);
});

const initBeamerNews = (element) => {
  if (window.beamer_config) return false;

  const basicData = {
    product_id: 'XLZlrlKD16321',
    selector: '#beamer-news',
    language: 'ES',
    counter: false,
    mobile: false,
    display: 'right',
    callback: function (num) {
      if (num > 0) {
        $('#beamer-news .chip.news-count').text(num).removeClass('d-none');
      }
    },
  };
  const extraData = element.data('extra-beamer') || {};
  window.beamer_config = merge(basicData, extraData);
  windowLoad.then(addScript);
};

if (!window.disableBeamer) {
  onmount('#beamer-news', function () {
    const button = $(this);
    const extraData = button.data('extra-beamer');

    if(extraData) {
      initBeamerNews(button);
    }
    else {
      button.on('after.success.ic', () => {
        initBeamerNews(button);
        button.removeAttr('data-extra-beamer');
      });
    }
  });
}
