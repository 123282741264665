/** @file TODO: documentar */
import onmount from 'onmount';
import numeral from 'numeral';

onmount('[data-control-status]', function (state) {
  const self = $(this);
  const $progreso = self.find('[data-behavior=progreso]');
  const $estado = self.find('[data-behavior=estado]');
  const $anticipos = self.find('[data-behavior=anticipos]');
  const $links = self.find('[data-behavior=links]');
  const $cerrarLink = self.find('[data-behavior=cerrar_link]');
  const $generarArchivos = self.find('[data-behavior=generar_archivos]');
  const $circulo = self.find('[data-behavior=circulo]');
  const $candado = self.find('[data-behavior="candado"]');
  const $variableAnualId = self.data('controlAno');
  const $identifier = self.data('identifier');
  const $headTools = '.head-tools';
  var $oldInput = [];

  state.intervals = [];

  function removeInterval(interval) {
    clearInterval(interval);
    const index = state.intervals.indexOf(interval);
    if (index !== -1) {
      state.intervals.splice(index, 1);
    }
  }

  function cardSelector(variableAnualId, stepCode) {
    return '#card-' + $identifier + '-' + variableAnualId + '-' + stepCode + ' ';
  }

  function handleProgress(card, progress) {
    self.find(card + $headTools).html(numeral(progress).format('0 %'));
    self.find(card + '.dropdown-menu > a:nth-child(1)').addClass('disabled');
    self.find(card + '.dropdown-menu > a:nth-child(2)').addClass('disabled');
    self.find(card + '.info-subtitle').html('Generando...');
    self.find(card + '.bux-card').addClass('disabled');
  }

  function handleCrearArchivoJob(job, interval) {
    const progress = getProgress(job);
    var card = cardSelector($variableAnualId, job.step_code);
    if ($oldInput[interval] === undefined) $oldInput[interval] = card;
    handleProgress(card, progress);
  }

  function getProgress(job) {
    return job.progress ? job.progress / job.total : 0;
  }

  function recoveryButtons(interval) {
    const oldInputByInterval = $oldInput[interval];
    const icon = '<i class=" fas fa-ellipsis-v status-icon-default" aria-hidden="true"></i>';

    self.find(oldInputByInterval + $headTools).html(icon);
    self.find(oldInputByInterval + '.dropdown-menu > a:nth-child(1)').removeClass('disabled');
    self.find(oldInputByInterval + '.dropdown-menu > a:nth-child(2)').removeClass('disabled');
    self.find(oldInputByInterval + '.dropdown-menu > a:nth-child(2)').removeAttr('hidden');
    self.find(oldInputByInterval + '.dropdown-menu > a:nth-child(1)').html('Regenerar');
    self.find(oldInputByInterval + '.info-subtitle').html('Actualizado');
    self.find(oldInputByInterval + '.bux-card').removeClass('disabled');
    self.find(oldInputByInterval + '[data-toggle="tooltip"]').tooltip('dispose');
    self.find(oldInputByInterval + '> .bux-card').removeClass('bux-card-warning');
    self.find(oldInputByInterval + '> .bux-card').addClass('bux-card-primary');
  }

  self.on('generar-archivo', function (_ev, url) {
    var interval;
    interval = setInterval(function () {
      $.ajax({
        url: url,
        success: function (job) {

          if (isFinite(getProgress(job))) {
            if (job.step_class === 'crear_archivo_job') {
              handleCrearArchivoJob(job, interval);
            }
          }
        },
        error: function (err) {
          if (err.status !== 404) return;

          recoveryButtons(interval);
          clearInterval(interval);
        },
      });
    }, 1000);

    state.intervals.push(interval);
  });

  const origText = self.find('[data-behavior=mensaje]').text();

  self.on('cerrando', function (_ev, cerrar, url) {
    $progreso.show();
    $estado.hide();
    $anticipos.hide();
    $links.hide();
    $cerrarLink.hide();
    $generarArchivos.hide();

    var progressBar = self.find('.progress'),
      msg = self.find('[data-behavior=mensaje]'),
      interval;

    var cardTypesArray = [
      'ddjj_1887',
      'certificados_renta',
      'descarga_resumen_ddjj',
      'reporte_utilidades_peru',
      'reporte_anticipo_utilidades_peru',
      'reporte_utilidades_asistencia_anual_peru',
      'reporte_utilidades_remuneraciones_anuales_peru',
    ];

    // Reset progress to 0
    progressBar.find('.progress-bar').css('width', '0%').text(numeral(0).format('0.0 %'));

    interval = setInterval(function () {
      $.ajax({
        url: url,
        success: function (job) {
          // If there are errors
          if (job.error || job.status == 'failed') {
            progressBar.removeClass('active');
            progressBar.find('.progress-bar').addClass('progress-bar-danger');
            removeInterval(interval);
          }

          const progress = job.progress ? job.progress / job.total : 0;
          // In job stage
          if (isFinite(progress)) {
            progressBar.find('.progress-bar').css('width', (progress * 100) + '%').text(numeral(progress).format('0.0 %'));
          }
          msg.text(job.step || origText);
        },
        error: function (err) {
          if (err.status !== 404) {
            return;
          }
          // Job is no loger in database which means it finished successfuly
          $progreso.hide();
          $circulo.text(cerrar ? 'C' : 'A');
          $estado.text(cerrar ? 'Cerrado' : 'Abierto');
          $candado.removeClass('candado-abierto candado-cerrado').addClass(cerrar ? 'candado-cerrado' : 'candado-abierto');
          $candado.find('.fa-lock,.fa-unlock').removeClass('fa-lock fa-unlock').addClass(cerrar ? 'fa-lock' : 'fa-unlock');
          $anticipos.show();
          $links.show();

          for (const cardType of cardTypesArray) {
            const ddjj1887Card = '#card-variable-anual-' + $variableAnualId + '-' + cardType + ' ';
            const ddjj1887Subtitle = self.find(ddjj1887Card + '.info-subtitle');

            if(ddjj1887Subtitle[0].outerText === 'Actualizado') {
              ddjj1887Subtitle.html('Desactualizado');
              self.find(ddjj1887Card + '[data-toggle="tooltip"]').tooltip('dispose');
              self.find(ddjj1887Card + '> .bux-card').removeClass('bux-card-primary');
              self.find(ddjj1887Card + '> .bux-card').addClass('bux-card-warning');
            }
          }

          if (!cerrar) {
            $cerrarLink.show();
            $generarArchivos.show();
          }
          removeInterval(interval);
        },
      });
    }, 1000);

    state.intervals.push(interval);
  });

  const status = self.data('controlStatus');
  if (status?.url) {
    self.trigger('cerrando', [status.cerrar, status.url]);
  }

  const statusFile = self.data('controlStatusFiles');
  if (statusFile?.urls?.length > 0) {
    for(const url of statusFile.urls) {
      self.trigger('generar-archivo', [url]);
    }
  }

}, function (state) {
  if (state.intervals) {
    state.intervals.forEach(i => { clearInterval(i); });
    state.intervals.splice(0, state.intervals.length);
  }
});
