/** @file TODO: documentar */
import onmount from 'onmount';

onmount('#credit_preview_date', async function () {
  const { default: moment } = await import('moment');
  const $self = $(this);
  const $currentCuotaInput = $self.find('#credit_cuota_actual');
  const $totalCuotaInput = $self.find('#credit_duracion');
  const $newStartDate = $self.find('#credit_start_date');
  const $checkbox = $self.find('#start_date_display_checkbox');
  const $form = $('#new_credit');
  const $startDateForm = $self.find('#form_start_date');

  $currentCuotaInput.on('keyup change' , updateDates);
  $totalCuotaInput.on('keyup change' , updateDates);
  $newStartDate.on('change', updateDates);
  $checkbox.on('change', toggleStartDate);
  $form.on('submit', setDates);

  function updateDates() {
    let endDate = findDate();
    let startDate = findDate();

    const currentCuota = parseInt($currentCuotaInput.val()) - 1;
    const totalCuota = parseInt($totalCuotaInput.val()) - currentCuota - 1;

    startDate = startDate.subtract(currentCuota, 'M');
    endDate = endDate.add(totalCuota, 'M');

    $self.find('#credit_fecha_primera_cuota').val(startDate.format('MM-YYYY'));

    if(isNaN(totalCuota) || totalCuota < 0) {
      $self.find('#credit_end_date').val('Indefinido');
    }
    else {
      $self.find('#credit_end_date').val(endDate.format('MM-YYYY'));
    }
  }

  function toggleStartDate() {
    $startDateForm.toggle();
    updateDates();
  }

  function findDate() {
    if ($startDateForm.is(":visible")) {
      return moment($newStartDate.val(),'MM-YYYY');
    }
    else {
      return moment($self.data('current-month'),'MM-YYYY');
    }
  }

  function setDates() {
    if ($startDateForm.is(":hidden")) {
      $newStartDate.val($self.data('current-month'));
    }
    const startDate = moment($newStartDate.val(),'MM-YYYY');
    $newStartDate.val(startDate);
  }
});
