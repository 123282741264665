/** @file TODO: documentar */
import onmount from 'onmount';
import 'cocoon-rails';

onmount("#multiple-centro-costos", function () {
  const self = this;
  // first time
  disableFirstElement();
  // add field button
  $(self).on('cocoon:after-insert', ['.centro-costo-fields', '.association-fields'], function () {
    disableFirstElement();
  });
  // on change number input
  $(self).on('change mouseup keyup', 'input[type=number]:visible:not(:first)', function () {
    calculateFirstInput();
  });
  // on remove input
  $(self).on('cocoon:after-remove', ['.centro-costo-fields', '.association-fields'], function () {
    disableFirstElement();
    calculateFirstInput();
  });

  //Antes de remover un elemento, se quitan los atributos required del peso y codigo
  $(self).on('cocoon:before-remove', function (_e, removido) {
    var $removido = $(removido);
    $removido.find(':input[required]').prop('required', false);
  });

  // local functions
  function calculateFirstInput() {
    let sum = 0;
    $(self).find('input.peso_porcentual[type=number]:visible:not(:first)').each(function () {
      sum += +$(this).val();
    });

    const firstInput = $(self).find("input.peso_porcentual[type=number]:visible:first");
    if (firstInput.data('round') === 2) {
      firstInput.val(Math.round((100 - sum) * 100) / 100);
    }
    else {
      firstInput.val(100 - sum);
    }
  }

  function disableFirstElement() {
    const firstInput = $(self).find("input.peso_porcentual[type=number]:visible:first");
    // if data option disable-only-persisted is true, only persiste
    const noDisablePersisted = !firstInput.data('no-disable-persisted') || $(self).find("input.peso_porcentual[type=number]:visible").length > 1;
    if (noDisablePersisted) {
      firstInput.prop("readonly", true);
    }
    if((noDisablePersisted && firstInput.val() === "0.0") || firstInput.val() === "") {
      firstInput.val(100);
    }
  }
});
