/** @file
 * Archivo de marcas de amplitud para los custom report
 */
import onmount from 'onmount';

const subCategoriesMap = {
  'vacation': 'Vacaciones',
  'vacation_balance': 'Vacaciones',
  'seleccion': 'Selección',
  'postulacion': 'Selección',
  'workflow_search_process': 'Solicitudes',
};

// Método para limpiar nombres de iconos de chips
function cleanChipText(text) {
  return text.replace(/(?:text_fields|numbers|date_range|toggle_on|close)/g, '').trim();
}

onmount('[data-custom-report-template-new-download="true"]', async function () {
  const customReport = $(this);
  const chipsArray = [];
  customReport.on('click', () => {
    const customReportData = {
      'source': 'Menu',
      'source_download': 'Direct',
      'export_new': window.location.pathname.includes('edit') ? 'Edit' : 'New',
      'export_category': subCategoriesMap[customReport.data('type')] ?
        subCategoriesMap[customReport.data('type')] : customReport.data('category'),
      'export_subcategory': subCategoriesMap[customReport.data('type')] ? customReport.data('category') : '',
    };
    $('.drop-container li .chip').each(function () {
      var text = $(this).text().replace(/\s+/g, ' ').trim();
      chipsArray.push(cleanChipText(text)); // Agrega el texto al array
    });
    if ($('.group-by-drop-container').length) {
      const groupByChipsArray = [];
      $('.group-by-drop-container li .chip').each(function () {
        var text = $(this).text().replace(/\s+/g, ' ').trim();
        groupByChipsArray.push(cleanChipText(text));
      });
      customReportData['exports_groupby_parameters'] = groupByChipsArray;
    }
    customReportData.export_parameters = chipsArray;
    const amplitudeData = { message: 'Download_Export', data: customReportData };
    customReport.trigger('amplitudeTracking', amplitudeData);
  });
});

onmount('[data-custom-report-template-save="true"]', async function () {
  const customReport = $(this);
  const chipsArray = [];
  customReport.on('click', () => {
    const customReportData = {
      'source': 'Menu',
      'export_new': window.location.pathname.includes('edit') ? 'Edit' : 'New',
      'export_category': subCategoriesMap[customReport.data('type')] ?
        subCategoriesMap[customReport.data('type')] : customReport.data('category'),
      'export_subcategory': subCategoriesMap[customReport.data('type')] ? customReport.data('category') : '',
    };
    $('.drop-container li .chip').each(function () {
      var text = $(this).text().replace(/\s+/g, ' ').trim();
      chipsArray.push(cleanChipText(text)); // Agrega el texto al array
    });
    customReportData.export_parameters = chipsArray;
    const amplitudeData = { message: 'Save_Export', data: customReportData };
    customReport.trigger('amplitudeTracking', amplitudeData);
  });
});
