/**
 * @file
 * * Permite actualizar un hint segun la opción seleccionada en un select
 *
 * data-hint-change="select" se usa en el contenedor del hint, del select
 * y de las opciones de texto para el hint. Las opciones del hint segun el
 * select deben tener el atributo [data-text-hint="${value}"].
 * Se pasa el texto de la traducción contenida dentro de la opción.*/
import onmount from 'onmount';
onmount('[data-hint-change="select"]', function () {
  const container = $(this);
  const select = container.find('select');
  const hintElement = container.find('[class="help-block"]');
  select.on('change', function () {
    const value = $(this).val();
    const newHintValue = container.find(`[data-text-hint="${value}"]`);
    hintElement.text(newHintValue[0].textContent);
  });
});
