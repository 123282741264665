/** @file Se encarga de mostrar un modal con las imágenes asociadas
 * a las publicaciones de un mural del portal de empleados */
import onmount from 'onmount';
onmount('[data-mural-gallery]', function () {
  let modalVideos;
  let slideIndex = 1;
  let actualMural;

  // se dispara cuando se hace click en una imágen, abre el modal de imágenes de galeria.
  $('[data-gallery-img]').click(function () {
    const index = $(this).data('gallery-img');
    actualMural = $(this).data('mural-index');
    modalVideos = $(`[data-mural-gallery="${actualMural}"] video`);
    openModal();
    currentSlide(index);
  });

  $('.gallery-close').click(function () {
    closeModal();
  });

  $('.gallery-prev').click(function () {
    plusSlides(-1);
  });

  $('.gallery-next').click(function () {
    plusSlides(1);
  });

  function openModal() {
    const
      LEFT_ARROW_CODE = 37,
      RIGHT_ARROW_CODE = 39,
      ESC_CODE = 27;

    $(`[data-mural-gallery="${actualMural}"]`).css('display', 'block');
    // permite usar las flechas del teclado para navegar
    // entre las imágenes del modal.
    $('body').on('keydown', e => {
      if ((e.keyCode || e.which) ===  LEFT_ARROW_CODE) {
        plusSlides(-1);
      }
      if ((e.keyCode || e.which) === RIGHT_ARROW_CODE) {
        plusSlides(1);
      }
      if ((e.keyCode || e.which) === ESC_CODE) {
        closeModal();
      }
    });
  }

  function closeModal() {
    stopVideos();
    $(`[data-mural-gallery="${actualMural}"]`).css('display', 'none');
    $('body').off('keydown');
  }

  // Next/previous controls
  function plusSlides(n) {
    stopVideos();
    showSlides(slideIndex += n);
  }

  function currentSlide(n) {
    stopVideos();
    showSlides(slideIndex = n);
  }

  // ubica el index de la imágen pulsada y la infomacion relativa a esta en el modal.
  function showSlides(n) {
    const slides = $(`[data-mural-slide="${actualMural}"]`);
    if (n > slides.length) { slideIndex = 1; }
    if (n < 1) { slideIndex = slides.length; }
    slides.each(function () {
      $(this).css('display', 'none');
    });
    slides[slideIndex - 1].style.display = 'flex';
  }

  function stopVideos() {
    modalVideos.each(function () {
      $(this).trigger('pause');
    });
  }
});
