/** @file TODO: documentar */

import onmount from 'onmount';
import Rails from '@rails/ujs';
Rails.start();

// Necesitamos asegurarnos que comportamientos que dependen de onmount
// se gatillen luego de navegar con remote. Como el remote puede
// autoeliminarse del dom, no podemos tener un solo handler global.
// Gatillamos un evento en el document porque se puede modificar cualquier
// parte de la página
onmount('[data-remote]', function () {
  this.addEventListener('ajax:success', () => {
    $(document).trigger('buk:load-content');
  });
});
