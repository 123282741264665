/** @file
 * Archivo de marcas de amplitud para modelo de beneficios
 */
import onmount from 'onmount';

/**
 * Adjunta un evento de seguimiento de Amplitude a un elemento específico, incluyendo
 * múltiples datos name, belong, type, category, source
 *
 * @param {string} elementName - Nombre del elemento al que se adjuntará el evento.
 * @param {string} eventMessage - Mensaje a enviar cuando se dispara el evento.
 */
function addAmplitudeEvent(elementName, eventMessage) {
  onmount(`${elementName}`, async function () {
    const element = $(this);
    const data = {
      'benefit_name': element.data('name'),
      'benefit_belong': element.data('belong'),
      'benefit_type': element.data('type'),
      'benefit_category': element.data('category'),
      'source': sessionStorage.getItem('benefitSource'),
      'benefit_open_from': sessionStorage.getItem('benefitOpenFrom'),
    };

    element.on('click', () => {
      element.trigger('amplitudeTracking', { message: eventMessage, data: data });
    });
  });
}

/**
 * Adjunta un evento de seguimiento de Amplitude a un elemento específico, incluyendo
 * múltiples datos name, belong, type, category, source para accesos a Zurich
 *
 * @param {string} elementName - Nombre del elemento al que se adjuntará el evento.
 */
function addAmplitudeEventAccessB2B(elementName) {
  onmount(`${elementName}`, async function () {
    const element = $(this);
    const kind = element.data('access-kind');
    const originMessage = `Open_${kind}`;

    const anchorLabel = element.text().replace('arrow_forward', '');
    const anchorText = anchorLabel.length ? anchorLabel : '';

    const data = {
      'benefit_name': element.data('name'),
      'benefit_belong': element.data('belong'),
      'benefit_type': element.data('type'),
      'benefit_category': element.data('category'),
      'source': sessionStorage.getItem('benefitSource'),
      'benefit_open_from': sessionStorage.getItem('benefitOpenFrom'),
      'access_name': anchorText,
    };

    element.on('click', () => {
      element.trigger('amplitudeTracking', { message: originMessage, data: data });
    });
  });
}

/**
 * Adjunta un evento de seguimiento de Amplitude a un elemento de slider, incluyendo
 * múltiples datos name, belong, type, category, source
 *
 * @param {Node} elementNode - Nodo del elemento slider al que se adjuntará el evento.
 * @param {string} eventMessage - Mensaje a enviar cuando se dispara el evento.
 */
function addSliderAmplitudeEvent(elementNode, eventMessage) {
  const element = $(elementNode);
  const data = {
    'benefit_name': element.data('name'),
    'benefit_belong': element.data('belong'),
    'benefit_type': element.data('type'),
    'benefit_category': element.data('category'),
    'source': sessionStorage.getItem('benefitSource'),
    'benefit_open_from': sessionStorage.getItem('benefitOpenFrom'),
  };
  element.trigger('amplitudeTracking', { message: eventMessage, data: data });
}

/**
 * Adjunta un evento simple de seguimiento de Amplitude a un elemento específico.
 *
 * @param {string} elementName - Nombre del elemento al que se adjuntará el evento.
 * @param {string} eventMessage - Mensaje a enviar cuando se dispara el evento.
 * @param {Array<string>} [dataNames = ['origin', 'source']] - Array de nombres de keys a enviar en data
 */
function addSimpleAmplitudeEvent(elementName, eventMessage, dataNames = ['origin', 'source']) {
  onmount(`${elementName}`, async function () {
    const element = $(this);
    const origin = element.data('origin');
    const source = element.data('source');
    const [originKey, sourceKey] = dataNames;
    const data = {
      [originKey]: origin,
      [sourceKey]: source,
    };
    element.on('click', () => {
      element.trigger('amplitudeTracking', { message: eventMessage, data: data });
    });
  });
}

/**
 * Define el origen del evento de Amplitude para un elemento específico, guardándolo
 * en el sessionStorage del navegador.
 *
 * @param {string} elementName - Nombre del elemento al que se asignará la fuente del evento.
 * @param {string} source - origen del evento.
 */
function setAmplitudeEventSource(elementName, source) {
  onmount(`${elementName}`, async function () {
    const element = $(this);
    element.on('click', () => {
      sessionStorage.setItem('benefitSource', source);
    });
  });
}

function setAmplitudeEventOpenFrom(elementName, source) {
  onmount(`${elementName}`, async function () {
    const element = $(this);
    element.on('click', () => {
      sessionStorage.setItem('benefitOpenFrom', source);
    });
  });
}

// función para detectar cuando se abre un sidebar despúes de haber hecho click en un elemento del carrusel
onmount('.BenefitCarousel', async function () {
  const element = $(this);

  element.on('click', () => {
    observeSidebar();
  });
});

function observeSidebar() {
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      const addedNodes = mutation.addedNodes;
      for (let i = 0; i < addedNodes.length; i++) {
        const node = addedNodes[i];
        if (node.nodeType === 1 && node.tagName === 'DIV' && node.classList.contains('aside-body--content')) {
          addSliderAmplitudeEvent(node, 'benefit_clicked_open_detail');
          observer.disconnect();
        }
      }
    });
  });

  const config = {
    childList: true,
    subtree: true, // Asegura que todos los descendientes sean observados
  };

  observer.observe(document.body, config);
}

addAmplitudeEvent('.benefit-show-detail', 'benefit_clicked_open_detail');
addAmplitudeEvent('#available-benefit-sidebar-url', 'benefit_clicked_external_link');
addAmplitudeEvent('#submit_benefit[data-btn-type="btn"]', 'benefit_button_clicked_request');
addAmplitudeEventAccessB2B('.submit_benefit_b2b_zurich');
addAmplitudeEventAccessB2B('.submit_benefit_b2b_relif');

setAmplitudeEventSource('.link-beneficios', 'Portal');
setAmplitudeEventSource('.sidebar__menu__content a[href="/benefits"]', 'Menu');
setAmplitudeEventSource('.main-sidebar a[href="/benefits"]', 'Menu');

setAmplitudeEventOpenFrom('.BenefitCarousel', 'Slider');
setAmplitudeEventOpenFrom('.benefit-show-detail', 'List');

addSimpleAmplitudeEvent('#group-link-plan-button', 'Start_Create_Group');
addSimpleAmplitudeEvent('#buk-benefit-banner-button', 'upselling_locked_icon_click', ['module_name', 'source']);
