/** @file
 * Archivo para manejar el formulario de creación de tipos de anotación
 * Crear el autorelleno del código en el formulario según el nombre puesto
 */
import { toCode } from '../../../../../../../../app/assets/javascripts/lib/string';

$(document).on('turbolinks:load', function () {
  function handleNameChange() {
    $('#logbook_type_code').val(toCode($('#logbook_type_name').val()));
  }

  $('#logbook_type_name').keyup(handleNameChange);
});
