/** @file TODO: documentar */
import { debounce } from 'lodash-es';
import onmount from 'onmount';
import { error } from '../lib/buk/notice';
import ('@packs/nomina/mexico/finiquito/app/assets/javascripts/pages/finiquito/preview-mexico');
import ('./finiquito/modal-preview-chile');
import numeral from 'numeral';

function post(params, url) {
  return $.ajax({
    method: 'POST',
    dataType: 'json',
    url: url,
    data: params,
  });
}

onmount('#create-finiquito:not(.v2)', function () {
  // toggle 'simular'
  const url = $(this).data('uf-url');
  function toggleButton() {
    const required = $('.finiquito-form').find(':input[required]').filter(function () {
        return $(this).parents('#vista-preview').length === 0;
      }),
      filled = required.filter(function () { return this.value !== ''; }),
      btn = $('#btn-simular');
    if (filled.length === required.length) {
      btn.attr('title', '').prop('disabled', false);
    }
    else {
      btn.attr('title', 'Llene los datos antes de simular').prop('disabled', true);
    }
  }
  $('.finiquito-form').on('change', ':input', debounce(function () {
    // Si somos un input que no es dentro del preview, borramos el preview para forzar una reactualización.
    const notRenderDraft = $('#render_draft').length === 0;
    if ($(this).parents('html').length === 1 && $(this).parents('#vista-preview').length === 0 && notRenderDraft) {
      $('#vista-preview').empty();
    }
    $('#render_draft').remove();
    toggleButton();
  }, 100));
  toggleButton();
  // TODO: deuda mision-vista-finiquito:
  // se deben eliminar las opciones de chile: finiquito_dias_vacaciones_proporcionales, finiquito_dias_vacaciones_pendientes y
  // condicionar esta llamada para que no se gatille cuando es un finiquito chileno, ya que con la nueva vista finiquito
  // se va a realizar el llamado dentro del modal de vacaciones, otra opción puede revisar la vista finiquito de los otros paises y ver si
  // se puede calcular las vacaciones directamente al simular el finiquito y asi se elimina este bloque de codigo por completo
  $('#finiquito_fecha').on('change', function () {
    if($(this).val()) {
      post({ vacacions_to_day: $(this).val() }, url)
        .done(function (data) {
          $('#finiquito_dias_vacaciones_proporcionales').val(data.dias_vacaciones_proporcionales);
          $('#finiquito_dias_vacaciones_pendientes').val(data.dias_vacaciones_pendientes);
          $('#finiquito_dias_vacaciones_ganadas_peru').val(data.dias_vacaciones_ganadas_peru);
          $('#finiquito_dias_vacaciones_vencidas_peru').val(data.dias_vacaciones_vencidas_peru);
          $('#finiquito_dias_vacaciones_truncas_peru').val(data.dias_vacaciones_truncas_peru);
        })
        .fail(function () {
          error('Error calculando las vacaciones proporcionales');
        });
    }
  }).trigger('change');
});

/*
Callback para cuando se cambia el valor de alguno de los campos del formulario
de finiquito que amerite volver a calcular la simulación.
*/
onmount('#create-finiquito.v2', function () {
  const btnVolver = $('#btn-volver');
  const btnGuardarBorrador = $('#btn-guardar-borrador');
  const btnSimular = $('#btn-simular-v2');
  const btnTerminarV2 = $('#btn-terminar-v2');

  const inputFechaPago = $('#finiquito_fecha_pago');
  inputFechaPago.prop('required', false);

  const labelInputFechaPago = $('label[for="finiquito_fecha_pago"]');
  const labelInputFechaPagoText = labelInputFechaPago.html();

  const required = $('.finiquito-form')
    .find(':input[required]')
    .filter((_, field) => $(field).parents('#vista-preview').length === 0);

  /**
   * Revisar si todos los campos requeridos están llenos.
   * @return {Boolean}
   */
  const fieldsAreFilled = () => {
    const filled = required.filter((_, field) => field.value !== '' || field.disabled);
    return required.length === filled.length;
  };

  /**
   * Revisar si el formulario de vista previa está presente.
   * @return {Boolean}
   */
  const previewIsPresent = () => $('#finiquito-form-preview').length > 0;

  /**
   * Declarar los campos requeridos para el cálculo del finiquito.
   */
  const requiredFields = () => {
    inputFechaPago.prop('required', true);
    $('#finiquito_document_templates_id').prop('required', true);
    $('#forma-pago-finiquito').find(':input[required]').each((_, field) => $(field).prop('required', true));
  };

  /**
   * Habilita el botón de simular si todos los campos requeridos están llenos.
   */
  const toggleButton = () => {
    $('#finiquito_document_templates_id').prop('required', false);
    if (fieldsAreFilled()) {
      btnSimular.attr('title', '').prop('disabled', false);
      btnGuardarBorrador.attr('title', '').prop('disabled', false);
    }
    else {
      btnSimular.attr('title', 'Llene los datos antes de simular').prop('disabled', true);
      btnGuardarBorrador.attr('title', 'Llene los datos antes de guardar el borrador').prop('disabled', true);
    }
  };

  if (fieldsAreFilled() && previewIsPresent()) {
    btnSimular.addClass('hidden');
    btnTerminarV2.removeClass('hidden');
    requiredFields();
    if(!labelInputFechaPago.text().includes('*')) {
      labelInputFechaPago.removeClass('optional').addClass('required').append(' *');
    }
  }
  else {
    btnSimular.removeClass('hidden');
    btnTerminarV2.addClass('hidden');
  }

  toggleButton();

  required.on('change', function () {
    $('#vista-preview').empty();
    btnVolver.attr('data-confirm-swal-disable', true);
    btnGuardarBorrador.addClass('hidden');
    btnSimular.removeClass('hidden');
    btnTerminarV2.addClass('hidden');
    if(labelInputFechaPago.text().includes('*')) {
      labelInputFechaPago.html(labelInputFechaPagoText);
    }
    toggleButton();
  });

  btnSimular.on('click', function () {
    labelInputFechaPago.removeClass('optional').addClass('required').append(' *');
  });

  btnGuardarBorrador.on('click', function () {
    inputFechaPago.removeAttr('required');
    $('#finiquito_document_templates_id').prop('required', false);
    $('#forma-pago-finiquito').find(':input[required]').each((_, field) => $(field).prop('required', false));
  });

  btnTerminarV2.on('click', requiredFields);
});

onmount('#finiquito-form-preview', function () {
  $('#btn-terminar, #btn-terminar-v2').prop('disabled', false);
});

onmount('#finiquito_otros_descuentos_peru', function () {
  $('#finiquito_suma_descuentos').on('change', function () {
    if($(this).val()) {
      $('#finiquito_otros_descuentos_peru').val(numeral($('#finiquito_suma_descuentos').val())._value.toFixed(2));
    }
  });
});

onmount('#generar_carta_aviso', function () {
  $('#generar_carta_aviso').change(function () {
    if(this.checked) {
      $('#finiquito_carta_aviso_id').prop('required', true);
    }
    else {
      $('#finiquito_carta_aviso_id').prop('required', false);
    }
  });
});

onmount('#partidas-remuneraciones', function () {
  const observer = new MutationObserver(function () {
    $('#btn-simular').prop('disabled', $('#alert-items-sin-remuneracion').length === 1);
  });
  observer.observe(document.getElementById('partidas-remuneraciones'), { childList: true });
});

function assignChildRows(table, tableName) {
  table.rows('[id*=parentRow]').every(function (_rowIdx, _tableLoop, _rowLoop) {
    var className = this.node().className.split(' ')[0];
    var childs = [];
    $(tableName + ' [id*=childRow][class*=' + className + ']').each(function (_i, el) {
      childs.push(el);
    });
    this.child(childs).hide();
  });
}

function setUpTable(tableName) {
  var table = $(tableName).DataTable({
    columns: [
      { data: 'expand', orderable: false },
      { data: 'item', orderable: false },
      { data: 'mes', orderable: false },
      { data: 'cts', orderable: false },
      { data: 'gratificacion', orderable: false },
      { data: 'vacaciones', orderable: false },
    ],
    responsive: true,
    paging: false,
    scrollCollapse: true,
    searching: false,
    info: false,
  });

  assignChildRows(table, tableName);

  // Event listener para expandir/contraer filas hijas
  $(tableName + ' tbody').on('click', 'td#expand button', function () {
    var tr = $(this).closest('tr');
    var row = table.row(tr);
    $(this).children('button i').toggleClass('fa-plus fa-minus');

    if (row.child.isShown()) {
      row.child.hide();
      tr.removeClass('shown');
    }
    else {
      row.child.show();
      tr.addClass('shown');
    }
  });
}

onmount('#tabla-fija-principal', function () {
  $(setUpTable('#tabla-fija-principal'));
});

onmount('#tabla-variable-principal', function () {
  $(setUpTable('#tabla-variable-principal'));
});

onmount('#tabla-variable-complementaria', function () {
  $(setUpTable('#tabla-variable-complementaria'));
});
