/** @file TODO: documentar */
import onmount from 'onmount';

onmount('#input-prepend', function () {
  const prepends = document.querySelectorAll('#input-prepend');
  prepends.forEach(function (prependItem) {
    prependItem.parentElement.classList.add('input-prepend');
  });
});

onmount('#input-append', function () {
  const appends = document.querySelectorAll('#input-append');
  appends.forEach(function (appendItem) {
    appendItem.parentElement.classList.add('input-append');
  });
});

onmount('.input-group', function () {
  $('.input-group-append').on('click', function () {
    const $parent = $(this).parent();
    $parent.find('input')?.trigger('focus');
  });
});

/**
 *
 * Esta función agrega un listener de evento `wheel.disableScroll` a los elementos
 * `input[type=number]` dentro del formulario especificado, y lo elimina cuando el
 * campo pierde el foco, previniendo cambios accidentales de valor al hacer scroll.
 *
 * @param {HTMLElement | JQuery} formElement - El elemento formulario que contiene los inputs numéricos.
 */
function handleNumberInputScroll(formElement) {
  $(formElement).on('focus wheel.disableScroll', 'input[type=number]', function (event) {
    event.preventDefault();
  });

  $(formElement).on('blur', 'input[type=number]', function () {
    $(this).off('wheel.disableScroll');
  });
}

/**
 * Aplica estilo a los labels de los campos requeridos, asegurando que el asterisco
 * indicador de campo obligatorio (*) sea consistente en todos los formularios.
 *
 * Esta función busca nodos de texto en los labels de los inputs requeridos y,
 * si el texto termina en '*', lo reemplaza con un asterisco estilizado.
 *
 * @param {HTMLElement | JQuery} formElement - El elemento formulario que contiene los labels requeridos.
 */
function styleRequiredLabels(formElement) {
  const bodyElement = $('body');
  const isFormImprovementsEnabled = bodyElement.data('simple-forms-improvements') === true;

  if (!isFormImprovementsEnabled) return;

  // Selecciona todos los labels de campos requeridos dentro del formulario
  const labels = formElement.querySelectorAll('label.required');

  // Itera sobre cada label requerido
  labels.forEach(label => {
    // Busca encontrar el nodo de texto dentro del label, ya que pueden contener otros elementos
    // como íconos + tooltips.
    const textNodes = Array.from(label.childNodes).filter(node => node.nodeType === Node.TEXT_NODE);

    // Itera sobre cada nodo de texto encontrado
    textNodes.forEach((textNode) => {
      const text = textNode.textContent.trim();

      // Verifica si el texto termina en '*', lo cual indica que es un campo obligatorio
      if (text.endsWith('*')) {
        // Elimina el asterisco del nodo de texto original para añadirlo como un elemento por aparte
        const newText = text.slice(0, -1).trim();
        textNode.textContent = newText;

        // Crea un nuevo elemento <span> para el asterisco y lo estiliza agregando la clase required-indicator
        const asteriskSpan = document.createElement('span');
        asteriskSpan.textContent = '*';
        asteriskSpan.classList.add('required-indicator');

        // Agrega el asterisco estilizado al label
        label.appendChild(asteriskSpan);
      }
    });
  });
}

// Delegación de eventos en el formulario para optimizar el rendimiento
onmount('form', function () {
  handleNumberInputScroll(this);
  styleRequiredLabels(this);
});
