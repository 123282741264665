/** @file TODO: documentar */
import onmount from 'onmount';

onmount('.bux-list', function () {
  if($(this).data('collapse') === false) return;

  $(this).on('click', '.bux-list-item > .header-list', function (event) {
    const target = $(event.target).closest('.bux-list-item');
    if (target.hasClass('disabled') || target.hasClass('type-list') || $(event.target).parents('.list-settings').length) return;
    target.toggleClass('active');
    target.find('.content-list').toggleClass('visible');
  });

  $(this).on('click', '.bux-list-item:not(.type-collapse)', function (event) {
    const target = $(event.target).closest('.bux-list-item');
    const widget = $(event.target).closest('.bux-list');
    widget.find('.selected-item').removeClass('selected-item');
    widget.find('.selected-icon').removeClass('selected-icon');
    target.find('.icon-item-list').addClass('selected-icon');
    target.addClass('selected-item');
  });

  // Cuando abro una pantalla menor o igual a 960 el list se cierra
  if ($(window).width() <= 960) {
    const element1 = $('.item-responsive.active');
    const element2 = $('.content-list-responsive.visible');
    element1.removeClass('active');
    element2.removeClass('visible');
  }

  // cuando cambio la ventana a una menor de 960 se cerrara el list
  window.onresize = function () {
    const element1 = $('.item-responsive.active');
    const element2 = $('.content-list-responsive.visible');

    if (window.innerWidth <= 960) {
      element1.removeClass('active');
      element2.removeClass('visible');
    }
  };
});
