/** @file TODO: documentar */

function toggle(target) {
  $(target).find('.fa[class*="fa-caret-"]').toggleClass('fa-caret-left fa-caret-down');
  //comportamiento caret cards
  const cardComponentCaret = $(target).closest('.bux-card-info').find('.fas[class*="fa-angle"]');
  cardComponentCaret.toggleClass('fa-angle-left fa-angle-down');
}
$(document).on('click', '[data-toggle="collapse"]', function () {
  $(this).find('[class*="fa-angle-"]').toggleClass('fa-rotate-90-inv');
  toggle(this);
});
$(document).on('show.bs.dropdown hide.bs.dropdown ', function (ev) {
  toggle(ev.relatedTarget);
});
