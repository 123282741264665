/** @file TODO: documentar */
/** Espera que datatables este inicializado
 *
 * @return {Promise}
 */
$.fn.waitDataTable = function waitDataTable() {
  return new Promise((resolve) => {
    if ($.fn.dataTable.isDataTable(this)) {
      resolve();
    }
    else {
      this.on('init.dt', () => resolve());
    }
  });
};
