/** @file Manejamos la respuesta de worker que procesa el importador de pagos */
import onmount from 'onmount';
import Intercooler from '../../../../../../../../app/assets/javascripts/lib/intercooler';

onmount('#licence-recovery-transaction-table', function () {
  window.workerNotificationPromise.then((workerNotification) => {
    workerNotification.suscribe(updateFinishJobs, 'finishJobStatus');
    workerNotification.start();
  });
}, function () {
  window.workerNotificationPromise.then((workerNotification) => {
    workerNotification.unsuscribe(updateFinishJobs, 'finishJobStatus');
  });
});

// Respuesta del worker, activa el trigger para actualizar la tabla cuando es la importación es correcta
function updateFinishJobs(_data) {
  _data.forEach(element => {
    element.custom_triggers?.forEach(trigger => {
      Intercooler.triggerRequest(document.getElementById(trigger));
    });
  });
}
