/** @file TODO: documentar */
import onmount from 'onmount';

var uploadCrops = {};
let format = '';

function readFile(input, id) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      const mimeType = input.files[0].type;
      format = mimeType.split('/')[1];
      uploadCrops[id].croppie('bind', {
        url: e.target.result,
      });
    };
    reader.readAsDataURL(input.files[0]);
  }
  else {
    alert("Sorry - your browser doesn't support the FileReader API");
  }
}

onmount('.croppie_image', async function () {
  await import ('croppie');
  const $this = $(this);
  const id = $this.data('input-id');
  const viewportScale = parseInt($this.data('viewport-scale'));
  const encodeBeforeSubmit = !!$this.data('encode-before-submit');

  $(`#upload_croppie_image_${id}`).on('change', function () {
    $(`#content_img_preview_${id}`).addClass('d-none'); // ocultamos preview
    $this.removeClass('d-none'); // mostramos el croppie

    readFile(this, id);
  });

  // evita una doble inicialización del componente
  if ($this.hasClass('croppie-container')) return;

  const params = $this.data();

  uploadCrops[id] = $this.croppie({
    viewport: {
      width: viewportScale > 1 ? params.viewportWidth / viewportScale : params.viewportWidth,
      height: viewportScale > 1 ? params.viewportHeight / viewportScale : params.viewportHeight,
      type: params.viewportType,
    },
    boundary: {
      width: params.boundaryWidth,
      height: params.boundaryHeight,
    },
    enableResize: params.enableResize,
    enableOrientation: params.enableOrientation,
  });

  // convertir imagen a base64 en input hidden
  async function imageEncode() {
    const resp = await uploadCrops[id].croppie('result', {
      type: 'base64',
      size: {
        width: params.viewportWidth,
        height: params.viewportHeight,
      },
      format: format,
    });

    if (!resp) { return; }

    $(`#result_croppie_${id}`)
      .val(resp)
      .trigger('change');
  }

  $(`.upload-result-${id}`).on('click', imageEncode); // convertir la imagen al hacer click en un botón

  // Esta es una implementación para que el croppie funcione sin el requerimiento de una modal
  if (encodeBeforeSubmit) {
    // convertir la imagen al hacer submit en el formulario padre de croppie
    const $form =  $this.closest('form');
    $form.submit(async (event) => {
      event.preventDefault(); // stop submit del formulario
      const imageName = $(`#upload_croppie_image_${id}`).val();
      if (imageName !== '') { // Si el input file contiene una imagen
        await imageEncode();
      }
      $form[0].submit(); // run submit del formulario
    });
  }
});
