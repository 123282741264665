/** @file TODO: documentar */
import { error } from '../../../../../../../../app/assets/javascripts/lib/buk/alert.js';
import onmount from 'onmount';

onmount(".ckeditor_textinput[name='logbook[message]']", function () {
  $(this).on('change', function () {
    const containsSvg = this.value.includes('src="data:image/svg+xml');
    if (containsSvg) {
      error('La imagen que deseas publicar no tiene el formato jpg, jpeg o png.');
      document.querySelector('[title$="logbook_message"]')
        .contentDocument
        .querySelector('.cke_editable')
        .querySelector('img[src*="data:image/svg+xml"]')
        .remove();
    }
  });
});
