/**
 * @file Calcula el porcentaje de término de una encuesta o evaluación.
 * Utiliza un objeto ´completion-data´ que contiene la información
 * de las pregunats requeridas de una encuesta, en la forma:
 *   { 'q_id': id_del_question, 'q_required_comment': si_la_pregunta_requiere_comentario }
 * El modelo Attempt puede crear estos datos para sí mismo.
 *
 * Para utilizarse, se debe crear con un elemento form y los datos de término como parámetros
 * @exmple
 *  const calculator = new CompletionPercentageCalculator($('#form'), $('completion-data').data('completion-data')
 *
 * EL método target recibe un elemento, el que se va a cambiar con el valor calculado, y una función opcional.
 * Si la función está ausente, el atributo value del elemento se cambia. Si la función está presente, se llama la
 * función y el atributo value no se cambia automáticamente.
 * @example
 *  calculator.target($('.progress'), function (newValue) { $('#an-element').data('completion', newValue) })
 *
 * El método listen() agrega un EventListener al elemento form asociado con esta calculadora. Cuando un campo
 * que cuenta para el porcentaje cambia de valor a uno no nulo, recalcula el porcentaje y
 * llama a la función que se dio al método target, si existe, o cambia el atributo value del elemento target.
 */
export class CompletionPercentageCalculator {
  userComment;
  answerText;
  nameRegex;
  form;
  max;
  elements;
  updateFunction;

  constructor(form, data) {
    this.form = form;
    this.userComment = 'user_comment';
    this.answerText = 'answer_text';
    this.nameRegex = /\[[^\[\]]+\]$/; // eslint-disable-line
    const formNames = this.formNames();
    this.elements = data.reduce((function (memo, elem) {
      const name = this.nameFor(elem.q_id, formNames);
      const answerName = name + '[answer_text]';
      const commentName = name + '[user_comment]';
      const entry = {
        'answered': (this.isAnswered(answerName) ? 1 : 0),
        'req_comment': elem.q_required_comment,
        'is_commented': (elem.q_required_comment && this.isAnswered(commentName) ? 1 : 0)
      };
      memo.set(name, entry);
      return memo;
    }).bind(this), new Map());
    this.max = this.getMax();
  }

  target(elem, func = null) {
    this.target = elem;
    this.updateFunction = func;
    return this;
  }

  listen() {
    this.form.addEventListener('change', ((event) => {
      const eTarget = event.target;
      const baseName = eTarget.name.replace(this.nameRegex, '');
      if(this.elements.get(baseName)) {
        this.elements.get(baseName)[this.commentOrAnswer(eTarget)] = (this.isAnswered(eTarget.name) ? 1 : 0);
        this.updateTarget();
      }
    }).bind(this));
  }

  commentOrAnswer(target) {
    return (target.name.includes(this.userComment) ? 'is_commented' : 'answered');
  }

  updateTarget() {
    if(this.updateFunction) return this.updateFunction(this.completionPercentage());
    this.target.value = this.completionPercentage();
  }

  completionPercentage() {
    let current = [...this.elements.values()].reduce(function (memo, elem) {
      return memo + elem['answered'] + (elem['req_comment'] ? elem['is_commented'] : 0);
    }, 0);
    if (current === 0) return 0;
    current = (current / this.max) * 100;
    return current;
  }

  isAnswered(name) {
    return this.form[name].value.toString() !== '';
  }

  formNames() {
    return new Set([...this.form.elements].map(elem => elem.name));
  }

  nameFor(id, formNames) {
    for(const name of formNames) {
      if (name.includes(id.toString())) {
        return name.replace(this.nameRegex, '');
      }
    }
  }

  getMax() {
    return [...this.elements.values()].reduce(function (memo, elem) {
      return memo + 1 + (elem['req_comment'] ? 1 : 0);
    }, 0);
  }
}
