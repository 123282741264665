
/**
 * @file Maneja las selecciones masivas o individuales de los datatables asincronos.
 */
import onmount from 'onmount';
import { countCheckbox } from '../../../../../../../../../app/assets/javascripts/components/multi-select-common.js';

onmount('[data-dt-multi-select]', async function () {
  const datatable = $(this);
  const storeInput = datatable.find('[data-dt-saved-selects]');

  // Extraemos el name de los inputs seleccionables de la tabla.
  const checkboxName = storeInput.data('dtSavedSelects');
  const selectAllCheckbox = datatable.find('[data-dt-select-all]');
  const querySelect = Boolean(datatable.data('dt-query-select'));
  const querySelectTable = querySelect ? makeDataTable() : undefined;
  if(querySelect) setQuerySelectTableEvents();

  selectAllCheckbox.on('click', onClickSelectAll);

  // Asociamos eventos cada vez que una pagina se carga.
  datatable.on('draw.dt', onLoadPage);

  ////////// Eventos

  function onLoadPage() {
    const checkboxs = selectableCheckboxs();
    if(querySelect) {
      querySelectSwitch(false);
      checkboxs.on('click', (_e) => {
        querySelectSwitch(false);
      });
    }

    // Marcamos los checkboxs de la pagina cargada
    markSavedCheckSelecteds(checkboxs);

    checkboxs.on('click', onClickCheckbox);
  }

  function onClickCheckbox(event) {
    const checkbox = event.currentTarget;
    checkbox.checked ? addSelectedIds(checkbox.value) : removeSelectedIds(checkbox.value);

    // Marcarmos/Descarmamos el checkbox de select_all si corresponde.
    const allCheckboxesChecked = findCheckboxWhereCheckedIs(false).length === 0;
    selectAllCheckbox.prop('checked', allCheckboxesChecked);
    if(querySelect) toggleAlert(allCheckboxesChecked);

    emitEventData();
  }

  function onClickSelectAll(event) {
    if(querySelect) querySelectSwitch(false);
    const selectAllCheck = event.currentTarget;
    const checkboxs = selectableCheckboxs();

    const allPageIds = Array.from(checkboxs.map((_i, checkbox) => checkbox.value));

    // Marcamos los checkboxs al mismo estado del select-all checkbox
    checkboxs.prop('checked', selectAllCheck.checked);

    // mostramos u ocultamos alerta de seleccion completa dependiendo el estado del checkbox
    if(querySelect) {
      setAlertText();
      toggleAlert(selectAllCheck.checked);
    }

    // Removemos o añadimos a la lista de ids guardados
    selectAllCheck.checked ? addSelectedIds(...allPageIds) : removeSelectedIds(...allPageIds);
    emitEventData();
  }

  ////////////////////// Metodos

  function selectableCheckboxs() {
    return datatable.find(`input[name="${checkboxName}"][type=checkbox]`);
  }

  function findCheckboxWhereCheckedIs(checked) {
    const pseudoClass = checked ? ':checked' : ':not(:checked)';
    return datatable.find(`input[name="${checkboxName}"][type=checkbox]${pseudoClass}`);
  }

  // Se encarga de pintar los checkbox cada vez que cambiamos de pagina.
  function markSavedCheckSelecteds(checkboxs) {
    const savedIds = getSelectedIds();

    // Si no hay nada guardado solo hay que poner select_all unchecked
    if (savedIds.size === 0) {
      selectAllCheckbox.prop('checked', false);
      return;
    }

    let allChecked = true;
    checkboxs.each((_i, checkbox) => {
      const isValueSaved = savedIds.has(checkbox.value);
      checkbox.checked = isValueSaved;

      allChecked = allChecked && isValueSaved;
    });

    // Si todos los check esta seteados marcamos el checkboxSelectAll.
    selectAllCheckbox.prop('checked', allChecked);
    if(querySelect) toggleAlert(allChecked);
  }

  function getSelectedIds() {
    const savedData = storeInput.val();
    // Devolvemos un conjunto que no admite repetidos.
    return new Set(savedData ? JSON.parse(savedData) : undefined);
  }

  // Emite un evento cada vez que cambia los elementos seleccionados de la tabla.
  function emitEventData() {
    datatable.trigger($.Event('dtDataChange', { selectedIds: Array.from(getSelectedIds()) }));

    // Esto lo ejecutamos para mantener la compatilibdad con el js de datatables síncronos lo más posible.
    countCheckbox(datatable);
  }

  //// METODOS DE AÑADIR Y ELIMINAR ENTRADAS DEL INPUT STORE
  function addSelectedIds() { addRemoveStoreInput(true, arguments); }

  function removeSelectedIds() { addRemoveStoreInput(false, arguments); }

  function addRemoveStoreInput(isAdd, values) {
    const savedIds = getSelectedIds();
    // Eliminamos o añadimos cada elemento pasado por parametro al conjunto de ids guardados
    Array.from(values).forEach(id => isAdd ? savedIds.add(id) : savedIds.delete(id));
    storeInput.val(JSON.stringify(Array.from(savedIds)));
  }

  //// METODOS PARA ENVIAR QUERY DE SELECCION COMPLETA AL CONTROLADOR
  function toggleAlert(checked) {
    if(checked) {
      $(`#${datatable.attr('id')}-alert-container`).removeClass('hidden');
    }
    else {
      $(`#${datatable.attr('id')}-alert-container`).addClass('hidden');
    }
  }

  function setAlertText() {
    const recordsTotalContainer = $(`#${datatable.attr('id')}-alert-total-records`);
    const recordsTotalAltContainer = $(`#${datatable.attr('id')}-alert-total-records-alt`);
    const recordsCurrentContainer = $(`#${datatable.attr('id')}-alert-current-records`);
    recordsTotalContainer.text(querySelectTable.ajax.json().recordsFiltered);
    recordsTotalAltContainer.text(querySelectTable.ajax.json().recordsFiltered);
    recordsCurrentContainer.text(selectableCheckboxs().length);
  }

  function makeDataTable() {
    return datatable.DataTable(datatable.data());
  }

  function querySelectSwitch(switchTo) {
    datatable.data('query-select-on', switchTo);
    toggleAlertContent();
    emitEventData();
  }

  function toggleAlertContent() {
    const querySelectOn = datatable.data('query-select-on');
    const alertContentOn = $(`#${datatable.attr('id')}-alert-content-on`);
    const alertContentOff = $(`#${datatable.attr('id')}-alert-content-off`);
    alertContentOn.toggleClass('hidden', querySelectOn);
    alertContentOff.toggleClass('hidden', !querySelectOn);
  }

  function setQuerySelectTableEvents() {
    datatable.on('dt:advancedFilter', (event) => {
      storeInput.data('dt-ransack-params', event.detail);
    });
    $(`.${datatable.attr('id')}-query-select-anchor`).on('click', (_event) => {
      querySelectSwitch(!datatable.data('query-select-on'));
    });
  }
});
