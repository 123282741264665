/** @file TODO: documentar */
import onmount from 'onmount';

let chip = '';
let chipText = '';
const mediaQueryDesktop = window.matchMedia('(min-width: 960px)');

onmount('#modo_dios_boton', function () {
  const guardarButton = $('#footer_godmode_button');
  const switchSession = $('#modo_dios_boton');
  guardarButton.on('click', ()=>{
    $.ajax({ url: '/modo_dios?format=js', method: 'get' }).done();
  });
  guardarButton[0].setAttribute('disabled', true);
  switchSession.on('click', ()=>{
    guardarButton[0].toggleAttribute('disabled');
  });
});

onmount('#session_chip > .chip', function () {
  chip = this;
  chipText = chip.innerText;

  mediaQueryDesktop.addEventListener('change', replaceChipText);
}, function () {
  mediaQueryDesktop.removeEventListener('change', replaceChipText);
});

function replaceChipText(event) {
  if (event.matches) {
    chip.innerText = chipText;

    return;
  }

  chip.innerText = chipText[0].toUpperCase();
}
