/** @file TODO: documentar */

import 'datatables.net';
import 'datatables.net-bs4';

import JSZip from 'jszip';
window.JSZip = JSZip;

import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons-bs4';

import './natural';
import '../../../../../packs/plataforma/building_blocks/\
datatables/app/assets/javascripts/vendor/datatables/wait-datatable';
