/** @file TODO: documentar */
'use strict';
import slugify from 'slugify';
/**
 * Limita el largo del string al valor especificado sin cortar ninguna palabra.
 */
const limitString = (string, minLength) => {
  const cadena = `${string}`;

  if (cadena.length < minLength) return cadena;

  const cortaPalabra = (procesada, porProcesar) => {
    const longitudActual = procesada.length + porProcesar.length + 1;
    if (longitudActual > minLength) return procesada;
    return `${procesada} ${porProcesar}`.trim();
  };

  const cadenaCortada = string.split(' ').reduce(cortaPalabra, '');

  return `${cadenaCortada}...`;
};

const toCode = (string, separator = '_') =>
  slugify(string, { replacement: separator, remove: null, lower: true });

// Funcion para pasar bytes a lectura humana. Formatea tamaños.
const numberToHumanSize = (bytes, decimals = 1) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const escapable = '\'"!#$%&()*+,./:;<=>?@\\[\\]^`{|}~',
  escapeRe = new RegExp('[' + escapable + ']', 'g');

/// <summary>Escapes a name for filtering in a jquery selector</summary>
/// <param name="name" type="String">The unescaped name</param>
/// <returns type="String">The escaped string or an empty string if the argument is null or undefined</returns>
const escapeName = (name) =>
  name?.replace ? name.replace(escapeRe, '\\$&') : '';

export { limitString, toCode, numberToHumanSize, escapeName };
