/** @file
 * Archivo de marcas de amplitud para módulo de cargos
 */
import onmount from 'onmount';

onmount('#role-copilot-form', async function () {
  const roleCopilotButton = $(this).find('#role_autocomplete_bukcopilot_edit_button, ' +
                                         '#role_autocomplete_bukcopilot_create_button');
  const roleData = {
    'new_role': roleCopilotButton.data('newRole'),
  };
  roleCopilotButton.click(function () {
    roleCopilotButton.trigger('amplitudeTracking', { message: 'Ask_Role_Copilot', data: roleData });
  });
});
