/** @file
 * Archivo de marcas de amplitud para ver liquidaciones
 */
import onmount from 'onmount';

onmount('[data-amplitude="Preview_Payroll_Documents"]', function () {
  const button = $(this);
  const source = button.data('source');

  button.on('click', () => {
    button.trigger('amplitudeTracking', {
      message: 'Preview_Payroll_Documents',
      data: {
        source,
      },
    });
  });
});

onmount('[data-amplitude="View_Payroll_Documents"]', function () {
  const button = $(this);
  const source = button.data('source');

  button.on('click', () => {
    button.trigger('amplitudeTracking', {
      message: 'View_Payroll_Documents',
      data: {
        source,
      },
    });
  });
});

onmount('[data-amplitude="Download_Payroll_Document"]', function () {
  const button = $(this);
  const source = button.data('source');
  const document = button.data('document');

  button.on('click', function () {
    button.trigger('amplitudeTracking', {
      message: 'Download_Payroll_Document',
      data: {
        source,
        'payroll_document_type': document,
      },
    });
  });
});

onmount('[data-amplitude="Open_Payroll_Document"]', function () {
  const button = $(this);
  const source = button.data('source');
  const document = button.data('document');

  button.on('click', function () {
    button.trigger('amplitudeTracking', {
      message: 'Open_Payroll_Document',
      data: {
        source,
        'payroll_document_type': document,
      },
    });
  });
});
