/** @file TODO: documentar */
import onmount from 'onmount';
import Cookies from 'js-cookie';

onmount('[data-widget="tree"]', function () { $(this).tree(); });
onmount('.box', function () {
  $(this).boxWidget({
    collapseTrigger: '[data-widget="collapse"]',
    // Deben mantenerse sincronizados con icons_helper.rb
    collapseIcon: 'fa-angle-down',
    expandIcon: 'fa-angle-left',
    removeIcon: 'fa-times',
  });
});

// Tiene que ser el mismo que en application.html.erb
const cookieName = 'buk-sidebar-collapsed';

$(document).on('expanded.pushMenu', () => Cookies.set(cookieName, false));
$(document).on('collapsed.pushMenu', () => Cookies.set(cookieName, true));

onmount('[data-toggle="push-menu"]', function () {
  $(this).pushMenu({ collapseScreenSize: 959 });
});
