/** @file
 * En componente toma una ruta contenida en un select y reedireciona a dicha ruta.
 */
import onmount from 'onmount';

onmount('[data-redirect-on-change="true"]', function () {
  const selector = $(this);
  selector.on('change', function () {
    const url = selector.find(':selected').val();
    window.location.replace(url);
  });
});
