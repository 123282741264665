/** @file Archivo utilizado para agregar interactividad a la vista de aceptación de TyC */
import onmount from 'onmount';

// Flujo desde liquidación
onmount('#aceptar_tc', function () {
  $('#aceptar_tc').on('click', function () {
    const checked = document.getElementById('aceptar_tc').checked;
    habilitarBotonFirmar(checked);
  });
});

function habilitarBotonFirmar(checked) {
  $('#boton-aceptar-tyc').prop('disabled', !checked);
}

// Flujo a través de los modals
onmount('#advances-terms-and-conditions', function () {
  $('#advances_accept_tyc').on('click', function () {
    const checked = document.getElementById('advances_accept_tyc').checked;
    enableNextStepSignTermsAndConditions(checked);
  });

  $('#advances-button-next-tyc').on('click', function () {
    enableCheckboxSignTermsAndConditions(false);
  });
});

function enableNextStepSignTermsAndConditions(enable) {
  $('#advances-button-next-tyc').prop('disabled', !enable);
}

function enableCheckboxSignTermsAndConditions(enable) {
  $('#advances_accept_tyc').prop('disabled', !enable);
}
