/** @file
*
* Contiene lógica para manejar elementos en la interfaz de usuario (UI) con base a
* la interacción del scroll vertical en mobile y desktop
*/

import onmount from 'onmount';

onmount('.fiji-body', function () {
  const wrapper = document.querySelector('.content-wrapper');
  const headerTable = this.querySelector('.fiji-header');

  if (!wrapper || !headerTable) return;

  const isMobile = window.innerWidth < 960;

  wrapper.addEventListener('scroll', () => handleScroll(wrapper, isMobile, headerTable));
});

/**
 * @function handleScroll Detecta y responde al scroll vertical para modificar elementos en la UI
 * @param {HTMLElement} wrapper Elemento que dispara el evento scroll
 * @param {boolean} isMobile Indica si la pantalla es mobile (menor a 960px)
 */
function handleScroll(wrapper, isMobile, headerTable) {

  // Array de elementos que serán manipulados con base al scroll vertical
  const elements = [
    { selector: '.main-header', className: 'hide-on-scroll' },
    { selector: '.box-header', className: 'sticky-on-scroll' },
  ];

  const scrollPosition = wrapper.scrollTop;
  const scrollHeader = document.querySelector(elements[0].selector)?.scrollTop || 0;
  const condition = scrollPosition > scrollHeader;

  headerTable.classList.toggle('shadow-on-scroll', condition);

  elements.forEach(item => {
    const element = document.querySelector(item.selector);
    if (element) {
      // Aplicar hide-on-scroll solo para .main-header en dispositivos móviles
      if (item.selector === '.main-header') {
        element.classList.toggle(item.className, condition && isMobile);
      }
      else {
        element.classList.toggle(item.className, condition);
      }
    }
  });
}
