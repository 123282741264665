/* eslint-disable max-len */
/** @file TODO: documentar */
import onmount from 'onmount';
// eslint-disable-next-line buk/no-static-import
import ckeditor from '../../../../../../../app/assets/javascripts/components/mail-ckeditor';

class PostulantesSelectionWidget {
  constructor(buttonsContainer, postulantesContainer) {
    this.buttons_container = buttonsContainer;
    this.postulantes_container = postulantesContainer;
    this.setHandlers();
    this.selected = 0;
  }
  getCheckboxContainers() {
    return this.postulantes_container.find('.postulante-checkbox');
  }

  getCheckboxsIn(container) {
    return container.find('.postulante-checkbox');
  }

  getPostulantesCheckboxes($filter) {
    var $checkboxes = $filter || this.getCheckboxContainers();
    return $checkboxes.find('input[type=checkbox]');
  }
  checkSelection(changedCheckbox) {
    var $checkboxZone = this.getCheckBoxZone(changedCheckbox);
    var selected = this.getPostulantesCheckboxes($('#lista_etapas')).filter(':checked').length;
    const employeesSearchTruora = document.getElementById('number_of_employees');
    if(employeesSearchTruora !== null) {
      employeesSearchTruora.innerHTML = selected;
    }
    if(this.selected === 0 && selected > 0) {
      this.buttons_container.trigger('buk:seleccion:postulantes:select');
    }
    if(this.selected > 0 && selected === 0) {
      this.buttons_container.trigger('buk:seleccion:postulantes:deselect');
    }
    this.selected = selected;

    if(changedCheckbox) {
      this.checkEtapaSelection($checkboxZone, $checkboxZone.data('estado'));
    }
  }

  checkEtapaSelection($etapa, estado) {
    var postulantes = $etapa.find('.postulante-checkbox').length;
    var checked = $etapa.find('.postulante-checkbox input:checked').length;
    var zoneSelector = `[data-action=select_etapa_${estado}]`;
    if(checked < postulantes) {
      $etapa.closest('.etapa')
        .find(zoneSelector)
        .removeClass('checked')
        .find('.etapa-check')
        .removeClass('hidden')
        .filter('.checked').addClass('hidden');
    }
    else{
      $etapa.closest('.etapa')
        .find(zoneSelector)
        .addClass('checked')
        .find('.etapa-check')
        .removeClass('hidden')
        .filter(':not(.checked)').addClass('hidden');
    }
  }

  selectAll($etapa) {
    this.getPostulantesCheckboxes($etapa).prop('checked', true);
    this.checkSelection();
  }

  selectNone($etapa) {
    this.getPostulantesCheckboxes($etapa).prop('checked', false);
    this.checkSelection();
  }

  checkEtapa(etapa, estado) {
    var $etapaCheckbox = $(etapa);
    var $checkboxesEtapa = $etapaCheckbox.closest('.etapa').find(`.${estado}`).find('.postulante-checkbox');
    if($etapaCheckbox.is('.checked')) {
      this.selectNone($checkboxesEtapa);
      $etapaCheckbox.removeClass('checked').find('.etapa-check').toggleClass('hidden');
    }
    else{
      this.selectAll($checkboxesEtapa);
      $etapaCheckbox.addClass('checked').find('.etapa-check').toggleClass('hidden');
    }

    // Se gatilla el metodo change de uno de los checkboxes para que los metodos para activar y desactivar
    // acciones lo tomen. Solo se toma un checkbox para evitar lanzar demasiados eventos en casos de muchos
    // postulantes en la etapa.
    $checkboxesEtapa.find('input[type=checkbox]').first().trigger('change');

    return false;
  }

  getCheckBoxZone(changedCheckbox) {
    var $checkbox = $(changedCheckbox);
    var checkboxZone;

    var etapaContainedArea =  $checkbox.closest('.contained');
    var etapaDiscartedArea =  $checkbox.closest('.discarted');

    if(etapaContainedArea.length > 0) {
      checkboxZone =  etapaContainedArea;
    }

    if(etapaDiscartedArea.length > 0) {
      checkboxZone = etapaDiscartedArea;
    }

    return checkboxZone;
  }

  setHandlers() {
    this.postulantes_container.find('[data-action=select_etapa_contained]').click((event) => this.checkEtapa(event.currentTarget, 'contained'));
    this.postulantes_container.find('[data-action=select_etapa_discarted]').click((event) => this.checkEtapa(event.currentTarget, 'discarted'));
    this.getCheckboxContainers().on('change', 'input[type=checkbox]', (event) => this.checkSelection(event.currentTarget));
  }
}
class MassMessagePostulantesWidget {
  constructor(postulantesList, postulanteIds) {
    this.postulantes_list = postulantesList;
    this.postulante_ids = postulanteIds;
    this.buttons = $('#send_email_button, #email_preview_button');
    this.setHandlers();
  }
  removePostulanteId(id) {
    var sel = 'input[value=' + id + ']';
    this.postulante_ids.find(sel).remove();
  }
  checkPostulantesCount() {
    var count = this.postulantes_list.find('[data-delete-postulante]').length;
    if(count === 0) {
      this.showEmptyList();
      this.disableButtons();
    }
  }
  showEmptyList() {
    this.postulantes_list.html('<p>Sin destinatarios</p>');
  }
  disableButtons() {
    this.buttons.prop('disabled', true);
  }
  deletePostulante($postulanteButton) {
    var id = $postulanteButton.data('delete-postulante');
    $postulanteButton.remove();
    this.removePostulanteId(id);
    this.checkPostulantesCount();
  }
  setHandlers() {
    this.postulantes_list.on('click', '[data-delete-postulante]', (event) => {
      event.preventDefault();
      this.deletePostulante($(event.currentTarget));
    });
  }
}

onmount('#message_content', async function (obj) {
  const { loadCKeditor, CKeditorVersion, loadCKeditor5 } = await import('@assets/javascripts/components/ckeditor/load');
  const { RICH_EDITOR_OPTIONS } = await import('@assets/javascripts/components/ckeditor/custom_configs');

  if (CKeditorVersion() === '4') {
    await loadCKeditor();
    obj.editor = ckeditor(this);
  }
  else {
    await loadCKeditor5(this, obj, RICH_EDITOR_OPTIONS());
  }

  $('#toggle-template-information').click(function () {
    $('#template-information').fadeToggle();
  });
}, function (obj) {
  obj.editor.destroy();
});

onmount('#mensajes', async function () {
  const { loadCKeditor, CKeditorVersion } = await import('@assets/javascripts/components/ckeditor/load');
  const errorViewMail = document.getElementById('errorViewMail').getAttribute('data-error-view-mail');

  if (CKeditorVersion() === '4') await loadCKeditor();
  var $modal  = $('#email_preview');
  var $emailForm = $('#new_message');
  var $emailPreviewContainer = $('#email_preview_result');
  var $loader = $('#buk-loader-container');
  function toggleLoadingEmail() {
    $loader.toggleClass('hidden');
  }
  function showEmailContent(content) {
    $emailPreviewContainer.html(content).removeClass('hidden');
  }
  function showEmailError() {
    showEmailContent(errorViewMail);
  }
  function getEmail(url) {
    $.get(url)
      .done(showEmailContent)
      .fail(showEmailError)
      .always(toggleLoadingEmail);
  }
  function getEmailPreview(url, data) {
    $.post(url, data)
      .done(showEmailContent)
      .fail(showEmailError)
      .always(toggleLoadingEmail);
  }
  $modal.on('show.bs.modal', function (e) {
    var openerButton = $(e.relatedTarget);
    toggleLoadingEmail();
    $emailPreviewContainer.addClass('hidden');
    if(openerButton.is('.show-message')) {
      getEmail(openerButton.data('url'));
    }
    else{
      if (CKeditorVersion() === '4') CKEDITOR.instances['message_content'].updateElement();
      getEmailPreview(openerButton.data('url'), $emailForm.serialize());
    }
  });
});

/**
 * Al montarse el contenido de lista etapas, o una fila en la vista de tabla, se agregan los eventos
 * para detectar el click en los checkbox y mostrar/ocultar el botón de envío masivo de mensajes.
 */
onmount('#lista_etapas > div, #applicants_in_stage_table_wrapper tr', function () {
  const massActions = $('[data-applicants-massive-messages]');
  const surveyAction = $('[data-applicants-massive-surveys]');
  const cvAnalysisAction = $('[data-applicants-massive-cv-analysis]');
  massActions.hide();
  surveyAction.hide();
  cvAnalysisAction.hide();

  const selectWidget = new PostulantesSelectionWidget(massActions, $(this));
  massActions.data('select-widget', selectWidget);
  massActions.on('buk:seleccion:postulantes:select', function () {
    massActions.removeClass('disabled').prop({ disabled: false }).show();
    surveyAction.show();
    cvAnalysisAction.show();
  });
  massActions.on('buk:seleccion:postulantes:deselect', function () {
    massActions.addClass('disabled').prop({ disabled: true }).hide();
    surveyAction.hide();
    cvAnalysisAction.hide();
  });
});

onmount('#mass_message_postulantes', function () {
  const massMessageWidget = new MassMessagePostulantesWidget($(this), $('#message_postulante_ids'));
  $(this).data('mass-message-widget', massMessageWidget);
});

onmount('#new_message', function () {
  $(this).on('ajax:error', function () {
    $('#message-response').removeClass('d-none').addClass('d-block');
  });
});

onmount('[data-toggle="edit-selection-stages"]', function () {
  const editableItems = $('[data-selection="editable"]');
  const activableItems = $('[data-selection="activable"]');
  $(this).click(function () {
    editableItems.toggleClass('hidden');
    activableItems.editable('toggleDisabled');

    return false;
  });
});
