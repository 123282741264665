/** @file TODO: documentar */
import onmount from 'onmount';

onmount('form.with_nested_form', function () {
  $(this).on('click', '.remove_record', function (event) {
    $(this).prev('input[type=hidden][name*="_destroy"]').val('1');
    const target = $(this).closest($(this).data('target') || 'tr');
    target.find("input, textarea").attr('required', false);
    if($(this).data('remove')) {
      target.remove();
    }
    else {
      target?.hide();
      target?.collapse('hide');
    }
    return event.preventDefault();
  });

  $(this).on('click', '.add_fields_row', function (event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    const target = $(this).data('target');
    const newElement = $($(this).data('fields').replace(regexp, time));
    newElement.appendTo(target).trigger('buk:load-content');

    return event.preventDefault();
  });
});
