/** @file TODO: documentar */
import { defaults, identity } from 'lodash-es';
import onmount from 'onmount';
import { isInDom } from '../../../../../../../../app/assets/javascripts/lib/interaction-functions';

const DISABLE_AUTOCALC_PROP = 'disable_autocalc';

function roundN(value, n) {
  return Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
}

function floorN(value, n = 0) {
  return Math.floor(value * Math.pow(10, n)) / Math.pow(10, n);
}

function AutoProductOf(element, selectors, options) {
  const self = this;
  const $element = $(element);
  options = defaults(options || {}, {
    formatFunc: identity,
    invertTotal: false,
    round: 2,
    floor: null,
  });

  this.eventMap = {
    'change autoproduct input': doProduct,
  };
  this.selectors = selectors;
  $(document).on(this.eventMap, this.selectors);
  doProduct();

  function doProduct() {
    if (!isInDom($element[0])) {
      self.destroy();
      return;
    }

    const disableAutoCalcProp = $element.attr(DISABLE_AUTOCALC_PROP);

    if(disableAutoCalcProp === 'true') {
      return;
    }

    var product = 1;
    // Refilter selector in case new elements were added
    $(selectors).each(function () {
      if ($(this).is(':disabled')) return;
      product *= self.getValue(this);
    });

    if (options.invertTotal) {
      product = -product;
    }
    product = product || 0;

    // el comportamiento por defecto es redondear a 2 decimales
    // a menos que se especifique floor en lugar de round
    product = options.floor === null ? roundN(product, options.round) : floorN(product, options.floor);

    if ($element.is(':input')) {
      $element.val(options.formatFunc(product));
    }
    else {
      // Todo: install numeral and use here
      $element.text(options.formatFunc(product));
    }
    // product =
    $element.data('autoProductValue', product).trigger('autoproduct').change();
  }
}

AutoProductOf.prototype.destroy = function () {
  $(document).off(this.eventMap, this.selectors);
};

AutoProductOf.prototype.getValue = function getValue(elem) {
  var $e = $(elem);
  if (typeof ($e.data('autoProductValue')) !== 'undefined') {
    return +$e.data('autoProductValue');
  }
  else if ($e.is(':input')) {
    return +$e.val();
  }
  return 0;
};

AutoProductOf.prototype.roundN = function (value, n = 0) {
  return Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
};

onmount('[data-autoproduct-of]', function (obj) {
  var $this = $(this),
    selectors = $this.data('autoproductOf'),
    formatFunc = $this.data('autoproductFormatFunc');

  if (typeof formatFunc === 'string') {
    formatFunc = window[formatFunc];
  }
  var autoproducts = [];
  $this.each(function () {
    const options = {
      formatFunc: formatFunc,
      invertTotal: $this.data('autoproductOfSign') === '-',
      round: $this.data('autoproductRound'),
      floor: $this.data('autoproductFloor'),
    };
    autoproducts.push(new AutoProductOf(this, selectors, options));
  });
  obj.autoproducts = autoproducts;
}, function (obj) {
  (obj.autoproducts || []).forEach(i => i.destroy());
});
