/** @file
 * Carga información sobre el tipo de permiso seleccionado
 * en un cuadro informativo, donde muestra la descripción y
 * si es con o sin goce de sueldo.
 *
 */
import onmount from 'onmount';

const handleAlertMessagePermission = () => {
  const licenceType = $('#licence_licence_type_id').find(':selected').text();
  const alertMesssageDaysPermission = $('#alert_messsage_days_permission');
  const alertMesssageHoursPermission = $('#alert_messsage_hours_permission');

  if (licenceType.toLocaleLowerCase().includes('hora')) {
    alertMesssageDaysPermission.css('display', 'none');
  }
  else {
    alertMesssageHoursPermission.css('display', 'none');
  }
};

onmount('div#licence_type_information', function () {
  $('#licence_licence_type_id').change(function () {
    const $this = $(this);
    if ($this.val() === '') {
      $('#licence_type_information').hide();
    }
    else {
      $('#licence_type_information').show();
      $('span#info_licence_withpay').text($this.find(':selected').data('with_pay'));
      $('span#info_licence_description').text($this.find(':selected').data('description'));
    }
  }).change();
});

onmount('form#new_licence', function () {
  // Manejo de errores: reemplazamos el formulario por el formulario con errores retornado
  $(this).on('ajax:error', function (event) {
    const xhr = event.detail[2];
    if (xhr.status === 422) {
      $(this).replaceWith(xhr.response);
      // Volvemos a gatillar los comportamientos adjuntos a los elementos del formulario
      $.onmount();
    }
  });

  // En form nueva licencia, si se vuelve a escoger tipo de licencia, se resetea el select de consecuencia_mexico
  $('#licence_tipo_licencia').on('change', function () {
    const selectedLicence = $(this).val();
    if (selectedLicence === 'riesgo_trabajo') {
      // Se setea valor de Ninguna y así no mostrar control de incapacidad
      $('#licence_consecuencia_mexico').val('ninguna_consecuencia');
      $('#control-incapacidad-container').hide();
    }
    else {
      // Si es otro tipo de licencia entonces se debe de mostrar control de incapacidad
      $('#control-incapacidad-container').show();
    }
  });

  $('#licence_consecuencia_mexico').on('change', function () {
    const selectedConsecuence = $(this).val();
    if (selectedConsecuence === 'ninguna_consecuencia' || selectedConsecuence === '') {
      $('#control-incapacidad-container').hide();
    }
    else {
      // Si se escoge culquier otra consecuencia se debe de mostrar control de incapacidad
      $('#control-incapacidad-container').show();
    }
  });

  $('#licence_start_date').on('change', function () {
    const timeStartDateDiv = $('#time_start_date');

    if (timeStartDateDiv) {
      const $self = $(this);
      const licenceStartDateVal = $self.val();
      const timeStartDateDefaultValue = timeStartDateDiv.data('defaultValue');
      const finalStartDate = licenceStartDateVal
        ? licenceStartDateVal.replaceAll('-', '/')
        : timeStartDateDefaultValue;

      timeStartDateDiv.html(finalStartDate);
    }
  });

  handleAlertMessagePermission();
});

onmount('#licence_public_permission_metadatum_attributes_motivo', function () {
  const motiveSelect = $('#licence_public_permission_metadatum_attributes_motivo');
  const typeSelect = $('#licence_public_permission_metadatum_attributes_tipo_permiso');
  motiveSelect.on('select2:select', function () {
    hideShowOthers(motiveSelect.val());
  });
  typeSelect.on('select2:select', function () {
    hideShowOthers(motiveSelect.val());
  });
  function hideShowOthers(selectedOption) {
    if (selectedOption === 'otros') {
      $('#otro_motivo').show();
    }
    else {
      $('#otro_motivo').hide();
    }
  }
});

// Si no esta activada la general el div de licence_porcentaje_aporte no existirá y por lo tanto,
// Esto no hará nada.
onmount('div#licence_porcentaje_aporte', function () {
  // Si el input de dias es distinto de 0 cuando montamos entonces mostramos el div porcentaje.
  const diasValue = parseFloat($('input#licence_dias_aporte').val());
  if (diasValue !== 0) { $('div#licence_porcentaje_aporte').removeClass('d-none'); }
  // Cada vez que cambie dias aporte a 0 se ocultara porcentaje y se reseteara a 100.0
  $('input#licence_dias_aporte').change(function () {
    const value = parseFloat($(this).val());
    const percentageDiv = $('div#licence_porcentaje_aporte');
    const percentageInput = $('input#licence_porcentaje_aporte');
    if (value === 0) {
      percentageDiv.addClass('d-none');
      percentageInput.val(100.0);
    }
    else { percentageDiv.removeClass('d-none'); }
  });
});

onmount('div#suma-dias-licencias', function () {
  $('#licence_dias_tomados').on('keyup', function () {
    const tipoVal = $('#licence_tipo_licencia').val();
    if (tipoVal !== 'subsidio_incapacidad_no_computable_para_cts') { $('div#suma-dias-licencias').hide(); }
  });

  $('#licence_tipo_licencia').on('change', function () {
    const tipoVal = $('#licence_tipo_licencia').val();
    if (tipoVal !== 'subsidio_incapacidad_no_computable_para_cts') { $('div#suma-dias-licencias').hide(); }
    tipoVal === 'subsidio_maternidad' ? $('#licence_dias_tomados').val(98) : $('#licence_dias_tomados').val('');
  });

  $('#licence_start_date').on('change', function () {
    const tipoVal = $('#licence_tipo_licencia').val();
    if (tipoVal !== 'subsidio_incapacidad_no_computable_para_cts') { $('div#suma-dias-licencias').hide(); }
  });
});

const parseTimeToInteger = time => {
  const [hours, minutes] = time.split(':').map(v => parseInt(v));
  return hours * 60 + minutes;
};

const parseIntegerToTime = (hours, minutes) => {
  let hoursString = '';
  let minutesString = '';

  if (hours > 0) {
    hoursString = `${hours} ${(hours === 1 ? 'hora' : 'horas')}`;
  }

  if (minutes > 0) {
    minutesString = `${minutes} min.`;
  }

  const result = `${hoursString} ${minutesString}`.trim();

  if (result === '') {
    return '0 horas';
  }

  return result;
};

const getHoursAndMinutes = (start, end) => {
  if (start === '' || end === '') {
    return '';
  }

  const startTimeInMinutes = parseTimeToInteger(start);
  let endTimeInMinutes = parseTimeToInteger(end);

  if (startTimeInMinutes > endTimeInMinutes) {
    endTimeInMinutes += 1440; // 1440 = 24 * 60 // 12 horas
  }

  let result = startTimeInMinutes - endTimeInMinutes;

  if (result < 0) {
    result *= -1;
  }

  const hours = Math.floor(result / 60);
  const minutes = result - (hours * 60);
  const resultStr = parseIntegerToTime(hours, minutes);

  return resultStr;
};

const handlerTime = () => {
  const diff = getHoursAndMinutes($('#licence_start_time').val(), $('#licence_end_time').val());
  const divTimeDiffInWords = $('#time_diff_in_words');

  if (!diff && divTimeDiffInWords.data('defaultValue')) {
    divTimeDiffInWords.html(divTimeDiffInWords.data('defaultValue'));
  }
  else {
    divTimeDiffInWords.html(diff);
  }
};

onmount('div#time_diff_in_words', function () {
  // para cuando se abra la edición del permiso
  handlerTime();

  $('#licence_start_time').on('blur', handlerTime);
  $('#licence_end_time').on('blur', handlerTime);
});

onmount('#edit_licence', function () {
  $('#licence_licence_type_id').on('change', function () {
    $("#licence_time_span").val($("#licence_licence_type_id option:selected").data('time_span'));
    if ($('#time_diff_in_words:visible').length) {
      // se hace al contrario por el delay que hay en las animaciones
      $('#licence_dias_tomados').attr('min', '1');
    }
    else {
      $('#licence_dias_tomados').removeAttr('min');
    }
  });
});

/**
 *
 * Esto busca actuar en los dias de aporte
 *
 * Espera aplicar el valor por defecto para los dias de aporte
 */
onmount('[data-licence-dias-aporte-default]', function () {
  const defaultValue = +this.dataset.licenceDiasAporteDefault;
  const diasAporte = $(this);
  $('#licence_dias_tomados').on('keyup', function (evt) {
    evt.preventDefault();
    let value = +evt.target.value;

    if(value === 0 || value > defaultValue || Number.isNaN(value))
      value = defaultValue;

    diasAporte.val(value);
  });
});
