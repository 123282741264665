/** @file TODO: documentar */
import Intercooler from '../lib/intercooler.js';
import onmount from 'onmount';
import { info } from '../lib/buk/alert.js';

window.Intercooler = Intercooler;

// Copiado de _MACROS en intercooler.js. Actualizar cuando eso cambie
const atributosIC = [
  'ic-get-from',
  'ic-post-to',
  'ic-put-to',
  'ic-patch-to',
  'ic-delete-from',
  'ic-style-src',
  'ic-attr-src',
  'ic-prepend-from',
  'ic-append-from',
  'ic-action',
  'ic-on-beforeSend',
  'ic-complete-action',
  'ic-action-target',
  'ic-on-success',
].map(i => `[${i}]`).join(',');

let contentLoaded = false;
$('body').one('nodesProcessed.ic', () => contentLoaded = true);
onmount(atributosIC, function () {
  // Intercooler.processNodes no es siempre idempotente (no sabemos por que)
  // https://github.com/intercoolerjs/intercooler-js/issues/272
  if (contentLoaded && $(this).data('elementAdded.ic') !== true) {
    Intercooler.processNodes(this);
  }
});

onmount('[ic-trigger-on-load]', function () {
  const action = () => {
    if ($(this).attr('ic-trigger-on-load') !== 'false') {
      Intercooler.triggerRequest(this);
    }
  };
  if (contentLoaded) {
    action();
  }
  else {
    $('body').one('nodesProcessed.ic', action);
  }
});

// Esta funcion nos permite lanzar un alert-info despues que se ejecuta con exito una request de intercooler
onmount('[ic-on-success-info]', function () {
  $(this).on('success.ic', function () {
    info($(this).attr('ic-on-success-info'));
  });
});
