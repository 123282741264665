/** @file Script que sirve para copiar un elemento al clipboard
 *
 * Recibe el texto a copiar
 * @example
 *   <button data-copy-to-clipboard="texto"/>
 *
 * */
import onmount from 'onmount';

onmount('[data-copy-to-clipboard]', function () {
  $(this).on('click', () => {
    var copyText = $(this).data('copy-to-clipboard');
    var texts = JSON.parse($(this).attr('data-text'));
    var textLinkCopied = texts[0];
    var textNotCopied = texts[1];
    var textUrlError = texts[2];

    var textArea = document.createElement('textarea');
    textArea.value = copyText;
    textArea.setAttribute('readonly', '');
    document.body.appendChild(textArea);
    textArea.select();
    try {
      // Ejecutar el comando de copiar al portapapeles
      var successful = document.execCommand('copy');
      var msg = successful ? textLinkCopied : textNotCopied;
      window.alert(msg);
    }
    catch (err) {
      // Si hay algún error, mostrar mensaje de error
      window.alert(textUrlError);
    }
    finally {
      document.body.removeChild(textArea);
    }
  });
});
