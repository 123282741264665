/** @file
 *
 * Para configurar el public path a un cdn en runtime (donde tendremos el asset host)
 * necesitamos setear esta variable. Esta variable solo existe dentro de webpack
 * asi que tenemos que definir la variable fuera de webpack, y copiarla aca
 *
 * Webpack/Babel reordena el código js para poner todos los imports primero, así que
 * debemos mover este código a un archivo que será importado, y este archivo debe ser
 * el primero en ser importado
 */

if (window.webpackPublicPath) {
  // Esta variable global es definida por webpack
  /* eslint-disable-next-line camelcase, no-undef */
  __webpack_public_path__ = window.webpackPublicPath;
}
