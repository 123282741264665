/**
 * @file Portal de Integración
 * @description Este archivo contiene funciones y eventos para interacciones dinámicas específicas del Portal de Integración.
 */

import onmount from 'onmount';

// Variable para rastrear si la lógica asociada a 'data-portal-integration-status-notify' ya se ejecutó
let dataStatusLoaded = false;

/**
 * Función que se ejecuta cuando se monta un elemento con el atributo 'data-portal-integration-status-notify',
 * con la finalidad de gatillar nuevamente el worker status para mostrar el notify con el estado actual del job
**/
onmount('[data-portal-integration-status-notify]', async function () {

  // Variable que actua como switch para activar y desactivar el worker status
  let intervalWorkStatus = false;

  if (!dataStatusLoaded) {
    dataStatusLoaded = true;

    // Obtemos la lista de los estados de los portales de integración asociados a un proceso
    const statusList = await getPortalIntegrationStatusList();

    // Verificar al menos si un estado tiene el valor 'running'
    const isRunning = statusList.some(element => element.dataset.portalIntegrationStatusNotify === 'running');

    if (isRunning) {
      intervalWorkStatus = setInterval(() => {
        activateWorkerStatus();
        clearIntervalWorkStatus();
      }, 500);
    }
  }

  /**
   * Obtiene la lista de elementos con el atributo 'data-portal-integration-status-notify'
   * @returns {Promise<Array>} Promesa que se resuelve con la lista de elementos
   */
  function getPortalIntegrationStatusList() {
    return new Promise(resolve => {
      setTimeout(() => {
        const statusList = document.querySelectorAll('[data-portal-integration-status-notify]');
        resolve(Array.from(statusList));
      }, 0);
    });
  }

  /**
   * Activar worker status
  */
  function activateWorkerStatus() {
    $(document).trigger('worker-status-check');
  }

  /**
   * Limpiar intervalo del worker_status
   * cuando se obtenga una sincronización de postulantes exitosa
  */
  function clearIntervalWorkStatus() {
    const notifySuccess = document.querySelector('.noty_type__success');
    const notifyError = document.querySelector('.noty_type__error');
    const titleClass = document.querySelector('.noty_content__title h4');
    const textInclude = titleClass && titleClass.textContent.includes('Sincronizando Postulantes');
    if ((notifySuccess || notifyError) && titleClass && textInclude) {
      clearInterval(intervalWorkStatus);
    }
  }

});
