/** @file TODO: documentar */
/*
 * Mantiene la consistencia del checkbox global de las data tables. cambiando su estado cuando se re-dibuja
 * la tabla
 *
 * Cada vez que se selecciona un checkbox, envía el evento `checkboxCount`
 * con la cantidad de elementos seleccionados en toda la tabla
 *
 */
import { debounce } from 'lodash-es';
import { countCheckbox } from "./multi-select-common.js";
import onmount from 'onmount';

onmount('input[type=checkbox][data-select-all]', async function () {
  const $selectAll = $(this);
  const $table = findTable($selectAll);
  await $table.waitDataTable();

  const $visibleSelectAll = visibleSelectAll($table);

  $table.on('change', 'tbody input[type=checkbox]', debounce((e) => {
    toggleCheckboxPerPage();
    toggleRowStyle($(e.target));
    countCheckbox($table);
  }, 5));

  $table.on('draw.dt', toggleCheckboxPerPage);

  $visibleSelectAll.on('change', function () {
    const inputs = getInputs(false);
    inputs.prop('checked', this.checked).trigger('change');
    inputs.each((_, input) => toggleRowStyle($(input)));
    countCheckbox($table);
  });

  function toggleCheckboxPerPage() {
    const inputs = getInputs(false).length;
    const checkedInputs = getInputs(true).length;
    const allChecked = inputs == checkedInputs;
    $visibleSelectAll.prop('checked', allChecked);
  }

  function getInputs(checked) {
    return $table.find(`tbody input[type=checkbox]${checked ? ':checked' : ''}`, $table.data('DataTable').rows({ page: 'current' }).nodes());
  }

  function toggleRowStyle(checkbox) {
    if(checkbox.is(":checked")) {
      checkbox.closest('tr').addClass('selected info');
    }
    else {
      checkbox.closest('tr').removeClass('selected info');
    }
  }

  /*
  * Cuando dataTable tiene extenciones que afectan la estructura de la tabla
  * (como columnas fijas), se generan más de un header (donde esta el checkbox select-all)
  * por lo que los inputs no encuentran el checkbox select-all visible.
  *
  * En este caso se le pasa el id por data-select-all-checkbox en la tabla
  *
  */
  function visibleSelectAll(table) {
    const target = table.data('select-all-checkbox');
    if (target) {
      return $(target);
    }
    return $selectAll;
  }

  function findTable(checkbox) {
    if (checkbox.parents('.dataTables_scroll').length) {
      return checkbox.closest('table[id]');
    }
    return checkbox.closest('table');
  }
});
