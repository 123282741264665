/** @file TODO: documentar */
import { toCode } from '../lib/string.js';

$(document).on('turbolinks:load', function () {

  function mostrar(element, required) {
    $(element).attr('required', required);
    $(element).prop('disabled', false);
    $(element).closest('div').show();
  }

  function esconder(element) {
    $(element).attr('required', false);
    $(element).prop('disabled', true);
    $(element).closest('div').hide();
  }

  function nombreVisible() {
    esconder('[id^="bono_name_"]');
    if ($('#bono_rebaja').val() === 'Especial') {
      esconder('#bono_proporcional_ausencia');
      mostrar('#bonos_especiales', true);
    }
    else {
      mostrar('#bono_name_general', true);
      esconder('#bonos_especiales');
    }
  }

  function monedaToggle(rebaja) {
    var amount = $('#bono_amount').closest('div');
    if (rebaja === 'Porcentaje Sueldo Base') {
      esconder('#bono_moneda');
      amount.addClass('col-sm-6').removeClass('col-sm-4');
    }
    else {
      amount.addClass('col-sm-4').removeClass('col-sm-5');
      mostrar('#bono_moneda');
    }
  }

  function handleNameChange() {
    $('#bono_code').val(toCode($('#bono_name_general').val()));
  }

  function toggleBasesBono() {
    const esBonoImponible = $('#bono_imponible').val() === 'true';
    const noImponibleTributable = $('#bono_no_imponible_tributable').prop('checked');
    const isFFRetencionProyectada = $('#proyectable_retencion_div').data('ff');

    if (esBonoImponible) {
      esconder('#bono_no_imponible_tributable');
    }
    else {
      mostrar('#bono_no_imponible_tributable');
    }
    // Alternar visibilidad de 'proyectable_retencion_div'
    if (isFFRetencionProyectada && (!esBonoImponible && noImponibleTributable ||  esBonoImponible)) {
      mostrar('#proyectable_retencion_div');
    }
    else {
      esconder('#proyectable_retencion_div');
    }
    // Desmarcar 'bono_proyectable_retencion' independientemente del estado
    $('#bono_proyectable_retencion').prop('checked', false);
  }

  $('#bono_imponible').change(function () {
    toggleBasesBono();
  });

  if (!$('#bono_code').is('[disabled=disabled]')) {
    $('#bono_name_general').keyup(handleNameChange);
  }

  $('#bono_rebaja').change(function () {
    esconder('#bono_proporcional_ausencia');
    $('#label_include_on_base_horas_no_trabajadas').addClass('display-none');
    // Porcentaje sueldo base seleccionado
    if ($('#bono_rebaja').val() === 'Porcentaje Sueldo Base') {
      $('#bono_amount').attr('step', '0.01');
      $('#label_bono_amount').text('Porcentaje (%)');
    }
    // Otra rebaja seleccionada
    else {
      $('#bono_amount').attr('step', '0.00001');
      $('#label_bono_amount').text('Monto');
      // Rebaja proporcional
      if ($('#bono_rebaja').val() === 'Proporcional') {
        mostrar('#bono_proporcional_ausencia', false);
        $('#bono_proporcional_ausencia').prop('checked', true);
      }

      if ($('#bono_rebaja').val() === 'Proporcional Ausencia') {
        $('#label_include_on_base_horas_no_trabajadas').removeClass('display-none');
      }
    }
    nombreVisible();
    toggleBasesBono();
    monedaToggle($('#bono_rebaja').val());
  });

  $('#asignacion').change(function () {
    if ($('#asignacion').val() === 'Personalizada') {
      $('#asignar').show();
    }
    else {
      $('#asignar').hide();
      $('div[data-division-id]').hide();
    }
  });

  $('#bono_gratificacion').change(function (event) {
    const { checked } = event.target;
    const imponible = $('#bono_imponible').val();

    if (imponible === 'true') {
      if (checked) {
        esconder('#bono_imponible_no_tributable');
        $('#bono_imponible_no_tributable').prop('checked', false);
      }
      else {
        mostrar('#bono_imponible_no_tributable');
      }
    }
  });

  //  desmarcar checkbox bono_proyectable_retencion en funcion al cambio del checkbox bono_no_imponible_tributable
  $('#bono_no_imponible_tributable').change(function (event) {
    const isImponibleFalse = $('#bono_imponible').val() === 'false';
    const isCheckboxChecked = event.target.checked;

    if (isImponibleFalse && isCheckboxChecked) {
      return;
    }

    $('#bono_proyectable_retencion').prop('checked', false);
  });

  function showComplementariaCheckbox() {
    var selectValue = $('#bono_tipo_remuneracion').val();

    if (selectValue === 'remuneracion_variable_complementaria') {
      $('#complementaria_checkbox_container').show();
    }
    else {
      $('#complementaria_checkbox_container').hide();
    }
  }

  showComplementariaCheckbox();

  $('#bono_tipo_remuneracion').change(function () {
    showComplementariaCheckbox();
  });
});
