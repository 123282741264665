/** @file Comportamientos del index de electronic payroll multipais **/

import onmount, { $ } from 'onmount';
// Se crea un interval que valida cada 5 segundos, con un maximo de 3 oportunidades que ejecuta la funcion envioFinished
onmount('#nomina-electronica-employees-nota_individual-table', function () {
  const intervalID = setInterval(envioFinished, 5000);
  setTimeout(()=> clearInterval(intervalID), 15000);
});

// Valida si existe la barra de progreso del job y si es afirmativo, se refresca la pagina al pasar 10 segundos.
function envioFinished() {
  const workerInProgress = $('#noty_layout__topRight').find('h4');
  if($(workerInProgress[0]).text().trim() === 'Enviando Nómina Electrónica') {
    setTimeout(() => location.reload(), 10000);
  }
}
