/** @file
 * Gives a box component the collapsable attribute and behaviour.
 */

/**
 * @param  {HTMLElement} objectBox
 */
export function setCollapsableAttributesToBox(objectBox) {
  if (objectBox === undefined) { return; }
  objectBox.addClass('collapsed-box');
  $(objectBox.children()[0]).attr('data-widget', 'collapse');
  $(objectBox.children()[0]).addClass('box-header-collapsed  ');
  addCollapseArrowToBox(objectBox);
}

/**
 * @param  {HTMLElement} objectBox
 */
export function collapseObjectBox(objectBox) {
  $(objectBox.children()[0]).on('click', function () {
    if (objectBox.hasClass('collapsed-box')) {
      changeBoxCollapseArrowDirection(objectBox, 'fa-angle-down', 'fa-angle-up');
      $(objectBox.children()[1]).show();
      objectBox.removeClass('collapsed-box');
    }
    else {
      changeBoxCollapseArrowDirection(objectBox, 'fa-angle-up', 'fa-angle-down');
      $(objectBox.children()[1]).hide(400);
      objectBox.addClass('collapsed-box');
    }
  });
}

/**
 * @param  {HTMLElement} objectBox
 */
export function addCollapseArrowToBox(objectBox) {
  const boxToolsDiv = $('<div class="box-tools"></div>');
  const BoxCollapsedDiv = $('<div class="box-collapsed"></div>');
  const collapseArrowList = $('<i class=" fas fa-angle-up " aria-hidden="true"></i>');
  $(BoxCollapsedDiv).append(collapseArrowList);
  $(boxToolsDiv).append(BoxCollapsedDiv);
  $(boxToolsDiv).appendTo($(objectBox.children()[0]));
}

function changeBoxCollapseArrowDirection(objectBox, classToAdd, classToRemove) {
  const boxCollapseArrow = $($($(objectBox.children()[0]).children()[1]).children()[0]).children();
  boxCollapseArrow.removeClass(classToRemove);
  boxCollapseArrow.addClass(classToAdd);
}
