/** @file
* Archivo de marcas de amplitud para modelo de reconocimiento
*/
import onmount from 'onmount';

onmount('#new_talent_recognition', async function () {

  $(document).on('#new_talent_recognition submit', async function () {
    const recognitionSource = $(this).find('#submit_review').data('source');
    const recognitionPublished = $(this);
    const recognitionData = {
      'recognitions_given_source': recognitionSource,
      'recognitions_given_receiver': $("input[name='email_receptor']").val() || 'noEmailRegistered',
    };
    recognitionPublished.trigger('amplitudeTracking', { message: 'recognition_given_sent', data: recognitionData });
  });
});

onmount('.recognitions-sidebar', async function () {
  const recognitionData = {
    'recognition_ID': $(this).data('recognitionId'),
    'recognition_open_details_source': $(this).data('source'),
  };
  $(this).trigger('amplitudeTracking', { message: 'recognition_clicked_open_detail', data: recognitionData });
});

onmount('.recognitions-react-list-item:not(.active)', async function () {
  const recognitionData = {
    'recognition_ID': $('.recognitions-sidebar').data('recognitionId') || $(this).data('recognitionId'),
    'recognition_open_details_source': $('.recognitions-sidebar').data('source') || $(this).data('source'),
    'reaction_type': $(this).find('.list-emoji').data('emoji'),
  };
  $(this).on('click', function () {
    $(this).trigger('amplitudeTracking', { message: 'recognition_clicked_like', data: recognitionData });
  });
});

onmount('.publish-comment-recognition', async function () {
  const recognitionData = {
    'recognition_ID': $('.recognitions-sidebar').data('recognitionId'),
    'recognition_open_details_source': $('.recognitions-sidebar').data('source'),
  };
  $(this).on('submit', function () {
    $(this).trigger('amplitudeTracking', { message: 'recognition_clicked_comment_sent', data: recognitionData });
  });
});
