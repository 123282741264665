/** @file Previsualiza el contenido del ckeditor de Plantilla de Emails y refresca su contenido en base a un template asociado */

import onmount, { $ } from 'onmount';
import { action } from '../lib/buk/alert.js';

/**
 * Previsualiza un correo sin tener que recargar la pagina o mostrarlo en un modal.
 *
 * A partir de un elemento en el HTML con la clase "form_with_ckeditor",
 * y la url de la acción que genera la previsualización del correo en su data "data-preview-url",
 * al hacer click en el radio button con id "#option-preview"
 * envia la información del front hacia la url indicada y remplaza el contenido en un elemento HTML con el id "#body_edit"
 */

onmount('.form_with_ckeditor', async function () {
  const { loadCKeditor, CKeditorVersion } = await import('../components/ckeditor/load');

  if (CKeditorVersion() === '4') await loadCKeditor();

  $('input[name="display-options"]').on('click change', function () {
    $('.ck-display').hide();

    const fields = {};

    if (document.getElementById('option-preview').checked === true) {
      $('#body_preview').show();
      $('#body_preview').html('Generando vista previa...');

      const textArea = $('#body_edit').find('textarea');
      let content;
      if (CKeditorVersion() === '4') {
        content = CKEDITOR.instances[textArea.attr('id')].getData();
      }
      else{
        content = textArea.val();
      }
      const url = textArea.data('preview-url');

      if ($('#letter_fields')) {
        $('#letter_fields [name^="offer_letter"]').each(function () {
          fields[this.name] = this.value;
        });
      }

      if ($('#rule_fields')) {
        $('#rule_fields [name^="rule"]').each(function () {
          fields[this.name] = this.value;
        });
      }
      $.post(
        url,
        {
          body: content.replace(/&quot;|&#39;/g, '"'),
          values: fields,
        },
        result => {
          $('#body_preview').html(result.data);
        }
      );
    }
    else {
      $('#body_edit').show();
    }
  });

  $('.mailing-template-ck-editor').on('change', function () {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    action(
      'Al cambiar la plantilla se perderá el contenido actual del correo, asunto y CC, ¿Continuar?',
      'Aceptar',
      'Cancelar'
    )
      .then(answer => {
        if (answer.value) {
          searchParams.set('mailing_template_id', this.value);
          url.search = searchParams.toString();
          window.location.href = url.toString();
        }
        else {
          $('#mailing_template_id').val(searchParams.get('mailing_template_id'));
        }
      });
  });

  $('.template-ck-editor').on('change', function () {
    action('Al cambiar la plantilla se perderá el contenido actual de la carta, ¿Continuar?', 'Aceptar', 'Cancelar')
      .then(answer => {
        if (answer.value) {
          const url = '/recruiting/offer_letter/templates/' + $(this).val() + '/body';
          const ckEditor = $('#body_edit').find('textarea');
          const ckEditorId = ckEditor.attr('id');

          $.get(url, result => {
            if (CKeditorVersion() === '4') {
              CKEDITOR.instances[ckEditorId].setData(result.data);
            }
            else{
              ckEditor.val(result.data);
              ckEditor.trigger('change');
            }
          });
        }
      });
  });

});
