/** @file TODO: documentar */
import onmount from 'onmount';

onmount('#fullcalendar-view', async function () {
  await import('fullcalendar');
  import('fullcalendar/dist/fullcalendar.css');
  const filterVacacions = $('#filter-vacacions');
  const filterLicences = $('#filter-licences');
  const filterAbsences = $('#filter-absences');
  const filterPermits = $('#filter-permits');

  const calendar = $(this);

  let source = calendar.data('url') + '?vacacions='
    + filterVacacions.is(':checked') + '&licences='
    + filterLicences.is(':checked') + '&absences='
    + filterAbsences.is(':checked') + '&permits='
    + filterPermits.is(':checked');

  const holidaysUrl = calendar.data('holidays-url');
  const eventSources = [{ url: source }];
  if (holidaysUrl !== '') {
    eventSources.push({ url: holidaysUrl });
  }

  calendar.fullCalendar({
    locale: 'es',
    themeSystem: 'bootstrap4',
    defaultDate: calendar.data('startDate'),
    eventSources: eventSources,
    selectable: true,
    selectHelper: true,
    eventLimit: true,
    header: {
      left: 'today prev,next',
      center: 'title',
      right: 'month, basicWeek, basicDay',
    },
    buttonText: {
      today: 'Hoy',
      month: 'mes',
      week: 'semana',
      day: 'día',
    },
    eventLimitText: 'Mostrar más',
    loading: function () {
      calendar.find('.loader').toggle();
    },
    eventRender: function (_event, element) {
      element.attr('href', '#');
    },
  });

  const checkboxFilters = filterVacacions.add(filterLicences).add(filterAbsences).add(filterPermits);

  onmount('#area_ids', function () {
    const filterArea = $('#area_ids');
    checkboxFilters.add(filterArea).on('change', function () {
      const newSource = calendar.data('url') + '?vacacions='
        + filterVacacions.is(':checked') + '&licences='
        + filterLicences.is(':checked') + '&absences='
        + filterAbsences.is(':checked') + '&permits='
        + filterPermits.is(':checked') + '&area_ids=' + filterArea.val();

      checkboxFilters.each(function () {
        $(this)
          .closest('.btn')
          .toggleClass('active', this.checked)
          .attr('aria-pressed', this.checked);
      });

      calendar.fullCalendar('removeEventSource', source);
      calendar.fullCalendar('refetchEvents');
      calendar.fullCalendar('addEventSource', newSource);
      calendar.fullCalendar('refetchEvents');

      source = newSource;
    });
  });
});
