/** @file TODO: documentar */
import onmount from 'onmount';

// Si el usuario se equivoca, esta funciona busca todos los collapse, verifica si tiene valores invalidos, y expande el collapse si es que tiene
onmount('.collapse', function () {
  const $self = $(this);
  var invalids;
  invalids = $self.find('[aria-invalid="true"]').length;
  if(invalids > 0 && !($self.hasClass("in"))) {$self.toggleClass("in");}
});
