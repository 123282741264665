/** @file TODO: documentar */
import onmount from 'onmount';

// método para restablecer el estado de los modales al navegar con Turbolinks
function resetEventsOnNavigation() {
  $('.modal').modal('hide');
  $('.modal-backdrop').remove();
  // quitamos estilos que bloquean el scroll, ya que se volvera a montar al recargar la página
  $('.modal').removeAttr('style');
  $('#toggle-modal-with-vars').removeAttr('style');
}

// levantamos modal automaticamente cuando cargamos página
// util para mostrar errores de validacion con formularios en modal
onmount('.modal[data-modal-show]', function () {
  const modal = $(this);
  if (modal.data('modalShow')) {
    modal.modal({ show: true });
  }
});

onmount('[data-modal]', function () {
  // si el elemento posee data-target, se levanta el modal al hacer click
  if($(this).data('target')) {
    $(this).on('click', function (event) {
      const target = $(this).data('target');
      $(target).modal({ show: true });
      return event.preventDefault();
    });
  }
});

// Escucha el evento 'before-cache' de Turbolinks
// Este evento se dispara justo antes de que Turbolinks guarde la página en caché
// Evita que los modales se queden abiertos al navegar entre páginas con Turbolinks
document.addEventListener('turbolinks:before-cache', () => {
  const modalPresent = document.querySelector('.modal.show');
  if (modalPresent) {
    resetEventsOnNavigation();
  }
});
