/** @file TODO: documentar */
import onmount from 'onmount';
import 'jquery.rut';

// [backspace, delete, tab, escape, K, enter, .]
const ALLOWED_INPUT_KEYS = [46, 8, 9, 27, 13, 75, 110, 190];
// [A, C, V]
const ALLOWED_CTRL_KEYS = [65, 67, 86];

const processKeyEvent = (e) => {
  if ($.inArray(e.keyCode, ALLOWED_INPUT_KEYS) !== -1 ||
  // Allow: Ctrl+[A,C,V], Command+[A,C,V]
  ($.inArray(e.keyCode, ALLOWED_CTRL_KEYS !== -1) && (e.ctrlKey === true || e.metaKey === true)) ||
  // Allow: home, end, left, right, down, up
  (e.keyCode >= 35 && e.keyCode <= 40)) {
    // let it happen, don't do anything
    return;
  }
  // Ensure that it is a number and stop the keypress
  if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
    e.preventDefault();
  }
};

onmount('[data-rut]', function () {
  $(this).rut({ formatOn: 'keyup', validateOn: 'keyup' })
    .on('rutInvalido', function () { $(this).parents('.form-group').addClass('has-error');})
    .on('rutValido', function () { $(this).parents('.form-group').removeClass('has-error');})
    .keydown(processKeyEvent);
});

onmount('[data-rut-only-format]', function () {
  $(this).rut({ formatOn: 'keyup' })
    .keydown(processKeyEvent);
});
