/** @file TODO: documentar */
import onmount from 'onmount';
import { error } from '../../../../../../../../app/assets/javascripts/lib/buk/notice';

onmount('#horas_no_trabajadas_form', function () {
  const $container = $(this);
  const $modal = $container.closest('.modal');
  const $form = $container.find('form');

  function updateLiquidacion(liquidacion) {
    const $htnLiq = $('#hnt_' + liquidacion.id);
    $htnLiq.html(liquidacion.horas_no_trabajadas);
  }
  $form.on('ajax:beforeSend', function () {
    $container.find('> .row:first').remove();
  });
  $form.on('ajax:success', function (event) {
    const liquidacion = event.detail[0];
    updateLiquidacion(liquidacion);
    $modal.modal('hide');
  });
  $form.on('ajax:error', function (event) {
    let errorMsg = 'Ha ocurrido un error.';
    const request = event.detail[0];
    if(request.error_msg) {
      errorMsg = request.error_msg;
    }
    error(errorMsg, $container);
  });
});

onmount('#horas_sobretiempos_form', function () {
  const $container = $(this);
  const $modal = $container.closest('.modal');
  const $form = $container.find('form');

  function updateLiquidacion(liquidacion) {
    const $heLiq = $(liquidacion.category + '_' + liquidacion.id);
    $heLiq.html(liquidacion[liquidacion['column']]);
  }
  $form.on('ajax:beforeSend', function () {
    $container.find('> .row:first').remove();
  });
  $form.on('ajax:success', function (event) {
    const liquidacion = event.detail[0];
    updateLiquidacion(liquidacion);
    $modal.modal('hide');
  });
  $form.on('ajax:error', function (event) {
    const request = event.detail[0];
    let errorMsg = 'Ha ocurrido un error.';
    if(request.error_msg) {
      errorMsg = request.error_msg;
    }
    error(errorMsg, $container);
  });
});
