/** @file TODO: documentar */
import onmount from 'onmount';

onmount('textarea[data-autosize]', async function () {
  const { default: autosize } = await import('autosize');
  autosize(this);
}, async function () {
  // autosize guarda cosas asi que para evitar leaks
  const { default: autosize } = await import('autosize');
  autosize.destroy(this);
});
