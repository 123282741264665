/** @file
 * Manejo de tooltips para formularios anidados con Cocoon
 */
import onmount from 'onmount';

onmount('.nested-fields', function () {
  const $this = $(this);

  $this.on('cocoon:before-remove', function (_e, field) {
    $(field).find('[data-toggle="tooltip"]').tooltip('dispose');
  });

  $this.on('cocoon:after-remove', function () {
    $('[data-toggle="tooltip"]').tooltip();
  });
});
